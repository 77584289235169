import {IDomain, IDomainNewHost, INewDsRecord} from '../types/domain';
import {IViewerContentType} from '../../../Common/Navigation/CardWithViewer/Type/IViewerContentType';
import {ContactContentViewer, RegistrantContentViewer} from '../../../Contacts/Detail/ContactVisualizer';
import * as React from 'react';
import i18n from 'i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGlobe, faServer, faShieldAlt, faUser, faUsersCog, faUserTie} from '@fortawesome/free-solid-svg-icons';
import {PaddedDiv} from '../../../Common/Styled/Detail';
import DomainBaseInfo from '../Info/DomainBaseInfo';
import {ContactList} from '../../../Contacts/Detail/ContactList';
import {Badge} from 'antd';
import {NameServer} from '../Info/NameServerTable/NameServer';
import {DSRecord} from "../Info/DSRecordTable/DSRecord";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

// TODO Rendere la funzione dinamica e non contringerla a prender il proprietà


export const buildContent = (props: IDomain) => {
    const contents: IViewerContentType[] = [];

    Object.keys(props).forEach((key: string, index: number) => {

        contents[0] = {
            component: <DomainBaseInfo {...props} />,
            label: <><FontAwesomeIcon icon={faGlobe}/> {i18n.t('tab_domain')}</>,
        };

        if (props.registrant) {
            contents[1] = {
                component: <RegistrantContentViewer {...props.registrant}/>,
                label: <><FontAwesomeIcon icon={faUserTie}/> {i18n.t('tab_registrant')}</>,
            };
        }

        if (props.admin) {
            contents[2] = {
                component: <ContactContentViewer {...props.admin}/>,
                label: <><FontAwesomeIcon icon={faUser}/> {i18n.t('tab_admin')}</>,
            };
        }

        if (props.techs && props.techs.length > 0) {
            contents[4] = {
                component: <ContactList contacts={props.techs}/>,
                label: <><FontAwesomeIcon icon={faUsersCog}/> {i18n.t('tab_techs')}</>,
            };
        }


        if ((props.hosts && props.hosts.length > 0) || (props.newHosts && props.newHosts.length > 0)) {
            contents[5] = {
                component: <PaddedDiv><NameServer hosts={props.hosts} newHosts={props.newHosts}/></PaddedDiv>,
                label: <HostsLabel newHosts={props.newHosts}/>,
            };
        }


        if ((props.dsRecords && props.dsRecords.length > 0) || (props.newDsRecords && props.newDsRecords.length > 0)) {
            contents[6] = {
                component: <PaddedDiv>
                    <DSRecord dsRecords={props.dsRecords} newDsRecord={props.newDsRecords}/>
                </PaddedDiv>,
                label: <DSRecordsLabel newDsRecords={props.newDsRecords}/>
            }
            ;
        }

    });
    return contents;
};


function BadgedLabel(props: { icon: IconProp, count: number, label: string }) {
    if (props.count > 0) {
        return (
            <><FontAwesomeIcon icon={props.icon}/> <Badge
              style={{backgroundColor: 'orange'}}
              count={props.count}
                offset={[10, -4]}
            >
                {props.label}
            </Badge>
            </>
        );
    } else {
        return (
            <><FontAwesomeIcon icon={props.icon}/> {props.label}</>
        );
    }
}

const DSRecordsLabel = (props: { newDsRecords?: INewDsRecord[] }) =>
    (
        <BadgedLabel
            count={props.newDsRecords && props.newDsRecords.filter((r)=>r.operation ==='add').length || 0}
            icon={faShieldAlt}
            label={i18n.t('tab_dsRecord')}/>
    )

const HostsLabel = (props: { newHosts?: IDomainNewHost[] }) =>
    (
        <BadgedLabel
            count={props.newHosts && props.newHosts.filter((r)=>r.type ==='add').length || 0}
            icon={faServer}
            label={i18n.t('tab_nameServers')}/>
    )

