import * as React from 'react';
import { AxiosError, AxiosPromise } from 'axios';
import { apiPostFax, apiPostProtocol } from '../../const/api-calls';
import { printAxiosFetchError } from '../Common/Navigation/FetchController/FetchController';
import { GenericDocument, IFax, IProtocol } from './documents';

export function useAddDocument(documentValue?: GenericDocument) {
  const [document, setDocument] = React.useState<GenericDocument | undefined>(
    documentValue,
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [responseStatus, setResponseStatus] = React.useState<
    number | undefined
  >(undefined);
  const [responseMessage, setResponseMessage] = React.useState('');

  function apiCall(doc: GenericDocument): AxiosPromise {
    if (doc.documentType === 'fax') {
      return apiPostFax(doc as IFax);
    } else {
      return apiPostProtocol(doc as IProtocol);
    }
  }

  const saveDocument = (doc: GenericDocument) =>
    new Promise<GenericDocument>((resolve, reject) => {
      setDocument(doc);
      setResponseMessage('');
      setLoading(true);
      apiCall(doc)
        .then(response => {
          console.debug(' response', JSON.stringify(response));
          setResponseStatus(response.status);
          setDocument(response.data);
          setLoading(false);
          resolve(response.data);
        })
        .catch((e: AxiosError) => {
          console.debug(
            ' JSON.stringify(e.response',
            JSON.stringify(e.response),
          );
          const status = e.response && e.response.status;
          setResponseStatus(status);
          if (e.response && e.response.data.errors) {
            setResponseMessage(
              e.response.data.errors.map((obj: any, index: number) =>
                index > 0 ? `; ${obj.message}` : obj.message,
              ),
            );
            reject(
              e.response.data.errors.map((obj: any, index: number) =>
                index > 0 ? `; ${obj.message}` : obj.message,
              ),
            );
          } else if (e.response && e.response.data.message) {
            setResponseMessage(e.response.data.message);
            reject(e.response.data.message);
          } else {
            setResponseMessage(printAxiosFetchError(e));
            reject(printAxiosFetchError(e));
          }
          setLoading(false);
        });
    });

  return { document, responseMessage, responseStatus, saveDocument, loading };
}

// async function fetchAsync() {
//   // await response of fetch call
//   let response = await fetch('<https://api.github.com>');
//   // only proceed once promise is resolved
//   let data = await response.json();
//   // only proceed once second promise is resolved
//   return data;
// }
//
// fetchAsync()
//   // log response or catch error of fetch promise
//   .then(data => console.log(data))
//   .catch(reason => console.log('Message:' + reason.message));
