import * as React from "react";
import {SelectProps} from "antd/lib/select";
import {FormComponentProps} from "antd/lib/form/Form";
import {Select} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIndustry, faLandmark, faToriiGate, faUserTie, faWarehouse} from "@fortawesome/free-solid-svg-icons";
import {faStoreAlt} from "@fortawesome/free-solid-svg-icons/faStoreAlt";
import {faSchool} from "@fortawesome/free-solid-svg-icons/faSchool";




const SelectEntityType = React.forwardRef((props:  FormComponentProps & SelectProps<{ disabled: boolean }>, ref) => {

    return (
        <Select  allowClear={true} placeholder={'Seleziona il tipo di registrante'}
                disabled={props.disabled} {...props}>
            <Select.Option value={1} >
                <FontAwesomeIcon icon={faUserTie}
                                 style={{color: 'rgba(0,0,0,.25)'}}/> Persone (1)</Select.Option>
            <Select.Option value={2}>
                <FontAwesomeIcon icon={faIndustry}
                                 style={{color: 'rgba(0,0,0,.25)'}}/> Società (2)</Select.Option>
            <Select.Option value={3}>
                <FontAwesomeIcon icon={faLandmark}
                                 style={{color: 'rgba(0,0,0,.25)'}}/> Profit (3)</Select.Option>
            <Select.Option value={4}>
                <FontAwesomeIcon icon={faStoreAlt}
                                 style={{color: 'rgba(0,0,0,.25)'}}/> No profit (4)</Select.Option>
            <Select.Option value={5}>
                <FontAwesomeIcon icon={faSchool}
                                 style={{color: 'rgba(0,0,0,.25)'}}/> Enti pubblici
                (5)</Select.Option>
            <Select.Option value={6}>
                <FontAwesomeIcon icon={faWarehouse}
                                 style={{color: 'rgba(0,0,0,.25)'}}/> Altri (6)</Select.Option>
            <Select.Option value={7}>
                <FontAwesomeIcon icon={faToriiGate}
                                 style={{color: 'rgba(0,0,0,.25)'}}/> Sog. stranieri
                (7)</Select.Option>
        </Select>
    );
})

export default SelectEntityType;
