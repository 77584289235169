import {Icon, Spin} from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import {SpinProps} from "antd/lib/spin";

const Loader = styled.div`
  position: fixed;
  z-index: 1031;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;


const antIcon = <Icon type="loading" style={{fontSize: 24}} spin/>;

const Spinner = (props: SpinProps) => <Spin indicator={antIcon} {...props} />;

/**
 * Spinner di caricamento centrabile nella pagina
 * @param pageCentered
 * @constructor
 */
const LoadingSpinner: React.FC<{ pageCentered?: boolean }> = ({pageCentered}) => (
    pageCentered ?
        <Loader>
            <Spinner tip="Loading.."/>
        </Loader>
        :
        <div style={{textAlign: 'center'}}><Spinner/></div>
);

export default LoadingSpinner;
