import * as React from "react";
import {GenericDocument} from "../documents";
import {FormComponentProps} from "antd/lib/form/Form";
import {Col, DatePicker, Form, InputNumber, Row} from "antd";

export const CommonDocumentForm: React.FC<{ items?: GenericDocument } & FormComponentProps> = (props) => {
    const {getFieldDecorator} = props.form;
    return <>
        <Row gutter={16}>
            <Col span={12}><Form.Item label="Data arrivo">
                {getFieldDecorator('dateArrive', {
                    rules: [
                        {
                            required: true,
                            message: 'Please input a date!',
                        },
                    ],
                })(<DatePicker showToday showTime placeholder="Select Time"/>)}
            </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Numero pagine">
                    {getFieldDecorator('numpag', {
                        rules: [
                            // {
                            //     type: 'number',
                            //     message: 'The input is not valid numPage!',
                            // },
                            {
                                required: true,
                                message: 'Please input a numPage!',
                            },
                        ],
                    })(<InputNumber style={{width: '150px'}}/>)}
                </Form.Item>
            </Col>
        </Row>
    </>
}