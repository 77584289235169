/***
 * Funzione per il recupero degli eventi legati al dominio relativi alla query passata.
 *
 * @param dispatch
 * @param domainEvents - caricati nello store
 * @param domainId - id del dominio
 * @param keycloak
 * @param location - attuale
 */
import {
  apiGetDomainAvailableEvents,
  apiGetEventsRequirements,
} from '../../../../const/api-calls';
import { IFetchStateType } from '../../../Type/IFetchtStateType';
import { IAvailableEvents } from '../types/domain';
import { message } from 'antd';

export function fetchDomainAvailableEvents(
  dispatch: any,
  domainAvailableEvents: IFetchStateType<IAvailableEvents>,
  domainId: string,
  location: any,
) {
  const resultQuery = `${location.pathname}${location.search}`;

  console.debug('resultQuery ', resultQuery);
  console.debug('domainEvents.query ', domainAvailableEvents.query);
  if (resultQuery === '') {
    dispatch({ type: 'DOMAIN_AVAILABLE_EVENTS_FETCH_CLEAN' });
  } else {
    // Se query memorizzato è diverso da quello cercato e non ci sono errori allora fetchDomains
    if (resultQuery.localeCompare(domainAvailableEvents.query) !== 0) {
      dispatch({
        type: 'DOMAIN_AVAILABLE_EVENTS_FETCH_LOAD',
        query: resultQuery,
      });

      apiGetDomainAvailableEvents(domainId)
        .then((res: any) => {
          dispatch({
            type: 'DOMAIN_AVAILABLE_EVENTS_FETCH_SUCCESS',
            query: resultQuery,
            payload: res,
          });
        })
        .catch((error: any | PromiseLike<IFetchStateType<[]>>) => {
          dispatch({ type: 'DOMAIN_AVAILABLE_EVENTS_FETCH_ERROR', error });
        });
    }
  }
}

export function fetchEventsRequirements(dispatch: any) {
  const resultQuery = `${location.pathname}${location.search}`;
  if (resultQuery === '') {
    dispatch({ type: 'EVENTS_REQUIREMENTS_FETCH_CLEAN' });
  } else {
    // Se query memorizzato è diverso da quello cercato e non ci sono errori allora fetchDomains

    apiGetEventsRequirements()
      .then((res: any) => {
        dispatch({
          type: 'EVENTS_REQUIREMENTS_FETCH_SUCCESS',
          query: resultQuery,
          payload: res,
        });
      })
      .catch((error: any | PromiseLike<IFetchStateType<[]>>) => {
        dispatch({ type: 'EVENTS_REQUIREMENTS_FETCH_ERROR', error });
        message.error(
          "Attenzione, è stato riscontrato un errore. Il caricamento della impostazioni di configurazione degli eventi eseguibili dall'operatore non è avvenuto correttamente. Si prega di segnalare il problema. ",
          20,
        );
      });
  }
}
