import * as React from 'react';
import { DispatchContext } from '../../../StoreProvider';
import { AuthContext } from '../../../Common/Auth/AuthProvider';
import { IRegistryEvent } from '../types/domainEvents';
import { Modal } from 'antd';
import { isEmpty } from 'lodash';
import { AxiosControllerComponent } from '../../../Common/Navigation/FetchController/FetchController';
import { apiPostDomainRegistryEvent } from '../../../../const/api-calls';
import { reloadDomain } from '../../Domain/operations';
import { useRouter } from '../../../Common/Hooks/useRouter';
import { IEventRequirement, IDomain } from '../../Domain/types/domain';
import { AvailableEventResult } from './AvailableEventResult';
import { ModalAvailableEventBody } from './ModalAvailableEventBody';
import { ModalAvailableTitle } from './ModalAvailableTitle';
import { ModalAvailableEventBodyAccept } from './ModalAvailableEventBodyAccept';
import { ModalAvailableEventBodyReject } from './ModalAvailableEventBodyReject';

const { confirm } = Modal;

export interface IModalAvailableEvent {
  domain: IDomain;
  eventName: string;
  availableEvent: IEventRequirement;
  visible: boolean;

  onOk(): void;

  onCancel(): void;
}

export const ModalAvailableEvent: React.FC<IModalAvailableEvent> = props => {
  const { keycloak } = React.useContext(AuthContext);
  const dispatch = React.useContext(DispatchContext);
  const { history, location } = useRouter();
  const { tokenParsed } = keycloak;

  const [reload, setReload] = React.useState(false);
  const [operationType, setOperationType] = React.useState<
    'accept' | 'reject' | undefined
  >(undefined);
  const [operationValues, setOperationValues] = React.useState<
    IRegistryEvent | any
  >({});

  React.useEffect(() => {
    setOperationValues({});
    setReload(false);
  }, [props.eventName]);

  function showConfirm(values: IRegistryEvent) {
    confirm({
      title: "Procedere nell'operazione " + values.name + '?',
      // content: 'Some descriptions',
      onOk() {
        setOperationValues(values);
        setReload(true);
        dispatch({ type: 'DOMAIN_AVAILABLE_EVENTS_FETCH_CLEAN' });
      },
      onCancel() {
        console.debug('Cancel');
      },
    });
  }

  const handleSubmit = (err: any, values: IRegistryEvent) => {
    if (!err) {
      showConfirm(values);
    }
  };

  const handleAfterClose = () => {
    if (reload) {
      reloadDomain(dispatch, history, location);
    }
  };

  const handleOnClose = () => {
    setOperationValues({});
    setOperationType(undefined);
  };

  console.log("ModalAvailableEvent",JSON.stringify(props.domain))


  const hasToShowAcceptButton =
    props.availableEvent?.statuses?.includes('succeeded') || false;
  const hasToShowRejectButton =
    props.availableEvent?.statuses?.includes('failed') || false;
  return (
    <Modal
      title={<ModalAvailableTitle eventName={props.eventName} />}
      closable={false}
      visible={props.visible}
      onCancel={() => {
        handleOnClose();
        props.onCancel();
      }}
      afterClose={handleAfterClose}
      okButtonProps={{ hidden: true }}
      cancelText={'Chiudi'}
      centered
      destroyOnClose={true}
    >
      {(() => {
        if (isEmpty(operationValues)) {
          switch (operationType) {
            case 'accept':
              return (
                <ModalAvailableEventBodyAccept
                  eventName={props.eventName}
                  availableEvent={props.availableEvent}
                  userId={tokenParsed.userId}
                  onSubmit={handleSubmit}
                />
              );
            //
            // return <>
            //   {title}
            //   <WrappedEventCreationForm
            //     onSubmit={handleSubmit}
            //     value={{ name: props.eventName, status: "succeeded", userId: tokenParsed.userId }} />
            // </>;

            case 'reject':
              return <ModalAvailableEventBodyReject />;

            default:
              return (
                <div style={{ textAlign: 'center' }}>
                  <ModalAvailableEventBody
                    showAcceptButton={hasToShowAcceptButton}
                    showRejectButton={hasToShowRejectButton}
                    eventName={props.eventName}
                    onClickReject={() => setOperationType('reject')}
                    onClickAccept={() => setOperationType('accept')}
                  />
                </div>
              );
          }
        } else {
          return (
            <AxiosControllerComponent
              call={apiPostDomainRegistryEvent(
                props.domain.id.toString(),
                operationValues,
                props.domain.version,
              )}
            >
              {response => <AvailableEventResult {...response} />}
            </AxiosControllerComponent>
          );
        }
      })()}
    </Modal>
  );
};
