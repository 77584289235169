import * as React from "react";
import {IDomainEvent, IEppEvent, IEppEventRequest, IEppEventResponse, IRegistryEvent} from "./types/domainEvents";
import i18n from "i18next";
import {Alert, Divider, Popover, Typography} from "antd";
import {capitalizeFirstLetter, moment} from "../../../utils/various";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faUser} from "@fortawesome/free-solid-svg-icons";
import {IUser, User} from "../../Users/User";
import eppCodeTranlsation from "../../../const/eppCodeTranlsation";
import {findIndex} from "lodash";

import {Document} from "../../Documents/Document";

const {Text} = Typography;


export const EppRequest: React.FC<{ event: IEppEventRequest }> = props => {
    return <div>
        <div><h3>{i18n.t('event_eppRequest')}</h3></div>
        {props.event.command &&
        <div style={{paddingLeft: '5px'}}><strong>{i18n.t('event_command')}</strong>:
            <Typography.Text code>{props.event.command}</Typography.Text></div>}
        {props.event.created &&
        <div style={{paddingLeft: '5px'}}>
            <strong>{i18n.t('event_created')}</strong>: {moment(props.event.created).format('D MMMM YYYY, h:mm:ss')}
        </div>}
        {props.event.clientTrId &&
        <div style={{paddingLeft: '5px'}}><strong>{i18n.t('event_clientTrId')}</strong>: <Text copyable
                                                                                               type={'secondary'}>
            {props.event.clientTrId}</Text>
        </div>}
    </div>;

};

const EppResponse: React.FC<{ event?: IEppEventResponse }> = props => {
    return <div>
        <div><h3>{i18n.t('event_eppResponse')}</h3></div>
        {props.event &&
        <>{props.event.code &&
        <div style={{paddingLeft: '5px'}}><strong>{i18n.t('event_code')}</strong>: <Text
            type="secondary">{props.event.code}</Text></div>}
            {props.event.codeName &&
            <div style={{paddingLeft: '5px'}}><strong>{i18n.t('event_codeName')}</strong>: {props.event.codeName}</div>}
            {props.event.reason &&
            <div style={{paddingLeft: '5px'}}><strong>{i18n.t('event_reason')}</strong>: <Text
                type={'secondary'}> {props.event.reason} </Text></div>}
            {props.event.reasonName &&
            <div style={{paddingLeft: '5px'}}><strong>{i18n.t('event_reasonName')}</strong>: {props.event.reasonName}
            </div>}
            {props.event.reasonMessage &&
            <div style={{paddingLeft: '5px'}}>
                <strong>{i18n.t('event_reasonMassage')}</strong>: {props.event.reasonMessage}
            </div>}
            {props.event.created &&
            <div style={{paddingLeft: '5px'}}>
                <strong>{i18n.t('event_created')}</strong>: {moment(props.event.created).format('D MMMM YYYY, h:mm:ss')}
            </div>}
            {props.event.serverTrId &&
            <div style={{paddingLeft: '5px'}}><strong>{i18n.t('event_serverTrId')}</strong>: <Text
                copyable type={'secondary'}>{props.event.serverTrId}</Text></div>}
        </>}
        {!props.event &&
        <Alert type={'warning'} message={'Dettaglio non presente'} description={'Probabile fallimento del comando EPP.'}/>}
    </div>;
};


export const DomainEvent: React.FC<{ event: IDomainEvent }> = ({event}) => {
    const {registry} = event;
    return (
        <div>
            <div>
                <h3>{'Creato evento Registry'}</h3>
            </div>
            {
                <>
                    <div style={{paddingLeft: '5px'}}>
                        <strong>{'Nome'}</strong>: {event.name}
                    </div>
                    <div style={{paddingLeft: '5px'}}>
                        <strong>{'Status'}</strong>: {event.status}
                    </div>
                    <div style={{paddingLeft: '5px'}}>
                        <strong>{'Creato'}</strong>: {moment(event.created).format('D MMMM YYYY, h:mm:ss')}
                    </div>
                </>
            }
            {registry &&
            <>
                {
                    registry.userId && <div style={{paddingLeft: '5px'}}>
                        <FontAwesomeIcon icon={faUser}/><strong> {i18n.t('event_user')}</strong>:
                        <User userId={registry.userId}>
                            {(user: IUser) => user.name}
                        </User>
                    </div>
                }
                {registry.documentId &&
                <div style={{paddingLeft: '5px'}}>
                    <strong>{i18n.t('event_documentId')}</strong>: {registry.documentId}
                </div>}
                {
                    registry.description &&
                    <div style={{paddingLeft: '5px'}}>
                        <strong>{i18n.t('event_description')}</strong>: {registry.description}
                    </div>
                }
            </>
            }
        </div>
    );
};


export function RegistryDescriptionContent(props: { data: IRegistryEvent }) {
    const {data} = props;

    if (data) {
        return <div
            style={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <div>{
                data.description && capitalizeFirstLetter(data.description)
            }</div>
            {
                data.documentId && <div
                    style={
                        {
                            borderLeft: '1px solid #E8E8E8',
                            marginLeft: 10,
                            paddingLeft: 10,
                            height: '100%',
                            fontSize: '16px',
                        }}>
                    <Document documentId={data.documentId}/>
                </div>
            }
        </div>;
    }

    return null;
}

export function EppDescriptionContent(props: { data: IEppEvent }) {
    const {data} = props;

    if (data) {
        let hintContent;

        hintContent = (
            <div>
                <EppRequest event={data.request}/>
                <Divider/>
                <EppResponse event={data.response}/>
            </div>
        );


        return <div
            style={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>

            <div>
                {
                    data.response &&
                    <EppCode code={data.response.code}/>
                }
            </div>
            <div
                style={
                    {
                        borderLeft: '1px solid #E8E8E8',
                        marginLeft: 10,
                        paddingLeft: 10,
                        height: '100%',
                        fontSize: '16px',
                    }}>
                <Popover content={hintContent}
                         title={<><FontAwesomeIcon icon={faInfoCircle}/> Dettaglio evento</>}
                >
                    <FontAwesomeIcon style={{cursor: 'pointer', color: data.response?'#40A9FF':'orange'}} icon={faInfoCircle}/>
                </Popover>
            </div>
        </div>;
    }

    return null;
}


/***
 * Stampa valore e titolo  corrispondenti al codice EPP passato per parametro
 * @param props
 * @constructor
 */
function EppCode(props: { code?: number }) {
    console.debug("EppCode props.code ", props.code)
    if (props.code) {
        const codeIndex = findIndex(eppCodeTranlsation, (o: { code: number, title: string }) => o.code === props.code);
        return <><strong>{props.code}</strong> - {eppCodeTranlsation[codeIndex]['title']}</>
    }
    return <></>
}
