import { IRegistrar } from '../../components/Registrar/IRegistrar';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import {
  INTERNAL_WHOIS_CONTACT_FIELDS_PRINT_ORDER,
  INTERNAL_WHOIS_REGISTRANT_FIELDS_PRINT_ORDER,
  logotItBase64,
  registroItBase64,
  WHOIS_CONTACT_FIELDS_NOT_CONSENTED_PRINT_ORDER,
  WHOIS_CONTACT_FIELDS_PRINT_ORDER,
  WHOIS_REGISTRANT_FIELDS_NOT_CONSENTED_PRINT_ORDER,
  WHOIS_REGISTRANT_FIELDS_PRINT_ORDER,
  whoisTableLayout,
  whoisTableWidths,
} from './const';
import {
  buildWhoisDomainBody,
  buildWhoisNameServersSection,
  buildWhoisRegistrarBody,
  buildWhoisTechsSection,
  buildWhoisContactBody,
  printWhoiCreationDateSubtitle,
  printWhoisRevisionSubtitle,
  printWhoisDocumentHeader,
} from './helper';
import { IDomain } from '../../components/Domains/Domain/types/domain';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

/***
 * Genera Report PDF del dettaglio whois
 * @param domain
 * @param refRevisionDateString
 * @param registrar
 * @param requestDate
 * @param type
 */
export function whoisPDFReport(
  domain: IDomain,
  refRevisionDateString?: string,
  registrar?: IRegistrar,
  requestDate?: string,
  type?: 'internal',
): any {
  // console.log('[whoisPDFReport] props.domain:', domain);
  // console.log(
  //   '[whoisPDFReport] props.refRevisionDateString:',
  //   refRevisionDateString,
  // );
  // console.log('[whoisPDFReport] props.registrar:', registrar);
  // console.log('[whoisPDFReport] props.requestDate:', requestDate);

  /**
   *
   */
  const documentDefinitions: TDocumentDefinitions = {
    header: (currentPage: any, pageCount: any, pageSize: any) => {
      // you can apply any logic and return any valid pdfmake element
      return [
        {
          text: printWhoisDocumentHeader(domain, type === 'internal'),
          style: 'header',
        },
        // {
        //     image: 'logoIt',
        //     absolutePosition: {x: 530, y: 10},
        //     height: 18,
        //     width: 23,
        //
        // },
        {
          image: 'registroIt',
          absolutePosition: { x: 520, y: 7 },
          height: 18,
          width: 55,
        },
        // {canvas: [{type: 'rect', x: 170, y: 132, w: pageSize.width - 170, h: 40}]},
      ];
    },
    footer: (currentPage: any, pageCount: any) => {
      return [
        {
          text: currentPage.toString() + ' di ' + pageCount,
          margin: [0, 20],
          alignment: 'center',
        },
      ];
    },
    content: [
      {
        text: `Dettaglio WHOIS ${type === 'internal' ? ' ad uso INTERNO' : ''}`,
        style: 'h1',
      },
      printWhoisRevisionSubtitle(refRevisionDateString),
      printWhoiCreationDateSubtitle(),
      // DOMAIN
      { text: 'Domain', style: 'subheader' },
      {
        style: 'whoisObjectTable',
        table: {
          widths: whoisTableWidths,
          dontBreakRows: true,
          body: buildWhoisDomainBody(domain),
        },
        layout: whoisTableLayout,
      },

      // Registrant
      { text: 'Registrant', style: 'subheader' },
      {
        style: 'whoisObjectTable',
        table: {
          widths: whoisTableWidths,
          body: buildWhoisContactBody(
            INTERNAL_WHOIS_REGISTRANT_FIELDS_PRINT_ORDER,
            WHOIS_REGISTRANT_FIELDS_PRINT_ORDER,
            WHOIS_REGISTRANT_FIELDS_NOT_CONSENTED_PRINT_ORDER,
            true,
            domain.registrant,
            type,
          ),
          dontBreakRows: true,
        },
        layout: whoisTableLayout,
      },

      // Admin
      {
        text: 'Admin',
        // pageBreak: 'before',
        style: 'subheader',
      },
      {
        style: 'whoisObjectTable',
        table: {
          widths: whoisTableWidths,
          body: buildWhoisContactBody(
            INTERNAL_WHOIS_CONTACT_FIELDS_PRINT_ORDER,
            WHOIS_CONTACT_FIELDS_PRINT_ORDER,
            WHOIS_CONTACT_FIELDS_NOT_CONSENTED_PRINT_ORDER,
            false,
            domain.admin,
            type,
          ),
          dontBreakRows: true,
        },
        layout: whoisTableLayout,
      },

      buildWhoisTechsSection(
        INTERNAL_WHOIS_CONTACT_FIELDS_PRINT_ORDER,
        WHOIS_CONTACT_FIELDS_PRINT_ORDER,
        WHOIS_CONTACT_FIELDS_NOT_CONSENTED_PRINT_ORDER,
        domain.techs,
        type,
      ),

      // Registrar
      { text: 'Registrar', style: 'subheader' },
      {
        style: 'whoisObjectTable',
        table: {
          widths: whoisTableWidths,
          body: buildWhoisRegistrarBody(registrar),
          dontBreakRows: true,
        },
        layout: whoisTableLayout,
      },
      buildWhoisNameServersSection(domain.hosts),
    ],

    images: {
      logoIt: logotItBase64,
      registroIt: registroItBase64,
    },
    styles: {
      header: {
        fontSize: 10,
        margin: [0, 10, 20, 0],
        color: 'grey',
        alignment: 'center',
      },
      h1: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
        decoration: 'underline',
      },
      subheader: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      revision: {
        fontSize: 13,
        bold: true,
        italics: true,
        margin: [0, -5, 0, 5],
        color: 'grey',
      },

      whoisObjectTable: {
        fontSize: 12,
        margin: [0, 5, 0, 20],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: 'black',
      },
      key: {
        margin: [5, 2, 0, 2],
      },
      value: {
        margin: [0, 2, 0, 2],
      },
      valueStrong: {
        bold: true,
        margin: [0, 2, 0, 2],
      },
    },
    defaultStyle: {
      // alignment: 'justify'
    },
  };
  pdfMake
    .createPdf(documentDefinitions)
    .download(
      `whois${type === 'internal' ? '-interno' : ''}-${domain.domain.replace(
        '.it',
        '_it',
      )}.pdf`,
    );
}
