import * as React from 'react';
import {CardDetailSection} from '../../Common/Styled/CardDetailSection';
import {IDomain} from './types/domain';
import {IDetailedRevision} from '../../Common/Navigation/Revisions/revisions';
import {Typography} from 'antd';
import Toolbar from "./Toolbar/Toolbar";
import {buildContent} from "./Detail/buildContent";
import CardWithViewer from "../../Common/Navigation/CardWithViewer/CardWithViewer";
import {createCanBoundTo} from "@casl/react";
import {AbilityContext} from "../../Common/Ability/AbilityContext";
import DomainEvents from "../Events/Scene/DomainEvents";
import RevisionsDrawer from "../Revisions/Drawer";
import {documentTitle} from "../../../utils/various";
import {SingleNoteController} from "../Notes/SingleNoteController";
import NotesDrawer from "../Notes/Drawer";
import {StateContext} from "../../StoreProvider";

interface IDomainMaster {
    domain: IDomain | IDetailedRevision;
    bgColor?: string;
    title: React.ReactNode;
    revNav?: React.ReactNode;
}

/*** Titolo
 *
 * @param props
 * @constructor
 */
function Title(props: { text: React.ReactNode, revNav: React.ReactNode }) {
    return (
        <Typography.Title level={2}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between', width: '100%',
            }}>
                {props.text} {props.revNav}</div>
        </Typography.Title>
    );
}

const DomainMaster: React.FC<IDomainMaster> = props => {
    documentTitle(`Dettaglio dominio ${props.domain.domainIDN ? props.domain.domainIDN : props.domain.domain}`);

    const {ability} = React.useContext(AbilityContext);
    const {domainNotes} = React.useContext(StateContext);


    const refRevisionDateRevision: string | undefined = (props.domain as IDetailedRevision) && (props.domain as IDetailedRevision).revision && (props.domain as IDetailedRevision).revision.date || undefined;

    const Can = createCanBoundTo(ability);
    return (
        <React.Fragment>
            <React.Fragment>
                <Title text={props.title} revNav={props.revNav}/>
                <CardWithViewer
                    defaultView={'tab'}
                    bgColor={props.bgColor}
                    extraTab={<Toolbar domain={props.domain} refRevision={refRevisionDateRevision}/>}
                    content={buildContent(props.domain)}
                    belowContent={
                        domainNotes.response &&
                        <div style={{backgroundColor: 'rgb(255 247 177)'}}>
                            <SingleNoteController/>
                        </div>
                    }
                />
                <Can do={"read"} on={"Event"}>
                    <CardDetailSection
                        style={{marginTop: 20}}
                        title={<Typography.Title level={2}>Dettaglio eventi</Typography.Title>}
                        bgcolor={props.bgColor}
                    >
                        <DomainEvents
                            refRevisionDate={refRevisionDateRevision}
                        />
                    </CardDetailSection>
                </Can>
            </React.Fragment>
            <Can do={"history"} on={"Domain"}>
                <RevisionsDrawer/>
            </Can>
            {/*<Can do={"history"} on={"Domain"}>*/}
            <NotesDrawer/>
            {/*</Can>*/}
        </React.Fragment>
    );
};
export default DomainMaster;


