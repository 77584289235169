import * as React from 'react';
import { Drawer as AddDocument } from 'antd';
import { WrappedAssociateDocumentProtocolForm } from './Forms/WrappedAssociateDocumentProtocolForm';
import { GenericDocument } from './documents';

export interface IDrawerGenericDocument {
  show: boolean;
  onClose: () => void;
  onSubmit: (values: GenericDocument) => void;
}

/***
 * Drawer che offre l'inserimento del documento da aggiungere all'evento
 * @param props
 * @constructor
 */
export function DrawerAddDocument(props: IDrawerGenericDocument) {
  const handleOnSubmit = (values: GenericDocument) => {
    props.onSubmit(values);
    props.onClose();
  };

  return (
    <AddDocument
      title="Associa un documento"
      width={720}
      onClose={props.onClose}
      visible={props.show}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <WrappedAssociateDocumentProtocolForm
        onSubmit={handleOnSubmit}
        onCancel={props.onClose}
      />
    </AddDocument>
  );
}
