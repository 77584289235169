// Template per la stampa del contatto
import {
  IContact,
  RegitrantEntityType,
} from '../../components/Contacts/Detail/contacts';
import { entityType } from '../../components/Contacts/Search/utils/utils';
import { whoisTableLayout, whoisTableWidths } from './const';
import {
  IDomain,
  IDomainHost,
  IDomainHostAddress,
} from '../../components/Domains/Domain/types/domain';
import { IRegistrar } from '../../components/Registrar/IRegistrar';
import _ from 'lodash';
import { moment } from '../various';
import { TableCell } from 'pdfmake/interfaces';

/***
 * Crea il template per il report whois di un contatto
 * @param fieldsPrintOrderInternal - ordine delle dei campi in caso interno da mostrare nella stampa (in lowercase)
 * @param fieldsPrintOrder - ordine delle dei campi da mostrare nella stampa (in lowercase)
 * @param fieldsNotConsentedPrint - ordine dei campi da mostrare quando non c'è il consent4Publish
 * @param isRegistrant
 * @param contact - dati del contatto
 * @param type - indica se la stampa è di tipo 'interno' (cioè senza filtra utente e quindi che mostra tutti i campi)
 */
export function buildWhoisContactBody(
  fieldsPrintOrderInternal: string[],
  fieldsPrintOrder: string[],
  fieldsNotConsentedPrint: string[],
  isRegistrant: boolean,
  contact?: IContact,
  type?: 'internal',
): any {
  const isInternal = type === 'internal';
  let contactTableCell: TableCell[][] = [];
  try {
    if (!contact) {
      throw new Error(
        '[contactTemplate] Generazione report non possibile perché il contatto è vuoto.',
      );
    } else {
      if (!isInternal && !contact.consentForPublish) {
        contactTableCell = buildContactDetailWithNotConsentTableCell(
          contact,
          isRegistrant,
        );
      } else {
        contactTableCell = buildContactDetailTableCell(contact); // stampa tutto
      }
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
  // Crea un array che tiene conto dei campi presenti in arrOrder prendendo gli oggetti da arrObj
  const returnOutput = (arrOrder: any, arrObj: any) =>
    arrOrder.reduce((acc: any, k: [], a: any, b: any, d: any) => {
      const j = _.findIndex(arrObj, (arr: any) => {
        return arr.find((elem: any) => {
          return elem?.text?.toLowerCase() === k + ':';
        });
      });
      if (j >= 0) {
        return [...acc, arrObj[j]];
      } else {
        return [...acc];
      }
    }, []);

  if (contact) {
    if (isInternal) {
      return returnOutput(fieldsPrintOrderInternal, contactTableCell);
    } else {
      if (contact.consentForPublish) {
        return returnOutput(fieldsPrintOrder, contactTableCell);
      } else {
        // stampa hidden
        return returnOutput(fieldsNotConsentedPrint, contactTableCell);
      }
    }
  }
}

/***
 * Formatta la stampa dei dati del dominio
 * @param domain
 */
export function buildWhoisDomainBody(domain: IDomain) {
  return [
    [
      { text: 'Domain:', style: 'key' },
      {
        text: domain.domainIDN ? domain.domainIDN : domain.domain,
        style: 'valueStrong',
      },
    ],
    [
      { text: 'Status:', style: 'key' },
      { text: domain.status, style: 'value' },
    ],
    [
      { text: 'Signed:', style: 'key' },
      { text: domain.signed ? 'SI' : 'NO', style: 'value' },
    ],
    [
      { text: 'Created:', style: 'key' },
      {
        text: moment(domain.created).format('YYYY-MM-DD HH:mm:ss'),
        style: 'value',
      },
    ],
    [
      { text: 'LastUpdate:', style: 'key' },
      {
        text: moment(domain.lastupdate).format('YYYY-MM-DD HH:mm:ss'),
        style: 'value',
      },
    ],
    [
      { text: 'ExpireDate:', style: 'key' },
      {
        text: moment(domain.expire).format('YYYY-MM-DD'),
        style: 'value',
      },
    ],
  ];
}

/**
 * popola una array con i valori del Contatto pronti da stampare
 * @param contact
 */
export function buildContactDetailTableCell(contact: IContact): TableCell[][] {
  const result = [
    [
      { text: 'ContactID:', style: 'key' },
      { text: contact.contactId, style: 'value' },
    ],
    [
      { text: 'Organization:', style: 'key' },
      { text: contact.org, style: 'value' },
    ],
    [
      { text: 'Name:', style: 'key' },
      { text: contact.name, style: 'value' },
    ],
    [
      { text: 'Address:', style: 'key' },
      {
        text: buildWhoisContactAddress(
          contact.streets,
          contact.city,
          contact.postalCode,
          contact.country.toUpperCase(),
          contact.state,
        ),
        style: 'value',
      },
    ],
    [
      { text: 'Phone:', style: 'key' },
      { text: contact.voice, style: 'value' },
    ],
    [
      { text: 'Fax:', style: 'key' },
      { text: contact.fax, style: 'value' },
    ],
    [
      { text: 'E-Mail:', style: 'key' },
      { text: contact.email, style: 'value' },
    ],
    [
      { text: 'Created:', style: 'key' },
      {
        text: moment(contact.created).format('YYYY-MM-DD HH:mm:ss'),
        style: 'value',
      },
    ],
    [
      { text: 'LastUpdate:', style: 'key' },
      {
        text: moment(contact.lastupdate).format('YYYY-MM-DD HH:mm:ss'),
        style: 'value',
      },
    ],
    [
      { text: 'ConsentForPublishing:', style: 'key' },
      {
        text: contact.consentForPublish ? 'YES' : 'NO',
        style: 'value',
      },
    ],
  ];

  if (contact.registrant) {
    if (contact.registrant.schoolCode) {
      result.push([
        { text: 'SchoolCode:', style: 'key' },
        {
          text: contact.registrant?.schoolCode,
          style: 'value',
        },
      ]);
    }
    result.push([
      { text: 'RegCode:', style: 'key' },
      { text: contact.registrant?.regcode || '-', style: 'value' },
    ]);
    result.push([
      {
        text: 'EntityType:',
        style: 'key',
      },
      {
        text: entityType(
          parseInt(contact.registrant.entityType.toString(), 10),
        ),
        style: 'value',
      },
    ]);
    result.push([
      { text: 'NationalityCode:', style: 'key' },
      {
        text: contact.registrant.nationality.toUpperCase(),
        style: 'value',
      },
    ]);
  }

  return result;
}

/**
 * popola una array con i valori del Contatto pronti da stampare nel caso
 * non ci sia il consenso di pubblicazione dati
 * @param contact
 * @param isRegistrant
 */
export function buildContactDetailWithNotConsentTableCell(
  contact: IContact,
  isRegistrant: boolean,
): TableCell[][] {
  return [
    [
      { text: 'Organization:', style: 'key' },
      {
        text: printOrgWhenNotConsented4Pub(
          contact.org,
          isRegistrant,
          contact.registrant?.entityType,
        ),
        style: 'value',
      },
    ],
    [
      { text: 'Name:', style: 'key' },
      { text: 'hidden', style: 'value' },
    ],
  ];
}

/**
 * Costruisce il body per la visualizzazione dinamica dei contatti Tech
 * @param fieldsPrintOrderInternal
 * @param fieldsPrintOrder
 * @param fieldsNotConsentedPrintOrder
 * @param techs
 * @param type
 */
export function buildWhoisTechsSection(
  fieldsPrintOrderInternal: string[],
  fieldsPrintOrder: string[],
  fieldsNotConsentedPrintOrder: string[],
  techs?: IContact[],
  type?: 'internal',
): any {
  const body: any[] = [];

  if (techs) {
    techs.forEach((contact, index) => {
      body.push({ text: 'Tech ' + (index + 1), style: 'subheader' });
      body.push({
        style: 'whoisObjectTable',
        table: {
          widths: whoisTableWidths,
          body: buildWhoisContactBody(
            fieldsPrintOrderInternal,
            fieldsPrintOrder,
            fieldsNotConsentedPrintOrder,
            false,
            contact,
            type,
          ),
          dontBreakRows: true,
        },
        layout: whoisTableLayout,
      });
    });
  }

  return body;
}

/***
 * formatta la stampa dell'indirizzo di un contatto
 * @param streets
 * @param city
 * @param province
 * @param country
 * @param cap
 */
export function buildWhoisContactAddress(
  streets: any[],
  city: string,
  province: string,
  country: string,
  cap?: string,
): string {
  let result = '';
  // crea una stringa contenente le vie (streets)
  result = streets.join('\n');
  result += `\n${city}\n${cap ? cap + '\n' : ''}${province}\n${country}`;
  return result;
}

export function printWhoisDocumentHeader(domain: IDomain, isInternal: boolean) {
  return `WHOIS ${isInternal ? '(interno) - ' : '-'} ${
    domain.domainIDN ? domain.domainIDN : domain.domain
  }  - ${moment(new Date()).format('LLL')}`;
}

/***
 * Formatta la stampa degli indirizzi ip del dns
 * @param addresses
 * @param type
 */
export function printDNSAddress(
  addresses?: IDomainHostAddress[],
  type?: 'v4' | 'v6',
) {
  if (addresses) {
    const selected = addresses.filter(value => value.type === type);
    return selected.length === 1 ? selected[0].address : '-';
  }
  return '-';
}

// Costruisce il body per la visualizzazione dinamica dei nameservers
export function buildWhoisNameServersSection(hosts?: IDomainHost[]): any {
  const body: any[] = [];
  const rows: any[] = [];

  if (hosts) {
    hosts.forEach((host, index) =>
      rows.push([
        host.name,
        printDNSAddress(host.addresses, 'v4'),
        printDNSAddress(host.addresses, 'v6'),
      ]),
    );
  }

  if (rows.length > 0) {
    body.push({ text: 'Nameservers ', style: 'subheader' });
    body.push({
      style: 'whoisObjectTable',
      table: {
        headerRows: 1,
        widths: ['*', 150, 150],
        body: [['Name', 'IpV4', 'IpV6']].concat(rows),
      },

      layout: whoisTableLayout,
    });
  }

  return body;
}

/***
 * Formatta la stampa del REgistrar
 * @param registrar
 */
export function buildWhoisRegistrarBody(registrar?: IRegistrar): any {
  let result: any[] = [];
  if (registrar) {
    result = [
      [
        { text: 'Organization:', style: 'key' },
        { text: registrar.name, style: 'value' },
      ],
      [
        { text: 'Name:', style: 'key' },
        { text: registrar.name, style: 'value' },
      ],
      [
        { text: 'Web:', style: 'key' },
        { text: registrar.web, style: 'value' },
      ],
      [
        { text: 'Email:', style: 'key' },
        { text: registrar.email, style: 'value' },
      ],
      [
        { text: 'Voice:', style: 'key' },
        { text: registrar.voice, style: 'value' },
      ],
      // [{text: 'DNSSEC:', style: 'key'}, {text: registrar.streets, style: 'value'}],
    ];
  }

  return result;
}

/***
 * WHois display revision
 * @param rev
 */
export function printWhoisRevisionSubtitle(rev: string | undefined) {
  if (rev) {
    const revisionDate = moment(rev).format('LLL');
    return {
      text: 'Revisione storica associata: ' + revisionDate,
      style: 'revision',
    };
  }
  return undefined;
}

/***
 * whois Display date
 */
export function printWhoiCreationDateSubtitle() {
  return {
    text: 'Data creazione documento: ' + moment(new Date()).format('LLL'),
    style: 'revision',
  };
}

/**
 * Stampa il valore passato tenendo conto delle regole legate al consent4pub
 * quando nel caso di org
 * @param _data
 * @param _isRegistrant
 * @param _entityType
 */
export function printOrgWhenNotConsented4Pub(
  _data: string,
  _isRegistrant: boolean,
  _entityType?: RegitrantEntityType,
): string {
  if (_entityType && _isRegistrant) {
    switch (_entityType) {
      case 1:
        return 'hidden';
      case 3:
        return 'hidden';
      default:
        return _data;
    }
  }
  return 'hidden';
}
