import React from 'react';
import { Div, Flex, PaperLayout, VerticalLayout } from '@nic/ui-comps';
import { IGovAdministrationAddress, IGovAdministrationData } from './Gov.types';
import { moment } from '../../utils/various';

interface IAmministrazioneViewer {
  amministrazione: IGovAdministrationData;
  view: 'minimal' | 'full';
  direction: 'row' | 'column';
}

const amministrazioneStyle: Record<string, React.CSSProperties> = {
  true: {
    border: '1px solid',
    borderRadius: '5px',
    padding: '20px',
    gap: 10,
  },
  false: {
    border: '1px solid',
    padding: '20px',
    borderRadius: '5px',
    gap: 10,
  },
};

function address(_address: IGovAdministrationAddress) {
  return (
    <Flex direction={'row'} justify={'flex-start'} style={{ gap: 5 }}>
      <Div>{_address.indirizzo}</Div>
      <Div>{_address.cap}</Div>
      <Div>{_address.comune}</Div>
      <Div>{_address.provincia}</Div>
    </Flex>
  );
}

function field(label: string, value: React.ReactNode) {
  return (
    <Flex direction={'column'} align={'flex-start'}>
      <Div fontSize={'0.9rem'} fontWeight={'700'}>{`${label}:`}</Div>
      <Div fontSize={'0.9rem'}>{value}</Div>
    </Flex>
  );
}

function full(amministrazione: IGovAdministrationData) {
  return (
    <VerticalLayout
      className={amministrazione.enabled ? 'info-box' : 'error-box'}
      style={amministrazioneStyle[`${amministrazione.enabled}`]}
    >
      {field('Codice IPA', amministrazione.codiceIpa)}
      {field('Denominazione', amministrazione.denominazione)}
      {field('Indirizzo', address(amministrazione.address))}
      {field('Codice Fiscale', amministrazione.codiceFiscale)}
      {field('Abilitato gov.it', amministrazione.enabled ? 'Si' : 'No')}
      {field('PEC', amministrazione.pec.join(','))}
      {field('Categoria', amministrazione.categoria)}
      {field(
        'Data creazione',
        `${moment(amministrazione.created).format('DD MMMM YYYY, HH:mm:ss')}`,
      )}
      {field(
        'Data ultimo aggiornamento',
        `${moment(amministrazione.lastUpdate).format(
          'DD MMMM YYYY, HH:mm:ss',
        )}`,
      )}
      {field(
        'Data ultima importazione',
        `${moment(amministrazione.lastImport).format(
          'DD MMMM YYYY, HH:mm:ss',
        )}`,
      )}
    </VerticalLayout>
  );
}

const AmministrazioneViewer: React.FC<IAmministrazioneViewer> = props => {
  const { amministrazione, view } = props;

  if (view === 'full') {
    return full(amministrazione);
  }

  return (
    <PaperLayout
      asCard={false}
      className={amministrazione.enabled ? 'info-box' : 'error-box'}
    >
      <VerticalLayout style={{ gap: 10 }}>
        {field('Codice IPA', amministrazione.codiceIpa)}
        {field('Denominazione', amministrazione.denominazione)}
      </VerticalLayout>
    </PaperLayout>
  );
};

export default AmministrazioneViewer;
