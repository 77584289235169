import * as React from 'react';
import { Icon, Menu } from 'antd';
import styled from 'styled-components';
import { MenuProps } from 'antd/lib/menu';
import { SearchBarMenu } from './SearchBarMenu';
import { Ability } from '@casl/ability';
import { GUIDELINE_LINK, MIDA_OLD_HOST } from '../../../../midaConfig';
import { useRouter } from '../../Hooks/useRouter';
import UserMenu from '../UserMenu/UserMenu';
import {
  faChessRook,
  faHouseUser,
  faMap,
  faTags,
  faFolderPlus,
  faMagnifyingGlassPlus,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JBillingMenu from '../JBillingMenu/JBillingMenu';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;

export const StyledMenu = styled(Menu)<MenuProps>`
  font-size: large !important;
  background-color: transparent !important;
  height: 65px !important;
  border-bottom: 0px !important;

  .ant-menu-item,
  .ant-menu-submenu {
    maxwidth: 300px !important;
    text-align: center !important;
    height: 64px !important;
    //border-left: 1px solid rgba(255, 255, 255, 0.2);
    line-height: 3.4 !important;
    color: white !important;
  }

  .ant-menu-item > a,
  .ant-menu-submenu > a,
  .ant-menu-submenu-title > a {
    color: white !important;
  }

  .ant-menu-item > a:hover,
  .ant-menu-submenu > a:hover,
  .ant-menu-submenu:hover,
  .ant-menu-submenu:hover,
  .ant-menu-submenu:hover {
    color: white !important;
  }

  .ant-menu-item:hover,
  .ant-menu-item-selected,
  .ant-menu-submenu-selected,
  .ant-menu-submenu:hover {
    background: #3b74a4 !important;
    border-bottom: 5px solid #6093be !important;
    color: white !important;
  }

  .right {
    float: right !important;
  }
`;

const MenuItem = styled(Menu.Item)`
  width: auto !important;
`;

interface IProps {
  ability: Ability;
}

const NavBarMenu: React.FC<IProps> = props => {
  const isPageWideLineeGuida = useMediaQuery('(min-width: 1420px)');
  const isPageWideMidaOld = useMediaQuery('(min-width: 1270px)');
  const isPageWideOthers = useMediaQuery('(min-width: 1150px)');

  const { pathname, history } = useRouter();
  const [current, setCurrent] = React.useState<string>('');

  React.useEffect(() => {
    setCurrent(pathname.split('/')[1]);
  }, [pathname]);

  const handleClick = (e: any) => {
    setCurrent(e.key);
  };

  const prefix: string = `/domains${
    props.ability.cannot('search', 'Domain') ? '/findByName' : ''
  }`;
  return (
    <div>
      <StyledMenu
        onClick={handleClick}
        selectedKeys={[current === '' ? 'home' : current]}
        mode="horizontal"
      >
        {/* ** DOMINI ** */}
        <SubMenu
          key="domains"
          className={current === 'domains' ? 'ant-menu-item-selected' : ''}
          onTitleClick={() => setCurrent('domains')}
          data-testid="menuitemDomains"
          title={
            <Link to={prefix} data-testid="domainsLink">
              <Icon type="global" />
              {isPageWideOthers && <span>Domini</span>}
            </Link>
          }
        >
          <Menu.Item
            key="1"
            onClick={() => history.push('/domains/advanced_search')}
          >
            <>
              <FontAwesomeIcon
                icon={faMagnifyingGlassPlus}
                style={{
                  marginRight: 10,
                  color: 'rgba(0,0,0,.50)',
                }}
              />
              Ricerca avanzata
            </>
          </Menu.Item>
          <Menu.Item key="5" disabled>
            <>
              <FontAwesomeIcon
                icon={faUserLock}
                style={{
                  marginRight: 10,
                  color: 'rgba(0,0,0,.50)',
                }}
              />
              Creazione domini riservati
            </>
          </Menu.Item>
          <Menu.ItemGroup key="g2" title="Gestione">
            <Menu.Item key="2" disabled>
              <>
                <FontAwesomeIcon
                  icon={faMap}
                  style={{
                    marginRight: 10,
                    color: 'rgba(0,0,0,.50)',
                  }}
                />
                Domini geografici
              </>
            </Menu.Item>
            <Menu.Item key="3" disabled>
              <>
                <FontAwesomeIcon
                  icon={faTags}
                  style={{
                    marginRight: 10,
                    color: 'rgba(0,0,0,.50)',
                  }}
                />
                Nomi riservati
              </>
            </Menu.Item>
            <Menu.Item key="4" disabled>
              <>
                <FontAwesomeIcon
                  icon={faFolderPlus}
                  style={{
                    marginRight: 10,
                    color: 'rgba(0,0,0,.50)',
                  }}
                />
                Bulk transfer
              </>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        {/* ** CONTATTI ** */}
        <SubMenu
          key="contacts"
          data-testid="menuitemContacts"
          className={current === 'contacts' ? 'ant-menu-item-selected' : ''}
          onTitleClick={() => setCurrent('contacts')}
          title={
            <Link
              to={`/contacts${
                props.ability.cannot('search', 'Contact')
                  ? '/findByContactId/'
                  : ''
              }`}
              data-testid="contactsLink"
            >
              <Icon type="user" />
              {isPageWideOthers && <span>Contatti</span>}
            </Link>
          }
        >
          <Menu.Item
            key="1"
            onClick={() => history.push('/contacts/advanced_search')}
          >
            <>
              <FontAwesomeIcon
                icon={faMagnifyingGlassPlus}
                style={{
                  marginRight: 10,
                  color: 'rgba(0,0,0,.50)',
                }}
              />
              Ricerca avanzata
            </>
          </Menu.Item>
        </SubMenu>
        {/* ** REGISTRAR ** */}
        <JBillingMenu
          title={
            <>
              <FontAwesomeIcon
                icon={faHouseUser}
                style={{ marginRight: 10, fontSize: 14 }}
              />
              {isPageWideOthers && <span>Registrar</span>}
            </>
          }
        />
        <MenuItem key="midaold">
          <a
            href={`http://${MIDA_OLD_HOST}/mida/home.seam`}
            data-testid="midaOldLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faChessRook}
              style={{ marginRight: 10, fontSize: '14px' }}
            />
            {isPageWideMidaOld && <span>Mida(old)</span>}
          </a>
        </MenuItem>
        <MenuItem key="lineeguida">
          <a
            href={GUIDELINE_LINK}
            data-testid="guidelineLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon type="read" />
            {isPageWideLineeGuida && <span>Linee Guida</span>}
          </a>
        </MenuItem>
        <UserMenu className={'right'} />
        {// Se non siamo in home mostra la ricerca
        current !== '' && (
          <MenuItem className={'right'}>
            <SearchBarMenu />
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
};

export default NavBarMenu;
// Aggiungere test che verifica  i link verso mida old e verso le linee guida
