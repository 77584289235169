import { Button, Form, Icon, Input } from "antd";
import * as React from "react";
import { FormComponentProps } from "antd/lib/form";
import styled from "styled-components";

const FormItem = Form.Item;

let uuid = 0;

/***
 * Permette l'inserimento delle vie (Streets)
 */
class StreetsFormItems extends React.Component<FormComponentProps & {items:any }> {


  public remove = (k: string) => {
    const { form } = this.props;


    // can use data-binding to get
    const fieldKeys = form.getFieldValue("streetkeys");
    // We need at least one street
    if (fieldKeys.length === 1) {
      return;
    }
    // can use data-binding to set
    form.setFieldsValue({
      "streetkeys": fieldKeys.filter((key: string) => key !== k)
    });
  };

  public add = () => {
    const { form } = this.props;
    uuid++;
    // can use data-binding to get
    const kk = form.getFieldValue("streetkeys");
    const nextKeys = kk.concat(uuid);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      "streetkeys": nextKeys
    });
  };


  public render() {
    // console.log("ajajajajaja ",this);
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const streets = (this.props.items &&  this.props.items.streets) || [];
    const STREETS_MAX = 3;


    const formItemLayout = {
      labelCol: {
        // xs: {span: 24},
        sm: { span: 4 }
      },
      wrapperCol: {
        // xs: {span: 24},
        sm: { span: 20 }
      }
    };

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        // xs: {span: 24, offset: 12},
        sm: { span: 24, offset: 9 }
      }
    };

    function fillKeysArrayWithIndexs(indexs:any) {
      if (indexs === 0) {
        indexs = 1; // Imposta almeno un campo strada
      }
      const arr = Array.apply(null, new Array(indexs));
      return arr.map((x:any, i:any) =>{
        return i;
      });
    }

    getFieldDecorator("streetkeys", {
      initialValue: fillKeysArrayWithIndexs(streets.length)
    });


    const keys = getFieldValue("streetkeys");
    const formItems = keys.map((k:any, index:any) => {
      return (
        <FormItem
          {...(index >= 0 ? formItemLayout : formItemLayoutWithOutLabel)}
          label={index >= 0 ?
            index + 1 : ""}
          required={true}
          key={k}
        >

          {getFieldDecorator(`street_${k}`, {
            initialValue: streets[k],
            validateTrigger: ["onChange", "onBlur"],
            rules: [{
              required: true,
              whitespace: true,
              message:   "Completa l'indirizzo" ,
            }]
          })(
            <Input
              placeholder={ "Indirizzo es. Via Moruzzi 1" }
              style={{ float: "left", width: "90%", marginRight: 8, marginLeft: 8 }}
              type="string" />
          )}
          {keys.length > 1 ? (
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              // disabled={keys.length === 1}
              onClick={() => {
                // this.props.onChange();
                this.remove(k);
              }}
            />
          ) : null}
        </FormItem>
      );
    });
    return (
      <SubFormItemBox >

      {formItems}
        <FormItem
          {...formItemLayoutWithOutLabel}>
          {keys.length < STREETS_MAX && <Button type="dashed" onClick={this.add} style={{ width: "15%" }} size={'small'}>
            <Icon type="plus" />
          </Button>

          }
        </FormItem>
      </SubFormItemBox>
    );
  }
}


export default  StreetsFormItems;


export const SubFormItemBox = styled.div`
    border: 1px solid #ccc;
    padding: 20px;
    textAlign: 'center';
    @media (max-width: 1400px) {
      .ant-row{
          .ant-form-item-label {
              text-align: left!important;
              
              //background-color: red;
          }
      } 
    }

`;
