import {ParsedQuery, stringify} from "query-string";
import {IDomains} from "../../Domains/Search/types/domains";
import {IFetchStateType} from "../../Type/IFetchtStateType";
import {apiGetContactByContactId, apiGetContacts} from "../../../const/api-calls";
import { IContacts } from '../types/contacts';
import { searchAdpater } from '../../../utils/url-manipulation';

/***
 * Funzione per il recupero dei domini relativi alla query passata.
 *
 * @param dispatch
 * @param contacts - valore proveniente dallo stato del reducer
 * @param query - queryString corrente
 * @param keycloak
 */
export function fetchContacts(dispatch: any, contacts: IFetchStateType<IContacts>, query: ParsedQuery<string | number>) {

    const queryString = stringify(query);

    if (queryString === '' || queryString === '?') {
        dispatch({type: 'CONTACTS_FETCH_CLEAN'})
    } else {
        dispatch({type: 'CONTACTS_FETCH_LOAD', query: queryString})
        apiGetContacts(searchAdpater(query)).then((res: any) => {
            dispatch({type: 'CONTACTS_FETCH_SUCCESS', payload: res})
        }).catch((error: any | PromiseLike<IDomains>) => {
            dispatch({type: 'CONTACTS_FETCH_ERROR', error})
        })
    }
}



export function fetchContactById(dispatch: any, contacts: IFetchStateType<IContacts>, query: ParsedQuery<string | number>) {

    const queryString = query.toString();

    if (queryString === '' || queryString === '?') {
        dispatch({type: 'CONTACTS_FETCH_CLEAN'})
    } else {
        dispatch({type: 'CONTACTS_FETCH_LOAD', query: queryString})
        apiGetContactByContactId(query.toString()).then((res: any) => {
            // adattamento per poter sfruttare il reducer di contacts cosi da poter visualizzare anche questo risultato
            // attraverso l'apposito pannello che accetta IContacts
            const contact2contacts = { data: {elements :[res.data]}};
            dispatch({type: 'CONTACTS_FETCH_SUCCESS', payload: contact2contacts})
        }).catch((error: any | PromiseLike<IDomains>) => {
            dispatch({type: 'CONTACTS_FETCH_ERROR', error})
        })
    }
}


