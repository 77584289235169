import styled from 'styled-components';
import {Card} from 'antd';
import {CardProps} from "antd/lib/card";

interface IMyProps {
    bgcolor?: string
}

export const CardDetailSection = styled(Card)<IMyProps & CardProps>`
  border-radius: 10px;
  > .ant-card-body {
    background-color: ${(props: IMyProps) => (props.bgcolor ? props.bgcolor : '#e5e5e559')};
  }
`;
