import * as React from 'react';
import {Divider, Modal, Spin, Typography} from 'antd';
// import styled from 'styled-components';
import {IRegistrar, ICustomer, IAddress, IRegistrarContact, IRegistrarEmail} from './IRegistrar';
import {StatusBadge} from './Registrar';
import {FetchController} from '../Common/Navigation/FetchController/FetchController';
import {JbillingLink} from './JBillingLink';
import {useFetchRegistrarContatcts, useFetchRegistrarCustomer, useFetchRegistrarEmails} from "./hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt, faFax, faHome, faMapMarkerAlt, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {Dd, Dl, DlBox, Dt} from "../Common/Styled/DefinitionList";
import {DMY} from "../../const/common";
import {moment} from "../../utils/various";

const {Text} = Typography;

// const Label = styled.span`
//     width: 200px;
//     font-weight: bold;
// `;


const DividerWithText: React.FC = props => (
    <Divider orientation="left"
             style={{
                 // fontWeight: 500,
                 color: '#40A9FF',
                 // fontSize: 14,
                 // margin: 10
             }}
    >
        {props.children}
    </Divider>
)


const DetailRow: React.FC<{ label: React.ReactNode, value: React.ReactNode }> = props => (
    <><Dt style={{width: 180, textAlign: 'right'}}>{props.label}</Dt><Dd>{props.value ? props.value : "-"}</Dd></>
)

/***
 * Pannello di visualizzazione dettaglio informazioni Registrar
 * @param props
 * @constructor
 */
export function RegistrarDetails(props: { customer: ICustomer, registrar: IRegistrar, contacts?: IRegistrarContact[], emails?: IRegistrarEmail[] }) {
    console.debug(' RegistrarDetails', props);
    return (
        <DlBox>
            <Dl>
                <DividerWithText>Info generali</DividerWithText>
                <DetailRow label="Id" value={<Text type="secondary">{props.customer.id}</Text>}/>
                <DetailRow label={'Status'} value={<RegistrarStatus status={props.registrar.status}
                                                                    statusDate={props.registrar.statusDate}/>}/>
                <DetailRow label={'Name'} value={props.registrar.name}/>
                {
                    props.registrar.status === 'active' &&
                    <>
                        <DetailRow label={'Org'} value={props.customer.org}/>
                        <DetailRow label={'RegCode'}
                                   value={<Typography.Text code>{props.customer.regcode}</Typography.Text>}/>
                        <DetailRow label={<>(legale) <FontAwesomeIcon icon={faMapMarkerAlt}/> </>}
                                   value={formatAddress({address: props.customer.legalAddress})}/>
                        <DividerWithText>Dati web</DividerWithText>
                        <DetailRow label={<FontAwesomeIcon icon={faHome}/>}
                                   value={formatUrl({url: props.registrar.web})}/>
                        <DetailRow label={<FontAwesomeIcon icon={faPhoneAlt}/>} value={props.registrar.voice}/>
                        <DetailRow label={<FontAwesomeIcon icon={faFax}/>} value={props.registrar.fax}/>
                        <DetailRow label={<FontAwesomeIcon icon={faAt}/>}
                                   value={<Typography.Text copyable>{props.registrar.email}</Typography.Text>}/>
                        <DetailRow label={<FontAwesomeIcon icon={faMapMarkerAlt}/>}
                                   value={formatAddress({address: props.registrar.webAddress})}/>
                    </>
                }
                <DividerWithText>Lista emails</DividerWithText>
                {
                    props.emails && props.emails.map((c: IRegistrarEmail, kk: number) =>
                        <div key={kk}>
                            <DetailRow
                                label={<>({c.type}) <FontAwesomeIcon icon={faAt}/></>}
                                value={<Typography.Text copyable>{c.email}</Typography.Text>}/>
                        </div>
                    )}
                <DividerWithText>Ulteriori dettagli</DividerWithText>
                <DetailRow label={'Link a JBilling'} value={<JbillingLink tag registrar={props.registrar}/>}/>

            </Dl>
        </DlBox>
    );
}


/**
 * Controllo per eseguire l'apertura del dettaglio del Registrar tramite Modale
 * @param props
 * @constructor
 */
const RegistrarPopover: React.FC<{ registrar: IRegistrar, show?: boolean }> = (props) => {
    const [show, setShow] = React.useState(props.show);
    const toggle = () => setShow(!show);
    return (
        <div>
            <StatusBadge registrant={props.registrar}>
                <a onClick={toggle}
                   style={{color: props.registrar.status === 'deleted' ? 'grey' : ''}}>
                    {props.registrar.name}
                </a>
            </StatusBadge>
            <Modal
                title={<Typography.Title level={3}>Dettaglio Registrar: <Typography.Text
                    mark>{props.registrar.name}</Typography.Text></Typography.Title>}
                centered
                visible={show}
                onOk={() => setShow(false)}
                onCancel={() => setShow(false)}
            >
                <RegistrarEmails registrar={props.registrar}>
                    {(emails: IRegistrarEmail[]) => <RegistrarCustomer registrar={props.registrar}>
                        {(customer: ICustomer) => {

                            if (customer && emails) {
                                return <RegistrarDetails registrar={props.registrar} customer={customer}
                                                         emails={emails}/>
                            } else {

                                return <Spin/>
                            }
                        }}
                    </RegistrarCustomer>
                    }
                </RegistrarEmails>
            </Modal>
        </div>);
};

export default RegistrarPopover;


/**
 * Controllo per caricare i dati Customer del Registrar
 * @param props
 * @constructor
 */
export const RegistrarCustomer: React.FC<{
    registrar: IRegistrar, children(props: ICustomer): JSX.Element;
}> = (props) => {
    const {loading, error, data} = useFetchRegistrarCustomer(props.registrar.id)

    return (
        <FetchController
            loading={loading}
            error={error}
            payload={data}
        >
            {(values) => props.children(values)}
        </FetchController>
    )
}

/**
 * Controllo per caricare i dati dei contatti del Registrar
 * @param props
 * @constructor
 */
export const RegistrarContacts: React.FC<{
    registrar: IRegistrar, children(props: IRegistrarContact[]): JSX.Element;
}> = (props) => {
    const {loading, error, data} = useFetchRegistrarContatcts(props.registrar.id)

    return (
        <FetchController
            loading={loading}
            error={error}
            payload={data}
        >
            {(values) => props.children(values)}
        </FetchController>
    )
}

/**
 * Controllo per caricare i dati delle mails del Registrar
 * @param props
 * @constructor
 */
export const RegistrarEmails: React.FC<{
    registrar: IRegistrar, children(props: IRegistrarEmail[]): JSX.Element;
}> = (props) => {
    const {loading, error, data} = useFetchRegistrarEmails(props.registrar.id)

    return (
        <FetchController
            loading={loading}
            error={error}
            payload={data}
        >
            {(values) => props.children(values)}
        </FetchController>
    )
}


/**
 * Formattatore dell'indirizzo
 * @param props
 */
function formatAddress(props: { address: IAddress }) {
    return `${props.address.street}, ${props.address.postalcode} - ${props.address.city} (${props.address.state}) - ${props.address.country.toUpperCase()}`
}


function formatUrl(props: { url?: string }) {
    const {url} = props;
    if (url) {
        return <a href={url}
                  target="_blank" rel="noopener noreferrer"
        >{url}</a>
    }
    return null;
}

function RegistrarStatus(props: { status?: 'active' | 'deleted' | 'suspended', statusDate?: string }) {
    switch (props.status) {
        case "active":
            return <div style={{color: '#52C51A'}}>attivo</div>
        case "suspended":
            return <div style={{color: 'orange'}}>sospeso ({moment(props.statusDate).format(DMY)})</div>
        case "deleted":
            return <div style={{color: 'red'}}>cancellato ({moment(props.statusDate).format(DMY)})</div>
        default:
            return <div style={{color: 'gray'}}>sconosciuto</div>
    }
}