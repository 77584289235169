import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './components/App';
import "./index.css"

const body = <App/>;

ReactDOM.render(body, document.getElementById('root'));
// registerServiceWorker();

// Aggiutno metodo per eseguire la deregistrazione del serviceWorker nel caso sia già attivo nel client
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
        registration.unregister();

        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(async (names) => {
                await Promise.all(names.map(name => caches.delete(name)));
            });
        }
    });
}
