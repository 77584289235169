import { Button, Steps } from "antd";
import * as React from "react";
import { WrappedEditContactForm } from "./EditContactForm";
import { StateContext } from "../../../StoreProvider";
import { IContact } from "../contacts";
import { GenericDocument } from "../../../Documents/documents";
import { WrappedEditEventCreationForm } from "./EditEventForm";
import { SummaryBox } from "./SummaryBox";

/***
 * STEPS
 *
 */

const { Step } = Steps;

export interface IEditContactOperation {
  type?: "registry" | "registrant";
  description?: string;
  contact?: IContact;
  status?: "accepted" | "rejected";
  documentId?: number;
  etag?: string;
  document?: GenericDocument;
  id?: number;
}

export interface IEditContactSteps {
  onDone(done:boolean):void;
}

const EditContactSteps : React.FC<IEditContactSteps> = ({onDone}) => {
  const [current, setCurrent] = React.useState(0);
  const [completed, setCompleted] = React.useState(false);
  const { contact } = React.useContext(StateContext);
  const [data, setData] = React.useState<IEditContactOperation | undefined>({});

  React.useEffect(() => {
    if (contact && contact.response && contact.response.data) {
      // console.log("EditContactSteps headers and contact ", contact.response.data);
      setData({ contact: contact.response.data, etag: contact.response.headers.etag });
    }
  }, []);

  // const onChange = (curr: number) => {
  //   console.log("onChange:", curr);
  //   setCurrent(curr);
  // };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleOnChangeStepData = (err: any, values: IEditContactOperation, step: number) => {
    // console.log("handleOnChangeStepData data ", data);
    // console.log("handleOnChangeStepData values ", values);
    setData({ ...data, ...values });
    if (!err) {
      setCurrent(step);
    }
    // console.log("data after", data);
  };

  const handleOnDone = (done: boolean) => {
    // console.log("handleOnDone ",done);
    setCompleted(done);
    onDone(done);
    // reload
  };

  const steps = [
    {
      title: "Tipo",
      content: <Step1 onSubmit={(errors, values) => handleOnChangeStepData(errors, values, 1)} />
    },
    {
      title: "Dettagli",
      content: <Step2 data={data} onSubmit={(errors, values) => handleOnChangeStepData(errors, values, 2)} />
    },
    {
      title: "Modifica",
      content: <Step3 data={data} onSubmit={(errors, values) => handleOnChangeStepData(errors, values, 3)} />
    },
    {
      title: "Riepilgo",
      content: contact && contact.response && contact.response.data &&
        <Step4 data={data} originalContact={contact.response.data} onDone={handleOnDone} />
    }
  ];
  return (
    <>
      <Steps current={current}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content" style={{ minHeight: 400 }}>
        {steps[current].content}
      </div>
      <div className="steps-action">
        {/*{current < steps.length - 1 && (*/}
        {/*  <Button type="primary" onClick={() => this.next()}>*/}
        {/*    Next*/}
        {/*  </Button>*/}
        {/*)}*/}
        {/*{current === steps.length - 1 && (*/}
        {/*  <Button type="primary" onClick={() => message.success('Processing complete!')}>*/}
        {/*    Done*/}
        {/*  </Button>*/}
        {/*)}*/}
        {current > 0 && !completed && (
          <Button style={{ marginTop: 10 }} onClick={() => prev()}>
            Precedente
          </Button>
        )}
      </div>
    </>
  );
};


/***
 * STEP1
 */
export interface IStep {
  data?: IEditContactOperation;
  onSubmit?(errors: any, values: IEditContactOperation): void
}

export function Step1(props: IStep) {
  return (
    <div style={{ marginTop: "10px", textAlign: "center" }}>
      <div style={{ display: "inline-block", marginTop: "100px" }}>
        <p><label>Richiesta da:</label></p>
        <Button
          data-testid="btnOpTypeRegistro"
          id="btnOpTypeRegistro"
          size={"large"}
          onClick={() => props.onSubmit && props.onSubmit(undefined, { type: "registry" })}>
          Registro
        </Button>
        <div style={{ display: "inline-block", width: 10 }} />
        <Button
          id="btnOpTypeRegistrante"
          size={"large"}
          onClick={() => props.onSubmit && props.onSubmit(undefined, { type: "registrant" })}>
          Registrante
        </Button>
      </div>
    </div>
  );
}


/***
 * STEP2
 */

export function Step2({ data, onSubmit }: IStep) {
  return (
    <div style={{ marginTop: "10px" }}>
      <WrappedEditEventCreationForm
        value={data}
        onSubmit={onSubmit}
      />
    </div>
  );
}

/***
 * STEP3
 */
export function Step3({ data, onSubmit }: IStep) {
  // console.log("data ", data);

  return (
    <div style={{ marginTop: "10px" }}>
      <WrappedEditContactForm
        value={data && data.contact}
        // nell'onSubmit si sovrascrivono le proprietà modificate del'oggetto contact originale
        onSubmit={(err, val) => onSubmit && onSubmit(err, { contact: { ...data?.contact, ...val } })}
        // onSubmit={(err, val) => onSubmit && onSubmit(err, { contact: val })}
        disabled={data && data.type === "registrant" && data.status === "rejected"}
      />
    </div>
  );
}


/***
 * STEP4
 */
export function Step4({ data, originalContact, onSubmit,onDone }: IStep & { originalContact: IContact , onDone(done:boolean):void}) {
  return (
    <div style={{ padding: 20 }}>
      <SummaryBox data={data} originalContact={originalContact} onDone={onDone} />
    </div>);
}


export default EditContactSteps;

