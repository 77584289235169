// const PaddedDiv = styled.div`padding: 10px`;


import {IDetailedRevision} from "../../Common/Navigation/Revisions/revisions";
import {IViewerContentType} from "../../Common/Navigation/CardWithViewer/Type/IViewerContentType";
import {DetailBox} from "../../Common/Navigation/Revisions/DetailCard/DetailCardBox";
import * as React from 'react';
import {Divider} from "antd";
import {RegistrantsInfoDiff} from "./Info/Diff/RegistrantsInfoDiff";
import {ContactsInfoDiff} from "./Info/Diff/ContactsInfoDiff";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserTie} from "@fortawesome/free-solid-svg-icons";
import i18n from 'i18next';
import {IContact} from "./contacts";

/**
 * Costruisce il contenuto da visualizzare nella modale di comparazione tre revisioni di domini.
 *
 * @param contactA
 * @param contactB
 */

export const buildDiffContent = (contactA: IDetailedRevision, contactB: IDetailedRevision) => {

    console.debug("buildDiffContent contactA: ", contactA, "contactB:", contactB)
    const cA = contactA as IContact
    const cB = contactB as IContact

    const contents: IViewerContentType[] = [];

    if (contactA && contactB) {

        Object.keys(contactA).forEach((key: string, index: number) => {
            console.debug("buildDiffContent contents[A]", contactA)


            contents[0] = {
                component: <>
                    <DetailBox revisionA={contactA.revision} revisionB={contactB.revision}>
                        <>
                            <ContactsInfoDiff type={"registrant"}
                                              contactB={contactB as IContact}
                                              contactA={contactA as IContact}/>
                            <Divider type={"horizontal"}/>
                            {contactA.registrant && contactB.registrant &&
                            <RegistrantsInfoDiff
                                registrantA={cA.registrant}
                                registrantB={cB.registrant}/>
                            }
                        </>
                    </DetailBox>
                </>,
                label: <><FontAwesomeIcon icon={faUserTie}/> {i18n.t('tab_contact')}</>,
            };
        })
    }

    return contents;
};
