import * as React from "react";
import { IStatues } from "../../../Domains/Domain/types/domain";
import { Status } from "../../Styled/Status";
import { moment } from "../../../../utils/various";

export const StatusList: React.FC<{ statuses: IStatues[] }> = props =>
  <ul>
    {
      props.statuses.map((s: IStatues, index: number) => (
        <li key={index}>
          <Status size={"small"}>{s.status}</Status>{` (${(moment(s.date).format("DD MMMM YYYY, HH:mm:ss"))})`}
        </li>
      ))}
  </ul>;
