import { ParsedQuery } from "query-string";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { Checkbox, Radio } from "antd";
import { IStreamFormat } from "../Feedback/ExportResult/ExportResult";
import * as React from "react";

/***
 * Selettore per decidere quali viste selezionare all'interno dell'esportazione
 * @param props
 * @constructor
 */
export function CheckboxViewSelector(props: {
  query: ParsedQuery,
  onChangeSelection(checkedValues: CheckboxValueType[]): void,
  options: string[],
  defaultValue?: CheckboxValueType[]
}) {


  return <div>
    <h3>Includi le informazioni</h3>
    <Checkbox.Group
      options={props.options}
      defaultValue={props.defaultValue}
      onChange={props.onChangeSelection}
    />
  </div>;
}

/***
 * Selettore del formato di esportazione passato al Downloader
 * @param props
 * @constructor
 */
export function RadioGroupExportFormat(props: { formatList: IStreamFormat[], onChangeFormat: (fmt: IStreamFormat) => void }) {
  const [format, setFormat] = React.useState<IStreamFormat | undefined>();
  const onChange = (e: any) => {
    const selecetd = props.formatList.filter((fmt) => fmt.value === e.target.value)[0];
    setFormat(selecetd);
    props.onChangeFormat(selecetd);
  };

  return <div>
    <h3>Scegli il formato d'esportazione</h3>
    <Radio.Group onChange={onChange} value={format} buttonStyle="solid">
      {props.formatList.map((f, i) => <Radio.Button key={i}
                                                    value={f.value}>{f.display}</Radio.Button>)}
    </Radio.Group>
  </div>;
}