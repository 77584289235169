import * as React from 'react';
import { IRegistrar } from './IRegistrar';
import { AxiosControllerComponent } from '../Common/Navigation/FetchController/FetchController';
import { apiGetRegistrar } from '../../const/api-calls';
import { Badge } from 'antd';
import { BadgeProps } from 'antd/lib/badge';


/**
 * Mostra lo stato del Registrante (Active, suspend,delete)  attraverso un pallino badge colorato
 * @param props
 * @constructor
 */
export const StatusBadge: React.FC<{ registrant: IRegistrar } & BadgeProps> = (props) => {
  let status: 'success' | 'warning' | 'default' | 'error';
  switch (props.registrant.status) {
    case 'active':
      status = 'success';
      break;
    case 'deleted':
      status = 'error';
      break;
    case 'suspended':
      status = 'warning';
      break;
    default:
      status = 'default';
      break;
  }
  return <Badge {...props} offset={[5, 0]}  status={status}>{props.children}</Badge>;
};


/***
 * Mostra il nome del registrar
 * @param props
 * @constructor
 */
export const RegistrarName = (props: IRegistrar) => {
  return (<span>{props.name}</span>);
};


export const Registrar: React.FC<{ registrarId: number, children: (name: IRegistrar) => void }> = ({registrarId, children}) => {

  return (
    <AxiosControllerComponent  call={apiGetRegistrar(registrarId)} >
      {(response) => <>
        {children(response.data as IRegistrar)}
      </>}
    </AxiosControllerComponent>
  );
}