import {IDomains} from "./types/domains";
import { AxiosResponse, AxiosError } from "axios";
import {IFetchStateType} from "../../Type/IFetchtStateType";

interface IDomainsActionType {
    type: 'DOMAINS_FETCH_SUCCESS' | 'DOMAINS_FETCH_ERROR' | 'DOMAINS_FETCH_LOAD' | 'DOMAINS_FETCH_CLEAN';
    payload?: AxiosResponse;
    error?: AxiosError;
    query?: any;
}



export const initialState: IFetchStateType<IDomains> = {
    loading: false,
    query: '',
    error: undefined,
    response: undefined
}

export const reducer = (state: IFetchStateType<IDomains>, action: IDomainsActionType) => {
    switch (action.type) {
        case "DOMAINS_FETCH_LOAD":
            return {loading: true, error: undefined, query: action.query, response: undefined}
        case "DOMAINS_FETCH_SUCCESS":
            return {loading: false, error: undefined, query: state.query, response: action.payload}
        case "DOMAINS_FETCH_ERROR":
            return {loading: false, error: action.error}
        case "DOMAINS_FETCH_CLEAN":
            return {loading: false, error: undefined, query: '', response: undefined}
        default:
            return state;
    }
};
export default reducer;