import * as React from 'react';

import domainReducer, {
  initialState as domainInitialState,
} from './Domains/Domain/reducer';
import domainsReducer, {
  initialState as domainsInitialState,
} from './Domains/Search/reducer';
import domainsRevisionReducer, {
  initialState as revisionInitialState,
} from './Domains/Revisions/Detail/reducer';
import domainsEventsReducer, {
  IFetchStateTypeDomainEvents,
  initialState as domainEventsInitialState,
} from './Domains/Events/reducer';
import domainRevisionsReducer, {
  initialState as domainRevisionsInitialState,
} from './Domains/Revisions/reducer';
import {
  domainAvailableEventsReducer,
  eventsRequirementsReducer,
  initialState as domainAvailableEventsInitialState,
  initialStateEventRequirements,
} from './Domains/Domain/Toolbar/reducer';
import domainNotesReducer, {
  ICustomFetchStateType,
  initialState as domainNotesInitialState,
} from './Domains/Notes/reducer';
import domainNoteReducer, {
  initialState as domainNoteInitialState,
  IReducerDomainNote,
} from './Domains/Notes/Note/reducer';

import contactReducer, {
  initialState as contactInitialState,
} from './Contacts/Detail/reducer';
import contactsReducer, {
  initialState as contactsInitialState,
} from './Contacts/Search/reducer';
import contactRevisionsReducer, {
  initialState as contactRevisionsInitialState,
} from './Contacts/Revisions/reducer';
import contactRevisionReducer, {
  initialState as contactRevisionInitialState,
} from './Contacts/Revisions/Detail/reducer';
import contactsEventsReducer, {
  IFetchStateTypeContactEvents,
  initialState as contactEventsInitialState,
} from './Contacts/Events/reducer';

import stateOrProvinceReducer, {
  initialState as stateOrProvinceInitialState,
  IStateOrProvince,
} from './Common/Input/SelectListStateProvince/reducer';

import { IDomains } from './Domains/Search/types/domains';
import { IFetchStateType } from './Type/IFetchtStateType';
import { IDomain, IEventsRequirements } from './Domains/Domain/types/domain';
import {
  IDetailedRevision,
  IRevisions,
} from './Common/Navigation/Revisions/revisions';

import { IContact } from './Contacts/Detail/contacts';
import { IContacts } from './Contacts/types/contacts';
import { IDomainNotes } from './Domains/Notes/types';

export interface IStateContextProps {
  domain: IFetchStateType<IDomain>;
  domains: IFetchStateType<IDomains>;
  domainRevisions: IFetchStateType<IRevisions>;
  domainAvailableEvents: IFetchStateType<any>;
  domainEvents: IFetchStateTypeDomainEvents;
  domainNotes: ICustomFetchStateType<IDomainNotes, { showDrawer: boolean }>;
  domainNote: IReducerDomainNote;
  revision: IFetchStateType<IDetailedRevision>;
  contactRevisions: IFetchStateType<IRevisions>;
  contacts: IFetchStateType<IContacts>;
  contact: IFetchStateType<IContact>;
  contactRevision: IFetchStateType<IDetailedRevision>;
  stateOrProvince: IFetchStateType<IStateOrProvince>;
  contactEvents: IFetchStateTypeContactEvents;
  domainContacts: IFetchStateTypeContactEvents;
  eventsRequirements: IFetchStateType<IEventsRequirements>;
}

export const DispatchContext = React.createContext({} as any);
export const StateContext = React.createContext({} as IStateContextProps);

const combineDispatch = (...dispatches: any) => (action: any) => {
  console.debug('ACTION ', action);

  dispatches.forEach((dispatch: any) => {
    dispatch(action);
  });
};

const StoreProvider: React.FC = props => {
  const [domains, domainsDispatch] = React.useReducer(
    domainsReducer,
    domainsInitialState,
  );
  const [domain, domainDispatch] = React.useReducer(
    domainReducer,
    domainInitialState,
  );
  const [revision, revisionDispatch] = React.useReducer(
    domainsRevisionReducer,
    revisionInitialState,
  );
  const [domainEvents, domainEventsDispatch] = React.useReducer(
    domainsEventsReducer,
    domainEventsInitialState,
  );
  const [domainNotes, domainNotesDispatch] = React.useReducer(
    domainNotesReducer,
    domainNotesInitialState,
  );
  const [domainNote, domainNoteDispatch] = React.useReducer(
    domainNoteReducer,
    domainNoteInitialState,
  );
  const [domainRevisions, domainRevisionsDispatch] = React.useReducer(
    domainRevisionsReducer,
    domainRevisionsInitialState,
  );
  const [contactRevisions, contactRevisionsDispatch] = React.useReducer(
    contactRevisionsReducer,
    contactRevisionsInitialState,
  );
  const [contactRevision, contactRevisionDispatch] = React.useReducer(
    contactRevisionReducer,
    contactRevisionInitialState,
  );
  const [contacts, contactsDispatch] = React.useReducer(
    contactsReducer,
    contactsInitialState,
  );
  const [contact, contactDispatch] = React.useReducer(
    contactReducer,
    contactInitialState,
  );
  const [
    domainAvailableEvents,
    domainAvailableEventsDispatch,
  ] = React.useReducer(
    domainAvailableEventsReducer,
    domainAvailableEventsInitialState,
  );
  const [eventsRequirements, eventsRequirementsDispatch] = React.useReducer(
    eventsRequirementsReducer,
    initialStateEventRequirements,
  );
  const [stateOrProvince, stateOrProvinceDispatch] = React.useReducer(
    stateOrProvinceReducer,
    stateOrProvinceInitialState,
  );
  const [contactEvents, contactEventsDispatch] = React.useReducer(
    contactsEventsReducer,
    contactEventsInitialState,
  );

  const combinedDispatch = React.useCallback(
    combineDispatch(
      domainsDispatch,
      domainDispatch,
      revisionDispatch,
      contactsDispatch,
      domainRevisionsDispatch,
      contactRevisionsDispatch,
      stateOrProvinceDispatch,
      domainEventsDispatch,
      contactDispatch,
      contactRevisionDispatch,
      domainNotesDispatch,
      domainNoteDispatch,
      domainAvailableEventsDispatch,
      eventsRequirementsDispatch,
      contactEventsDispatch,
    ),
    [
      domainsDispatch,
      domainDispatch,
      revisionDispatch,
      contactsDispatch,
      domainRevisionsDispatch,
      contactRevisionsDispatch,
      stateOrProvinceDispatch,
      domainEventsDispatch,
      contactDispatch,
      contactRevisionDispatch,
      domainNotesDispatch,
      domainNoteDispatch,
      domainAvailableEventsDispatch,
      eventsRequirementsDispatch,
      contactEventsDispatch,
    ],
  );
  const combinedState: any = React.useMemo(
    () => ({
      domains,
      domain,
      revision,
      contacts,
      domainRevisions,
      contactRevisions,
      stateOrProvince,
      domainEvents,
      contact,
      contactRevision,
      domainNotes,
      domainNote,
      domainAvailableEvents,
      contactEvents,
      eventsRequirements,
    }),
    [
      domains,
      domain,
      revision,
      contacts,
      domainRevisions,
      contactRevisions,
      stateOrProvince,
      domainEvents,
      contact,
      contactRevision,
      domainNotes,
      domainNote,
      domainAvailableEvents,
      contactEvents,
      eventsRequirements,
    ],
  );

  return (
    <DispatchContext.Provider value={combinedDispatch}>
      <StateContext.Provider value={combinedState}>
        {props.children}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export default StoreProvider;
