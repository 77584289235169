import * as React from "react";
import {API_REGISTRARS} from "../../../midaConfig";
import {axiosFetch} from "../../../utils/various";
import {SelectProps} from "antd/lib/select";
import {default as SelectLookUp} from "../../Common/SelectLookUp/SelectLookUp";
import {IRegistrar, IRegistrars} from "../IRegistrar";
import {debounce} from "lodash";

export interface IContactLookUp extends SelectProps {
    form: any;
    icon: string;
}


const RegistrarLookUp = React.forwardRef((props: IContactLookUp, ref) => {

    let currentValue: string;
    const [loading, setLoading] = React.useState(false);

    function fetch(value: any, callback: any, keycloak: any,) {
        currentValue = value;
        setLoading(true);
        axiosFetch(
            (res: any) => {
                const fetchedData = res.data as IRegistrars;
                // console.log("fetchedData!! ", fetchedData)
                if (currentValue === value && fetchedData) {
                    const data = [] as any;
                    if (fetchedData.elements) {
                        fetchedData.elements

                            // .filter((r: IRegistrar) => !r.name.includes("-MNT"))
                            .map((r: IRegistrar) => {
                                data.push({
                                    value: r.id,
                                    text: `${r.name}`,
                                })
                            });
                    }
                    callback(data);
                }
                setLoading(false);

            }, `${API_REGISTRARS}?name=startsWith:${value}`,
            keycloak)
    }


    const debouncedFetch = debounce(fetch, 300);

    return (
        <SelectLookUp forwardRef={ref} loading={loading} {...props} fetch={(a, b, c) => debouncedFetch(a, b, c)}/>
    );
})


export default RegistrarLookUp;





