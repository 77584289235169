import * as React from 'react';
import { documentTitle } from '../../../../../utils/various';
import { BoxForm } from '../../../../Common/Styled/BoxForm';
import { FetchController } from '../../../../Common/Navigation/FetchController/FetchController';
import { useRouter } from '../../../../Common/Hooks/useRouter';
import { ParsedQuery } from 'query-string';
import { IFetchStateType } from '../../../../Type/IFetchtStateType';
import { DispatchContext, StateContext } from '../../../../StoreProvider';
import { SearchPane } from './SearchPane';
import { IContacts } from '../../../types/contacts';
import { ResultsPane } from '../../ResultsPane';
import { fetchContactById } from '../../operations';

const LimitedSearchContactsComponent: React.FC<{
  query: ParsedQuery<React.ReactText>;
  contacts: IFetchStateType<IContacts>;
  showSingleDomain?: boolean;
}> = ({ query, contacts, showSingleDomain }) => (
  <BoxForm>
    <SearchPane query={query} domains={contacts} />
    <FetchController {...contacts}>
      {data => <ResultsPane contacts={data as IContacts} />}
    </FetchController>
  </BoxForm>
);

/**
 * Pagina di ricerca dominio.
 *
 * Mostra il form di ricerca dominio (AdvancedContactsSearchFormPane) con sotto la visualizzazione dei risultati.
 * Il risultato viene recuperatio tramite la fetchSingleDomain che tramite un context ritorna il dominio all'interno di domains.
 * La gestione della fetchSingleDomain è demandata al FetchController.
 *
 * Una volta ottenuti il dominio viene visualizzato per mezzo del ResultPane.
 */
const ContactSearchPage: React.FunctionComponent<{}> = props => {
  documentTitle('Ricerca contatto');

  const {
    match: { params },
  } = useRouter();

  const dispatch = React.useContext(DispatchContext);
  const { contacts } = React.useContext(StateContext);

  React.useEffect(() => {
    if (params['contact']) {
      fetchContactById(dispatch, contacts, params['contact']);
    }
  }, [params['contact']]);
  console.debug(' params.domain', params['contact']);
  return (
    <LimitedSearchContactsComponent
      query={params['contact']}
      contacts={contacts}
    />
  );
};

export default ContactSearchPage;
