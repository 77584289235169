import {IFetchStateType} from "../../Type/IFetchtStateType";
import {IRevisions} from "../../Common/Navigation/Revisions/revisions";
import {apiGetContactRevisions} from "../../../const/api-calls";

/***
 * Funzione per il recupero del dettaglio dominio relativi alla query passata.
 *
 * @param dispatch
 * @param domainRevision
 * @param contactId
 * @param keycloak
 */
export function fetchContactRevisions(dispatch: any, domainRevision: IFetchStateType<IRevisions>, contactId: string) {
    const query = `contacts/${contactId}/revisions`;

    // Se query memorizzato è diverso da quello cercato e non ci sono errori allora fetchContacts
    if (query.localeCompare(domainRevision.query) !== 0) {

        dispatch({type: 'CONTACT_REVISIONS_FETCH_LOAD', query})

        apiGetContactRevisions(contactId).then((res: any) => {
            dispatch({type: 'CONTACT_REVISIONS_FETCH_SUCCESS', query, payload: res})
        }).catch((error: any | PromiseLike<IRevisions>) => {
            dispatch({type: 'CONTACT_REVISIONS_FETCH_ERROR', error})
        })
    }
}