import * as React from "react";
import { Button, Icon } from "antd";

// import StyledButton from './styled/StyledButton';

export interface ISortOrderButton {
  /** Callback function to be called when user clicks on button */
  onClick?: (a: any) => void;
  children: string | React.ReactElement<any>;
  /** Renders a disabled button and prevents onClick */
  disabled?: boolean;
  /** Nome del campo */
  fieldName: string;
  /** Nome del campo passato */
  value?: string;
  /** Direzione di ordinamento passata */
  order?: string;
}

/**
 * Bottone che gestisce l'ordinamento dei campi nei risultati
 * @param children
 * @param disabled
 * @param value
 * @param fieldName
 * @param order
 * @param onClick
 * @constructor
 */
const SortOrderButton: React.FC<ISortOrderButton> = (
  {
    children,
    disabled = false,
    value,
    fieldName,
    order,
    onClick
  }) => {

  const [direction, setDirection] = React.useState(init(order));
  const selected = fieldName === value;

  function init(ord?: string) {
    if (ord === "ascend" || ord === "asc") {
      return "ascend";
    }
    if (ord === "descend" || ord === "desc") {
      return "descend";
    }
    return undefined;
  }

  const handleOnClick = () => {
    let ord = direction;

    if (ord === undefined) {
      ord = "ascend";
    } else if (ord === "ascend") {
      ord = "descend";
    } else if (ord === "descend") {
      ord = "ascend";
    }

    if (onClick) {
      onClick({ field: fieldName, order: ord });
    }

    setDirection(ord);
  };

  return (
    <Button disabled={disabled} onClick={handleOnClick}>
      {children} {selected ? <Icon type={direction === "descend" ? `caret-down` : `caret-up`} /> : ""}
    </Button>
  );
};

export default SortOrderButton;
