import { IProtocol } from './documents';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import { ProtocolContent } from './ProtocolContent';
import { moment } from '../../utils/various';
import { DMYdotHMS } from '../../const/common';

export function PopoverProtocol(props: {
  data: IProtocol;
  showTitle?: boolean;
}) {
  return (
    <Popover
      content={<ProtocolContent {...props.data} />}
      title={
        <div>
          <FontAwesomeIcon icon={faPaperclip} />
          {` Dettaglio Protocollo`}
        </div>
      }
    >
      <a target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faPaperclip} />
        {props.showTitle && (
          <>{` ${props.data.domain}, ${props.data.registrar} (${moment(
            props.data.dateArrive,
          ).format(DMYdotHMS)})`}</>
        )}
      </a>
    </Popover>
  );
}
