import * as React from 'react';
import { Button, Col, Form, Input, InputNumber, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { IRegistryEvent } from '../types/domainEvents';
import { GenericDocument } from '../../../Documents/documents';
import { capitalizeFirstLetter } from '../../../../utils/various';
import { IEventRequirement } from '../../Domain/types/domain';
import { AddModifyDocumentButton } from './AddModifyDocumentButton';
import {PopoverDocument} from "../../../Documents/PopoverDocument";

const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

interface IOperationForm extends FormComponentProps {
  value?: IRegistryEvent;
  availableEvent: IEventRequirement;
  documentValue?: GenericDocument | undefined;
  onSubmit: (err: any, values: any) => void;
}

/***
 * Form impiegata per definire i campi utili alla creazione di un nuovo evento
 * @param value
 * @param onSubmit
 * @param form
 * @param documentValue
 * @param availableEvent
 * @constructor
 */
const AvailableEventForm: React.FC<IOperationForm> = ({
  value,
  onSubmit,
  form,
  documentValue,
  availableEvent,
}) => {
  const hasRequiredDocument = availableEvent.document === 'required';
  const hasRequiredDescription = availableEvent.description === 'required';

  const [localDocument, setLocalDocument] = React.useState<{
    error?: string;
    document?: GenericDocument;
    status?: number;
  }>({});

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      onSubmit(err, { ...values, document: localDocument });
    });
  };

  const handleOnSubmitAddDocument = (
    genericDocument?: GenericDocument,
    error?: string,
  ) => {
    setLocalDocument({ error, document: genericDocument });
    if (genericDocument) {
      form.setFieldsValue({ documentId: genericDocument.id });
    }
  };

  const ShowDocument = (documentData: GenericDocument) => (
    <Col span={2}>
      <div style={{ paddingLeft: 10 }}>
        <PopoverDocument {...documentData} />
      </div>
    </Col>
  );

  return (
    <div className="available_event_form">
      <Form layout="horizontal" onSubmit={handleSubmit}>
        {/* Name */}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item {...formItemLayout} label={<span>Nome</span>}>
              {form.getFieldDecorator('name', {
                initialValue: value && value.name,
              })(<Input style={{ fontWeight: 'bold' }} disabled />)}
            </Form.Item>
          </Col>
        </Row>

        {/* Status */}
        <Row>
          <Col span={24}>
            <Form.Item
              {...formItemLayout}
              style={{ display: 'none' }}
              label={<span>Status</span>}
            >
              {form.getFieldDecorator('status', {
                initialValue: value && value.status,
              })(<Input style={{ fontWeight: 'bold' }} disabled />)}
            </Form.Item>
          </Col>
        </Row>

        {/* Description */}
        <Row>
          <Col span={24}>
            <Form.Item {...formItemLayout} label={<span>Descrizione</span>}>
              {form.getFieldDecorator('description', {
                rules: [
                  {
                    required: hasRequiredDescription,
                    message: "E' necessario inserire una descrizione",
                  },
                ],
                initialValue: value && value.description,
              })(
                <TextArea
                  data-testid="textareaDescription"
                  rows={4}
                  placeholder={'Inserire una descrizione ...'}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>

        {/* Document */}
        <Row>
          <Col span={24}>
            <Form.Item
              {...formItemLayout}
              label={
                localDocument.document
                  ? capitalizeFirstLetter(localDocument.document.documentType)
                  : 'Documento'
              }
            >
              <Row>
                <Col span={22}>
                  {form.getFieldDecorator('documentId', {
                    initialValue:
                      localDocument?.document?.id || documentValue?.id,
                    rules: [
                      {
                        required: hasRequiredDocument,
                        message: "E' necessario inserire un documento",
                      },
                    ],
                  })(<InputNumber style={{ width: '100%' }} disabled />)}
                  {localDocument.error && (
                    <div
                      className="ant-form-explain"
                      style={{ color: '#f5222d' }}
                    >
                      {localDocument.error}
                    </div>
                  )}
                </Col>
                {localDocument.document && ShowDocument(localDocument.document)}
                {documentValue && ShowDocument(documentValue)}
              </Row>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <AddModifyDocumentButton
                onSubmitAddDocument={handleOnSubmitAddDocument}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Buttons */}
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button onClick={() => form.resetFields()}>Cancella</Button>
            <Button
              data-testid={'btnNext'}
              style={{ marginLeft: 8 }}
              type="primary"
              htmlType="submit"
            >
              Applica
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export const WrappedAvailableEventForm = Form.create<IOperationForm>({
  name: 'operation_form',
})(AvailableEventForm);

/***
 * {"id":14402219,"documentType":"protocol","protNum":55555,"protYear":5444,"dateArrive":"2021-03-11T14:34:22","numpag":11}
 */
