import styled from 'styled-components';
import FAIcon from '../../../../Common/Layout/FAIcon/FAIcon';
import * as React from 'react';
import { EX_DATE_ALERT, EX_DATE_EXPIRE, EX_DATE_EXPIRED, EX_DATE_INFO } from './const';

function themeChooser(dateType: any) {
  switch (dateType) {
    case EX_DATE_ALERT:
      return { border: '1px solid #fff3cf', color: '#ffa340' };
    case EX_DATE_EXPIRE:
      return { border: '1px solid #fcd0ef', color: '#fe0d4a' };
    case EX_DATE_INFO:
      return { border: '1px solid #d2eafb', color: '#54affd' };
    default:
      return { border: '1px solid gray', color: 'gray' };
  }
}

const CountDown = styled.div<{ type: any }>`
  ${props => themeChooser(props.type)};
  font-size: 10px;
  padding: 2px;
  border-radius: 15px;
  width: 22px;
  text-align: center;
  display: inline-block;
`;

const DateBox = styled.span``;

const SpanDate = styled.span<{ type: any }>`
  color: ${props => (props.type === EX_DATE_EXPIRED ? 'red' : '')};
  margin-right: 5px;
`;

interface IExpireDate {
  date: string;
  type: string;
  days: number;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  onFocus?(): void;
  onClick?(): void;
}

const ExpireDate: React.FC<IExpireDate> = props => (
  <DateBox
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    onFocus={props.onFocus}
    onClick={props.onClick}
  >
    <SpanDate type={props.type}>{props.date}</SpanDate>
    {[EX_DATE_ALERT, EX_DATE_INFO, EX_DATE_EXPIRE].includes(props.type) ? (
      <CountDown type={props.type}>{props.days}</CountDown>
    // ) : type === EX_DATE_EXPIRED.toString ? (
    ) : props.type === EX_DATE_EXPIRED ? (
      <FAIcon style={{ color: 'red' }} />
    ) : (
      ''
    )}
  </DateBox>
);

// ExpireDate.propTypes = {
//   date: PropTypes.string,
//   type: PropTypes.oneOf([EX_DATE_EXPIRED, EX_DATE_INFO, EX_DATE_EXPIRE, EX_DATE_ALERT,'']),
//   days: PropTypes.number
// };

export default ExpireDate;
