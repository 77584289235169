import * as React from 'react';
import { Card, Form, Select } from 'antd';
import { SelectStatusMono } from '../../Common/Input/SelectStatus/SelectStatus';
import { FormComponentProps } from 'antd/lib/form/Form';
import { SelectProps } from 'antd/lib/select';
import * as events from 'events';
import { useQuery } from '../../../utils/various';
import { useHistory } from 'react-router-dom';
import { stringify } from 'query-string';
import { omit } from 'lodash';
import { Flex } from '../../Common/Layout/Flex/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

export const EventSelectorPane: React.FC<{
  style?: React.CSSProperties;
  onAddFilter?: (filter: any, values: any) => void;
  onClick?: (e: events) => void;
}> = ({ style, onAddFilter }) => {
  return (
    <Card
      size="small"
      title="Filtra Eventi"
      style={{
        ...style,
        backgroundColor: '#FBFBFB',
        borderRadius: '5px',
        marginBottom: 10,
      }}
    >
      <Flex flexDirection={'row'} container style={{ gap: 5 }}>
        <Flex flexDirection={'column'} container>
          Stato
          <EventStatusSelector onAddFilter={onAddFilter} />
        </Flex>
        <Flex flexDirection={'column'} container>
          Nome evento
          <EventSelector onAddFilter={onAddFilter} />
        </Flex>
      </Flex>
    </Card>
  );
};

export interface IEventSelector {
  onAddFilter?: (filter: any, values: any) => void;
  onClick?: (e: events) => void;
}

const EventSelector: React.FC<IEventSelector> = ({ onAddFilter }) => {
  const query = useQuery();
  const h = useHistory();
  const handle = (c: any) => {
    if (c && onAddFilter) {
      onAddFilter('name', c);
    } else {
      h.push(h.location.pathname + '?' + stringify(omit(query, 'name')));
    }
  };
  return (
    <SelectStatusMonoForm
      style={{ width: 300 }}
      value={query['name'] ? (query['name'] as string) : undefined}
      onChange={handle}
    />
  );
};

const EventStatusSelector: React.FC<IEventSelector> = ({ onAddFilter }) => {
  const query = useQuery();
  const h = useHistory();
  const param = 'status';
  const handle = (c: any) => {
    if (c && onAddFilter) {
      onAddFilter(param, c);
    } else {
      h.push(h.location.pathname + '?' + stringify(omit(query, param)));
    }
  };
  return (
    <Select
      onChange={handle}
      style={{ width: '150px' }}
      value={query[param] ? (query[param] as string) : undefined}
      placeholder={'Stato evento'}
      allowClear
    >
      <Select.Option value="succeeded">
        <FontAwesomeIcon icon={faCheck} /> - Successo
      </Select.Option>
      <Select.Option value="failed">
        <FontAwesomeIcon icon={faXmark} /> - Fallito
      </Select.Option>
    </Select>
  );
};

export interface ISelectStatusMonoForm {
  style?: React.CSSProperties;
  item?: any;
}

export const SelectStatusMonoForm = Form.create<
  ISelectStatusMonoForm & SelectProps & FormComponentProps
>({
  name: 'select_status_mono_form',
})(SelectStatusMono);
