import {IDomain} from '../../../Domain/types/domain';
import {ISortedInfo, ITabledResult} from '../../../../Type/Common';
import {Table} from 'antd';
import * as React from 'react';
import {AbilityContext} from '../../../../Common/Ability/AbilityContext';
import {HeaderIcon} from '../../../../Contacts/Search/TabledContactsResults';
import {faEdit, faGlobe, faHome, faTag, faUser, faUserTie} from '@fortawesome/free-solid-svg-icons';
import {moment} from '../../../../../utils/various';
import {IContact} from '../../../../Contacts/Detail/contacts';
import {IRegistrar} from '../../../../Registrar/IRegistrar';
import AddFilter from '../../../../Common/Navigation/Filter/AddFilter';
import {faPlug} from '@fortawesome/free-solid-svg-icons/faPlug';
import {DomainNameTitle} from '../../../Domain/Info/DomainName/DomainName';
import {columnSortOrder} from '../../../../../utils/url-manipulation';
import {Link} from 'react-router-dom';
import {FilteredLabelField} from '../../../../Common/Styled/TableWrapped';
import {Status} from '../../../../Common/Styled/Status';
import {Registrar} from '../../../../Registrar/Registrar';
import {JbillingLink} from '../../../../Registrar/JBillingLink';

export type IDomainTabledResults = { data: IDomain[] | undefined } & ITabledResult;
const {Column} = Table;


/***
 * Visualizza  una tabella con la lista dei domini
 * @param props
 * @constructor
 */

const TabledResult: React.FC<IDomainTabledResults> = (props) => {
    const [sortedInfo] = React.useState<ISortedInfo>(
        {order: props.sortOrder, columnKey: props.sortColumnKey},
    );
    const {ability} = React.useContext(AbilityContext);
    return (
        <Table
            dataSource={props.data}
            rowKey={(record) => record.id.toString()}
            pagination={false}
            bordered
            scroll={{x:true}}
            onChange={props.handleOnChange}

        >
            {/* Domain */}
            <Column
                title={<span><HeaderIcon icon={faGlobe}/> Domain</span>}
                dataIndex={'domain'}
                key={'domain'}
                sorter={true}
                sortOrder={columnSortOrder('domain', sortedInfo)}
                render={(text: any, record: IDomain) =>
                    <DomainNameTitle key={'title' + record.id} domain={record} ability={ability}/>}
            />

            {/* Registrar */}
            <Column
                title={<span><HeaderIcon icon={faHome}/> Registrar</span>}
                dataIndex='registrarId'
                key='registrarId'
                render={(text: string, record: IContact) => {
                    return <div key={'reg' + record.id}>
                        <Registrar registrarId={record.registrarId} children={(registrar: IRegistrar) =>
                            <FilteredLabelField>
                                <JbillingLink registrar={registrar}/>
                                <AddFilter
                                    text={{key: registrar.id, label: registrar.name}}
                                    filter={'registrarId'}
                                    onAddFilter={props.onAddFilter}
                                />
                            </FilteredLabelField>}/>
                    </div>;
                }}
            />

            {/* Registrant*/}
            <Column
                title={<span><HeaderIcon icon={faUserTie}/> Registrant</span>}
                dataIndex={'registrant'}
                key={'registrant'}
                render={(text: string, record: IDomain) => {
                    return <div
                        key={'registrant' + record.id}>
                        {record.registrant &&
                            <FilteredLabelField>
                                <Link
                                    to={`/contacts/${record.registrant.id.toString()}`}>{record.registrant.org || record.registrant.name}</Link>
                                <AddFilter
                                    text={record.registrant && record.registrant.contactId}
                                    filter={'registrant.contactId'}
                                    onAddFilter={props.onAddFilter}
                                />
                            </FilteredLabelField>}
                    </div>;
                }}
            />

            {/* Admin */}
            <Column
                title={<span><HeaderIcon icon={faUser}/> Admin</span>}
                dataIndex={'admin'}
                key={'admin'}
                render={(text: string, record: IDomain) => {
                    return <div key={'admin' + record.id}>
                        {record.admin &&
                            <FilteredLabelField>
                                <Link
                                    to={`/contacts/${record.admin.id.toString()}`}>{record.admin.org || record.admin.name}</Link>
                                <AddFilter
                                    text={record.admin && record.admin.contactId}
                                    filter={'admin.contactId'}
                                    onAddFilter={props.onAddFilter}
                                />
                            </FilteredLabelField>
                        }
                    </div>;
                }}
            />

            {/* Status */}
            <Column
                title={<span><HeaderIcon icon={faTag}/> Status</span>}
                dataIndex={'status'}
                key={'status'}
                render={(text: string, record: IDomain) => {
                    return <div key={'status' + record.status}>
                        <FilteredLabelField>
                            <Status size={'small'}>{record.status}</Status>
                            <AddFilter text={record.statuses.map((s: { status: string, date: any }) => s.status)}
                                       filter={'status'}
                                       onAddFilter={props.onAddFilter}
                            />
                        </FilteredLabelField>
                    </div>;
                }}
            />

            {/* Created */}
            <Column
                title={<span><HeaderIcon icon={faPlug}/> Created</span>}
                dataIndex='created'
                key='created'
                sorter={true}
                sortOrder={columnSortOrder('created', sortedInfo)}
                render={(text: string, record: IContact) => {
                    return <div
                        key={'created' + record.id}>{moment(text).format('LLL')}
                    </div>;
                }}
            />

            {/* Last Update*/}
            <Column
                title={<span><HeaderIcon icon={faEdit}/> Last Update</span>}
                dataIndex='lastupdate'
                key='lastupdate'
                sorter={true}
                sortOrder={columnSortOrder('lastupdate', sortedInfo)}
                render={(text: string, record: IContact) => {
                    return <div
                        key={'lastupdate' + record.id}>{moment(text).format('LLL')}
                    </div>;
                }}
            />

        </Table>
    );
};
export default TabledResult;