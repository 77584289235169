import styled from "styled-components";

/***
 * Description List Box
 */
export const DlBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 20px;
`;

/***
 * Description List
 */
export const Dl = styled.dl`
  display: inline;
  margin: 0;
  margin-bottom: 30px;
  margin-right: 20px;
`;

/***
 * Define Term
 */
export const Dt = styled.dt`
  float: left;
  width: auto;
  font-weight: bold;
`;

/***
 * Define description term
 */
export const Dd = styled.dd`
  margin-left: 200px;
`;
