import * as React from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Checkbox,
  DatePicker,
  Typography,
  Divider,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { ParsedQuery } from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faEraser,
  faHouseUser,
  faLock,
  faServer,
  faUser,
  faUserCog,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { multipleMomentValues } from '../../../../../../utils/various';
import SelectEntityType from '../../../../../Common/Input/SelectEntityType/SelectEntityType';
import SelectCountry from '../../../../../Common/Input/SelectCountry/SelectCountry';
import RegistrarLookUp from '../../../../../Registrar/LocaStorage/RegistrarLookUp';
import {
  RegCodeTips,
  SchoolCodeTips,
  StatuseTips,
  StateOrProvinceTips,
} from '../../../../../../const/TipsComponent';
import { SelectStatusMulti } from '../../../../../Common/Input/SelectStatus/SelectStatus';
import SelectStateOrProvince from '../../../../../Common/Input/SelectStateOrProvince/SelectStateOrProvince';
import { YMD, YMDHMS } from '../../../../../../const/common';
import { transformNestedFieldsIntoNestedDotFields } from '../../../utils';

const RangePicker = DatePicker.RangePicker;

// TODO finire di completare la customizzazione dei campi di ricerca e capire le regole e i validatori

export interface IAdvancedDomainsSearchForm {
  advanced?: boolean;
  values: ParsedQuery<React.ReactText>;
  onSubmit: (err: any, values: any) => void;
}

const AdvancedDomainsSearchRawForm: React.FC<IAdvancedDomainsSearchForm &
  FormComponentProps> = React.memo(props => {
  // const { query } = useRouter();

  // const [expand, setExpand] = React.useState(
  //   Object.keys(query).includes('advancedSearch'),
  // );
  const [itemValues, setItemValues] = React.useState<
    ParsedQuery<React.ReactText> | undefined
  >({});

  // console.log('qury', Object.keys(query).includes('advancedSearch'));
  React.useEffect(() => {
    setItemValues(props.values);
  }, [props.values]);

  // Per generare dinamicamente i campi da visualizzare
  const getFields = () => {
    const { getFieldDecorator } = props.form;
    const children = [];

    // if (expand) {
    // ==========
    //  COMMON
    // ==========

    // ID
    // children.push(
    //   <Col xs={24} md={12} xl={8} key={'domain'} style={{ display: 'block' }}>
    //     <Form.Item>
    //       {getFieldDecorator(`domain`, {
    //         initialValue: (itemValues && itemValues.domain) || undefined,
    //
    //         rules: [
    //           {
    //             required: false,
    //             message: 'Inserisci un dominio',
    //           },
    //         ],
    //       })(
    //         <Input
    //           prefix={
    //             <FontAwesomeIcon
    //               icon={faGlobe}
    //               style={{ color: 'rgba(0,0,0,.25)' }}
    //             />
    //           }
    //           placeholder="dominio.it"
    //           allowClear
    //         />,
    //       )}
    //     </Form.Item>
    //   </Col>,
    // );

    // Deleted
    children.push(
      <Col span={4} key={'deleted'} style={{ display: 'block' }}>
        <Form.Item>
          {getFieldDecorator('showDeleted', {
            valuePropName: 'checked',
            initialValue: (itemValues && itemValues.showDeleted) || true,
          })(
            <Checkbox>
              <FontAwesomeIcon
                icon={faEraser}
                style={{ color: 'rgba(0,0,0,.25)' }}
              />{' '}
              {'deleted'}
            </Checkbox>,
          )}
        </Form.Item>
      </Col>,
    );

    // Signed
    children.push(
      <Col span={4} key={'signed'} style={{ display: 'block' }}>
        <Form.Item>
          {getFieldDecorator('signed', {
            valuePropName: 'signed',
            initialValue: itemValues && itemValues.signed,
          })(
            <Checkbox>
              <FontAwesomeIcon
                icon={faLock}
                style={{ color: 'rgba(0,0,0,.25)' }}
              />{' '}
              {'signed'}
            </Checkbox>,
          )}
        </Form.Item>
      </Col>,
    );

    // Status
    children.push(
      <Col xs={24} md={12} xl={8} key={'status'} style={{ display: 'block' }}>
        <Form.Item label={<StatuseTips />}>
          {getFieldDecorator(`status`, {
            initialValue: (itemValues && itemValues.status) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(
            // <   SS {...props}/>
            // <SelectStatus {...props}/>
            // <SelectStatus2 {...props}/>
            <SelectStatusMulti {...props} />,
            // <Cascader options={options} changeOnSelect/>
            // <Cascader changeOnSelect options={CASCADER_STATUS_OPTIONS}/>
          )}
        </Form.Item>
      </Col>,
    );

    // Hostname
    children.push(
      <Col xs={24} md={12} xl={8} key={'hostname'} style={{ display: 'block' }}>
        <Form.Item label={'Nameserver'}>
          {getFieldDecorator(`hostname`, {
            initialValue: (itemValues && itemValues.hostname) || undefined,

            rules: [
              {
                required: false,
                message: 'Input something!',
              },
            ],
          })(
            <Input
              prefix={
                <FontAwesomeIcon
                  icon={faServer}
                  style={{ color: 'rgba(0,0,0,.25)' }}
                />
              }
              placeholder="hostname o IP"
              allowClear
            />,
          )}
        </Form.Item>
      </Col>,
    );

    // ============
    //  REGISTRAR
    // ============

    children.push(
      <Divider key={'divRegistrar'} type={'horizontal'}>
        {' '}
        <FontAwesomeIcon icon={faHouseUser} /> Registrar
      </Divider>,
    );

    // RegistrarId
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'RegistrarId'}
        style={{ display: 'block' }}
      >
        <Form.Item label={'Registrar'}>
          {getFieldDecorator(`registrarId`, {
            initialValue:
              itemValues &&
              itemValues[`registrarId.label`] &&
              itemValues[`registrarId.key`]
                ? {
                    key: itemValues[`registrarId.key`],
                    label: itemValues[`registrarId.label`],
                  }
                : undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(
            <RegistrarLookUp
              icon={'home'}
              {...props}
              allowClear
              placeholder={<React.Fragment>es. REGISTRY-REG</React.Fragment>}
            />,
          )}
        </Form.Item>
      </Col>,
    );

    // RegistrarCreated
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrarCreated'}
        style={{ display: 'block' }}
      >
        <Form.Item label={'RegistrarCreated'}>
          {getFieldDecorator(`registrarIdCreated`, {
            initialValue:
              itemValues &&
              itemValues[`registrarIdCreated.label`] &&
              itemValues[`registrarIdCreated.key`]
                ? {
                    key: itemValues[`registrarIdCreated.key`],
                    label: itemValues[`registrarIdCreated.label`],
                  }
                : undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(
            <RegistrarLookUp
              icon={'home'}
              form={props.form}
              allowClear
              placeholder={<React.Fragment>es. REGISTRY-REG</React.Fragment>}
            />,
          )}
        </Form.Item>
      </Col>,
    );

    // ============
    //  REGISTRANT
    // ============

    children.push(
      <Divider key={'divRegistrant'} type={'horizontal'}>
        {' '}
        <FontAwesomeIcon icon={faUserTie} /> Registrant
      </Divider>,
    );

    // RegistrantContactID
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantContactID'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`ContactId`}>
          {getFieldDecorator(`registrant.contactId`, {
            initialValue:
              (itemValues && itemValues[`registrant.contactId`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. REG1" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // RegistrantName
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantName'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`Name`}>
          {getFieldDecorator(`registrant.name-ft`, {
            initialValue:
              (itemValues && itemValues[`registrant.name-ft`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. Mario Rossi" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // RegistrantRegCode
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantRegcode'}
        style={{ display: 'block' }}
      >
        <Form.Item label={<RegCodeTips />}>
          {getFieldDecorator(`registrant.regcode`, {
            initialValue:
              (itemValues && itemValues[`registrant.regcode`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. 02118311006" allowClear />)}
        </Form.Item>
      </Col>,
    );

    // RegistrantOrg
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantOrg'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`Org`}>
          {getFieldDecorator(`registrant.org-ft`, {
            initialValue:
              (itemValues && itemValues[`registrant.org-ft`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(
            <Input placeholder="es. Istituto Informatica " allowClear={true} />,
          )}
        </Form.Item>
      </Col>,
    );

    // RegistrantCity
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'RegistrantCity'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`City`}>
          {getFieldDecorator(`registrant.city`, {
            initialValue:
              (itemValues && itemValues[`registrant.city`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. Pisa" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // RegistrantSchoolCode
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantSchoolcode'}
        style={{ display: 'block' }}
      >
        <Form.Item label={<SchoolCodeTips />}>
          {getFieldDecorator(`registrant.schoolCode`, {
            initialValue:
              (itemValues && itemValues[`registrant.schoolCode`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. PIIC83300V" allowClear />)}
        </Form.Item>
      </Col>,
    );

    // RegistrantEntityType
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantEntityType'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`Entity Type`}>
          {getFieldDecorator(`registrant.registrantType`, {
            initialValue:
              (itemValues && itemValues[`registrant.registrantType`]) ||
              undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<SelectEntityType {...props} disabled={false} />)}
        </Form.Item>
      </Col>,
    );

    // nationality
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantNationality'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`Nationality`}>
          {getFieldDecorator(`registrant.nationality`, {
            initialValue:
              (itemValues && itemValues[`registrant.nationality`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<SelectCountry {...props} placeholder="es. IT (Italy)" />)}
        </Form.Item>
      </Col>,
    );

    // Country
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantCountry'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`Country`}>
          {getFieldDecorator(`registrant.country`, {
            initialValue:
              (itemValues && itemValues[`registrant.country`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<SelectCountry {...props} placeholder="es. IT (Italy)" />)}
        </Form.Item>
      </Col>,
    );

    // StateOrProvince
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantState'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`StateOrProvince`}>
          {getFieldDecorator(`registrant.state`, {
            initialValue:
              (itemValues && itemValues[`registrant.state`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<SelectStateOrProvince />)}
        </Form.Item>
      </Col>,
    );

    // email
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantEmail'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`E-mail`}>
          {getFieldDecorator(`registrant.email`, {
            initialValue:
              (itemValues && itemValues[`registrant.email`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. mario.rossi@nic.it" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // ==========
    //  ADMIN
    // ==========
    children.push(
      <Divider key={'divAdmin'} type={'horizontal'}>
        {' '}
        <FontAwesomeIcon icon={faUser} /> Admin
      </Divider>,
    );

    // AdminContactID
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'AdminContactID'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`ContactId`}>
          {getFieldDecorator(`admin.contactId`, {
            initialValue:
              (itemValues && itemValues[`admin.contactId`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. ADM1" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // AdminName
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'adminName'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`Name`}>
          {getFieldDecorator(`admin.name-ft`, {
            initialValue:
              (itemValues && itemValues[`admin.name-ft`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. Mario Rossi" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // AdminOrg
    children.push(
      <Col xs={24} md={12} xl={8} key={'adminOrg'} style={{ display: 'block' }}>
        <Form.Item label={`Org`}>
          {getFieldDecorator(`admin.org-ft`, {
            initialValue:
              (itemValues && itemValues[`admin.org-ft`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(
            <Input placeholder="es. Istituto Informatica" allowClear={true} />,
          )}
        </Form.Item>
      </Col>,
    );

    // AdminCity
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'AdminCity'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`City`}>
          {getFieldDecorator(`admin.city`, {
            initialValue: (itemValues && itemValues[`admin.city`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. Pisa" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // Country
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'adminCountry'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`Country`}>
          {getFieldDecorator(`admin.country`, {
            initialValue:
              (itemValues && itemValues[`admin.country`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<SelectCountry {...props} placeholder="es. IT (Italy)" />)}
        </Form.Item>
      </Col>,
    );

    // StateOrProvince
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'adminState'}
        style={{ display: 'block' }}
      >
        <Form.Item label={<StateOrProvinceTips />}>
          {getFieldDecorator(`admin.state`, {
            initialValue:
              (itemValues && itemValues[`admin.state`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<SelectStateOrProvince />)}
        </Form.Item>
      </Col>,
    );

    // email
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'adminEmail'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`E-mail`}>
          {getFieldDecorator(`admin.email`, {
            initialValue:
              (itemValues && itemValues[`admin.email`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. mario.rossi@nic.it" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // ==========
    //  TECH
    // ==========
    children.push(
      <Divider key={'divTech'} type={'horizontal'}>
        {' '}
        <FontAwesomeIcon icon={faUserCog} /> Tech
      </Divider>,
    );

    // TechContactID
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'techContactID'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`ContactId`}>
          {getFieldDecorator(`tech.contactId`, {
            initialValue:
              (itemValues && itemValues[`tech.contactId`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. TECH1" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // TechName
    children.push(
      <Col xs={24} md={12} xl={8} key={'techName'} style={{ display: 'block' }}>
        <Form.Item label={`Name`}>
          {getFieldDecorator(`tech.name-ft`, {
            initialValue:
              (itemValues && itemValues[`tech.name-ft`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. Mario Rossi" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // TechOrg
    children.push(
      <Col xs={24} md={12} xl={8} key={'techOrg'} style={{ display: 'block' }}>
        <Form.Item label={`Org`}>
          {getFieldDecorator(`tech.org-ft`, {
            initialValue:
              (itemValues && itemValues[`tech.org-ft`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(
            <Input placeholder="es. Istituto Informatica" allowClear={true} />,
          )}
        </Form.Item>
      </Col>,
    );

    // TechCity
    children.push(
      <Col xs={24} md={12} xl={8} key={'TechCity'} style={{ display: 'block' }}>
        <Form.Item label={`City`}>
          {getFieldDecorator(`tech.city`, {
            initialValue: (itemValues && itemValues[`tech.city`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. Pisa" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // Country
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'techCountry'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`Country`}>
          {getFieldDecorator(`tech.country`, {
            initialValue:
              (itemValues && itemValues[`tech.country`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<SelectCountry {...props} placeholder="es. IT (Italy)" />)}
        </Form.Item>
      </Col>,
    );

    // StateOrProvince
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'techState'}
        style={{ display: 'block' }}
      >
        <Form.Item label={<StateOrProvinceTips />}>
          {getFieldDecorator(`tech.state`, {
            initialValue: (itemValues && itemValues[`tech.state`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<SelectStateOrProvince />)}
        </Form.Item>
      </Col>,
    );

    // email
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'techEmail'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`E-mail`}>
          {getFieldDecorator(`tech.email`, {
            initialValue: (itemValues && itemValues[`tech.email`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. mario.rossi@nic.it" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // ==========
    //  DATE
    // ==========

    children.push(
      <Divider key={'divDate'} type={'horizontal'}>
        {' '}
        <FontAwesomeIcon icon={faCalendar} /> Date
      </Divider>,
    );

    // Created Range
    children.push(
      <Col xs={24} md={12} xl={8} key={'created'} style={{ display: 'block' }}>
        <Form.Item label={'Created'}>
          {getFieldDecorator(`created-range`, {
            initialValue:
              (itemValues &&
                multipleMomentValues(itemValues, 'created-range')) ||
              undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<RangePicker style={{ width: '100%' }} showToday showTime />)}
        </Form.Item>
      </Col>,
    );

    // Expire
    children.push(
      <Col xs={24} md={12} xl={8} key={'expire'} style={{ display: 'block' }}>
        <Form.Item label={'Expire'}>
          {getFieldDecorator(`expire-range`, {
            initialValue:
              (itemValues &&
                multipleMomentValues(itemValues, 'expire-range')) ||
              undefined,
            rules: [
              {
                required: false,
                message: 'Input something!',
              },
            ],
          })(<RangePicker style={{ width: '100%' }} showToday />)}
        </Form.Item>
      </Col>,
    );

    // Last Update Range
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'lastUpdate'}
        style={{ display: 'block' }}
      >
        <Form.Item label={'LastUpdate'}>
          {getFieldDecorator(`lastupdate-range`, {
            initialValue:
              (itemValues &&
                multipleMomentValues(itemValues, 'lastupdate-range')) ||
              undefined,
            rules: [
              {
                required: false,
                message: 'Input something!',
              },
            ],
          })(<RangePicker style={{ width: '100%' }} showTime />)}
        </Form.Item>
      </Col>,
    );
    // } else {
    //   // ID
    //   children.push(
    //     <Col span={9} key={'domain2'} style={{ display: 'block' }}>
    //       <Form.Item label={``}>
    //         {getFieldDecorator(`domain`, {
    //           initialValue: (itemValues && itemValues.domain) || undefined,
    //
    //           rules: [
    //             {
    //               required: true,
    //               message: 'Inserisci un nome a dominio .it',
    //             },
    //           ],
    //         })(
    //           <Input
    //             prefix={
    //               <FontAwesomeIcon
    //                 icon={faGlobe}
    //                 style={{ color: 'rgba(0,0,0,0.25)' }}
    //               />
    //             }
    //             placeholder="dominio.it"
    //             allowClear={true}
    //           />,
    //         )}
    //       </Form.Item>
    //     </Col>,
    //   );
    //
    //   // Deleted
    //   children.push(
    //     <Col span={3} key={'deleted2'} style={{ display: 'block' }}>
    //       <Form.Item>
    //         {getFieldDecorator('showDeleted', {
    //           valuePropName: 'checked',
    //           initialValue: (itemValues && itemValues.showDeleted) || true,
    //         })(
    //           <Checkbox data-testid="deleted-checkbox">
    //             <FontAwesomeIcon
    //               icon={faEraser}
    //               style={{ color: 'rgba(0,0,0,.25)' }}
    //             />{' '}
    //             {'deleted'}
    //           </Checkbox>,
    //         )}
    //       </Form.Item>
    //     </Col>,
    //   );
    // }

    return children;
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    props.form.validateFields((err: any, values: any) => {
      // console.log('Advanced Domain Form  - Received values: ', values,);

      if (err) {
        console.error('***** err', err);
      }

      if (!err) {
        // Should format date value before submit.
        const createdRange = values['created-range'] || [];
        const expireRange = values['expire-range'] || [];
        const lastupdateRange = values['lastupdate-range'] || [];

        let valuesToSubmit: any;
        valuesToSubmit = {
          ...values,
          'created-field': values['created-field']
            ? values['created-field'].format(YMDHMS)
            : undefined,
          'expire-field': values['expire-field']
            ? values['expire-field'].format(YMDHMS)
            : undefined,
          'lastupdate-field': values['lastupdate-field']
            ? values['lastupdate-field'].format(YMDHMS)
            : undefined,
          'created-range':
            createdRange && createdRange.length > 0
              ? [createdRange[0].format(YMDHMS), createdRange[1].format(YMDHMS)]
              : [],
          'expire-range':
            expireRange && expireRange.length > 0
              ? [expireRange[0].format(YMD), expireRange[1].format(YMD)]
              : [],
          'lastupdate-range':
            lastupdateRange && lastupdateRange.length > 0
              ? [
                  lastupdateRange[0].format(YMDHMS),
                  lastupdateRange[1].format(YMDHMS),
                ]
              : [],
          domain: values.domain && values.domain.toLowerCase(),
        };
        // console.log('*Received values of form*: ', valuesToSubmit);

        valuesToSubmit = transformNestedFieldsIntoNestedDotFields(
          valuesToSubmit,
        );

        props.onSubmit(err, valuesToSubmit);
      }
    });
  };

  const handleClear = () => {
    props.form.resetFields();
  };

  const handleReset = () => {
    handleClear();
    setItemValues(undefined);
  };

  // const toggle = () => {
  //   setExpand(!expand);
  // };

  const buttons = () => (
    <div style={{ lineHeight: 3 }}>
      <Button
        type="primary"
        htmlType="submit"
        data-testid="searchDomains-button"
      >
        Search
      </Button>
      {/*<Button style={{marginLeft: 8}} onClick={handleClear}>*/}
      {/*Clear*/}
      {/*</Button>*/}
      <Button
        style={{ marginLeft: 8 }}
        onClick={handleReset}
        data-testid="reset-button"
      >
        Reset
      </Button>
      {/*<a style={{ marginLeft: 8, fontSize: 12 }} */}
      {/*   onClick={toggle}*/}
      {/*>*/}
      {/*  Espandi <Icon type={expand ? 'up' : 'down'} />*/}
      {/*</a>*/}
    </div>
  );

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={2}>Ricerca avanzata domini</Typography.Title>
      </div>
      <Form
        data-testid="searchDomains-form"
        className="ant-advanced-search-form"
        onSubmit={handleSearch}
      >
        {/*{expand ? (*/}
        <div>
          <Row key={'fields'} gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 1]}>
            {getFields()}
          </Row>
          <Row key={'buttons'}>
            {' '}
            <Col span={24} style={{ textAlign: 'right' }}>
              {buttons()}
            </Col>
          </Row>
        </div>
        {/*) : (*/}
        {/*  // Single search*/}
        {/*  <Row type={'flex'} gutter={24} justify={'center'}>*/}
        {/*    {getFields()}*/}
        {/*    {buttons()}*/}
        {/*  </Row>*/}
        {/*)}*/}
      </Form>
    </>
  );
});

export const AdvancedDomainsSearchForm = Form.create<
  IAdvancedDomainsSearchForm & FormComponentProps
>({ name: 'domains_advanced_search' })(AdvancedDomainsSearchRawForm);
