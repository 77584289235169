import * as React from "react";
import {Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";

/**
 * Controllo con icona (filtro) per aggiungere un filtro alla ricerca corrente
 * @param text - testo visualizzato
 * @param filter - filtro su cui si interviene
 * @param onAddFilter - cosa accade quando clicco sul filtro
 * @constructor
 */

const AddFilter: React.FC<{
    text: any, filter: string, onAddFilter?: (filter: any, values: any) => void
}> = ({text,filter,onAddFilter}) => {
    if (text ) {
        return <Tooltip title={`Aggiungi filtro`}>
            <FontAwesomeIcon
                style={{cursor: "pointer", display: "inline", marginLeft: 10}} icon={faFilter}
                color={'grey'} size={'xs'}
                onClick={() => onAddFilter && onAddFilter(filter, text)}
            />
        </Tooltip>
    } else {
        return <></>
    }

}
export default AddFilter;