import * as React from "react";
import { Icon, message, Modal } from "antd";
import * as ReactMarkdown from "react-markdown";
import styled from "styled-components";

/***
 * Componenente che visualizza una modale contenente il Changelog che riporta la storia delle modifiche apportate
 * all'applicazione. La lista della modifiche da visualizzare viene estratta dal file 'changelog.md', qui grazie ad una
 * espressione regolare si va a recuperare una lista contenente tutti 'i titoli contraddistinti da 3 ### e contenenti
 * la parola Rilascio. Di questi, il primo chiamato releaseFound[0], viene utilizzato all'interno del localstorage
 * secondo questa logica:
 *
 *  all'intenro del localstorage viene creata una variabile 'changelog', se questa è vuota significa che la modale
 *  deve essere visualizzata per l'utente attuale altrimenti no. Una volta che questo preme su OK della modale,
 *  allora nel localstorage viene salvata la variabile 'changelog' con il valore di releaseFound[0].
 *  Nelle successive fasi, se il valore presente in 'changelog' non corrisponde con quello di releaseFound[0] allora la
 *  modale viene riproposta perchè questo sta indicare che c'è stato un nuovo rilascio.
 * @param props
 * @constructor
 */


const StyledModal = styled(Modal)`{
  .ant-modal-header {
    background-color: #4CAF50;
  }

  .ant-modal-title {
    color: white;
  }

}`;


function versionDetector(text :string){
  const releaseFound = text.match(/^#{1}(.*)\[(.*)\]\(/gm) || [];
  const versionFound = releaseFound.length > 0 && releaseFound[0] && releaseFound[0].match(/\[(.*)\]/);
  return (versionFound && versionFound[1] || "0.0");
}


/***
 * Notifica il rilascio di una nuova versione del codice verificando nel localstorage
 * @constructor
 */
export function NotifyChangelog() {

  React.useEffect(() => {
      fetch("CHANGELOG.MD").then(res => res.text()).then(text => {

        // Regola che recupera il primo titolo contenente la parola Rilascio
        // const releaseFound = text.match(/^#{3}(?!#)(.*)\bRilascio\b(.*)/gm) || [];
        const version = versionDetector(text);
        if (localStorage.getItem("changelog") !== version ) {
          message.info(
            <div
              style={{ display: "inline" }}>
              E' stata rilasciata una nuova versione <b>{version}</b>
              &nbsp;
              visualizza il
              &nbsp;
              <ChangelogModal label="changelog" linkable />
            </div>, 5);
          // Inibisce la visualizzazione della  prossima notifica
          localStorage.setItem("changelog", version);
        }
      });
    }, []
  );
  return <></>;
}

const ChangelogModal: (props: { label: string, linkable?: boolean }) => JSX.Element = (props: { label: string, linkable: boolean }) => {
  const [showChangelog, setShowChangeLog] = React.useState(false);
  const [markdown, setMarkdown] = React.useState("");
  const [lastRelease, setLastRelease] = React.useState("");

  React.useEffect(() => {
      fetch("CHANGELOG.md").then(res => res.text()).then(text => {

        // Regola che recupera il primo titolo contenente la parola Rilascio
        const version = versionDetector(text);

        setLastRelease(version);
        if (localStorage.getItem("changelog") !== version) {
          setMarkdown(text);
        }
      });
    }, []
  );

  const handleOpenModal = () => {
    setShowChangeLog(true);
  };

  const handleOnOk = () => {
    localStorage.setItem("changelog", lastRelease);
    setShowChangeLog(false);
  };

  return (
    <>
      {props.linkable ? <a onClick={handleOpenModal}>{props.label}</a> :
        <span onClick={handleOpenModal}>{props.label}</span>}
      <StyledModal
        title={<div><Icon type="pull-request" /> Changelog</div>}
        visible={showChangelog}
        onOk={handleOnOk}
        onCancel={() => setShowChangeLog(false)}
        okText={"Letto"}
      >
        <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
          {markdown && <ReactMarkdown children={markdown}/>}
        </div>
      </StyledModal>
    </>
  );
};
export default ChangelogModal;