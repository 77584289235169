import { has, keys, omit, pickBy } from 'lodash';
import { ParsedQuery } from 'query-string';

/**
 * Performa la trasformazione da campo nested a campo nested separto da .
 *
 * Es. {admin : {contacID : 'CCTL1'}} viene trasformato in 'admin.contactID=CCTL1'
 * @param values
 * @param fieldsValue
 * @param fieldName
 */

export function nestedFieldToNestedDotField(
  values: any,
  fieldsValue: any,
  fieldName: string,
): any {
  if (values[fieldName]) {
    Object.keys(values[fieldName]).forEach((k: string) => {
      const nestedValue = values[fieldName][k];
      if (
        nestedValue !== undefined &&
        nestedValue !== '' &&
        nestedValue !== null
      ) {
        fieldsValue[`${fieldName}.${k}`] = nestedValue;
      }
    });
  }
  return fieldsValue;
}

/***
 * Prende tutti i fields in fieldValues che hanno più di un livello, quindi nestedField,  (ad es. admin : {contacID : 'CCTL1'}} e li
 * trasforma in campi nested ma separati dal .  ('admin.contactID=CCTL1')
 *
 * Ritorna un valore che corrisponde a fieldsValue aggiornato secondo la logica descritta.
 *
 * @param fieldsValue
 */
export function transformNestedFieldsIntoNestedDotFields(
  fieldsValue: any,
): object {
  // ritorna il nome dei nested fields di prima livello (es. {admin : {contactID : ...}, registrant : {contactID : ...}}
  // corrisponde a  ['admin','registrant']
  const nestedfields = Object.entries(fieldsValue)
    .filter(entry => isNestedField(entry[1]))
    .map(entry => entry[0]);

  // scarta tutti i campi con valori nulli (undefined o '') presenti nei nestedFields (es. viene scarta {admin: {name : undefined} }
  let valuesToSubmit: any = pickBy(
    omit(fieldsValue, nestedfields),
    value => value !== undefined && value !== '' && value !== null,
  );

  nestedfields.forEach(
    field =>
      (valuesToSubmit = nestedFieldToNestedDotField(
        fieldsValue,
        valuesToSubmit,
        field,
      )),
  );
  // console.log("REAL valuesToSubmit ", valuesToSubmit)
  return valuesToSubmit;
}

/***
 * Dice se l'oggetto passatto è un nested Field
 * @param val
 */
export function isNestedField(val: any) {
  return val instanceof Object && !(val instanceof Array);
}

/**
 * Ritorna true o false in relazione al valore del parametro showDeleted e domain presenti nei parametri di ricerca
 * Se true la logica adattota vorrebbe che si visualizzasse direttamente il dettaglio del dominio
 *
 * @param parsedQuery
 */
export function showSingleDomain(
  parsedQuery: ParsedQuery<string | number>,
): boolean {
  // Si conta il numero di parametri
  if (
    keys(parsedQuery).length === 2 &&
    has(parsedQuery, 'showDeleted') &&
    has(parsedQuery, 'domain')
  ) {
    return parsedQuery['showDeleted'] === 'false';
  }
  return false;
}
