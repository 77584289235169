import {IDomainNote} from "../types";

interface IDomainNoteActionType {
    type: 'DOMAIN_NOTE_LOAD' | 'DOMAIN_NOTE_CLEAN' | 'DOMAIN_NOTE_EDIT' | 'DOMAIN_NOTE_EDIT_DONE';
    payload?: number;
}

export interface IReducerDomainNote {
    loading: boolean;
    edit?: boolean;
    data?: IDomainNote
}

export const initialState: IReducerDomainNote = {
    loading: false,
    edit: false,
    data: undefined
}

export const reducer = (state: IReducerDomainNote, action: IDomainNoteActionType) => {
    switch (action.type) {
        case "DOMAIN_NOTE_LOAD":
            return {loading: false, data: action.payload}
        case "DOMAIN_NOTE_CLEAN":
            return {loading: false, data: undefined}
        case "DOMAIN_NOTE_EDIT":
            return {loading: false, data: action.payload, edit: true}
        case "DOMAIN_NOTE_EDIT_DONE":
            return {...state, edit: false}
        default:
            return state;
    }
};
export default reducer;