import * as React from "react";
import { Select } from "antd";
import { InputProps } from "antd/lib/input";
import { StateContext } from "../../../StoreProvider";
import { IOptionSelectList } from "../../SelectList/SelectList";
import { FormComponentProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";

const { Option } = Select;

interface ISelectP {
  condition?: "province" | "state";
}

const SelectStateOrProvince = React.forwardRef((props: InputProps & ISelectP | FormComponentProps & SelectProps & ISelectP, ref) => {

  const { stateOrProvince } = React.useContext(StateContext);
  // console.log("stateOrProvince ", props);

  const loadingOption = <Option value={"Loading..."} key={"loading"}>Loading....</Option>;

  const provinceOptions: JSX.Element[] | undefined = stateOrProvince.payload && stateOrProvince.payload.provinces.map((d: IOptionSelectList, index) =>
    <Option
      value={d.value}
      key={d.value + index}>{`${d.value} - (${d.label})`}</Option>);

  const stateOptions: JSX.Element[] | undefined = stateOrProvince.payload && stateOrProvince.payload.countries.map((d: IOptionSelectList, index) =>
    <Option
      value={d.value}
      key={d.value + index}>{`${d.value} - (${d.label})`}</Option>);

  const { condition, ...rest } = props;

  let options: JSX.Element[] | undefined;

  switch (condition) {
    case "province":
      options = provinceOptions?.slice();
      break;
    case "state":
      options = stateOptions?.slice();
      break;
    default:
      options = stateOptions && provinceOptions && provinceOptions.slice().concat(stateOptions.slice());
      break;

  }


  return (
    <Select
      {...rest as FormComponentProps}
      loading={stateOrProvince.loading}
      placeholder={"Es. Pisa o PI"}
      showSearch
      allowClear
      showArrow={true}
      filterOption={(input, option) => {
        if (option && option.props && option.props.children) {
          return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
        return false;
      }}
    >

      {stateOrProvince.loading ? loadingOption : options}
    </Select>);
});

export default SelectStateOrProvince;