import * as React from 'react';
import { Badge } from 'antd';

export function Current() {
  return <span> <Badge dot offset={[5, 0]} color={'green'}> Configurazione corrente</Badge></span>;
};

export function Future() {
  // return <><Badge dot offset={[5, 0]} color={'#B3B3B3'}> Futuri</Badge></>;
  return <><Badge dot offset={[5, 0]} color={'orange'}>Nameserver in validazione</Badge></>;
};

export function FutureConfiguration() {
  // return <><Badge dot offset={[5, 0]} color={'#B3B3B3'}> Futuri</Badge></>;
  return <><Badge dot offset={[5, 0]} color={'orange'}>Configurazione finale dopo validazione</Badge></>;
};



