import { Table as LocalTable } from "antd";
import * as React from "react";
import styled from "styled-components";
import { capitalizeFirstLetter, moment } from "../../../utils/various";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { order } from "../../Contacts/Search/TabledContactsResults";
import { SorterResult, SortOrder, TableCurrentDataSource } from "antd/lib/table/interface";
import { PaginationConfig } from "antd/lib/pagination";
import AddFilter from "../../Common/Navigation/Filter/AddFilter";
import { ISortedInfo } from "../../Type/Common";
import { FilteredLabelField } from "../../Common/Styled/TableWrapped";
import { TwoLinesDate } from "../../Common/DataDisplay/Dates/Dates";
import { IContactEvent } from "./types/contactEvents";
import UserWithAvatar from "../../Common/DataDisplay/UserWithAvatar/UserWIthAvatar";


import {Document} from "../../Documents/Document";

export function acronym(str: string) {
  return str.replace("-", "").split(/\s/).reduce((response, word) => response += word.slice(0, 1), "");
}

// const { Text } = Typography;

const StyledTable = styled(LocalTable)`
  background-color: white;
  
  tr.fail {
    color: #F5222C;
    background-color: snow ;
  }
  
  tr.succeeded {
    color: #52C419;
    background-color: honeydew;
  }
  tr.hide-succeeded {
    color: #c3e2c5;
  }
  
  tr.hide-fail {
    color: #f4d2d2;
  }
  
  td.white {
    background-color: white;
  }
`;

function handleClassNameRow(record: any, refRevisionDate?: string): string {

  const status = "status";
  const created = "created";

  const recordDate = moment(record[created]);
  let classsName = "";

  record[status] === "accepted" ? classsName += " succeeded" : classsName += " fail";

  if (refRevisionDate) {
    const checkDate = moment(refRevisionDate);
    if (recordDate > checkDate) {
      record[status] === "accepted" ? classsName += " hide-succeeded" : classsName += " hide-fail";

    }
  }

  return classsName;
}


const Table: React.FC<{
  contactEvents: IContactEvent[], refRevisionDate?: string, sortOrder?: SortOrder
  sortColumnKey?: string,
  onAddFilter?: (filter: any, values: string) => void
  onChange?:
    (pagination: PaginationConfig, filters: Partial<Record<keyof any, string[]>>
      , sorter: SorterResult<any>, extra: TableCurrentDataSource<any>) => void
}> = props => {

  const [sortedInfo] = React.useState<ISortedInfo>(
    { order: props.sortOrder, columnKey: props.sortColumnKey }
  );

  const columns = [
    {
      /** Tipologia dell’evento: 'registry' o 'registrant' */
      dataIndex: "type",
      key: "type",
      title: "Tipo",
      sorter: true,
      sortOrder: order("type", sortedInfo),
      width: 100,

      render: (text: string) => {
        return (
          <FilteredLabelField>
            {text}
            <AddFilter
              text={text}
              filter={"type"}
              onAddFilter={props.onAddFilter}
            />
          </FilteredLabelField>);
      }
    },
    {
      /** Descrizione dell’evento */

      dataIndex: "description",
      key: "description",
      title: "Descrizione",
      render: (text: string, record: IContactEvent) => {
        if (text) {
          return (
            <DescriptionContent text={text} documentId={record.documentId} />
          );
        } else {
          return "";
        }
      }
    },

    {
      /** Stato dell’evento: 'accepted' o 'rejected' */
      dataIndex: "status",
      key: "status",
      title: "Esito",
      width: 50,
      render: (text: string) => {
        let element;
        switch (text) {
          case "accepted":
            element = <FontAwesomeIcon icon={faCheckCircle} />;
            break;
          case "rejected":
            element = <FontAwesomeIcon icon={faTimesCircle} />;
            break;
          default:
            element = "n.d";
        }
        return <FilteredLabelField
          style={{ textAlign: "center", justifyContent: "center", fontSize: 16 }}>
          {element}
          <AddFilter
            text={text}
            filter={"status"}
            onAddFilter={props.onAddFilter}
          />
        </FilteredLabelField>;
      }
    },

    {
      /** Id dell’utente che ha generato l’evento */
      dataIndex: "userId",
      key: "user",
      title: "Utente",
      width: 65,
      className: "white",
      // https://marcoslooten.com/blog/creating-avatars-with-colors-using-the-modulus/

      render: (text: string, record: IContactEvent) => {
        if (text) {
          return <div style={{ textAlign: "center", justifyContent: "center" }}>
            <UserWithAvatar userId={parseInt(text, 10)} />
          </div>;
        } else {
          return "";
        }
      }
    },

    {
      /** Data creazione dell’evento */
      dataIndex: "created",
      key: "created",
      title: "Creato",
      sorter: true,
      sortOrder: order("created", sortedInfo),
      width: "160px",
      className: "white",
      render: (text: string) => {
        // return <span>{moment(text).format('DD/MM/YYYY, HH:mm:ss')}</span>;
        return <div style={{ color: "rgba(0, 0, 0, 0.65)" }}><TwoLinesDate date={text} /></div>;

      }
    }
    // {
    //   // REFGISTRAR
    //   dataIndex: 'registrarId',
    //   key: 'registrarId',
    //   title: 'Registrar',
    //   width: 200,
    //   className: 'white',
    //   render: (text: string, record: IDomainEvent) => {
    //     return <div style={{color: 'rgba(0, 0, 0, 0.65)'}}>
    //       <Registrar registrarId={record.registrarId} children={(registrar: IRegistrar) =>
    //         <FilteredLabelField>
    //           <div style={{
    //             color: stringToHslColor(registrar.name, 60, 50),
    //           }}>{registrar.name}</div>
    //           <AddFilter
    //             text={{key: registrar.id, label: registrar.name}}
    //             filter={'registrarId'}
    //             onAddFilter={props.onAddFilter}
    //           />
    //         </FilteredLabelField>}/>
    //     </div>;
    //   },
    // },

  ];


  return <StyledTable
    dataSource={props.contactEvents}
    columns={columns}
    rowKey="id"
    bordered
    // scroll={{  y: 300 }}
    pagination={false}
    size={"small"}
    rowClassName={(record) => handleClassNameRow(record, props.refRevisionDate)}
    onChange={props.onChange}
  />;
};
export default Table;


export function DescriptionContent(props: { text?: string, documentId?: number }) {
  const { text, documentId } = props;

  if (text) {
    return <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "space-between"
      }}>
      <div>{
        text && capitalizeFirstLetter(text)
      }</div>
      {
        documentId && <div
          style={
            {
              borderLeft: "1px solid #E8E8E8",
              marginLeft: 10,
              paddingLeft: 10,
              height: "100%",
              fontSize: "16px"
            }}>
          <Document documentId={documentId} />
        </div>
      }
    </div>;
  }

  return null;
}