import * as React from "react";

export const Flex = (props:IFlexProps) => (
    <div
        className={props.className}
        style={{
            display: props.container ? 'flex' : 'block',
            justifyContent: props.justifyContent || 'flex-start',
            flexDirection: props.flexDirection || 'row',
            flexGrow: props.flexGrow || 0,
            flexBasis: props.flexBasis || 'auto',
            flexShrink: props.flexShrink || 1,
            flexWrap: props.flexWrap || 'nowrap',
            flex: props.flex || '0 1 auto',
            alignItems: props.alignItems || 'stretch',
            margin: props.margin || 0,
            marginTop: props.marginTop || 0,
            marginBottom: props.marginBottom || 0,
            padding: props.padding || '0',
            width: props.width || 'auto',
            height: props.height || 'auto',
            maxWidth: props.maxWidth || 'none',
            ...props.style

        }}
    >
        {props.children}
    </div>
)


/*** TYPESCRIPT TYPES ***/

interface IFlexProps {
    style?: React.CSSProperties,
    children?: any,
    className?: string,
    container?: boolean,
    /****** Container Props ********/
    flexDirection?: 'row' | 'column' | 'row-reverse',
    justifyContent?:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'space-between'
        | 'space-around'
        | 'initial'
        | 'inherit',
    flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse',
    alignItems?:
        | 'stretch'
        | 'center'
        | 'flex-start'
        | 'flex-end'
        | 'baseline'
        | 'initial'
        | 'inherit',
    /****** Child Props ********/
    flexGrow?: number,
    flexShrink?: number,
    flexBasis?: number,
    flex?: string,
    /****** Common Layout Props ********/
    padding?: string,
    margin?: number,
    marginTop?: number,
    marginBottom?: number,
    width?: string,
    height?: string,
    maxWidth?: string,
    maxHeight?: string
}