import {IFax} from "./documents";
import {Popover} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFax} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import {FaxLink} from "./FaxLink";
import {FaxContent} from "./FaxContent";

export function PopoverFax(props: { data: IFax }) {
    return (
        <Popover
            content={<FaxContent {...props.data}/>}
            title={
                <div>
                    <FontAwesomeIcon icon={faFax}/>
                    {` Dettaglio Fax`}
                </div>
            }>
            <a href={FaxLink(props.data)} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFax}/>
            </a>
        </Popover>
    );
}