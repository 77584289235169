import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ParsedQuery, stringify } from 'query-string';
import { AdvancedDomainsSearchForm } from './AdvancedDomainsSearchForm';

/**
 *  Pannello che contiene la Form di ricerca dei domini con la gestione del evento Submit
 * @constructor
 */

export const AdvancedDomainsSearchFormPane: React.FC<{
  values: ParsedQuery<React.ReactText>;
}> = ({ values }) => {
  const history = useHistory();

  const handleOnSubmit = (err: any, val: any) => {
    if (!err && val) {
      history.push(`/domains/advanced_search?${stringify(val)}`);
    } else {
      // non dovrebbe mai accadere per i controlli che ci sono, ma se accadesse, redirigi
      history.push(`/domains`);
    }
  };

  return (
    <AdvancedDomainsSearchForm onSubmit={handleOnSubmit} values={values} />
  );
};
