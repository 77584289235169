import * as React from 'react';
import { Dd, Dl, DlBox, Dt } from '../../../Common/Styled/DefinitionList';
import { moment } from '../../../../utils/various';
import { IDomain, IStatues } from '../types/domain';
import ExpireDateContainer from './DomainExpireDate/DomainExpireDate';
import i18n from 'i18next';
import {
  BasicPopoverFilter,
  DomainFilter,
} from '../../../Common/Navigation/Filter/Filter';
import { DomainName } from './DomainName/DomainName';
import { HelpTips } from '../../../Common/DataDisplay/HelpTips/HelpTips';
import { Divider, Typography } from 'antd';
import { Status } from '../../../Common/Styled/Status';
import { AbilityContext } from '../../../Common/Ability/AbilityContext';
import { StatusList } from '../../../Common/DataDisplay/StatusList/StatusList';

const EMPTY_STRING = 'n.d.';

export function formatStatuses(statuses?: IStatues[]): string {
  if (statuses) {
    const result: string[] = statuses.map(
      (s: IStatues, index: number) =>
        s.status + ` (${moment(s.date).format('DD MMMM YYYY, HH:mm:ss')})`,
    );
    return result.join(', ');
  }
  return EMPTY_STRING;
}

const DomainBasic: React.FC<IDomain> = props => {
  const { ability } = React.useContext(AbilityContext);

  return (
    <DlBox>
      <Dl>
        <Dt>{i18n.t('domain_name')}:</Dt>
        <Dd>
          <DomainName name={props.domain} signed={props.signed} />
        </Dd>
        {props.domainIDN && (
          <React.Fragment>
            <Dt>{i18n.t('domain_nameIDN')}:</Dt>
            <Dd>{props.domainIDN}</Dd>
          </React.Fragment>
        )}
        <Dt>{i18n.t('domain_crDate')}:</Dt>
        <Dd>
          {props.created
            ? moment(props.created).format('DD MMMM YYYY, HH:mm:ss')
            : EMPTY_STRING}
          {props.created && (
            <BasicPopoverFilter
              allowed={ability.can('search', 'Domain')}
              content={
                <div>
                  <div>
                    <DomainFilter
                      url={`/domains?created-range=${props.created}&created-range=${props.created}`}
                      content={`created=${moment(props.created).format(
                        'DD MMMM YYYY, HH:mm:ss',
                      )}`}
                    />
                  </div>

                  {/*<div>*/}
                  {/*    <DomainFilter*/}
                  {/*        url={`/domains?created-range=${moment(props.created).subtract(2, 'days').format("YYYY-MM-DD")}&created-range=${moment(props.created).format("YYYY-MM-DD")}`}*/}
                  {/*        content={`2 GG precedenti`}/>*/}
                  {/*</div>*/}
                  {/*<div>*/}
                  {/*    <DomainFilter*/}
                  {/*        url={`/domains?created-range=${moment(props.created).subtract(7, 'days').format("YYYY-MM-DD")}&created-range=${moment(props.created).format("YYYY-MM-DD")}`}*/}
                  {/*        content={`1 W precedente`}/>*/}
                  {/*</div>*/}
                </div>
              }
            />
          )}
        </Dd>
        <Dt>{i18n.t('domain_exDate')}:</Dt>
        <Dd>
          {props.expire ? (
            <ExpireDateContainer exDate={new Date(props.expire)} />
          ) : (
            EMPTY_STRING
          )}
        </Dd>
        <Dt>{i18n.t('domain_upDate')}:</Dt>
        <Dd>
          {props.lastupdate
            ? moment(props.lastupdate).format('DD MMMM YYYY, HH:mm:ss')
            : EMPTY_STRING}
        </Dd>
        <Dt>{i18n.t('domain_status')}:</Dt>
        <Dd>
          <Status size={'normal'}>{props.status}</Status>{' '}
          <Divider type={'vertical'} />
          <HelpTips
            title={
              <Typography.Title level={2}>{'Dettaglio Stati'}</Typography.Title>
            }
            content={<StatusList statuses={props.statuses} />}
            icon={'info-circle'}
            iconClassName={'icon_detail'}
          />
        </Dd>
        <Dt>{i18n.t('domain_dnssec')}:</Dt>
        <Dd>{props.signed ? i18n.t('yes') : i18n.t('no')}</Dd>
      </Dl>
    </DlBox>
  );
};
export default DomainBasic;
