import * as React from "react";
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from "./Common/Auth/AuthProvider";
import AppContent from "./AppContent";
import { internazionalization } from "../utils/lang";
import StoreProvider from "./StoreProvider";
import { AbilityProvider } from "./Common/Ability/AbilityContext";


const App: React.FC = props => {
  internazionalization();
  return (
    <AuthProvider>
      <BrowserRouter>
        <AbilityProvider>
          <StoreProvider>
            <AppContent />
          </StoreProvider>
        </AbilityProvider>
      </BrowserRouter>
    </AuthProvider>

  );
};

export default App;
