import * as React from 'react';
import {IRegistrant} from '../../contacts';
import i18n from "i18next";
import {DetailContent} from "../../../../Common/Styled/Detail";
import {
    DetailRowWrapper,
    eqString,
    handleEmptyValue
} from "../../../../Common/Navigation/Revisions/DetailCard/DetailCardBox";
import {ENTITY_TYPE} from "../../const";
import { StateField } from "../../../../Common/DataDisplay/StateOrProvinceField/StateOrProvinceField";

/***
 * Mostra le informazioni di due Registranti evidenziandone le differenze
 * @param registrantA
 * @param registrantB
 * @constructor
 */

export const RegistrantsInfoDiff: React.FC<{ registrantA?: IRegistrant, registrantB?: IRegistrant }> = ({registrantA, registrantB}) => {
    return (
        <>
            {/*regCode*/}
            <DetailRowWrapper
                label={`${i18n.t('contact_regCode')}:`}
                column2={
                    <DetailContent
                        isDifferent={
                            registrantA !== undefined &&
                            registrantB !== undefined &&
                            !eqString(registrantA.regcode, registrantB.regcode)
                        }>
                        {handleEmptyValue(registrantA, 'regcode')}
                    </DetailContent>
                }
                column3={
                    <DetailContent
                        isDifferent={
                            registrantB !== undefined &&
                            registrantA !== undefined &&
                            !eqString(registrantA.regcode, registrantB.regcode)
                        }>
                        {handleEmptyValue(registrantB, 'regcode')}
                    </DetailContent>
                }/>


            {/*schoolCode*/}
            <DetailRowWrapper
                label={`${i18n.t('contact_schoolCode')}:`}
                column2={
                    <DetailContent
                        isDifferent={
                            registrantA !== undefined &&
                            registrantB !== undefined &&
                            !eqString(registrantA.schoolCode, registrantB.schoolCode)
                        }>
                        {handleEmptyValue(registrantA, 'schoolCode')}
                    </DetailContent>
                }
                column3={
                    <DetailContent
                        isDifferent={registrantB !== undefined &&
                        registrantA !== undefined &&
                        !eqString(registrantA.schoolCode, registrantB.schoolCode)
                        }>
                        {handleEmptyValue(registrantB, 'schoolCode')}
                    </DetailContent>
                }/>

            {/*entityType*/}
            <DetailRowWrapper
                label={`${i18n.t('contact_entityType')}:`}
                column2={
                    <DetailContent
                        isDifferent={
                            registrantA !== undefined &&
                            registrantB !== undefined &&
                            !eqString(registrantA.entityType.toString(), registrantB.entityType.toString())
                        }>
                        {registrantA && registrantA.entityType} - <i>{`(${ENTITY_TYPE[Number(registrantA && registrantA.entityType) - 1]})`}</i>
                    </DetailContent>
                }
                column3={
                    <DetailContent
                        isDifferent={
                            registrantB !== undefined &&
                            registrantA !== undefined &&
                            !eqString(registrantA.entityType.toString(), registrantB.entityType.toString())
                        }
                    >
                        {registrantB && registrantB.entityType} - <i>{`(${ENTITY_TYPE[Number(registrantB && registrantB.entityType) - 1]})`}</i>
                    </DetailContent>
                }/>

            {/*nationality*/}
            <DetailRowWrapper
                label={`${i18n.t('contact_nationality')}:`}
                column2={
                    <DetailContent
                        isDifferent={
                            registrantA !== undefined &&
                            registrantB !== undefined &&
                            !eqString(registrantA.nationality, registrantB.nationality)
                        }>
                      <StateField val={registrantA && registrantA.nationality} />
                    </DetailContent>
                }
                column3={
                    <DetailContent
                        isDifferent={
                            registrantB !== undefined &&
                            registrantA !== undefined &&
                            !eqString(registrantA.nationality, registrantB.nationality)
                        }>
                        <StateField val={registrantB && registrantB.nationality} />
                    </DetailContent>
                }/>
        </>

    );
}
