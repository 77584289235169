import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ParsedQuery, stringify } from 'query-string';
import { IContacts } from '../../../types/contacts';
import { WrappedContactsSearchForm } from './ContactsSearchForm';

const SearchPane: React.FC<{
  values: ParsedQuery<React.ReactText>;
}> = props => {
  const history = useHistory();

  const handleOnSubmit = (err: any, values: IContacts) => {
    console.debug(' values', values);
    const field = 'main';
    if (values) {
      // Se la ricerca è una ricerca semplice contatti allora viene impiegato il campo di ricerca [field].
      // se [field] contiene uno spazio allora significa che la ricerca è per nome mentre se non contiene spazi
      // allora significa che la ricerca contiene un contactID.
      if (values[field]) {
        const search: string = values[field];
        if (search.includes(' ')) {
          history.push(
            `/contacts?${stringify(values).replace(field, 'name-ft')}`,
          );
        } else {
          history.push(
            `/contacts?${stringify(values).replace(field, 'contactId')}`,
          );
        }
      } else {
        history.push(`/contacts?${stringify(values)}`);
      }
    } else {
      // non dovrebbe mai accadere per i controlli che ci sono, ma se accadesse, redirigi
      history.push(`/contacts`);
    }
  };

  return (
    <>
      <WrappedContactsSearchForm
        onSubmit={handleOnSubmit}
        values={props.values}
      />
    </>
  );
};

export default SearchPane;
