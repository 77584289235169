import {
    faArrowsLeftRightToLine,
    faCircleMinus,
    faCirclePause,
    faCirclePlay,
    faHand,
    faHouseLock,
    faLock,
    faPersonCircleMinus,
    faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";


export interface IEventIconInfo {
    icon: IconDefinition,
    opacity?: number
    text?: string,
    name?: string,
    color?: string,
}

export interface IEventIconInfoMapping {
    [key: string]: IEventIconInfo
}

export const EventIconInfoMapping: IEventIconInfoMapping = {
    'server-lock': {
        icon: faLock,
        opacity: 1,
        text: 'Descrizione server-lock',
        name: 'Server Lock',
    },
    'server-unlock': {
        icon: faUnlock,
        opacity: 1,
        text: 'Descrizione server-unlock',
        name: 'Server Unlock',
    },
    'server-unhold': {
        icon: faCirclePlay,
        text: 'Descrizione server-unhold',
        name: 'Server Unhold',
    },
    'server-hold': {
        icon: faCirclePause,
        text: 'Descrizione server-hold',
        name: 'Server Hold',
    },
    'server-lock-by-accuracy': {
        icon: faLock,
        opacity: 1,
        color: 'orange',
        text: 'Descrizione server-lock-by-accuracy',
        name: 'Server Lock per accuratezza',
    },
    'server-hold-by-accuracy': {
        icon: faCirclePause,
        color: 'orange',
        text: 'Descrizione server-hold-by-accuracy',
        name: 'Server Hold per accuratezza',
    },
    'end-server-delete-prohibited': {
        icon: faHouseLock,
        opacity: 1,
        text: 'Descrizione end-server-delete-prohibited',
        name: 'Disattiva Server Delete Prohibited',
    },
    'start-server-delete-prohibited': {
        icon: faHouseLock,
        opacity: 1,
        text: 'Descrizione start-server-delete-prohibited',
        name: 'Attiva Server Delete Prohibited',
    },
    'challenge-start': {
        icon: faPersonCircleMinus,
        opacity: 1,
        text: 'Descrizione challenge-start',
        name: 'Contestazione',
    },
    'challenge-end': {
        icon: faHouseLock,
        opacity: 1,
        text: 'Descrizione challenge-end',
        name: 'Rimuovi Contestazione',
    },
    'domain-delete-by-accuracy': {
        icon: faCircleMinus,
        color: 'orange',
        text: 'Descrizione domain-delete-by-accuracy',
        name: 'Cancellazione da parte del Registro per accuratezza',
    },
    'domain-deleted-by-registry': {
        icon: faPersonCircleMinus,
        opacity: 1,
        text: 'Descrizione domain-deleted-by-registry',
        name: 'Cancellazione da parte del Registro',
    },
    'revoke-started': {
        icon: faArrowsLeftRightToLine,
        opacity: 1,
        text: 'Descrizione revoke-started',
        name: 'Server Unlock',
    },
    'not-allowed-request': {
        icon: faHand,
        opacity: 1,
        text: 'Descrizione not-allowed-request',
        name: 'Richiesta non permessa',
    },
    // 'bulk-transfer-request': {
    //     icon: faRightLeft,
    //     opacity: 1,
    //     text: 'Descrizione bulk-transfer-request',
    //     name: 'Richiesta non permessa',
    // },
};
