import { Tabs } from 'antd';
import * as React from 'react';
import { IViewerContentType } from '../Type/IViewerContentType';

const TabView: React.FC<{content:IViewerContentType[]}> = ({ content }) => {
  return (
    <React.Fragment>
      <Tabs defaultActiveKey="0" style={{backgroundColor: 'white'}}>
        {content.map((data: IViewerContentType, index: number) => (
          <Tabs.TabPane tab={data.label} key={index.toString()}>
            {data.component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </React.Fragment>
  );
};
export default TabView;
