import * as React from 'react';
import { IDomain } from '../../../Domain/types/domain';
import { Ability } from '@casl/ability';
import { Avatar, Divider, List, Tooltip } from 'antd';
import { DomainNameTitle } from '../../../Domain/Info/DomainName/DomainName';
import { IRegistrar } from '../../../../Registrar/IRegistrar';
import { StatusbarIcon } from '../../../../Common/Styled/StatusBar';
import { faHome, faUser, faUserTie } from '@fortawesome/free-solid-svg-icons';
import AddFilter from '../../../../Common/Navigation/Filter/AddFilter';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { faPlug } from '@fortawesome/free-solid-svg-icons/faPlug';
import { calcAvatarString, moment } from "../../../../../utils/various";
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { Status } from '../../../../Common/Styled/Status';
import { Registrar, RegistrarName } from '../../../../Registrar/Registrar';



/***
 * Rappresenta l'oggetto che identifica il dominio mostrando il summary:
 * - avatar, con le iniziali dello stato
 * - data creazione
 * - stato
 * - data modifica
 *
 * @param item
 * @param ability
 * @constructor
 */
const Item: React.FC<{
  item: IDomain, ability: Ability<any>, onAddFilter?: (filter: any, values: string) => void
}> = ({ item, ability, onAddFilter }) => (
  <List.Item key={item.lastupdate}>
    <List.Item.Meta
      avatar={
        item.status && item.status !== 'deleted' ? (
          <Avatar
            style={{ backgroundColor: '#87d068' }}>{item.status && calcAvatarString(item.status)}</Avatar>
        ) : (
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
            {item.status && calcAvatarString(item.status)}
          </Avatar>
        )
      }
      title={
        <DomainNameTitle domain={item} ability={ability}/>
      }
      description={
        <div style={{ textAlign: 'left' }}>
          <div>
            <Registrar
              registrarId={item.registrarId}
              children={(registrar: IRegistrar) => <>
                <StatusbarIcon icon={faHome}/>
                <b>
                  <RegistrarName {...registrar} />
                </b>
                <AddFilter
                  text={{ key: registrar.id, label: registrar.name }}
                  filter={'registrarId'}
                  onAddFilter={onAddFilter}/>
              </>
              }/>
          </div>

          <div>{
            item.admin &&
            <Tooltip title='Admin'>
              <StatusbarIcon icon={faUser}/>
              {item.admin.name}
              <AddFilter text={item.admin.contactId} filter={'admin.contactId'}
                         onAddFilter={onAddFilter}/>
            </Tooltip>
          }</div>
          <div>{
            item.registrant &&
            <Tooltip title='Registrant'>
              <StatusbarIcon icon={faUserTie}/>
              {item.registrant.org}
              <AddFilter text={item.registrant.contactId} filter={'registrant.contactId'}
                         onAddFilter={onAddFilter}/>
            </Tooltip>
          }
          </div>
          <div>
            <Tooltip title="Stato">
              <StatusbarIcon icon={faTag}/>
              <i><b><Status size={'small'}>{item.status}</Status></b>
                <AddFilter text={item.statuses.map((s: { status: string, date: any }) => s.status)} filter={'status'}
                           onAddFilter={onAddFilter}/>
              </i>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Creazione">
              <StatusbarIcon icon={faPlug}/>
              {item.created && moment(item.created).format('DD MMMM YYYY, HH:mm:ss')}
            </Tooltip><Divider type={'vertical'}/>
            <Tooltip title="Ultima modifica">
              <StatusbarIcon icon={faEdit}/>
              {item.lastupdate && moment(item.lastupdate).format('DD MMMM YYYY, HH:mm:ss')}
            </Tooltip>
          </div>
          <Divider/>
        </div>
      }
    />
    {/*<Tooltip title='Registrar'>{item.registrar}</Tooltip>*/}
  </List.Item>
);

export default Item;