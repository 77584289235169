import { AxiosError, AxiosResponse } from 'axios';
import { IFetchStateType } from '../../../Type/IFetchtStateType';
import { IEventsRequirements } from '../types/domain';

interface IDomainActionType {
  type:
    | 'DOMAIN_AVAILABLE_EVENTS_FETCH_SUCCESS'
    | 'DOMAIN_AVAILABLE_EVENTS_FETCH_ERROR'
    | 'DOMAIN_AVAILABLE_EVENTS_FETCH_LOAD'
    | 'DOMAIN_AVAILABLE_EVENTS_FETCH_CLEAN';
  payload?: AxiosResponse;
  error?: AxiosError;
  query?: any;
}

export const initialState: IFetchStateType<any> = {
  loading: false,
  query: '',
  error: undefined,
  response: undefined,
};

export const domainAvailableEventsReducer = (
  state: IFetchStateType<any>,
  action: IDomainActionType,
) => {
  switch (action.type) {
    case 'DOMAIN_AVAILABLE_EVENTS_FETCH_LOAD':
      return {
        loading: true,
        error: undefined,
        query: action.query,
        response: undefined,
      };
    case 'DOMAIN_AVAILABLE_EVENTS_FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        error: undefined,
        response: action.payload,
      };
    case 'DOMAIN_AVAILABLE_EVENTS_FETCH_ERROR':
      return { ...state, loading: false, error: action.error };
    case 'DOMAIN_AVAILABLE_EVENTS_FETCH_CLEAN':
      return {
        ...state,
        loading: false,
        error: undefined,
        query: '',
        response: undefined,
      };
    default:
      return state;
  }
};

interface IEventRequirementsActionType {
  type:
    | 'EVENTS_REQUIREMENTS_FETCH_SUCCESS'
    | 'EVENTS_REQUIREMENTS_FETCH_ERROR'
    | 'EVENTS_REQUIREMENTS_FETCH_LOAD'
    | 'EVENTS_REQUIREMENTS_FETCH_CLEAN';
  payload?: AxiosResponse;
  error?: AxiosError;
  query?: any;
}

export const initialStateEventRequirements: IFetchStateType<IEventsRequirements> = {
  loading: false,
  query: '',
  error: undefined,
  response: undefined,
};

export const eventsRequirementsReducer = (
  state: IFetchStateType<IEventsRequirements>,
  action: IEventRequirementsActionType,
) => {
  switch (action.type) {
    case 'EVENTS_REQUIREMENTS_FETCH_LOAD':
      return {
        loading: true,
        error: undefined,
        query: action.query,
        response: undefined,
      };
    case 'EVENTS_REQUIREMENTS_FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        error: undefined,
        response: action.payload,
      };
    case 'EVENTS_REQUIREMENTS_FETCH_ERROR':
      return { ...state, loading: false, error: action.error };
    case 'EVENTS_REQUIREMENTS_FETCH_CLEAN':
      return {
        ...state,
        loading: false,
        error: undefined,
        query: '',
        response: undefined,
      };
    default:
      return state;
  }
};
