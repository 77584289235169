import { IProtocol } from './documents';
import { moment } from '../../utils/various';
import { DMYdotHMS } from '../../const/common';
import { Divider, Typography } from 'antd';
import * as React from 'react';
import { LabelContent } from './LabelContent';
const { Text } = Typography;

/**
 * Contenuto relativo al Protocollo usato per visualizzarne i dettagli
 * @param props
 * @constructor
 */
export function ProtocolContent(props: IProtocol) {
  console.log('ProtocolContent', props);
  return (
    <div style={{ width: '300px' }}>
      {/*<LabelContent label={'Tipo'} content={'Protocollo'}/>*/}
      <LabelContent
        label={'Id'}
        content={<Text type="secondary">{props.id}</Text>}
      />
      <div style={{ marginTop: 10 }} />
      <LabelContent
        label={'Anno protocollo'}
        content={<Text copyable>{props.protYear?.toString()}</Text>}
      />
      <LabelContent
        label={'Numero protocollo'}
        content={<Text copyable>{props.protNum?.toString()}</Text>}
      />
      <LabelContent
        label={'Numero pagine'}
        content={props.numpag?.toString()}
      />
      <LabelContent
        label={'Data arrivo'}
        content={moment(props.dateArrive).format(DMYdotHMS)}
      />
      {(props.org || props.domain || props.registrar) && <Divider />}
      {props.org && <LabelContent label={'Org'} content={props.org} />}
      {props.domain && <LabelContent label={'Domain'} content={props.domain} />}
      {props.registrar && (
        <LabelContent label={'Registrar'} content={props.registrar} />
      )}
    </div>
  );
}
