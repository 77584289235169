import { RouteComponentProps } from 'react-router-dom';
import * as React from 'react';
import { documentTitle, useQuery } from '../../../../../../utils/various';
import { BoxForm } from '../../../../../Common/Styled/BoxForm';
import { DispatchContext, StateContext } from '../../../../../StoreProvider';
import { fetchContacts } from '../../../operations';
import AdvancedContactsSearchFormPane from './AdvancedContactsSearchFormPane';
import { FetchController } from '../../../../../Common/Navigation/FetchController/FetchController';
import { ResultsPane } from '../../../ResultsPane';
import { stringify } from 'querystring';
import { IContacts } from '../../../../types/contacts';
import {
  addFilter,
  sortResult,
} from '../../../../../../utils/url-manipulation';
import { useRouter } from '../../../../../Common/Hooks/useRouter';

// TODO semplificare usando useLocation e useHistory e rimuovere il passaggio di RouteComponentProps

const AdvancedContactsSearchPage: React.FunctionComponent<RouteComponentProps<{}>> = props => {
  documentTitle('Ricerca avanzata contatti');

  const query = useQuery();

  const dispatch = React.useContext(DispatchContext);
  const { contacts } = React.useContext(StateContext);

  const { history, location } = useRouter();

  React.useEffect(() => {
    if (contacts.query.localeCompare(stringify(query)) !== 0) {
      fetchContacts(dispatch, contacts, query);
    }
  }, [stringify(query)]);

  const handleOnSort = (a: any, b: any, d: any) => {
    sortResult(d, query, history, location);
  };

  const handleAddFilter = (filter: string, values: string) => {
    addFilter(filter, values, query, history, location);
  };
  return (
    <BoxForm>
      <AdvancedContactsSearchFormPane values={query} />
      <FetchController {...contacts}>
        {(value: IContacts) => {
          return (
            <ResultsPane
              contacts={value}
              onAddFilter={handleAddFilter}
              onSort={handleOnSort}
            />
          );
        }}
      </FetchController>
    </BoxForm>
  );
};

export default AdvancedContactsSearchPage;
