import * as React from "react";
import { MenuProps } from "antd/lib/menu";
import { Button, Form, Menu, Tooltip } from "antd";
import RegistrarLookUp from "../../../Registrar/LocaStorage/RegistrarLookUp";
import { FormComponentProps } from "antd/lib/form/Form";
import { JBILLING_HOST } from "../../../../midaConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { Registrar } from "../../../Registrar/Registrar";
import { IRegistrar } from "../../../Registrar/IRegistrar";
import RegistrarPopover from "../../../Registrar/RegistrarPopover";

const SubMenu = Menu.SubMenu;

/***
 * Voce di menu che si integra.
 * Riporta i controlli legati alla visualizzazione dei dettagli del Registrar quindi
 * link a  Jbilling e una specie di motore di ricerca per Registrar
 *
 */
export const JBillingMenu: React.FC<MenuProps & { title: JSX.Element }> = props => {
  const [regData, setRegData] = React.useState<number | undefined>(undefined);

  const handleOnSubmit = (err: any, value: any) => {
    if (value.registrarId) {
      setRegData(value.registrarId.key);
    }
  };


  // const link = `http://${JBILLING_HOST}/contract/${value.registrarId.key}`;
  //                     console.log("link ", link);
  //                     const win = window.open(link, "_blank", "noopener noreferrer");
  //                     if (win) {
  //                         win.focus();
  //                     }

  return (
    <SubMenu {...props}
             title={props.title}>
      <div style={{ padding: 10, marginLeft: 10 }}>
        <RegistrarForm onSubmit={handleOnSubmit} onChange={() => setRegData(undefined)} />
        {regData && <Registrar registrarId={regData}
                               children={(registrar: IRegistrar) =>
                                 <div style={{ textAlign: "center" }}>
                                   <Tooltip title={"Visualizza dettaglio del Registrar"}>
                                     <RegistrarPopover registrar={registrar} show />
                                   </Tooltip>
                                 </div>
                               } />}
      </div>

      <Menu.Item key="jbillingLink" style={{ borderTop: "1px solid #d9d9d9" }}>
        <a href={`http://${JBILLING_HOST}`}
           target="_blank" rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faPiggyBank} style={{ marginRight: 10 }} />
          JBilling
        </a>
      </Menu.Item>
    </SubMenu>
  );
};
export default JBillingMenu;


const RegistrarRawForm: React.FC<FormComponentProps & { onSubmit: any, onChange?: any, onSelect?: any }> = props => {
  const [showSubmit, setShowSubmit] = React.useState(false);


  const handleOnSelect = (a: any, b: any) => {
    if (a.key) {
      setShowSubmit(true);
    } else {
      setShowSubmit(false);
    }
  };

  const handleOnChange = (val: any) => {
    if (!val) {
      setShowSubmit(false);
    }
    props.onChange(val);
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    props.form.validateFields((err: any, values: any) => {
      if (values.registrarId.key) {
        setShowSubmit(true);
      }
      props.onSubmit(err, values);
    });
  };
  return (
    <Form onSubmit={handleOnSubmit}>
      <Form.Item label={"Ricerca Registrar"}>
        {props.form.getFieldDecorator(`registrarId`, {
          rules: [
            {
              required: false
            }
          ]
        })(
          <RegistrarLookUp style={{ width: "200px" }}
                           icon={"home"}
                           {...props}
                           allowClear
                           onSelect={handleOnSelect}
                           onChange={handleOnChange}
                           placeholder={<React.Fragment>
                             Es. REGISTRY-REG
                           </React.Fragment>}
          />
        )}
        {
          showSubmit &&
          <Tooltip title={"Carica i dettagli del Registrar"}>
            <Button style={{ marginLeft: 5 }} type="primary"
                    size={"small"} htmlType="submit"
                    data-testid="searchDomains-button">
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </Tooltip>
        }
      </Form.Item>
    </Form>
  );
};


const RegistrarForm = Form.create<FormComponentProps & { onSubmit: any, onChange?: any, onSelect?: any }>({ name: "registrar_form" })(RegistrarRawForm);