import {IFax} from './documents';
import {FAXSERVER_HOST} from '../../midaConfig';
import {moment} from '../../utils/various';

/***
 * Crea il link per puntare al FAX
 * @param fax
 */
export function FaxLink(fax: IFax) {
    const arrived = moment(fax.dateArrive);
    const faxserverSwitched = moment('2016-10-20');
    if (arrived > faxserverSwitched) {
        return `https://${FAXSERVER_HOST}/received/${fax.faxId}`;
    } else {

        return `https://${FAXSERVER_HOST}/${fax.type === 'F' ? 'lar' : 'cambi'}/${arrived.format('YYYY')}/${arrived.format('MM')}/${fax.faxId}`;
    }
}
