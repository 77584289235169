import { AxiosResponse, AxiosError } from "axios";
import {IFetchStateType} from "../../Type/IFetchtStateType";
import { IContacts } from '../types/contacts';

interface IContactsActionType {
    type: 'CONTACTS_FETCH_SUCCESS' | 'CONTACTS_FETCH_ERROR' | 'CONTACTS_FETCH_LOAD' | 'CONTACTS_FETCH_CLEAN';
    payload?: AxiosResponse;
    error?: AxiosError;
    query?: any;
}



export const initialState: IFetchStateType<IContacts> = {
    loading: false,
    query: '',
    error: undefined,
    response: undefined
}

export const reducer = (state: IFetchStateType<IContacts>, action: IContactsActionType) => {
    switch (action.type) {
        case "CONTACTS_FETCH_LOAD":
            return {loading: true, error: undefined, query: action.query, response: undefined}
        case "CONTACTS_FETCH_SUCCESS":
            return {loading: false, error: undefined, query: state.query, response: action.payload}
        case "CONTACTS_FETCH_ERROR":
            return {loading: false, error: action.error}
        case "CONTACTS_FETCH_CLEAN":
            return {loading: false, error: undefined, query: '', response: undefined}
        default:
            return state;
    }
};
export default reducer;