import * as React from "react";
import { Modal } from "antd";
import { IContact } from "../contacts";
import EditContactSteps from "./Steps";
import { reloadContact } from "../operations";
import { useRouter } from "../../../Common/Hooks/useRouter";
import { DispatchContext } from "../../../StoreProvider";


export interface IEditContactModal {
  contact: IContact,
  label?: string,
  disabled: boolean
}

export const EditContactModal: React.FC<IEditContactModal> = ({ contact, label ,disabled}) => {

  const [visible, setVisible] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const { history, location } = useRouter();
  const dispatch = React.useContext(DispatchContext);

  // ??? CI va?
  const showModal = () => {
    if (!disabled) {
      setVisible(true);
    }
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleOnDone = (done: boolean) => {
    setCompleted(done);
  };

  const handleReload = () => {
    if (completed) {
      reloadContact(dispatch, history, location);
    }
  };

  return (
    <>
      <div onClick={showModal} style={{ display: "inline-block" }}>
        {label ? label : " Modifica"}
      </div>
      <Modal
        width={800}
        title="Modifica contatto"
        visible={visible}
        centered
        onOk={handleOk}
        onCancel={handleOk}
        afterClose={handleReload}
        destroyOnClose
        okButtonProps={{ disabled: !completed, style: { display: completed ? "" : "none" } }}
        cancelButtonProps={{ style: { display: !completed ? "" : "none" } }}
      >
        {visible && <EditContactSteps onDone={handleOnDone} />}
      </Modal>
    </>
  );
};




