import {Col} from "antd";
import {PopoverDocument} from "./PopoverDocument";
import * as React from "react";
import {GenericDocument} from "./documents";


export interface IShowDocument {
    documentData: GenericDocument
}


/***
 * Visualizza l'icona che mostra un pop up con le info del documento
 * @param documentData
 * @constructor
 */
export function ShowDocument({documentData}: IShowDocument) {
    return <Col span={2}>
        <div style={{paddingLeft: 10}}>
            <PopoverDocument {...documentData} />
        </div>
    </Col>;
}