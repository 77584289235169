import * as React from 'react';
import { Icon, Popover } from 'antd';

export const IconTip: React.FC<{
  content?: React.ReactNode;
  title?: React.ReactNode;
  icon?: string;
  iconStyle?: React.CSSProperties;
  color?: string;
  iconClassName?: string;
}> = ({ content, title, icon, iconStyle, color, iconClassName }) => (
  <Popover
    overlayStyle={{ zIndex: 3000 }}
    content={content}
    title={title}
    trigger="hover"
  >
    <Icon
      className={`popover_icon ${iconClassName}`}
      type={icon || 'question-circle'}
      style={{ color, ...iconStyle }}
    />
  </Popover>
);
