import {IFetchStateType} from "../../../Type/IFetchtStateType";
import {IDetailedRevision} from "../../../Common/Navigation/Revisions/revisions";
import {apiPatchedGetContactRevision} from "../../../../const/api-calls";

/***
 * Funzione per il recupero del dettaglio dominio relativi alla query passata.
 *
 * @param dispatch
 * @param contactId
 * @param revisionNumber
 * @param contact
 * @param query - queryString corrente
 * @param keycloak
 */
export function fetchContactRevision(dispatch: any, contactId: string, revisionNumber: string, contact: IFetchStateType<IDetailedRevision>, query: string) {


    if (query === '' || query === '?') {
        dispatch({type: 'CONTACT_REVISION_FETCH_CLEAN'})
    } else {
        dispatch({type: 'CONTACT_REVISION_FETCH_LOAD', query})
        // apiGetContactRevision(contactId, revisionNumber, keycloak).then((res: any) => {
        //     dispatch({type: 'CONTACT_REVISION_FETCH_SUCCESS', payload: res})
        // }).catch((error: any | PromiseLike<IDetailedRevision>) => {
        //     dispatch({type: 'CONTACT_REVISION_FETCH_ERROR', error})
        // })
        apiPatchedGetContactRevision(contactId, revisionNumber).then((res: any) => {
            dispatch({type: 'CONTACT_REVISION_FETCH_SUCCESS', payload: res})
        }).catch((error: any | PromiseLike<IDetailedRevision>) => {
            dispatch({type: 'CONTACT_REVISION_FETCH_ERROR', error})
        })
    }

}