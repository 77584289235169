import { Icon, Spin, Timeline as LocalTimeLine, Typography } from "antd";
import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import { IRevision, IRevisions } from "../revisions";
import { RevisionItem, RevisionText } from "../RevisionItem";
import EmptyWithDescription from "../../../DataDisplay/EmptyWithDescription/EmptyWithDescription";
import { useQuery } from "../../../../../utils/various";
import { stringify } from "query-string";
import { RevisionTag } from "../CompareRevisionModal";

const { Text } = Typography;


const LocalSpinner = () => <div className="demo-loading-container"><Spin /></div>;


export const LocalRevisionText: React.FC<{ revisionUrl: string, rev: IRevision, extra?: React.ReactNode }> = ({
                                                                                                                revisionUrl,
                                                                                                                rev,
                                                                                                                extra
                                                                                                              }) =>
  <div style={{ textAlign: "left" }}>
    <Text strong code>
      <RevisionText rev={rev} />
      {extra && extra}
    </Text>
  </div>;


export interface ITimeline {
  data: IRevisions,
  loading: boolean,
  revisionUrl: string,
  fetchFunction: (resourceId: string, revisionId: string, keycloack: any) => any
}

const Timeline: React.FC<ITimeline> = ({ data, loading, revisionUrl, fetchFunction }) => {

  const { params } = useRouteMatch();
  const query = useQuery();

  return (
    <div>
      <LocalTimeLine mode={"alternate"}>

        {loading && <LocalSpinner />}

        {data.elements && data.elements.map((rev: IRevision, index: number) => {

          if (rev) {

            const _revisionUrl = `${revisionUrl}/${rev.number}?${stringify(query)}`;
            const selectedRevision = <RevisionTag text={"Corrente"} color={"orange"} />;
            // const lastRevision = <RevisionTag text={"Ultima"} color={"green"} />;
            const selected = (r: IRevision, i: number) => params["revision"] === r.number.toString() || (params["revision"] === undefined && i === 0);
            const isLastRevision = (index === 0);

            return (
              <LocalTimeLine.Item
                key={index}
                dot={
                  selected(rev, index) ?
                    <Icon type="clock-circle-o" style={{ color: "#52C41A", fontSize: "20px" }} /> :
                    ""
                }>
                {selected(rev, index) ?
                  <RevisionItem
                    revisionUrl={_revisionUrl}
                    rev={rev}
                    linkable={false}
                    extra={(isLastRevision) ?
                      // <>{selectedRevision}{lastRevision} </> :
                      <></> :
                      <>{selectedRevision}</>}
                    fetchFunction={fetchFunction}
                  /> :
                  <RevisionItem
                    revisionUrl={_revisionUrl}
                    rev={rev}
                    linkable={true}
                    extra={(isLastRevision) ?
                      <>{(params["revision"] === undefined) ? selectedRevision : ""} </> :
                      <></>
                    }
                    fetchFunction={fetchFunction}
                  />}
              </LocalTimeLine.Item>
            );
          }
          return <EmptyWithDescription description={"no"} key={"s"} />;
        })}
      </LocalTimeLine>
    </div>
  );
};
export default Timeline;
