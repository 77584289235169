import {IDomainNote} from "../types";
import {AxiosError} from "axios";
import {WrappedEditorForm} from "./EditorForm";
import * as React from "react";

/**
 * Visualizza l'editor per craere una nuova nota
 * @param props
 * @constructor
 */
export function Editor(props: {
    onSubmit: (err: any, value: any) => any;
    onCancel: any,
    loading: boolean,
    value?: IDomainNote,
    error?: AxiosError
}) {
    return <div>
        <WrappedEditorForm
            value={props.value}
            loading={props.loading}
            onCancel={props.onCancel}
            error={props.error}
            onSubmit={props.onSubmit}
        />
    </div>
}

