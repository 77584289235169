import { FormComponentProps } from "antd/lib/form/Form";
import { AxiosError } from "axios";
import * as React from "react";
import { Button, Col, Divider, Form, Input, Row, Switch } from "antd";
import { printAxiosFetchError } from "../../../Common/Navigation/FetchController/FetchController";
import { IContact } from "../contacts";
import SelectCountry from "../../../Common/Input/SelectCountry/SelectCountry";
import SelectEntityType from "../../../Common/Input/SelectEntityType/SelectEntityType";
import StreetsFormItems from "./StreetsFormItems";
import SelectStateOrProvince from "../../../Common/Input/SelectStateOrProvince/SelectStateOrProvince";

/**
 * EDIT CONTACT FORM
 */

interface IEditContactForm extends FormComponentProps {
  value?: IContact;
  loading?: boolean;
  disabled?: boolean;
  onCancel?: any;
  error?: AxiosError;
  onSubmit?: (err: any, value: any) => any;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const EditContactForm: React.FC<IEditContactForm> = ({ value, form, onSubmit, error, loading, onCancel, disabled }) => {

  const { getFieldDecorator } = form;

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields((err: any, values: IContact & any) => {
      if (!err && onSubmit) {

        // ricostruisce l'array da sottomettere che aggiorna il campo streets
        const editedStreet: string[] = [];
        values["streetkeys"].forEach((k: number) => editedStreet.push(values[`street_${k}`].toString()));
        onSubmit(err, { ...values, streets: editedStreet });
      }
    });
  };

  const handleOnOrgName = (rule: any, val: any, callback: any) => {
    const { getFieldValue } = form;
    if (value?.registrant && getFieldValue("registrant.entityType") === 1) {
      if (getFieldValue("name") !== getFieldValue("org")) {
        callback("Il nome e l'org devono coincidere se entityType = 1");
      }
    }

    callback();
  };

  const handleConsent4Pub = (rule: any, val: any, callback: any) => {
    const { getFieldValue } = form;

    if (value?.registrant && getFieldValue("registrant.entityType") !== 1) {
      if (getFieldValue("consentForPublish") !== true) {
        callback("Se il registrante è diverso da persona fisica il consenso è obbligatorio");
      }
    }

    callback();
  };

  const handleTelephoneNumber = (rule: any, val: any, callback: any) => {
    const errors = [];
    const regExp = new RegExp(/\+\d{1,3}\.\d{1,14}/);
    if (!regExp.test(val)) {
      errors.push("Formato invalido");
    }

    if (val.includes(" ")) {
      errors.push("Contiene spazi");
    }

    if (val.toString().length > 14) {
      errors.push("Eccede la quantità massima di caratteri, 14");
    }
    if (errors.length > 0) {
      callback(errors.join(","));
    }


    callback();
  };


  const handlePostalCode = (rule: any, val: any, callback: any) => {
    const { getFieldValue } = form;

    if (getFieldValue("country") === "it"  && val.length > 5) {
        callback("Per gli italiani deve essere composto di 5 caratteri");
    }

    callback();
  };

  return (

    <Form  {...formItemLayout} onSubmit={handleOnSubmit} className={"ant-advanced-search-form"}>

      <Form.Item label={"Nome"}>
        {getFieldDecorator(`name`, {
          initialValue: value && value.name,
          rules: [
            {
              required: true,
              message: "Inserisci il nome "
            }
          ]
        })(
          <Input
            // Se registrante il campo Nome è modificabile solo se il Registrante è diverso da persona fisica (cioè EntityType <> 1);
            //  il campo Nazione è mod
            disabled={disabled || (value?.registrant && form.getFieldValue("registrant.entityType") === 1)}
            data-testid='inputName'
            placeholder={"name"}

          />)}
      </Form.Item>

      <Form.Item label={"Org"}>
        {getFieldDecorator(`org`, {
          initialValue: value && value.org,
          rules: [
            {
              required: true,
              message: "Inserisci un organizzazione "

            }, {
              validator: handleOnOrgName
            }
          ]
        })(
          <Input
            data-testid="inputOrg"
            placeholder={"org"}
            disabled={disabled}
          />)}
      </Form.Item>

      <Form.Item label={"E-Mail"}>
        {getFieldDecorator(`email`, {
          initialValue: value && value.email,
          rules: [
            {
              required: true,
              message: "Inserisci una email valida",
              type: "email"
            }
          ]
        })(
          <Input
            placeholder={"email"}
            disabled={disabled}

          />)}
      </Form.Item>

      <Form.Item label={"Voice"}>
        {getFieldDecorator(`voice`, {
          initialValue: value && value.voice,
          rules: [
            {
              required: true,
              message: "Inserisci un numero di telefono "
            }, { validator: handleTelephoneNumber }
          ]
        })(
          <Input
            placeholder={"voice"}
            disabled={disabled}

          />)}
      </Form.Item>

      <Form.Item label={"Fax"}>
        {getFieldDecorator(`fax`, {
          initialValue: value && value.fax,
          rules: [{ validator: handleTelephoneNumber }]
        })(
          <Input
            placeholder={"fax"}
            disabled={disabled}

          />)}
      </Form.Item>

      <Form.Item label={"City"}>
        {getFieldDecorator(`city`, {
          initialValue: value && value.city,
          rules: [
            {
              required: true,
              message: "Inserisci una città "
            }
          ]
        })(
          <Input
            placeholder={"city"}
            disabled={disabled}

          />)}
      </Form.Item>

      {/*<Form.Item label={"Streets"}>*/}
      {/*  {getFieldDecorator(`streets`, {*/}
      {/*    initialValue: value && value.streets,*/}
      {/*    rules: [*/}
      {/*      {*/}
      {/*        required: true,*/}
      {/*        message: "Inserisci uno o più indirizzi "*/}
      {/*      }*/}
      {/*    ]*/}
      {/*  })(*/}
      {/*    <Input*/}
      {/*      placeholder={"streets"}*/}
      {/*      disabled={disabled}*/}

      {/*    />)}*/}
      {/*</Form.Item>*/}

      <Form.Item label={"Streets"}>
        {getFieldDecorator(`streets`, {
          initialValue: value && value.streets
        })(<StreetsFormItems form={form} items={value} />)}
      </Form.Item>

      <Form.Item label={"Postal code"}>
        {getFieldDecorator(`postalCode`, {
          initialValue: value && value.postalCode,
          rules: [
            {
              required: true,
              message: "Inserisci un codice postale "
            }, { validator: handlePostalCode }
          ]
        })(
          <Input
            placeholder={"Codice postale"}
            disabled={disabled}

          />)}
      </Form.Item>

      <Form.Item label={"StateOrProvince"}>
        {getFieldDecorator(`state`, {
          initialValue: value && value.state,
          rules: [
            {
              required: false,
              message: "Inserisci una provincia o stato "
            }
          ]
        })(
          <SelectStateOrProvince condition={form.getFieldValue(`country`) === "it" ? "province" : "state"} />
        )}
      </Form.Item>

      <Form.Item label={"Country"}>
        {getFieldDecorator(`country`, {
          initialValue: value && value.country,
          rules: [
            {
              required: true,
              message: "Inserisci una nazione "
            }
          ]
        })(
          <SelectCountry form={form} disabled={disabled} placeholder="es. IT (Italy)" />)}
      </Form.Item>

      <Form.Item label={"Consent For Publish"}>
        {getFieldDecorator("consentForPublish", {
          valuePropName: "checked",
          initialValue: !!value && value.consentForPublish,
          rules: [{
            required: true
          }, {
            validator: handleConsent4Pub
          }
          ]
        })(
          <Switch
            // onChange={onChange}
            disabled={disabled}
            checkedChildren={"consenti"}
            unCheckedChildren={"nega"}
            style={{ float: "left" }}
          />
        )}
      </Form.Item>


      {
        value?.registrant &&
        <>
          <Divider type={"horizontal"} dashed />

          <Form.Item label={"RegCode"}>
            {getFieldDecorator(`registrant.regcode`, {
              initialValue: value && value.registrant.regcode,
              rules: [
                {
                  required: true,
                  message: "Inserisci un regcode "
                }
              ]
            })(
              <Input
                placeholder={"regcode"}
                disabled={disabled}

              />)}
          </Form.Item>
          {
            value.registrant.schoolCode &&
            <Form.Item label={"registrant.schoolCode"}>
              {getFieldDecorator(`schoolCode`, {
                initialValue: value && value.registrant.schoolCode,
                rules: [
                  {
                    required: false,
                    message: "Inserisci uno schoolcode "
                  }
                ]
              })(
                <Input
                  placeholder={"schoolCode"}
                  disabled={disabled}

                />)}
            </Form.Item>
          }

          <Form.Item label={"entityType"}>
            {getFieldDecorator(`registrant.entityType`, {
              initialValue: value && value.registrant.entityType,
              rules: [
                {
                  required: true,
                  message: "Inserisci l'entityType "
                }
              ]
            })(
              <SelectEntityType form={form} disabled={disabled} />
            )}
          </Form.Item>

          <Form.Item label={"nationality"}>
            {getFieldDecorator(`registrant.nationality`, {
              initialValue: value && value.registrant.nationality,
              rules: [
                {
                  required: true,
                  message: "Inserisci una nazionalità "
                }
              ]
            })(
              // il campo Nazione è modificabile solo se il Registrante è una persona fisica (cioè
              // EntityType = 1);
              <SelectCountry
                form={form}
                disabled={disabled || (form.getFieldValue("registrant.entityType") !== 1)}
                placeholder="es. IT (Italy)" />)}
          </Form.Item>
        </>

      }


      <Form.Item>
        {error && <div style={{ color: "red" }}>{printAxiosFetchError(error)}</div>}
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button onClick={() => form.resetFields()}>
              Pulisci
            </Button>
            <Button style={{ marginLeft: 8 }} data-testid="btnSubmitEditContact" id="btnSubmitEditContact"
                    htmlType="submit" type="primary"

                    loading={loading}>
              Procedi
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};
export const WrappedEditContactForm = Form.create<IEditContactForm>({ name: "horizontal_login" })(EditContactForm);

