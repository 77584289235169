import * as React from 'react';
import ViewerSelectorBox from './styled/ViewSelectorBox';
import Selector from './Selector';
import { ViewType } from './Type/ViewType';
import  i18n  from 'i18next';

interface IViewControlBar{
  default: ViewType;
  align: 'left' | 'center' | 'right';

  onChange(view: ViewType): void;
}

export class ViewControlBar extends React.Component<IViewControlBar, { selected: string }> {
  public state = {
    selected: this.props.default,
  };

  public onClick = (type: ViewType) => {
    this.setState({ selected: type });
    this.props.onChange(type);
  };

  public render() {
    return (
      <ViewerSelectorBox align={this.props.align}>
        <Selector
          type="bars"
          onClick={this.onClick.bind(this, 'list')}
          selected={this.state.selected === 'list'}
          tooltipText={i18n.t("cardWithViewer_showAsList")}
        />
        <Selector
          type="appstore-o"
          onClick={this.onClick.bind(this, 'grid')}
          selected={this.state.selected === 'grid'}
          tooltipText={i18n.t("cardWithViewer_showAsGrid")}
        />
        <Selector
          type="folder"
          onClick={this.onClick.bind(this, 'tab')}
          selected={this.state.selected === 'tab'}
          tooltipText={i18n.t("cardWithViewer_showAsTab")}
        />
      </ViewerSelectorBox>
    );
  }
}
