import * as React from 'react';
import { BoxForm } from '../../../../Common/Styled/BoxForm';
import { FetchController } from '../../../../Common/Navigation/FetchController/FetchController';
import { ParsedQuery } from 'query-string';
import { IFetchStateType } from '../../../../Type/IFetchtStateType';
import { IDomains } from '../../types/domains';
import { ResultsPane } from '../../Results/ResultsPane';
import { SearchPane } from './SearchPane';

export const LimitedSearchDomainsComponent: React.FC<{
  query: ParsedQuery<React.ReactText>;
  domains: IFetchStateType<IDomains>;
  showSingleDomain?: boolean;
}> = ({ query, domains, showSingleDomain }) => (
  <BoxForm>
    <SearchPane query={query} domains={domains} />
    <FetchController {...domains}>
      {data => (
        <ResultsPane
          showSingleDomain={showSingleDomain}
          value={data as IDomains}
        />
      )}
    </FetchController>
  </BoxForm>
);
