import * as React from 'react';
import { stringify } from 'query-string';
import { DispatchContext, StateContext } from '../../../../../StoreProvider';
import { fetchDomains } from '../../../operations';
import { documentTitle, useQuery } from '../../../../../../utils/various';
import { FiltersBarPane } from '../../../../../Common/Navigation/FiltersBarPane/FiltersBarPane';
import { FetchController } from '../../../../../Common/Navigation/FetchController/FetchController';
import { ResultsPane } from '../../../Results/ResultsPane';
import { IDomains } from '../../../types/domains';
import { BoxForm } from '../../../../../Common/Styled/BoxForm';
import { AdvancedDomainsSearchFormPane } from './AdvancedDomainsSearchFormPane';

/**
 * Pagina di ricerca domini.
 *
 * Si recuperano i domini tramite la fetch e poi viene passato il risulato al SearchDomainComponent che:
 *
 * mostra il form di ricerca domini (AdvancedDomainsSearchFormPane) con sotto la visualizzazione dei risultati.
 * I risultati vengono recuperati tramite la fetchDomains che tramite un context ritorna i domains.
 * La gestione della fetchDomains è demandata al FetchController.
 *
 * Una volta ottenuti i domini vengono visualizzati per mezzo del ResultPane.
 */
const AdvancedDomainsSearchPage: React.FunctionComponent<{}> = props => {
  documentTitle('Ricerca domini avanzata');
  const query = useQuery();
  const dispatch = React.useContext(DispatchContext);
  const { domains } = React.useContext(StateContext);

  React.useEffect(() => {
    // TODO remove -  fetchDomains(dispatch, domains, omit(query, 'advancedSearch'));
    fetchDomains(dispatch, domains, query);
  }, [stringify(query)]);

  return (
    <BoxForm>
      <AdvancedDomainsSearchFormPane values={query} />
      <FiltersBarPane urlPrefix={'/domains/advanced_search'} />
      <FetchController {...domains}>
        {data => (
          <ResultsPane showSingleDomain={false} value={data as IDomains} />
        )}
      </FetchController>
    </BoxForm>
  );
};

export default AdvancedDomainsSearchPage;
