import { IDomainHost, IDomainNewHost } from "../../types/domain";
import * as React from "react";
import { DomainNameServerInValidationTable, DomainNameServerTable, DomainNewNameServerTable } from "./NameServerTables";
import { Current, Future, FutureConfiguration } from "../TableTitles";
import { Collapse } from "antd";

const { Panel } = Collapse;

export const NameServer: React.FC<{ hosts?: IDomainHost[], newHosts?: IDomainNewHost[] }> = ({ hosts, newHosts }) => {
  return (
    <div>

      <Collapse defaultActiveKey={['1','2']}>

        <Panel header={<Current />} key="1">
          {hosts && hosts.length > 0 && <DomainNameServerTable hosts={hosts} />}
        </Panel>

        {
          newHosts && hosts && newHosts.length > 0 &&
          <Panel header={<FutureConfiguration />} key="2">
            <DomainNameServerInValidationTable hosts={hosts} newHosts={newHosts} />
          </Panel>
        }

        {newHosts && newHosts.length > 0 &&
        <Panel header={<Future />} key="3">
          <DomainNewNameServerTable newHosts={newHosts} />
        </Panel>}
      </Collapse>
    </div>
  );
};