import {IRegistrar} from '../../../Registrar/IRegistrar';
import {IAvailableEvents, IDomain, IEventsRequirements} from '../types/domain';
import * as React from 'react';
import {AbilityContext} from '../../../Common/Ability/AbilityContext';
import {Button, Divider, Dropdown, Icon, Menu, Modal} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf, faKey, faW} from '@fortawesome/free-solid-svg-icons';

import {faCamera} from '@fortawesome/free-solid-svg-icons/faCamera';
import {SnapshotWhois} from './SnapshotWhois';
import {AuthInfo} from '../Info/AuthInfo/AuthInfo';
import {EventIconInfoMapping, IEventIconInfo} from '../../Events/const';
import {whoisPDFReport} from '../../../../utils/whois/report';
import {isEmpty} from "lodash";

/***
 * ACTION MENU
 */

export interface IActionMenu {
    /** eventi applicabili disponibili per il dominio in oggetto */
    availableEvents: IAvailableEvents;
    /** dati del registrar */
    registrar?: IRegistrar;
    /** dati del dominio */
    domain: IDomain;
    /** data di revisione */
    refRevision?: string;
    /** data di revisione */
    revisionNumber?: string;
    eventsRequirementsError?: boolean;
    eventsRequirements?: IEventsRequirements;

    onSelectOperation(operation: string): void;

    open(): void;
}

/***
 * Drop down menu che mostra le azioni disponibili
 * @param props
 * @constructor
 */
export function ActionMenu(props: IActionMenu) {
    const {ability} = React.useContext(AbilityContext);
    const {registrar, domain, refRevision, availableEvents, eventsRequirements} = props;

    console.log('[ActionMenu] - reloadaa', availableEvents);

    function handlePrintWhois(
        _data: IDomain,
        _revision?: string,
        _registrar?: IRegistrar,
        _type?: 'internal',
    ) {
        if (registrar) {
            whoisPDFReport(_data, _revision, _registrar, undefined, _type);
        } else {
            console.warn(
                'Problemi nella creazione del pdf per mancanza di tutti i dati',
            );
        }
    }

    const buildMenu = (
        availableEventList: IAvailableEvents,
        enableOperation: boolean,
    ) => (
        <Menu>
            {/* -- STRUMENTI -- */}
            <Menu.ItemGroup key="tools1" title="Strumenti">
                {/* AUTHINFO */}
                <Menu.Item
                    key={'authinfo'}
                    disabled={!ability.can('read', 'Authinfo')}
                    onClick={() =>
                        openActionModal(
                            domain,
                            'Visualizza authinfo\n',
                            <AuthInfo
                                domain={domain}
                                refRevision={refRevision}
                                revisionNumber={props.revisionNumber}
                            />,
                        )
                    }
                >
          <span>
            <FontAwesomeIcon icon={faKey} style={{marginRight: 5}}/>
             Visualizza authinfo
          </span>
                </Menu.Item>
            </Menu.ItemGroup>

            {/* -- REPORTS -- */}
            <Menu.ItemGroup key="reports2" title="Reports">
                {/* WHOIS */}
                <Menu.Item
                    key="whois"
                    onClick={() => handlePrintWhois(domain, refRevision, registrar)}
                    disabled={!domain.registrant || !registrar}
                >
                    <FontAwesomeIcon icon={faW} style={{marginRight: 5}}/> WHOIS
                </Menu.Item>
                <Menu.Item
                    key="whoisInternal"
                    onClick={() =>
                        handlePrintWhois(domain, refRevision, registrar, 'internal')
                    }
                    disabled={!domain.registrant || !registrar}
                >
                    <FontAwesomeIcon icon={faFilePdf} style={{marginRight: 5}}/> WHOIS
                    (uso interno)
                </Menu.Item>

                {/* WHOIS SNAPSHOT */}
                <Menu.Item
                    key="snapshot-whois"
                    onClick={() =>
                        openActionModal(
                            domain,
                            'WHOIS Snapshot',
                            <SnapshotWhois domain={domain}/>,
                        )
                    }
                >
          <span>
            <FontAwesomeIcon icon={faCamera} style={{marginRight: 5}}/>
             WHOIS Snapshot
          </span>
                </Menu.Item>
            </Menu.ItemGroup>

            {/* -- OPERAZIONI -- */}

            {enableOperation && availableEventList && availableEventList.length > 0 && (
                <Menu.ItemGroup key="operations3" title="Operazioni compatibili">
                    {availableEventList.map((value: string) => (
                        (eventsRequirements && eventsRequirements[value]) && <Menu.Item
                            disabled={props.eventsRequirementsError}
                            key={value}
                            onClick={() => props.onSelectOperation(value)}
                        >
                            <span>
                                {!isEmpty(EventIconInfoMapping[value]) ? renderActionMenuInfo(EventIconInfoMapping[value]) : value}</span>
                        </Menu.Item>

                    ))}
                </Menu.ItemGroup>
            )}
        </Menu>
    );

    return (
        <Dropdown
            overlay={buildMenu(availableEvents, !refRevision)}
            placement="bottomLeft"
        >
            <Button>
                Azioni
                <Divider type="vertical"/>
                <Icon type="setting" theme="filled"/>
            </Button>
        </Dropdown>
    );
}


function renderActionMenuInfo(actionItem: IEventIconInfo) {
    return <><FontAwesomeIcon
        icon={
            actionItem.icon
        }
        opacity={
            actionItem.opacity
        }
        color={
            actionItem.color
        }
        style={{marginRight: 5}}
    />
        {
            actionItem.name
        }</>
}

/* Apre una modale con all'interno il componente passato in content **/
function openActionModal(
    domain: IDomain,
    title: string,
    content: React.ReactNode,
) {
    return Modal.info({
        title,
        centered: true,
        width: 500,
        okText: 'Chiudi',
        content,
    });
}
