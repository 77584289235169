import {IDomainDsRecord, INewDsRecord} from "../../types/domain";
import * as React from "react";
import {CardDetailSection} from "../../../../Common/Styled/CardDetailSection";
import {DSRecordTable, NewDSRecordTable} from "./DSRecordTables";
import {Current, Future} from "../TableTitles";

export const DSRecord: React.FC<{ dsRecords?: IDomainDsRecord[], newDsRecord?: INewDsRecord[] }> = ({dsRecords, newDsRecord}) => {
    return <div>
        {
            dsRecords && dsRecords.length > 0 &&
            <CardDetailSection title={<Current/>}>
                <DSRecordTable dsRecords={dsRecords}/>
            </CardDetailSection>
        }
        {
            dsRecords && dsRecords.length > 0 && newDsRecord && newDsRecord.length > 0 && <div style={{height: 50}}/>}
        {
            newDsRecord && newDsRecord.length > 0 &&
            <CardDetailSection title={<Future/>}>
                <NewDSRecordTable newDsRecords={newDsRecord}/>
            </CardDetailSection>
        }
    </div>
}