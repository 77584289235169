import * as React from 'react';
import {Divider, Icon, Tooltip} from 'antd';
import {IDomain} from '../types/domain';
import {moment} from '../../../../utils/various';
import {faGlobe} from '@fortawesome/free-solid-svg-icons/faGlobe';
import {faTag} from '@fortawesome/free-solid-svg-icons/faTag';
import RegistrarPopover from '../../../Registrar/RegistrarPopover';
import {EMPTY} from '../../../../const/common';
import {StatusbarIcon, StyledStatusbarContent, Text} from '../../../Common/Styled/StatusBar';
import {faExternalLinkAlt, faHouseUser, faPlug, faUserTie, faPlugCircleExclamation} from '@fortawesome/free-solid-svg-icons';
import {DomainName} from '../Info/DomainName/DomainName';
import styled from 'styled-components';
import {Status} from '../../../Common/Styled/Status';
import {FetchController} from "../../../Common/Navigation/FetchController/FetchController";
import {IRegistrar} from "../../../Registrar/IRegistrar";
import { AxiosError } from 'axios';

const DivSection = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 7px;

`;

let truncate: React.CSSProperties;
truncate = {
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

const Statusbar: React.FC<{ domain: IDomain, org: string, registrar:{loading : boolean,data?:IRegistrar,error?:AxiosError},refRevision?: string }> = (
    {
        domain: {domain, domainIDN, created, expire, status, signed, registrant, registrarId, id}, org, refRevision,
        registrar:{loading,data,error}
    },
) => {


    return (
        <StyledStatusbarContent>

            <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <DivSection style={{
                        backgroundColor: '#F6F6F6',
                        border: '1px solid #40A8FF',
                        borderRadius: 5,
                        padding: 8,
                    }}>

                        <Tooltip title="Nome domino">
                            <StatusbarIcon icon={faGlobe}/>
                            <DomainName name={domainIDN ? domainIDN : domain} signed={signed}
                                        linkTo={`/domains/${id}`}/>
                        </Tooltip>
                    </DivSection>
                    {refRevision &&
                    <div style={{
                        fontSize: '9px',
                        color: 'grey',
                        marginTop: '5px',
                        textAlign: 'center',
                    }}><Icon
                        type="arrow-up"/> Clicca per tornare alla revisione attuale
                    </div>
                    }</div>
            </>

            <DivSection style={{marginLeft: 10}}>
                <a href={`http://${domain}`} target="_blank" rel="noopener noreferrer">
                    <Tooltip title={`Apri "${domain}" come link esterno`}>
                        <StatusbarIcon icon={faExternalLinkAlt}/>
                    </Tooltip></a>
            </DivSection>

            <Divider type="vertical"/>

            <Tooltip title="Data di creazione">
                <DivSection>
                    <StatusbarIcon icon={faPlug}/>
                    <Text>{created ? moment(created).format('LLL') : 'n.d.'}</Text>
                </DivSection>
            </Tooltip>

          <Divider type="vertical"/>

          <Tooltip title="Data di Scadenza">
            <DivSection>
              <StatusbarIcon icon={faPlugCircleExclamation}/>
              <Text>{expire ? moment(expire).format('LLL') : 'n.d.'}</Text>
            </DivSection>
          </Tooltip>

            <Divider type="vertical"/>

            <Tooltip title="Stato dominio">
                <DivSection>
                    <StatusbarIcon icon={faTag}/>
                    <Text>{status ? <Status size={'small'}>{status}</Status> : EMPTY}</Text>
                </DivSection>
            </Tooltip>


            {registrant && (
                <>
                    <Divider type="vertical"/>

                    <Tooltip title={registrant.org ? registrant.org : EMPTY}>
                        <DivSection>
                            <StatusbarIcon icon={faUserTie}/>
                            <Text style={truncate}>
                                {registrant.org ? registrant.org : EMPTY}
                            </Text>
                        </DivSection>
                    </Tooltip>
                </>
            )}

            <Divider type="vertical"/>

            <Tooltip title="Registrar">
                <DivSection>
                    <StatusbarIcon icon={faHouseUser}/>
                    <FetchController
                        loading={loading}
                        error={error}
                        payload={data}
                    >
                        {(values) => <RegistrarPopover registrar={values as IRegistrar}/>}
                    </FetchController>
                    {/*<RetrieveCachedRegistrar id={registrarId.toString(10)} children={RegistrarPopover}/>*/}
                </DivSection>
            </Tooltip>

        </StyledStatusbarContent>
    );
}
export default Statusbar;
