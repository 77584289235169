import * as React from 'react';
import { Button } from 'antd';

// TODO formattare la pagina vedere esempio
// https://github.com/ant-design/ant-design-pro/blob/master/src/components/Exception/index.less
// https://preview.pro.ant.design/exception/500
// https://pro.ant.design/components/Exception/

const Exception500: React.FC<{description?: string}> = props => (
  <div style={{ textAlign: 'center' }}>
    <img src={'https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg'} />
    <h3>500</h3>
    <p>{props.description}</p>
    <Button/>
  </div>
);
export default Exception500;



export const Exception500Little: React.FC<{description?: string}> = props => (
    <div style={{ textAlign: 'center' }}>
        {/*<img src={'https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg'} />*/}
        <h3>500</h3>
        <p>{props.description}</p>
        {/*<Button><Link to={'/'}>Home</Link></Button>*/}
    </div>
);
