import * as React from 'react';
import {IContact} from '../contacts';
import {Dl} from "../../../Common/Styled/DefinitionList";
import {RegistrantBaseInfo} from "./Base/RegistrantBaseInfo";

/**
 * Visulizza le info base del registrante utilizzando il TAG DL (description list)
 * @param props
 * @constructor
 */

export const RegistrantInfo: React.FC<IContact & {clean?:boolean}> = props => (
    <Dl>
        <RegistrantBaseInfo {...props}/>
    </Dl>
);
