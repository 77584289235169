import * as React from 'react';
import i18n from 'i18next';
import {moment} from "../../../../utils/various";
import {
    DetailContent,
} from "../../../Common/Styled/Detail";
import {
    DetailBox,
    DetailRowWrapper,
} from "../../../Common/Navigation/Revisions/DetailCard/DetailCardBox";
import {columnValue} from "../../../Contacts/Detail/Info/Diff/ContactsInfoDiff";
import {IDetailedRevision} from "../../../Common/Navigation/Revisions/revisions";

const DomainDiffBasic: React.FC<{ domainA: IDetailedRevision, domainB: IDetailedRevision }> = ({domainA, domainB}) => {

    return (
        <DetailBox revisionA={domainA.revision} revisionB={domainB.revision}>
            <>
                {/* Domain Name */}
                <DetailRowWrapper
                    label={`${i18n.t('domain_name')}:`}
                    column2={columnValue('name', [domainA, domainB], 0)}
                    column3={columnValue('name', [domainA, domainB], 1)}/>

                {/* Domain Name IDN */}
                {(domainA.domainIDN || domainB.domainIDN) &&
                <DetailRowWrapper
                    label={`${i18n.t('domain_nameIDN')}:`}
                    column2={columnValue('domainIDN', [domainA, domainB], 0)}
                    column3={columnValue('domainIDN', [domainA, domainB], 1)}/>
                }

                {/* CREATED */}
                <DetailRowWrapper
                    label={`${i18n.t('domain_crDate')}:`}
                    column2={columnValue('created', [domainA, domainB], 0, (value) => moment(value).format('DD MMMM YYYY, HH:mm:ss'))}
                    column3={columnValue('created', [domainA, domainB], 1, (value) => moment(value).format('DD MMMM YYYY, HH:mm:ss'))}
                />

                {/* ExpireDate */}
                <DetailRowWrapper
                    label={`${i18n.t('domain_exDate')}:`}
                    column2={columnValue('expire', [domainA, domainB], 0, (value) => moment(value).format('LL'))}
                    column3={columnValue('expire', [domainA, domainB], 1, (value) => moment(value).format('LL'))}
                />

                {/*  LastUpdate  */}
                <DetailRowWrapper
                    label={`${i18n.t('domain_upDate')}:`}
                    column2={columnValue('lastupdate', [domainA, domainB], 0, (value) => moment(value).format('DD MMMM YYYY, HH:mm:ss'))}
                    column3={columnValue('lastupdate', [domainA, domainB], 1, (value) => moment(value).format('DD MMMM YYYY, HH:mm:ss'))}
                />

                {/*  Statuses */}
                <DetailRowWrapper
                    label={`${i18n.t('domain_status')}:`}
                    column2={columnValue('status', [domainA, domainB], 0)}
                    column3={columnValue('status', [domainA, domainB], 1)}/>

                {/* DNSSec */}
                <DetailRowWrapper
                    label={`${i18n.t('domain_dnssec')}:`}
                    column2={
                        <DetailContent
                            isDifferent={Boolean(domainA.signed) !== Boolean(domainB.signed)}>
                            {domainA.signed ? i18n.t('yes') : i18n.t('no')}
                        </DetailContent>}
                    column3={
                        <DetailContent
                            isDifferent={Boolean(domainA.signed) !== Boolean(domainB.signed)}>
                            {domainB.signed ? i18n.t('yes') : i18n.t('no')}
                        </DetailContent>}/>
            </>
        </DetailBox>
    );
};
export default DomainDiffBasic;
