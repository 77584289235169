import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const StyledStatusbarContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  //flex: 1 1 20em;
  @media (max-width:800px) {
    flex-direction: column;
  }
`;

export const StyledStatusbarContent = styled.div`
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
`
export const StatusbarIcon = styled(FontAwesomeIcon)`
  font-size: 14pt;
  font-weight: 100;
  color: #b3b3b3;
  margin-right: 7px;
`;

export const Text = styled.div`
  //font-size: 10pt;
`;

