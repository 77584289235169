import { GenericDocument } from '../../../Documents/documents';
import * as React from 'react';
import { useModal } from '../../../Common/Hooks/useModal';
import { useAddDocument } from '../../../Documents/hooks';
import { Button } from 'antd';
import { AddDocumentTips } from '../../../../const/TipsComponent';
import { DrawerAddDocument } from '../../../Documents/DrawerAddDocument';

export interface IAddModifyDocumentButton {
  onSubmitAddDocument: (values?: GenericDocument, error?: string) => void;
}

/**
 * Bottone che apre un Drawer per l'aggiunta di un nuovo documento
 * Il Drawer contiene un form al suo interno per registrare documenti o fax
 * @param onSubmitAddDocument
 * @constructor
 */
export const AddModifyDocumentButton: React.FC<IAddModifyDocumentButton> = ({
  onSubmitAddDocument,
}) => {
  const { visibile, open, close } = useModal();
  const { saveDocument, responseStatus, document, loading } = useAddDocument();

  const handleSubmitAddDocument = async (values: GenericDocument) => {
    try {
      const result = await saveDocument(values);
      onSubmitAddDocument(result, undefined);
    } catch (err) {
      onSubmitAddDocument(undefined, err);
    }
  };

  const responseSuccess =
    responseStatus &&
    document &&
    200 <= responseStatus &&
    responseStatus <= 300;

  const buttonLabel = `${responseSuccess ? 'Cambia' : 'Associa'} documento`;

  const buttonStyleLine = responseSuccess ? 'dashed' : 'primary';

  return (
    <div className={'add_modify_document_button'}>
      <Button
        loading={loading}
        type={buttonStyleLine}
        onClick={open}
        icon={'plus'}
      >
        {buttonLabel}
      </Button>
      <AddDocumentTips />
      <DrawerAddDocument
        show={visibile}
        onClose={close}
        onSubmit={values => handleSubmitAddDocument(values)}
      />
    </div>
  );
};
