/**
 * Dichiarazione che definisce il tipo window customizzato
 * per supportare i dati specificati nelle configurazioni
 * presenti in /resources
 */
declare global {
  interface Window {
    runConfig: MidaConfig;
  }
}

type MidaConfig = {
  REACT_APP_PREFIX_DOMAINS_SERVICE: string;
  REACT_APP_PREFIX_DOMAINS_GOV_SERVICE: string;
  REACT_APP_PREFIX_CONTACTS_SERVICE: string;
  REACT_APP_PREFIX_CUSTOMERS_SERVICE: string;
  REACT_APP_PREFIX_DOMAINS_RESERVED_SERVICE: string;
  REACT_APP_PREFIX_USERS_SERVICE: string;
  REACT_APP_PREFIX_DOCUMENTS_SERVICE: string;
  REACT_APP_JBILLING_HOST: string;
  REACT_APP_MIDA_OLD_HOST: string;
  REACT_APP_FAXSERVER_HOST: string;
  REACT_APP_GUIDELINE_LINK: string;
};

/**
 * Funzione per recuperare la configurazione dell'app
 */
function getWindowRunConfig() {
  return { ...window['runConfig'] };
}

/***
 * File di raccordo per l'estrazione delle variabili di ambiente utili alla ricostruzione degli endpoint dei
 * servizi REST.
 *
 *
 */

export const PREFIX_DOMAINS_SERVICE =
  getWindowRunConfig().REACT_APP_PREFIX_DOMAINS_SERVICE ||
  process.env.REACT_APP_PREFIX_DOMAINS_SERVICE;

export const PREFIX_DOMAINS_GOV_SERVICE =
  getWindowRunConfig().REACT_APP_PREFIX_DOMAINS_GOV_SERVICE ||
  process.env.REACT_APP_PREFIX_DOMAINS_GOV_SERVICE;
export const PREFIX_CONTACTS_SERVICE =
  getWindowRunConfig().REACT_APP_PREFIX_DOMAINS_SERVICE ||
  process.env.REACT_APP_PREFIX_DOMAINS_SERVICE;
export const PREFIX_USERS_SERVICE =
  getWindowRunConfig().REACT_APP_PREFIX_USERS_SERVICE ||
  process.env.REACT_APP_PREFIX_USERS_SERVICE;
export const PREFIX_CUSTOMERS_SERVICE =
  getWindowRunConfig().REACT_APP_PREFIX_CUSTOMERS_SERVICE ||
  process.env.REACT_APP_PREFIX_CUSTOMERS_SERVICE;
export const PREFIX_DOCUMENTS_SERVICE =
  getWindowRunConfig().REACT_APP_PREFIX_DOCUMENTS_SERVICE ||
  process.env.REACT_APP_PREFIX_DOCUMENTS_SERVICE;
export const PREFIX_DOMAINS_RESERVED_SERVICE =
  getWindowRunConfig().REACT_APP_PREFIX_DOMAINS_RESERVED_SERVICE ||
  process.env.REACT_APP_PREFIX_DOMAINS_RESERVED_SERVICE;
export const JBILLING_HOST =
  getWindowRunConfig().REACT_APP_JBILLING_HOST ||
  process.env.REACT_APP_JBILLING_HOST;
export const MIDA_OLD_HOST =
  getWindowRunConfig().REACT_APP_MIDA_OLD_HOST ||
  process.env.REACT_APP_MIDA_OLD_HOST;
export const FAXSERVER_HOST =
  getWindowRunConfig().REACT_APP_FAXSERVER_HOST ||
  process.env.REACT_APP_FAXSERVER_HOST;
export const GUIDELINE_LINK =
  getWindowRunConfig().REACT_APP_GUIDELINE_LINK ||
  process.env.REACT_APP_GUIDELINE_LINK;

export const API_DOMAINS = PREFIX_DOMAINS_SERVICE + '/domains';
export const API_DOMAINS_GOV = PREFIX_DOMAINS_GOV_SERVICE + '/v1';
export const API_NOTES = PREFIX_DOMAINS_SERVICE + '/notes';
export const API_USERS = PREFIX_USERS_SERVICE + '/users';
export const API_SECURITY = PREFIX_USERS_SERVICE;
export const API_CONTACTS = PREFIX_CONTACTS_SERVICE + '/contacts';
export const API_REGISTRARS = PREFIX_CUSTOMERS_SERVICE + '/registrars';
export const API_DOCUMENTS = PREFIX_DOCUMENTS_SERVICE + '/documents';
export const API_EVENTS = PREFIX_DOMAINS_SERVICE + '/events';
export const API_DOMAINS_RESERVED = PREFIX_DOMAINS_RESERVED_SERVICE;
