import * as React from 'react';
import { IDomainHost, IDomainHostAddress, IDomainNewHost } from '../../types/domain';
import { TableWrapped } from '../../../../Common/Styled/TableWrapped';
import DomainsCounter from '../../../../Common/Navigation/AssociatedDomainsCounter/DomainsCounter';
import { Divider } from 'antd';
import { moment } from '../../../../../utils/various';
import {AbilityContext} from "../../../../Common/Ability/AbilityContext";

function LinkedNamerServer(props:{value:string}){
  const {ability} = React.useContext(AbilityContext);

  return ability.can("search", "Domain")?<div>
    <span>{props.value}</span>
    <Divider type={'vertical'}/>
    <DomainsCounter
        query={`hostname=${props.value}`}
        linkTo={`/domains?hostname=${props.value}`}
    />
    {/*<BasicPopoverFilter*/}
    {/*    content={<DomainFilter url={`/domains?hostname=${value}`}*/}
    {/*                           content={`hostname=${value}`}/>}*/}
    {/*/>*/}
  </div>:    <span>{props.value}</span>

}

const columns = [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Nome',
    align: 'center' as 'center',
    render: (value: string,record:any) => {
      if (value) {
        return value && record.type === 'rem' ? value : <LinkedNamerServer value={value}/>
      } else {
        return '-';
      }
    },
  },
  {
    title: 'Indirizzo IPv4',
    dataIndex: 'addresses',
    align: 'center' as 'center',
    key: 'ip4',
    render: (value: IDomainHostAddress[]) => {
      if (value && value.length > 0) {
        const ipv4 = value.filter(val => val.type === 'v4');
        if (ipv4.length > 0) {
          return ipv4[0].address;
        }
      }

      return '-';
    },
  },
  {
    title: 'Indirizzo IPv6',
    dataIndex: 'addresses',
    align: 'center' as 'center',
    key: 'ip6',
    render: (value: IDomainHostAddress[]) => {
      if (value && value.length > 0) {
        const ipv6 = value.filter(val => val.type === 'v6');
        if (ipv6.length > 0) {
          return <span style={{ wordBreak: 'break-all' }}>{ipv6[0].address}</span>;
        }
      }

      return '-';
    },
  },
];

const extraColumns = [
  {
    title: 'Created',
    dataIndex: 'created',
    align: 'center' as 'center',
    key: 'created',
    render: (value: string) => {
      if (value) {
        return moment(value).format('DD MMMM YYYY, HH:mm:ss');
      }

      return '-';
    },
  },
  /*{
    title: 'Type',
    dataIndex: 'type',
    align: 'center' as 'center',ne
    key: 'type',
    render: (value: 'rem' | 'add') => {
      return <FontAwesomeIcon icon={value === 'rem' ? faMinus : faPlus}/>;
    },
  },*/
];


export const DomainNameServerTable: React.FC<{ hosts?: IDomainHost[] }> = ({ hosts }) => {
  return (
    <TableWrapped
      style={{backgroundColor:'white'}}
      dataSource={hosts}
      columns={columns}
      rowKey="name"
      pagination={false}
      size={'small'}
      bordered={true}
    />
  );
};


export const DomainNewNameServerTable: React.FC<{ newHosts?: IDomainNewHost[] }> = ({ newHosts }) => {
  return (
    <TableWrapped
      style={{backgroundColor:'white'}}
      dataSource={newHosts}
      columns={[...columns, ...extraColumns]}
      rowKey="name"
      pagination={false}
      size={'small'}
      bordered={true}
      rowClassName={(record: IDomainNewHost) => record.type === 'rem' ? 'remove' : 'add'}
    />
  );

};

export const DomainNameServerInValidationTable: React.FC<{ hosts:IDomainHost[], newHosts: IDomainNewHost[] }> = ({ hosts,newHosts }) => {
  const remHosts : IDomainHost[] = newHosts && newHosts.filter((v:IDomainNewHost)=> v.type === 'rem').map((v:IDomainNewHost)=> ({name:v.name,addresses:v.addresses}));
  const addHosts: IDomainHost[]  = newHosts && newHosts?.filter((v:IDomainNewHost)=> v.type === 'add').map((v:IDomainNewHost)=> ({name:v.name,addresses:v.addresses}));
  const resultHosts : IDomainHost[] = hosts?.concat(addHosts)?.filter((v)=>!remHosts.map(rh=>rh.name).includes(v.name));
  // console.log(" remHosts, addHosts,resultHosts ",remHosts,addHosts,resultHosts);
  return (
    <TableWrapped
      style={{backgroundColor:'white'}}
      dataSource={resultHosts}
      columns={columns}
      rowKey="name"
      pagination={false}
      size={'small'}
      bordered={true}
    />
  );

};