import styled from "styled-components";

export const BoxForm = styled.div`
  //background-color: rgba(32, 117, 187, 0.27);
  padding: 20px;
  border-radius: 10px;
  //Seleziona la prima form e tutti i primi figli ant-card per dare spazio ta di loro
  form,
  > .ant-card {
    margin-bottom: 20px;
  }
`;