import * as React from "react";
import {IPaginationProps} from "../Pagination/type";
import {useHistory, useLocation} from "react-router-dom";
import {stringify} from "query-string";
import Pagination from "../Pagination/Pagination";
import {useQuery} from "../../../../utils/various";

/***
 * Paginatore che utilizza le proprietà del router (location,history) per navigare tra le pagine e size

 * @param {IPage} page - contenente le informazioni della paginazione
 * @constructor
 */
export const Paginator: React.FC<IPaginationProps> = ({page}) => {

    const history = useHistory()
    const location = useLocation();
    const query = useQuery();

    const handleOnChangePage = (p: number) => {
        query.page = p.toString();
        history.push(`${location.pathname}?${stringify(query)}`);
    };

    const handleOnChangeSize = (s: number) => {
        query.size = s.toString();
        history.push(`${location.pathname}?${stringify(query)}`);
    };

    if (page && page.totalElements && page.totalElements > 0) {
        return (
            <Pagination page={page}
                        onChangePage={handleOnChangePage}
                        onChangeSize={handleOnChangeSize}/>
        );
    } else {
        return <div/>
    }

};
