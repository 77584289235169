import {IFetchStateType} from "../../Type/IFetchtStateType";
import {  apiGetDomainNotes} from "../../../const/api-calls";
import {IDomainNotes} from "./types";
import {IDomainEvents} from "../Events/types/domainEvents";

/***
 * Funzione per il recupero del dettaglio dominio relativi alla query passata.
 *
 * @param dispatch
 * @param domainNotes
 * @param domainId
 * @param query - queryString corrente
 * @param keycloak
 */
export function fetchDomainNotes(dispatch: any, domainNotes: IFetchStateType<IDomainNotes>, domainId: string,  location: any) {
    const resultQuery = `${location.pathname}${location.search}`;


    console.debug("resultQuery ",resultQuery)
    console.debug("domainEvents.query ",domainNotes.query)
    if (resultQuery === '') {
        dispatch({type: 'DOMAIN_NOTES_FETCH_CLEAN'})
    } else {
        // Se query memorizzato è diverso da quello cercato e non ci sono errori allora fetchDomains
        if (resultQuery.localeCompare(domainNotes.query) !== 0) {

            dispatch({type: 'DOMAIN_NOTES_FETCH_LOAD', query: resultQuery})



            apiGetDomainNotes(domainId, resultQuery).then((res: any) => {
                dispatch({type: 'DOMAIN_NOTES_FETCH_SUCCESS', query: resultQuery, payload: res})
            }).catch((error: any | PromiseLike<IDomainEvents>) => {
                dispatch({type: 'DOMAIN_NOTES_FETCH_ERROR', error})
            })
        }
    }
}






