import * as React from "react";
import { Dl } from "../../../Common/Styled/DefinitionList";
import { ContactBaseInfo, IContactBaseInfo } from "./Base/ContactBaseInfo";

/***
 * Visulizza le info base del contatto utilizzando il TAG DL (description list)
 * @param props
 * @constructor
 */



export const ContactInfo: React.FC<IContactBaseInfo> = props => (
  <Dl>
    <ContactBaseInfo  {...props}/>
  </Dl>
);
