import { Empty } from 'antd';
import * as React from 'react';

/**
 * Visualizza un icona che mostra Empty con una eventuale descrizione
 * @param description
 * @constructor
 */
const EmptyWithDescription: React.FC<{ description?: string }> = ({ description }) => (
  <Empty
    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
    description={<span>{description}</span>}
  />
);
export default EmptyWithDescription;
