import { IUser, User } from "../../../Users/User";
import { Avatar, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { stringToHslColor } from "../../../../utils/various";
import * as React from "react";
import { acronym } from "../../../Contacts/Events/Table";

export interface IUserWithAvatar {
  /** id utente */
  userId: number;
}

/***
 *  Visualizza con un cerchio le iniziali del nome e congnome utente e un Tooltip con il nome completo
 * colorando anche lo sfondo del cerchio a seconda delle iniziali visualizzate
 * @param props
 * @constructor
 */
function UserWithAvatar(props: IUserWithAvatar) {
  return <User userId={props.userId}>
    {(user: IUser) => <Tooltip title={<><FontAwesomeIcon icon={faUser} />&nbsp;{user.name}</>}>
      {console.log(" colors", stringToHslColor(user.name, 30, 80))}
      <Avatar size={"small"}
              style={{
                backgroundColor: stringToHslColor(user.name, 30, 80)
              }}>{acronym(user.name)}</Avatar>
    </Tooltip>}

  </User>;
}

export default UserWithAvatar;