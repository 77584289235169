import * as React from 'react';
import { IFax, IProtocol} from './documents';
import {PopoverProtocol} from "./PopoverProtocol";
import {PopoverFax} from "./PopoverFax";


/***
 * Pop over utilizzato per mostrare le info del documento
 * @param props
 * @constructor
 */
export function PopoverDocument(props: IFax | IProtocol) {
    if (props.documentType === 'protocol') {
        return <PopoverProtocol data={props as IProtocol}/>;
    } else {
        return <PopoverFax data={props as IFax}/>;
    }
}
