import { IViewerContentType } from '../../../Common/Navigation/CardWithViewer/Type/IViewerContentType';
import * as React from 'react';
import i18n from 'i18next';
import DomainDiffBasic from '../Info/DomainDiffBasic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import { ContactDiffList } from '../../../Contacts/Detail/ContactList';
import { faUserTie } from '@fortawesome/free-solid-svg-icons/faUserTie';
import { RegistrantsInfoDiff } from '../../../Contacts/Detail/Info/Diff/RegistrantsInfoDiff';
import {
  ContactsInfoDiff,
  RegistrarsInfoDiff,
} from '../../../Contacts/Detail/Info/Diff/ContactsInfoDiff';
import { Divider } from 'antd';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import {
  DetailBox,
  RevisionATitle,
  RevisionBTitle,
} from '../../../Common/Navigation/Revisions/DetailCard/DetailCardBox';
import { faServer, faHouseUser } from '@fortawesome/free-solid-svg-icons';
import NameServerDiffTable from '../Info/NameServerTable/NameServerDiffTable';
import DSRecordDiffTable from '../Info/DSRecordTable/DSRecordDiffTable';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons/faShieldAlt';
import { PaddedDiv } from '../../../Common/Styled/Detail';
import { IDetailedRevision } from '../../../Common/Navigation/Revisions/revisions';
import { apiGetRegistrar } from '../../../../const/api-calls';

// const PaddedDiv = styled.div`padding: 10px`;

/**
 * Costruisce il contenuto da visualizzare nella modale di comparazione tre revisioni di domini.
 *
 * Nello specifico crea i tabs:  Dominio, Registrante, Admin, Contatti, NameServer, DsRecords, Registrars
 *
 * @param domainA
 * @param domainB
 */

export const buildDiffContent = (
  domainA: IDetailedRevision,
  domainB: IDetailedRevision,
) => {
  // console.log("buildDiffContent domainA: ", domainA, "domainB:", domainB)

  const contents: IViewerContentType[] = [];
  const [regA, setRegA] = React.useState({});
  const [regB, setRegB] = React.useState({});
  React.useEffect(() => {
    if (
      !isObjectUndefined(domainA) &&
      !isObjectUndefined(domainB) &&
      domainA.registrarId &&
      domainB.registrarId
    ) {
      apiGetRegistrar(domainA.registrarId).then(r => setRegA(r.data));
      apiGetRegistrar(domainB.registrarId).then(r => setRegB(r.data));
    }
  }, [domainA, domainB]);
  if (!isObjectUndefined(domainA) && !isObjectUndefined(domainB)) {
    Object.keys(domainA).forEach((key: string, index: number) => {
      contents[0] = {
        component: <DomainDiffBasic domainA={domainA} domainB={domainB} />,
        label: (
          <>
            <FontAwesomeIcon icon={faGlobe} /> {i18n.t('tab_domain')}
          </>
        ),
      };

      if (domainA.registrant || domainB.registrant) {
        contents[1] = {
          component: (
            <>
              <DetailBox
                revisionA={domainA.revision}
                revisionB={domainB.revision}
              >
                <>
                  <ContactsInfoDiff
                    contactB={domainB.registrant}
                    contactA={domainA.registrant}
                  />
                  <Divider type={'horizontal'} />
                  <RegistrantsInfoDiff
                    registrantA={
                      domainA.registrant && domainA.registrant.registrant
                    }
                    registrantB={
                      domainB.registrant && domainB.registrant.registrant
                    }
                  />
                </>
              </DetailBox>
            </>
          ),
          label: (
            <>
              <FontAwesomeIcon icon={faUserTie} /> {i18n.t('tab_registrant')}
            </>
          ),
        };
      }

      if (domainA.admin || domainB.admin) {
        contents[2] = {
          component: (
            <DetailBox
              revisionA={domainA.revision}
              revisionB={domainB.revision}
            >
              <ContactsInfoDiff
                contactB={domainB.admin}
                contactA={domainA.admin}
              />
            </DetailBox>
          ),
          label: (
            <>
              <FontAwesomeIcon icon={faUser} /> {i18n.t('tab_admin')}
            </>
          ),
        };
      }

      if (domainA.techs && domainB.techs) {
        contents[4] = {
          component: (
            <ContactDiffList
              contactsA={domainA.techs}
              contactsB={domainB.techs}
            />
          ),
          label: (
            <>
              <FontAwesomeIcon icon={faUsersCog} /> {i18n.t('tab_techs')}
            </>
          ),
        };
      }

      if (domainA.hosts && domainB.hosts) {
        contents[5] = {
          component: (
            <PaddedDiv className={'padded_div'}>
              {RevisionATitle(domainA.revision)}
              <NameServerDiffTable
                hostsLeft={domainA.hosts}
                hostsRight={domainB.hosts}
              />
              {RevisionBTitle(domainB.revision)}
              <NameServerDiffTable
                hostsLeft={domainB.hosts}
                hostsRight={domainA.hosts}
              />
            </PaddedDiv>
          ),
          label: (
            <>
              <FontAwesomeIcon icon={faServer} /> {i18n.t('tab_nameServers')}
            </>
          ),
        };
      }

      if (domainA.dsRecords && domainB.dsRecords) {
        contents[6] = {
          component: (
            <PaddedDiv>
              {RevisionATitle(domainA.revision)}
              <DSRecordDiffTable
                dsRecordLeft={domainA.dsRecords}
                dsRecordRight={domainB.dsRecords}
              />
              {RevisionBTitle(domainB.revision)}
              <DSRecordDiffTable
                dsRecordRight={domainB.dsRecords}
                dsRecordLeft={domainA.dsRecords}
              />
            </PaddedDiv>
          ),
          label: (
            <>
              <FontAwesomeIcon icon={faShieldAlt} /> {i18n.t('tab_dsRecord')}
            </>
          ),
        };
      }

      if (domainA.registrarId && domainB.registrarId) {
        contents[7] = {
          component: (
            <DetailBox
              revisionA={domainA.revision}
              revisionB={domainB.revision}
            >
              <RegistrarsInfoDiff registrarA={regA} registrarB={regB} />
            </DetailBox>
          ),
          label: (
            <>
              <FontAwesomeIcon icon={faHouseUser} /> {i18n.t('tab_registrar')}
            </>
          ),
        };
      }
    });
  }

  return contents;
};

function isObjectUndefined(obj: object) {
  return Object.is(obj, undefined);
}
