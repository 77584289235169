import { faTag } from "@fortawesome/free-solid-svg-icons/faTag";
import * as React from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { Divider, Tooltip } from "antd";
import { IContact } from "./contacts";
import { StatusbarIcon, StyledStatusbarContent, Text } from "../../Common/Styled/StatusBar";
import { moment } from "../../../utils/various";
import RegistrarPopover from "../../Registrar/RegistrarPopover";
import { contactTypeTag } from "../Search/utils/utils";
import { faPlug } from "@fortawesome/free-solid-svg-icons/faPlug";
import { faHouseUser } from "@fortawesome/free-solid-svg-icons";
import { Registrar } from "../../Registrar/Registrar";
import { IRegistrar } from "../../Registrar/IRegistrar";
import { Status } from "../../Common/Styled/Status";
import { EMPTY } from "../../../const/common";


/***
 * ATTENZIONE DA RIVEDERE ,
 *
 * utilizzare metodo usato su src/components/Domains/Domain/Statusbar/Statusbar.tsx
 * */

const ContactStatusBar: React.FC<{ contact: IContact }> = (
  {
    contact
  }
) => (

  <StyledStatusbarContent className={'contact_status_bar'}>
    {console.log("contact",contact)}
    <div style={{ backgroundColor: "#F6F6F6", border: "1px solid #40A8FF", borderRadius: 5, padding: 8 }}>
      <Tooltip title="ContactId e nome contatto">
        <StatusbarIcon icon={faUser} />
        {contact.contactId}<Divider type="vertical" />{contact.org}
      </Tooltip>
    </div>
    <Divider type="vertical" />
    <Tooltip title="Data di creazione ">
      <StatusbarIcon icon={faPlug} />
    </Tooltip>
    <Text>{contact.created ? moment(contact.created).format("LL") : "n.d."}</Text>

    <Divider type="vertical" />
    <Tooltip title="Tipologia ed entityType">
      <StatusbarIcon icon={faTag} />
    </Tooltip>
    <Text>{contactTypeTag(contact)}</Text>

    <Divider type="vertical" />
    <Tooltip title="Stato contatto">
        <div style={{display:"flex", flexDirection:'row'}}> <StatusbarIcon icon={faTag} />
        <Text>{contact.status ? <Status size={"small"}>{contact.status}</Status> : EMPTY}</Text>
        </div>
    </Tooltip>

    <Divider type="vertical" />
    <Tooltip title="Registrar">
      <StatusbarIcon icon={faHouseUser} />
    </Tooltip>
    {/*<Text>{registrarId ? registrarId : EMPTY}</Text>*/}
    <Registrar registrarId={contact.registrarId}
               children={(registrar: IRegistrar) => <RegistrarPopover registrar={registrar} />} />
  </StyledStatusbarContent>
);
export default ContactStatusBar;
