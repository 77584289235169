import { IOptionSelectList } from "../../SelectList/SelectList";
import * as React from "react";
import { StateContext } from "../../../StoreProvider";

/***
 * Mostra la descrizione estesa delle province e State
 * @param val
 * @constructor
 */
export const StateOrProvinceField: React.FC<{ val?: string }> = ({ val }) => {
  const { stateOrProvince } = React.useContext(StateContext);

  if (val) {

    const { countries } = stateOrProvince.payload && stateOrProvince.payload || {
      countries: [{
        value: "-",
        label: "-"
      }] as IOptionSelectList[]
    };

    const { provinces } = stateOrProvince.payload && stateOrProvince.payload || {
      provinces: [{
        value: "-",
        label: "-"
      }] as IOptionSelectList[]
    };

    const country = countries.filter((c: { value: string, label: string }) => val && c.value ===  val);
    const province = provinces.filter((p: { value: string, label: string }) => val && p.value ===   val);

    if (province.length > 0 && country.length > 0) {
      return <><span>{province[0].value} - <i>({province[0].label})</i></span> o <span>{country[0].value} - <i>({country[0].label})</i></span></>;
    }

    if (province.length > 0) {
      return <span>{province[0].value} - <i>({province[0].label})</i></span>;
    }

    if (country.length > 0) {
      return <span>{country[0].value} - <i>({country[0].label})</i></span>;
    }

    return <span>{val}</span>;
  }

  return <span>-</span>;
};

export const ProvinceField: React.FC<{ val?: string }> = (props) => {
  const { stateOrProvince } = React.useContext(StateContext);

  if (props.val) {

    const { provinces } = stateOrProvince.payload && stateOrProvince.payload || {
      provinces: [{
        value: "-",
        label: "-"
      }] as IOptionSelectList[]
    };

    const province = provinces.filter((c: { value: string, label: string }) => c.value === props.val && props.val.toLowerCase());

    if (province.length > 0) {
      return <span>{province[0].value.toUpperCase()} - <i>({province[0].label})</i></span>;
    }

    return <span>{props.val}</span>;
  }

  return <span>-</span>;
};

export const StateField: React.FC<{ val?: string }> = (props) => {
  const { stateOrProvince } = React.useContext(StateContext);

  if (props.val) {

    const { countries } = stateOrProvince.payload && stateOrProvince.payload || {
      countries: [{
        value: "-",
        label: "-"
      }] as IOptionSelectList[]
    };


    const country = countries.filter((c: { value: string, label: string }) => c.value === props.val && props.val);

    if (country.length > 0) {
      return <span>{country[0].value.toUpperCase()} - <i>({country[0].label})</i></span>;
    }


    return <span>{props.val}</span>;
  }

  return <span>-</span>;
};