import { Icon, Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IDomain } from '../../types/domain';
import { AppAbility } from '../../../../Common/Ability/config';

const IconSigned = styled(Icon)`
  margin-left: 6px;
  font-size: 11pt;
  font-weight: 800;
  color: #00a854;
`;


const Signed = (props: { show: boolean }) => props.show ?
  <Tooltip title="Firmato">
    <IconSigned type="lock"/>
  </Tooltip> : <></>;

;
/**
 * Visualizza il nome a dominio con il simbolo lucchetto quando signed. Non esegue controllo ability.
 * Il nome visualizzato è quello raw - punycode
 * @param name
 * @param signed
 * @param linkTo
 * @constructor
 */
export const DomainName: React.FC<{ name: string; signed: boolean; linkTo?: string }> = ({ name, signed, linkTo }) => {
  return (
    <span>
      {linkTo ? <Link to={linkTo}>{name}</Link> : name}
      <Signed show={signed}/>
    </span>
  );
};


/***
 * Visualizza il nome a dominio con il simbolo del lucchetto quando signed. Esegue controllo ability
 * @param domain
 * @param ability
 * @constructor
 */
export const DomainNameTitle: React.FC<{ domain: IDomain, ability: AppAbility }> = ({ domain, ability }) => {

  const domainName = domain.domainIDN ? domain.domainIDN : domain.domain;

  if (ability.can('read', 'Domain')) {
    return <>
      <StyledLink to={`/domains/${domain.id}`}>
        {domainName}
      </StyledLink>
      <Signed show={domain.signed}/>
    </>;
  } else {
    return <div>
      {domainName}
    </div>;
  }
};


const StyledLink = styled(props => <Link {...props} />)` 
  font-size: 16px;
  color: #1890ff!important;
  
  :hover {
    text-decoration: underline!important;
  }
`;