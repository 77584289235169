import {AxiosResponse, AxiosError} from "axios";
import {IFetchStateType} from "../../../Type/IFetchtStateType";
import {IDetailedRevision} from "../../../Common/Navigation/Revisions/revisions";

interface IDomainActionType {
    type: 'CONTACT_REVISION_FETCH_SUCCESS' | 'CONTACT_REVISION_FETCH_ERROR' | 'CONTACT_REVISION_FETCH_LOAD' | 'CONTACT_REVISION_FETCH_CLEAN';
    payload?: AxiosResponse;
    error?: AxiosError;
    query?: any;
}


export const initialState: IFetchStateType<IDetailedRevision> = {
    loading: false,
    query: '',
    error: undefined,
    response: undefined
}

export const reducer = (state: IFetchStateType<IDetailedRevision>, action: IDomainActionType) => {
    switch (action.type) {
        case "CONTACT_REVISION_FETCH_LOAD":
            return {loading: true, error: undefined, query: action.query, response: undefined}
        case "CONTACT_REVISION_FETCH_SUCCESS":
            return {...state, loading: false, error: undefined, response: action.payload}
        case "CONTACT_REVISION_FETCH_ERROR":
            return {...state, loading: false, error: action.error}
        case "CONTACT_REVISION_FETCH_CLEAN":
            return {loading: false, error: undefined, query: '', response: undefined}
        default:
            return state;
    }
};
export default reducer;