import styled, {css} from "styled-components";

export const DetailWrapperBox = styled.div`
    margin: 15px;
    display: flex;
    flex-direction: column;
`;

export const DetailRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`;

export const DetailColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const DetailCompareColumnA = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    border-left: 1px solid #E8E8E8;
    padding-left: 10px;
`;

export const DetailCompareColumnB = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    border-left: 1px solid #E8E8E8;
    padding-left: 10px;
`;

export const DetailHeader = styled.div`
    //height: 30px;
    font-weight: bold;
    margin-bottom: 20px;
`;
export const DetailLabel = styled.div`
    font-weight: bold;
    overflow-wrap: anywhere;
`;

export const DetailContent = styled.div<{ isDifferent?: boolean, leftBorder?: boolean }>`
      overflow-wrap: anywhere;
      color: lightgray;
      ${props => props.isDifferent && css`
          background: #E9F8FF;
          border: 1px solid white;
          color: #727272;`
      }`;


export const PaddedDiv = styled.div`padding: 10px`
;
