import * as React from "react";
import { Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { StateContext } from "../../../StoreProvider";
import { IOptionSelectList } from "../../SelectList/SelectList";

const { Option } = Select;

const SelectCountry = React.forwardRef((props: FormComponentProps & SelectProps, ref) => {

  const { stateOrProvince } = React.useContext(StateContext);

  const loadingOption = <Option value={"Loading..."} key={"loading"}>Loading....</Option>;

  const options = stateOrProvince.payload && stateOrProvince.payload.countries.map((d: IOptionSelectList) => <Option
    value={d.value}
    key={d.value}>{`${d.value.toUpperCase()} - (${d.label})`}</Option>);

  return (

    <Select
      {...props}
      loading={stateOrProvince.loading}
      showSearch
      allowClear
      showArrow={true}
      filterOption={(input, option) => {
        if (option && option.props && option.props.children) {
          return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
        return false;
      }}
    >

      {stateOrProvince.loading ? loadingOption : options}
    </Select>
  );
});

export default SelectCountry;