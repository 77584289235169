import * as React from "react";
import { IContact } from "./contacts";
import { CardDetailSection } from "../../Common/Styled/CardDetailSection";

import { Card, Typography } from "antd";
import { RegistrantInfo } from "./Info/RegistrantInfo";
import { IDetailedRevision } from "../../Common/Navigation/Revisions/revisions";
import { AbilityContext } from "../../Common/Ability/AbilityContext";
import { createCanBoundTo } from "@casl/react";
import Drawer from "../Revisions/Drawer";
import { documentTitle } from "../../../utils/various";
import ContactEvents from "../Events/Scene/ContactEvents";
import Toolbar from "./Toolbar/Toolbar";

interface IContactMaster {
  contact: IContact | IDetailedRevision;
  title: JSX.Element;
  extraTab?: React.ReactNode;
  revNav?: JSX.Element,
  bgColor?: string;
}

const ContactMaster: React.FC<IContactMaster> = props => {
  const { ability } = React.useContext(AbilityContext);
  const Can = createCanBoundTo(ability);
  documentTitle(`Dettaglio contatto ${props.contact}`);

  const refRevisionDateRevision: string | undefined = (props.contact as IDetailedRevision) && (props.contact as IDetailedRevision).revision && (props.contact as IDetailedRevision).revision.date || undefined;

  return (
    <React.Fragment>
      <Typography.Title level={2}>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          {props.title}{props.revNav}
        </div>
      </Typography.Title>

      <CardDetailSection
        title={
          <div>
              <Toolbar contact={props.contact as IContact} refRevision={refRevisionDateRevision} />
          </div>
        }
      >
        <Card>
          <RegistrantInfo  {...props.contact as IContact} />
        </Card>
      </CardDetailSection>
      <Can do={"history"} on={"Contact"}>
        <Drawer />
        {/*<RevisionsDrawer >*/}
        {/*    {props.contact && props.contact.id &&*/}
        {/*    <RevisionsScroller url={`${API_CONTACTS}/${props.contact.id}/revisions`}>*/}
        {/*        {({loadedData, loading}) => <ContactRevisionsTimeline data={loadedData} loading={loading}/>}*/}
        {/*    </RevisionsScroller>}*/}
        {/*</RevisionsDrawer>*/}
      </Can>
      <Can do={"read"} on={"Event"}>
        <CardDetailSection
          style={{ marginTop: 20 }}
          title={<Typography.Title level={2}>Dettaglio eventi</Typography.Title>}
          bgcolor={props.bgColor}
        >
          <ContactEvents refRevisionDate={refRevisionDateRevision} />
        </CardDetailSection>
      </Can>
    </React.Fragment>
  );
};
export default ContactMaster;