import {IFax} from "./documents";
import {moment} from "../../utils/various";
import {DMYdotHMSmS} from "../../const/common";
import {Divider, Typography} from "antd";
import * as React from "react";
import {FaxLink} from "./FaxLink";
import {LabelContent} from "./LabelContent";
const {Text} = Typography;

/***
 * Contenuto relativo al Fax usato per visualizzarne i dettagli
 * @param props
 * @constructor
 */
export function FaxContent(props: IFax) {
    return (
        <div style={{width: '300px'}}>
            {/*<LabelContent label={'Tipo'} content={'Fax'}/>*/}
            <LabelContent label={'Id'} content={<Text type="secondary">{props.id}</Text>}/>
            <div style={{marginTop: 10}}/>
            <LabelContent label={'FaxId'} content={
                <Text copyable>{props.faxId.toString()}</Text>
            }
            />
            <LabelContent label={'Tipo'} content={props.type.toString()}/>
            <LabelContent label={'Numero pagine'} content={props.numpag.toString()}/>
            <LabelContent label={'Data arrivo'} content={moment(props.dateArrive).format(DMYdotHMSmS)}/>
            <Divider/>
            <LabelContent label={'Link'} content={
                <Text copyable><a href={FaxLink(props)} target="_blank"
                                  rel="noopener noreferrer">{FaxLink(props)}</a></Text>
            }
            />


        </div>
    );
}