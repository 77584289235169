import { AxiosError, AxiosResponse } from 'axios';
import { IFetchStateType } from '../../Type/IFetchtStateType';
import { IDomain } from './types/domain';

interface IDomainActionType {
  type:
    | 'DOMAIN_FETCH_SUCCESS'
    | 'DOMAIN_FETCH_ERROR'
    | 'DOMAIN_FETCH_LOAD'
    | 'DOMAIN_FETCH_CLEAN';
  payload?: AxiosResponse;
  error?: AxiosError;
  query?: any;
}

export const initialState: IFetchStateType<IDomain> = {
  loading: false,
  query: '',
  error: undefined,
  response: undefined,
};


export const reducer = (
  state: IFetchStateType<IDomain>,
  action: IDomainActionType,
) => {
  switch (action.type) {
    case 'DOMAIN_FETCH_LOAD':
      return {
        loading: true,
        error: undefined,
        query: action.query,
        response: undefined,
      };
    case 'DOMAIN_FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        error: undefined,
        response: action.payload,
      };
    case 'DOMAIN_FETCH_ERROR':
      return { ...state, loading: false, error: action.error };
    case 'DOMAIN_FETCH_CLEAN':
      return {
        ...state,
        loading: false,
        error: undefined,
        query: '',
        response: undefined,
      };
    default:
      return state;
  }
};
export default reducer;
