import * as React from 'react';
import {apiGetUser} from '../../const/api-calls';
import {AxiosControllerComponent} from '../Common/Navigation/FetchController/FetchController';

export interface IUser {
    'created': string;
    'email': string;
    'id': number;
    'lastPasswordChange': string;
    'lastupdate': string;
    'name': string;
    'registrarId': number;
    'status': string;
    'username': string;

}

export const User: React.FC<{ userId: number, children: (name: IUser) => void }> = ({userId, children}) => {

    return (
        <AxiosControllerComponent  call={apiGetUser(userId)}>
            {(response) => <>
                {children(response.data as IUser)}
            </>}
        </AxiosControllerComponent>
    );
}

export function UserName(props:{userId:number}){
    return <User
        userId={props.userId}>
        {(user: IUser) => user.name}
    </User>
}