import * as React from 'react';
import styled from "styled-components";
import SearchBarBox from "../SearchBar/SearchBarBox";
import {AbilityContext} from "../../../Common/Ability/AbilityContext";
import {Alert} from "antd";
import  { NotifyChangelog } from "../../../Common/Feedback/ChangeLogModal/ChangeLogModal";
import {documentTitle} from "../../../../utils/various";


export const HomeBox = styled.div`
  //background-color: #2075bb45;
  //border-radius: 5px;
  margin-left: 25%;
  margin-right: 25%;
  //margin-top: 30px;
  //padding-bottom: 20px;
  //padding-top: 20px;

  @media screen and (max-width: 1000px){
    margin-left: 5%;
    margin-right: 5%;
  }

  @media screen and (min-width: 1000px){
    margin-left: 10%;
    margin-right: 10%;
  }
`;

/***
 * Mostra il logo in versione banner nella Homepage
 * @constructor
 */
export const BannerLogo: React.FC = () => {
    return (
        <img src={"./mida-banner-it.png"} alt="Banner" style={{height: '220px'}}/>
    )
}

/***
 * Mostra un alert seguendo la logica delle abilities attese.
 * Se non è permessa la ricerca di un Dominio o di un contatto allora viene  notificato all'utente.
 * @constructor
 */
const AbilityAlert: React.FC = () => {
    const {ability} = React.useContext(AbilityContext);
    return (
        <>{(ability.cannot("search", "Domain") || ability.cannot("search", "Contact")) &&
        <Alert
            message="Informazione utente"
            description={`Attenzione, l'utente non dispone dei permessi necessari per eseguire ricerche estese per   ${ability.cannot("search", "Domain") ? 'Domini' : ''} ${ability.cannot("search", "Contact") ? 'Contatti' : ''} `}
            type="info"
            showIcon
            closeText="Chiudi"
        />}</>)
}

/**
 * Mostra uno spazio tra Banner e barra di ricerca
 * @constructor
 */
function Space() {
    return <div style={{marginTop: 50, marginBottom: 50}}>&nbsp;</div>
}


/**
 * Componente che realizza la HomePage
 * @param props
 * @constructor
 */
const HomePage: React.FC = props => {
    documentTitle('Homepage');
    return (
        <div style={{textAlign: 'center'}}>
            <BannerLogo/>
            <HomeBox>
                <Space/>
                <AbilityAlert/>
                <SearchBarBox {...props} boxed={true} boxedSize={'small'}/>
                <NotifyChangelog/>
            </HomeBox>
        </div>
    );
};

export default HomePage;

