import * as React from "react";
import { IContact } from "../../contacts";
import i18n from "i18next";
import { DetailRowWrapper, eqString } from "../../../../Common/Navigation/Revisions/DetailCard/DetailCardBox";
import { DetailContent } from "../../../../Common/Styled/Detail";
import { moment } from "../../../../../utils/various";
import { LastUpdateRegistrantTips } from "../../../../../const/TipsComponent";


/***
 * Stampa la riga della tebella di confronto segnalando le differenze tra due diversi valori tra A e B.
 * @param label
 * @param revisionItems
 * @param revisionIndex
 * @param format
 */
export const columnValue = (label: string, revisionItems: any, revisionIndex: 0 | 1, format?: (value: any) => void) => {

  let value = "-";
  // recupera il valore da far visualizzare nel campo
  if (revisionItems && revisionItems[revisionIndex] && revisionItems[revisionIndex][label] && revisionItems[revisionIndex][label].toString()) {
    value = format ? format(revisionItems[revisionIndex][label]) : revisionItems[revisionIndex][label].toString();
  }

  if (revisionItems && (revisionItems[0] !== undefined && revisionItems[1] !== undefined)) {
    return <DetailContent
      isDifferent={
        !eqString(revisionItems[0][label], revisionItems[1][label])
      }>
      {value}
    </DetailContent>;
  } else {
    return <DetailContent isDifferent={true}>
      {value}
    </DetailContent>;
  }
};

/***
 * Stampa la riga della tebella di confronto segnalando le differenze tra due diversi array di valori tra A e B.
 * @param label
 * @param revisionItems
 * @param revisionIndex
 * @param format
 */
export const columnArrayValue = (label: string, revisionItems: any, revisionIndex: 0 | 1, format?: (value: any) => void) => {

  let value = "-";
  if (revisionItems && revisionItems[revisionIndex] && revisionItems[revisionIndex][label]) {
    value = format ? format(revisionItems[revisionIndex][label]) : revisionItems[revisionIndex][label].toString();
  }

  console.debug("RevisionItems", revisionItems);

  if (revisionItems && (revisionItems[0] !== undefined && revisionItems[1] !== undefined)) {

    const result: any[] = [];
    const maxIndex = Math.max(revisionItems[0][label].length, revisionItems[1][label].length);

    let i;
    for (i = 0; i < maxIndex; i++) {
      let _value = "-";
      // recupera il valore da far visualizzare nel campo
      if (revisionItems && revisionItems[revisionIndex] && revisionItems[revisionIndex][label][i]) {
        _value = format ? format(revisionItems[revisionIndex][label])[i] : revisionItems[revisionIndex][label][i];
      }
      console.log("revisionItems[0][label][i] ",revisionItems[0][label][i],i);
      console.log("revisionItems[1][label][i] ",revisionItems[1][label][i],i);
      result.push(<DetailContent key={i}
                                 isDifferent={
                                   !eqString(revisionItems[0][label][i], revisionItems[1][label][i])
                                 }>
        {_value}
      </DetailContent>);
    }
    return <div>
      {result}
    </div>;
  } else {
    return <DetailContent isDifferent={true}>
      {value}
    </DetailContent>;
  }
};

/***
 * Mostra le informazioni di due Contatti (Tech o Admin) evidenziandone le differenze
 * @param contactA
 * @param contactB
 * @constructor
 */
export const ContactsInfoDiff: React.FC<{ contactA?: IContact, contactB?: IContact, type?: "registrant" | "admin" | "techs" }> = ({ contactA, contactB, type }) => {
  console.debug("ContactsInfoDiff PROPS [A]]", contactA);
  console.debug("ContactsInfoDiff PROPS [B]", contactB);
  return (
    <>
      {/* ContactID*/}
      <DetailRowWrapper
        label={`${i18n.t("contact_id")}`}
        column2={columnValue("contactId", [contactA, contactB], 0)}
        column3={columnValue("contactId", [contactA, contactB], 1)}
      />

      {/*   Name */}
      <DetailRowWrapper
        label={`${i18n.t("contact_name")}:`}
        column2={columnValue("name", [contactA, contactB], 0)}
        column3={columnValue("name", [contactA, contactB], 1)}
      />

      {/*  Organizzation */}
      <DetailRowWrapper
        label={`${i18n.t("contact_org")}:`}
        column2={columnValue("org", [contactA, contactB], 0)}
        column3={columnValue("org", [contactA, contactB], 1)}
      />

      {/*  CountryCode */}
      <DetailRowWrapper
        label={`${i18n.t("contact_cc")}:`}
        column2={columnValue("country", [contactA, contactB], 0)}
        column3={columnValue("country", [contactA, contactB], 1)}
      />

      {/*  City */}
      <DetailRowWrapper
        label={`${i18n.t("contact_city")}:`}
        column2={columnValue("city", [contactA, contactB], 0)}
        column3={columnValue("city", [contactA, contactB], 1)}
      />

      {/*  CAp */}
      <DetailRowWrapper
        label={`${i18n.t("contact_cap")}:`}
        column2={columnValue("postalCode", [contactA, contactB], 0)}
        column3={columnValue("postalCode", [contactA, contactB], 1)}
      />

      {/* StateOrProvince*/}
      <DetailRowWrapper
        label={`${i18n.t("contact_sp")}:`}
        column2={columnValue("state", [contactA, contactB], 0)}
        column3={columnValue("state", [contactA, contactB], 1)}
      />

      {/* Street*/}
      <DetailRowWrapper
        label={`${i18n.t("contact_street")}:`}
        column2={columnArrayValue("streets", [contactA, contactB], 0)}
        column3={columnArrayValue("streets", [contactA, contactB], 1)}
      />
      {/*  Voice */}
      <DetailRowWrapper
        label={`${i18n.t("contact_voice")}:`}
        column2={columnValue("voice", [contactA, contactB], 0)}
        column3={columnValue("voice", [contactA, contactB], 1)}
      />

      {/*  Fax */}
      <DetailRowWrapper
        label={`${i18n.t("contact_fax")}:`}
        column2={columnValue("fax", [contactA, contactB], 0)}
        column3={columnValue("fax", [contactA, contactB], 1)}
      />

      {/*  Email */}
      <DetailRowWrapper
        label={`${i18n.t("contact_email")}:`}
        column2={columnValue("email", [contactA, contactB], 0)}
        column3={columnValue("email", [contactA, contactB], 1)}
      />

      {/*  Creation */}
      <DetailRowWrapper
        label={`${i18n.t("contact_crDate")}:`}
        column2={columnValue("created", [contactA, contactB], 0, (value) => moment(value).format("DD MMMM YYYY, HH:mm:ss"))}
        column3={columnValue("created", [contactA, contactB], 1, (value) => moment(value).format("DD MMMM YYYY, HH:mm:ss"))}
      />

      {/*  LastUpdate */}
      <DetailRowWrapper
        label={
          type === "registrant" ?
            <>{`${i18n.t("contact_upDate")}`}<LastUpdateRegistrantTips /> :</> :
            <>{`${i18n.t("contact_upDate")}`}</>
        }
        column2={columnValue("lastupdate", [contactA, contactB], 0, (value) => moment(value).format("DD MMMM YYYY, HH:mm:ss"))}
        column3={columnValue("lastupdate", [contactA, contactB], 1, (value) => moment(value).format("DD MMMM YYYY, HH:mm:ss"))}
      />

      {/*  Consent */}
      <DetailRowWrapper
        label={`${i18n.t("contact_consentForPublishing")}:`}
        column2={columnValue("consentForPublish", [contactA, contactB], 0, (value) => value ? i18n.t("yes") : i18n.t("no"))}
        column3={columnValue("consentForPublish", [contactA, contactB], 1, (value) => value ? i18n.t("yes") : i18n.t("no"))}
      />
    </>

  );
};

export const RegistrarsInfoDiff: React.FC<{ registrarA?: any, registrarB?: any }> = ({ registrarA, registrarB }) => {
  // console.debug("RegistrarsInfoDiff PROPS ", registrarA, registrarB);
  return (
    <>
      {/* ContactID*/}
      <DetailRowWrapper
        label={`${i18n.t("registrar_id")}`}
        column2={columnValue("id", [registrarA, registrarB], 0)}
        column3={columnValue("id", [registrarA, registrarB], 1)}
      />
      <DetailRowWrapper
        label={`${i18n.t("registrar_name")}`}
        column2={columnValue("name", [registrarA, registrarB], 0)}
        column3={columnValue("name", [registrarA, registrarB], 1)}
      />
    </>);
};