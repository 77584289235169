import {Comment, Avatar, Button,} from "antd";
import * as React from "react";
import {IDomainNote} from "./types";
import {DispatchContext, StateContext} from "../../StoreProvider";
import {apiDeleteDomainNote, apiPutDomainNote, apiPostDomainNotes} from "../../../const/api-calls";
import {AxiosError} from "axios";
import {fetchDomainNotes,} from "./operations";
import {useLocation, useRouteMatch} from "react-router-dom";
import {Editor} from "./Editor/Editor";
import {NoteItem} from "./NoteItem";

export function SingleNoteController() {

    const {domain} = React.useContext(StateContext);
    const {domainNotes} = React.useContext(StateContext);
    const {domainNote} = React.useContext(StateContext);
    const dispatch = React.useContext(DispatchContext);
    const location = useLocation();

    const [showEditor, setShowEditor] = React.useState(false);
    const [edit, setEdit] = React.useState(domainNote.edit);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<AxiosError | undefined>(undefined);
    const data = domainNote.data ? domainNote.data : domainNotes.response && domainNotes.response.data.elements[0]
    const counter = domainNotes.response && domainNotes.response.data.page.totalElements || 0;
    const {params} = useRouteMatch();

    React.useEffect(() => {
        setEdit(domainNote.edit);
    }, [domainNote, domainNotes])

    function reloadNotes() {
        const domainId = params['domain'];
        dispatch({type: 'DOMAIN_NOTES_FETCH_CLEAN'})
        fetchDomainNotes(dispatch, domainNotes, domainId.toString(),  location)
    }


    const handleOnSubmit = (err: any, values: IDomainNote) => {


        const success = () => {
            setLoading(false);
            setShowEditor(false);
            setEdit(false);
            dispatch({type: 'DOMAIN_NOTE_CLEAN'});
            reloadNotes()
        }

        const fail = (e: AxiosError) => {
            setLoading(false);
            setError(e);
        }


        if (domain.response && domain.response.data) {
            const domainId = domain.response.data.id;
            setLoading(true);

            if (edit && data){
                apiPutDomainNote(data.id, {
                    description: values.description,
                    note: values.note
                }).then((res) => {
                    success();
                }).catch((e) => {
                        fail(e);
                    }
                )
            } else {
                apiPostDomainNotes(domainId.toString(), {
                    description: values.description,
                    note: values.note
                }).then((res) => {
                    success();
                }).catch((e) => {
                        fail(e);
                    }
                )
            }

        }
    }

    const handleOnDelete = (id: number) => {
        apiDeleteDomainNote(id).then(() => true)
        dispatch({type: 'DOMAIN_NOTE_CLEAN'});
        reloadNotes();
    }

    const handleShowAll = () => {
        dispatch({type: 'DOMAIN_NOTES_OPEN'});
    }

    const handleOnEdit = () => {
        setShowEditor(true);
        setEdit(true);
    }

    const handleOnCancel = () => {
        setShowEditor(false);
        setEdit(false);
    }

    const handleOnNew = () => {
        setShowEditor(true);
    }

    if (showEditor || edit) {
        // Editor Nuova Nota o Edita Nota
        return <div style={{padding: '0px 10px'}}>
            <Comment
                avatar={
                    <Avatar
                        src="https://www.netclipart.com/pp/m/63-639230_clipart-king-icon-king-cartoon-icon-png.png"
                        alt="Han Solo"
                    />
                }
                author={!edit ? 'Nuova nota' : "Edita nota"}
                content={
                    <Editor
                        onSubmit={handleOnSubmit}
                        loading={loading}
                        error={error}
                        value={edit ? data : undefined}
                        onCancel={handleOnCancel}
                    />
                }
            />
        </div>
    } else {
        if (data) {
            // Visualizza nota
            return <div style={{padding: '0px 10px'}}>
                <NoteItem
                    control={"full"}
                    onNew={handleOnNew}
                    onDelete={handleOnDelete}
                    onEdit={handleOnEdit}
                    onShowAll={handleShowAll}
                    data={data}
                    counter={counter}
                />
            </div>
        } else {
            // Bottone Nuova nota
            return <div style={{padding: '10px 0px 0px 0px', backgroundColor: "#F6F6F6"}}>
                <Button style={{backgroundColor: "#FFF7B1"}}
                        icon="message"
                        size={'small'} onClick={handleOnNew}>Crea nota</Button>
            </div>
        }
    }
}
