import * as React from 'react';
import { parse, ParsedQuery, stringify } from 'query-string';
import { Card, Tag } from 'antd';
import { useQuery } from '../../../../utils/various';
import { useHistory } from 'react-router-dom';
import { isEmpty, omit } from 'lodash';
import * as punycode from 'punycode';
import { searchAdpater } from '../../../../utils/url-manipulation';
import * as moment from 'moment'


const exludedKeys: string[] = ['page', 'size', 'sort'];


/***
 * Pannello che visualizza i filtri applicati recuperati dalla barra degli indirizzi e ai quali vengono applicate
 * varie politiche che ne determinano la visualizzazione o meno
 *
 * onClose è il metodo applicato alla chiusura del filtro scelto
 *
 * @param parsedQuery
 * @param urlPrefix
 * @param style
 * @param parsedQuery
 * @constructor
 */
export const FiltersBarPane: React.FC<{ parsedQuery?: ParsedQuery<string | number>, urlPrefix: string, style?: any }> = ({ parsedQuery, urlPrefix, style }) => {

  const history = useHistory();
  const query = parsedQuery || useQuery();
  const TAG_COLOR = 'volcano';
  const EXCLUDED_PARAMS = ['sort', 'page', 'size'];

  /**
   * logica applicata alla chisura del filtro scelto
   *
   * Veongo rimossi dalla barra degli indirizzi tutti quei parametri che contengono il nome del filtro scelto come
   * prefisso, ad esempio filtro.key, filtro.label filtro-*
   *
   * @param e
   * @param key
   * @param value
   */
  const onClose = (e: any, key: string, value: string[] | string | null | undefined) => {

    const resultSearch = query ? query : {};
    const toRemove: any[] = [];

    // Aggiunge le chiavi da rimuovere prendendo tutti i campi (*) che rispettano il pattern [chiave]-*
    Object.keys(resultSearch).filter((k: string) => k.includes(key + '-')).forEach((keyVal, index) => {
      // console.log("- OnCloseKey", keyVal);
      toRemove.push(keyVal);
    });

    // Aggiunge le chiavi da rimuovere prendendo tutti i campi (*) che rispettano il pattern [chiave].*
    Object.keys(resultSearch).filter((k: string) => k.includes(key + '.')).forEach((keyVal, index) => {
      toRemove.push(keyVal);
    });

    toRemove.push(key);

    const resultedParams = omit(resultSearch, toRemove.concat(EXCLUDED_PARAMS));
    const stringifiedResultedParams = stringify(resultedParams);

    // console.log('omtitted', resultedParams);
    // console.log('stringifiedResultedParams', stringifiedResultedParams);

    history.push(`${urlPrefix}?${stringifiedResultedParams}`);
  };

  if (!isEmpty(query) && Object.keys(omit(query, EXCLUDED_PARAMS)).length > 0) {

    const adaptedSearchParams = parse(searchAdpater(query));
    const labelInValueValues = getLabelInValueValues(adaptedSearchParams);

    return (
      <Card size="small" title="Filtri applicati"
            style={{ ...style, backgroundColor: '#FBFBFB', borderRadius: '5px' }}>
        {
          // Esclude dai filtri visualizzabili i parametri da escludere (vedi filtri) e prende in
          // considerazione solo quelli rimasti
          Object.keys(adaptedSearchParams)
            // se ci sono campi labelInValues li esclude dalla lista dei filtri
            .filter((p: string) => {
              const key = p.split('.')[0];
              return !(adaptedSearchParams[key + '.key'] && adaptedSearchParams[key + '.label']);
            })
            // si escludono anche i valori presenti in exludedKeys
            .filter((v: string) => !exludedKeys.includes(v)).map(
            (key, index) => {

              const value: string[] | string | null | undefined = (adaptedSearchParams[key]);

              if (value) {
                if (Array.isArray(value) && value.length > 1) {
                  let formattedOutput: React.ReactNode;

                  if (key.includes('status')) {
                    // Il contenuto dell'array va sperato con / perchè è lo stato dei domini
                    formattedOutput =
                      <span>{value.join(' / ').replace('notEquals:', 'not ')}</span>;
                  } else if (key.includes('created')) {

                    formattedOutput = <span>{
                      value.map((v: string) => moment(v.replace('ge:', '')
                        .replace('lt:', '')).format('LLL')).join(' ~ ')
                    }
                                            </span>;
                  } else {
                    // Quando il contenuto del filtro è un array, le parole vengono separate da uno spazio
                    // viene anche sovrascritto il + nei casi di ricerca Full Text
                    formattedOutput =
                      <span>{
                        value.map((e: string) => e.replace('+', ''))
                          .join(' ')
                      }
                                                </span>;
                  }

                  return (
                    <Tag
                      color={TAG_COLOR}
                      closable
                      onClose={(e: any) => onClose(e, key, value)}
                      key={index + key}><span
                      style={{ textTransform: 'capitalize' }}>{key} </span>
                      <strong>{formattedOutput}</strong>
                    </Tag>
                  );
                } else {
                  let result = value.toString();
                  if (key.includes('domain')) {
                    result = punycode.toUnicode(result);
                  }

                  return <Tag color={TAG_COLOR}
                              closable
                              onClose={(e: any) => onClose(e, key, value)}
                              key={index + key}><span
                    style={{ textTransform: 'capitalize' }}>{key} </span>
                    <strong>{result.replace('+', '')}</strong>
                  </Tag>;
                }
              } else {
                return <></>;
              }
            },
          )
        }
        {
          // Per i campi LabelInValue il campo risultante `field` viene mostrato nei filtri con
          // con il valore di field.label
          Object.keys(labelInValueValues).map((key, index) => {
            const value: string[] | string | null | undefined = (labelInValueValues[key]);
            return <Tag color={TAG_COLOR}
                        closable
                        onClose={(e: any) => onClose(e, key, value)}
                        key={index + key}><span
              style={{ textTransform: 'capitalize' }}>{key} </span><strong>{value}</strong>
            </Tag>;
          })
        }
      </Card>
    );
  }

  return <div data-testid='empty-box'/>;
};

/***
 * Riconosce se esistono campi trattati come labelInValue e genera un terzo campo contenente un il valore del campo
 * key:
 *
 * filed.key=1&field.key=primovalore   viente trasformato in filed.key=1&field.key=primovalore&filed=1
 *
 * @param params
 */
function getLabelInValueValues(params: ParsedQuery<any>) {
  return Object.keys(params).filter((p: string) => {
    const key = p.split('.')[0];
    return (params[key + '.key'] && params[key + '.label']);
  }).reduce((accumulator: any, currentValue: string) => {
    const keyValue = currentValue.includes('.label') ? currentValue : undefined;
    if (keyValue) {
      const currentKey = currentValue.split('.')[0];
      return { ...accumulator, [currentKey]: params[keyValue] };
    }
    return accumulator;

  }, {});
}

// ritorna le chiavi dei campi che
export function isLabelInValue(params: ParsedQuery<any>) {
  return Object.keys(params).filter((p: string) => {
    const key = p.split('.')[0];
    return (params[key + '.key'] && params[key + '.label']);
  });
}

