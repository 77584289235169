import * as React from 'react';
import {ContactInfo} from './Info/ContactInfo';
import {DlBox} from '../../Common/Styled/DefinitionList';
import {IContact} from './contacts';
import {RegistrantInfo} from "./Info/RegistrantInfo";
import {ContactList} from "./ContactList";


/***
 * Visualizza il contenuto delle informazioni di un contatto (Admin o Tech)
 *
 * @param props
 * @constructor
 */
export const ContactContentViewer = (props: IContact[] | IContact) => {

    // Mostra il contenuto di una lista di contatti (Techs)
    if (Array.isArray(props)) {
        return <ContactList contacts={props}/>;
    } else {
        // Mostra il contenuto di un contatto (Admin)
        return (
            <DlBox>
                <ContactInfo contact={props} />
            </DlBox>
        );
    }
};

/**
 * Visualizza il contenuto delle informazioni relative al contatto Registrant
 * @param props
 * @constructor
 */
export const RegistrantContentViewer = (props: IContact) => {
    return (
        <DlBox>
            <RegistrantInfo {...props} />
        </DlBox>
    );
};
