import { ParsedQuery, stringify } from 'query-string';
import { omit } from 'lodash';
import { ISortedInfo } from '../components/Type/Common';
import { SorterResult } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import * as React from 'react';
import { moment } from './various';
import { toASCII } from 'punycode';

/**
 * Ritorna il valore di order  usabile in Table estrapolandolo da ISortedInfo usando la chiave columnKey
 * @param columnKey
 * @param sortedInfo
 */
export function columnSortOrder(columnKey: string, sortedInfo: ISortedInfo): boolean | 'ascend' | 'descend' | undefined {
  console.debug('columnKey ', columnKey);
  console.debug('sortedInfo ', sortedInfo);
  if (sortedInfo.columnKey === columnKey) {
    return sortedInfo.order;
  }
  return undefined;
}

export function orderTypeAdapter(value: string): string | undefined {
  switch (value) {
    case 'ascend':
      return 'asc';
    case 'descend':
      return 'desc';
    default:
      return undefined;
  }
}

export function normalizeOrderDirection(value?: string): SortOrder | undefined {
  if (value === 'asc') {
    return 'ascend';
  }

  if (value === 'desc') {
    return 'descend';
  }

  return undefined;
}

/*** Decodifica un campo (ad esempio sort='') del formato = nome,[asc|desc] trasformandolo in
 *     { order?: SortOrder, columnKey?: string }
 */
export function decodeQuerySort(field?: (string | number)[] | string | number | null | undefined): { order?: SortOrder; columnKey?: string } {
  if (field) {
    const res: string [] = field ? field.toString().split(',') : [];
    return { order: normalizeOrderDirection(res[1]), columnKey: res[0] };
  }
  return { order: undefined, columnKey: undefined };
}

/**
 * Logica per Impostare l'ordinamento  (corretto, secondo le api) di un campo nel Router tramite history.push
 * @param content
 * @param query
 * @param history
 * @param location
 */
export function sortResult(content: SorterResult<any>, query: ParsedQuery, history: any, location: any) {
  if (content.field) {
    query.sort = `${content.field}${content.order ? ',' + orderTypeAdapter(content.order) : ''}`;
  }
  history.push(`${location.pathname}?${stringify(query)}`);
}


/**
 *  Logica per l'aggiunta di un filtro
 *
 * @param filter
 * @param values
 * @param query
 * @param history
 * @param location
 */
export function addFilter(filter: any, values: any, query: ParsedQuery, history: any, location: any) {
  const resultSearch = query;

  const toRemove: any[] = ['page', 'size', 'page'];
  const ojbKeys = Object.keys(values);

  if (ojbKeys.includes('key') && ojbKeys.includes('label')) {
    resultSearch[`${filter}.key`] = values.key;
    resultSearch[`${filter}.label`] = values.label;
    // } if (values instanceof Array ){
    //     resultSearch[`${filter}`] = values.join("/");
  } else {
    resultSearch[`${filter}`] = values;
  }

  history.push(`${location.pathname}?${stringify(omit(resultSearch, toRemove),
  )}`);
}

/**
 * Funzione che adatta i parametri search contenuti nella ULR per l'API.
 * In pratica per tutti quei campi per i quali è previsto un criterio di ricerca
 * (equals, contains, startsWith, endsWith, notEquals, ....) la funzione si aspetta che nella search (ricerca) della
 * URL ci siano due parametri cosi fatti "nomecampo-field" e "nomecampo-criteria".
 * Il primo che contiene il valore da cercare e il secondo con il criterio da applicare.
 * Il risultato sarà un terzo campo, "nomecampo" che contiene la fusione dei due campi sompra "nomecampo=criteria:field"
 * aggiunto in uscita della funzione assieme a tutti gli altri campi di partenza che non contengono "-" all'interno
 * della search.
 *
 * Es.
 *
 * input => /domains-service/contacts?city=pisa&name-criteria=startsWith&name-field=mario&nameOrOrg-criteria=startsWith&nameOrOrg-field=mario
 * output => /domains-service/contacts?city=pisa&name=startsWith%3Amario&nameOrOrg=startsWith%3Amario
 *
 * @param {ParsedQuery<React.ReactText>} parsedQuery
 * @returns {string}
 */
export function searchAdpater(parsedQuery: ParsedQuery<React.ReactText>): string {

  const query = parsedQuery;
  let result = {};

  // console.log(" searchAdpater INPUT", parsedQuery);


  // **************************************************
  //
  //      Tratta campo con criterio di ricerca
  //
  //      nomecampo-field e nomecampo-criteria
  //
  // **************************************************

  // Costruisce un campo finalKey usando il valore finalKey-field e  finalKey-criteria cosi finalKey=finalKey-field:finalKey-criteria
  Object.keys(query).filter((k: string) => k.includes('-field')).forEach((key, index) => {

    const finalKey = key.substr(0, key.indexOf('-'));
    const criteria: string = query[`${finalKey}-criteria`] as string;
    const field: string = query[key] as string;

    // console.log("finalKey ", finalKey);
    // console.log("criteria ", criteria);
    // console.log("field ", field);

    query[finalKey] = (criteria && criteria.length > 0) ? criteria + ':' + field : 'equals:' + field;
  });

  // // Costruisce un campo finalKey usando il valore finalKey-field e  finalKey-criteria cosi finalKey=finalKey-field:finalKey-criteria
  // Object.keys(query).filter((k: string) => k.includes("-range")).forEach((key, index) => {
  //
  //     const finalKey = key.substr(0, key.indexOf("-"));
  //     const field: string = query[key] as string;
  //
  //     console.log("finalKey ",finalKey);
  //     console.log("field ",field);
  //
  //     console.log("result[key] = query[key];", result[key] = query[key]);
  //     const res: any[] = [];
  //     if (query[key] !== undefined) {
  //         const a : string[] = query[key] as [];
  //         a.forEach((c) => res.push(moment(c).format(('DD-MM-YYYY hh:mm:ss'))));
  //     }
  //     console.log("result[key] = res", res);
  //
  //     result[key]=res;
  // });

  // **************************************************
  //
  //      Tratta campo con ricerca fulltext
  //
  //      nomecampo-ft
  //
  // **************************************************

  // Costruisce un campo finalKey usando il valore 'finalKey'-ft
  Object.keys(query).filter((k: string) => k.includes('-ft')).forEach((key, index) => {

    const finalKey = key.substr(0, key.indexOf('-'));
    const field: string = query[key] as string;

    // console.log("finalKey ", finalKey, field);
    // console.log("fullTextTokenization", fullTextTokenization(field))

    query[finalKey.replace('_', '.')] = fullTextTokenization(field);
  });

  // **************************************************
  //
  //      Esclusione dei campi che contengono -
  //
  //      nomecampo-field e nomecampo-criteria
  //
  // **************************************************

  // Esclude tutti i campi che contengono -
  Object.keys(query).filter((k: string) => !k.includes('-')).forEach((key, index) => {
    result[key.replace('_', '.')] = query[key];
  });


  result['created'] = rangeDateAdapter(query['created-range']);
  result['expire'] = rangeDateAdapter(query['expire-range']);
  result['lastupdate'] = rangeDateAdapter(query['lastupdate-range']);
  result = getLabelInValueValues(parsedQuery, result);

  const domain = query['domain'] || undefined;
  if (domain !== undefined) {
    result['domain'] = toASCII(domain as string);
  }


  // console.log(" searchAdpater OUTPUT", stringify(result),result);


  return stringify(result);
}

/***
 * Presa la data crea un range di un 1 giorno
 * es. DA=ge:cuentDate&A=lt:currentDate+1giorno
 *
 * @param dateField
 */
export function rangeDateAdapter(dateField: React.ReactText[] | string | number | null | undefined): string[] {
  // Logica di ricerca per le date DA=ge:cuentDate&A=lt:currentDate+1giorno
  if (dateField) {
    const dateRange = dateField;
    if (Array.isArray(dateRange)) {
      // console.log("dateRange ", dateRange)
      return ['ge:' + moment(dateRange[0]).format('YYYY-MM-DDThh:mm:ss'), 'lt:' + moment(dateRange[1]).add(1, 'days').format('YYYY-MM-DDThh:mm:ss')];
    }
  }
  return [];
}

/***
 * Splitta le stringhe composta da spazio e per ogni termine composta da più di 2ch prepende un '+' davanti
 *
 * Es. ?name-ft=Mario%20Rossi diventa ?name=+mario&
 * @param words
 */
export function fullTextTokenization(words: string) {
  const res: string [] = words.split(' ');
  // console.log(" res", res);
  // return res.map((v: string) => v.trim().charAt(0) === '-' ? v.trim() : "+".concat(v.trim()));
  return res.filter((str: string) => str.length > 2).map((v: string) => (v.trim().charAt(0) === '-' || v.trim().charAt(0) === '+') ? v.trim() : '+'.concat(v.trim()));
}

/***
 * Estrae da params i campi che sono LabelIn e cioè quelli contraddistinti da {key, label}
 * @param params
 * @param acc
 */
function getLabelInValueValues(params: ParsedQuery<any>, acc: any) {
  return Object.keys(params).filter((p: string) => {
    const key = p.split('.')[0];
    return (params[key + '.key'] && params[key + '.label']);
  }).reduce((accumulator: any, currentValue: string) => {
    console.debug('curr ', currentValue);
    const keyValue = currentValue.includes('.key') ? currentValue : undefined;
    if (keyValue) {
      const currentKey = currentValue.split('.')[0];
      return { ...accumulator, [currentKey]: params[keyValue] };
    }
    return accumulator;

  }, acc);
}