import {apiGetContactEvents} from "../../../const/api-calls";
import {parse} from "query-string";
import { IFetchStateTypeContactEvents } from "./reducer";
import { IContactEvents } from "./types/contactEvents";

/***
 * Funzione per il recupero degli eventi legati al dominio relativi alla query passata.
 *
 * @param dispatch
 * @param contactEvents
 * @param contactId
 * @param keycloak
 * @param location - attuale
 */
export function fetchContactEvents(dispatch: any, contactEvents: IFetchStateTypeContactEvents, contactId: string, keycloak: any, location: any) {
    const resultQuery = `${location.pathname}${location.search}`;


    console.log("resultQuery ",resultQuery)
    console.log("contactEvents.query ",contactEvents.query)
    if (resultQuery === '') {
        dispatch({type: 'CONTACT_EVENTS_FETCH_CLEAN'})
    } else {
        // Se query memorizzato è diverso da quello cercato e non ci sono errori allora fetchContacts
        if (resultQuery.localeCompare(contactEvents.query) !== 0) {

            dispatch({type: 'CONTACT_EVENTS_FETCH_LOAD', query: resultQuery})


            const searchParams = lookForKeyLabel('registrarId',location.search)

            apiGetContactEvents(contactId, searchParams, keycloak).then((res: any) => {
                dispatch({type: 'CONTACT_EVENTS_FETCH_SUCCESS', query: resultQuery, payload: res})
            }).catch((error: any | PromiseLike<IContactEvents>) => {
                dispatch({type: 'CONTACT_EVENTS_FETCH_ERROR', error})
            })
        }
    }
}


/***
 * Cerca se esistono campi innestati con key e label e se esitono ritorna un terzo campo con il valore di key
 */
function lookForKeyLabel(keyName: string,locationSearch: string){
    const parsedQuery = parse(locationSearch);
    if (locationSearch && locationSearch.includes(keyName.concat(".key")) && locationSearch.includes((keyName.concat(".label")))){
        return locationSearch.concat(`&${keyName}=${parsedQuery[`${keyName}.key`]}`)
    }

    return  locationSearch;
}