import * as React from 'react';
import {useRouteMatch} from 'react-router-dom';
import {IScene} from '../../../../Type/IScene';
import RevisionPrevNext from '../../../../Common/Navigation/Revisions/RevisionPrevNext/RevisionPrevNext';
import DomainMaster from '../../../Domain/DomainMaster';
import {Tag} from 'antd';
import {faHistory} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DispatchContext, StateContext} from '../../../../StoreProvider';
import {fetchDomainRevision} from '../operations';
import {FetchController} from '../../../../Common/Navigation/FetchController/FetchController';
import {IDetailedRevision} from '../../../../Common/Navigation/Revisions/revisions';
import {isDomainSync} from '../../../../../utils/various';
import {Registrar} from '../../../../Registrar/Registrar';
import {useRouter} from "../../../../Common/Hooks/useRouter";

const DomainRevisionPage: React.FC<IScene> = props => {

    const dispatch = React.useContext(DispatchContext);
    const {revision} = React.useContext(StateContext);
    const {domainRevisions} = React.useContext(StateContext);
    const {location, history} = useRouter();
    const {params} = useRouteMatch();

    const query = `${location.pathname}${location.search}`;

    React.useEffect(() => {
        fetchDomainRevision(dispatch, params['domain'], params['revision'], revision, query);
    }, [query]);


    return (
        <FetchController {...revision}>
            {(data: IDetailedRevision) => (
                <DomainMaster
                    bgColor="#B7CBE1"
                    title={
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{marginRight: '10px'}}>{'Dettaglio dominio'}</div>
                            <Tag color="#108ee9"><FontAwesomeIcon icon={faHistory}/> REVISIONE</Tag>
                            <Registrar registrarId={data.registrarId}
                                       children={(reg) => isDomainSync(reg) ?
                                           <Tag color={'volcano'}>SINCRONO</Tag> : <></>}
                            />
                            <RevType type={data.revision.type}/>
                        </div>
                    }
                    revNav={
                        domainRevisions.response &&
                        <div style={{float: 'right'}}><RevisionPrevNext
                            {...data.revision}
                            revisions={domainRevisions.response.data}
                            onClickNext={(nextRev) => history.push(nextRev.number.toString())}
                            onClickPrev={(prevRev) => history.push(prevRev.number.toString())}
                        /></div>
                    }
                    domain={data}
                />
            )}
        </FetchController>
    );
};

export default DomainRevisionPage;

const RevType: React.FC<{ type: string | undefined }> = ({type}) => {
    if (type === 'MOD') {
        return <Tag color="#f50">Tipo: MODIFICA</Tag>;
    }

    if (type === 'ADD') {
        return <Tag color="#87d068">Tipo: CREAZIONE</Tag>;
    }
    return <div/>;
};