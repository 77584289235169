import * as React from "react";
import { Button, Col, Form, Input, Radio, Row } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import AddDocumentFormItem from "../../../Documents/AddDocumentFormItem";
import { IEditContactOperation } from "./Steps";

const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const EditEventForm: React.FC<IEditEventCreationForm> = ({ value, onSubmit, form }) => {

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (onSubmit) {
        // console.log("EditEventForm values ", values);
        onSubmit(err, values);
      }
    });
  };

  const isDescriptionRequired = value?.type === 'registrant' && form.getFieldValue("status") === "rejected"
  return (
    <>
      <Form className="ant-advanced-search-form" layout="horizontal" onSubmit={handleSubmit}>

        <Row>
          <Col span={24}>
            <Form.Item {...formItemLayout}
                       label={
                         <span>Status</span>
                       }
            >
              {form.getFieldDecorator("status", {
                initialValue: value?.type === "registry" ? "accepted" : value && value.status,
                rules: [
                  {
                    required: true,
                    message: "Seleziona uno stato "
                  }
                ]
              })(<Radio.Group defaultValue={value?.type === "registry" ? "accepted" : ""}>
                  <Radio.Button value="accepted">Accetta</Radio.Button>
                  <Radio.Button disabled={value?.type === "registry"} value="rejected">Respingi</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>

        {/* Description */}
        <Row>
          <Col span={24}>
            <Form.Item {...formItemLayout} label={
              <span>Descrizione&nbsp;
            </span>
            }
            >
              {form.getFieldDecorator("description", {
                initialValue: value && value.description,
                rules: [
                  {
                    required: isDescriptionRequired,
                    message: "Inserisci una descrizione "
                  }
                ]
              })(<TextArea data-testid='textareaDescription' rows={4}
                           placeholder={"Inserire una descrizione ..."} />)}
            </Form.Item>
          </Col>
        </Row>

        {
          value?.type === "registrant" &&
          <AddDocumentFormItem
            form={form}
            documentValue={value ? value.document : undefined}
            required={true}
          />
        }
        {/* Buttons */}
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button onClick={() => form.resetFields()}>
              Pulisci
            </Button>
            <Button data-testid={"btnNext"} style={{ marginLeft: 8 }} type="primary" htmlType="submit">
              Procedi
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export const WrappedEditEventCreationForm = Form.create<IEditEventCreationForm>({ name: "operation_form" })(EditEventForm);

interface IEditEventCreationForm extends FormComponentProps {
  value?: IEditContactOperation
  onSubmit?: (err: any, value: any) => any;
}


/***
 * {"id":14402219,"documentType":"protocol","protNum":55555,"protYear":5444,"dateArrive":"2021-03-11T14:34:22","numpag":11}
 */