import { IFetchStateType } from '../../Type/IFetchtStateType';
import { IDomain } from './types/domain';
import {
  apiGetContactDetails,
  apiGetDomainDetails,
} from '../../../const/api-calls';
import { AxiosResponse } from 'axios';
import { IContact } from '../../Contacts/Detail/contacts';

/***
 * Funzione per il recupero del dettaglio dominio relativi alla query passata.
 *
 * @param dispatch
 * @param domain - valore proveniente dallo stato del reducer
 * @param domainId
 * @param query - queryString corrente
 */
export function fetchDomain(
  dispatch: any,
  domain: IFetchStateType<IDomain>,
  domainId: string,
  query: string,
) {
  if (query === '' || query === '?') {
    dispatch({ type: 'DOMAIN_FETCH_CLEAN' });
  } else if (domain.query.localeCompare(query) !== 0) {
    dispatch({ type: 'DOMAIN_FETCH_LOAD', query });
    dispatch({ type: 'DOMAIN_NOTES_FETCH_CLEAN' });

    apiGetDomainDetails(domainId)
      .then((res: any) => {
        dispatch({ type: 'DOMAIN_FETCH_SUCCESS', query, payload: res });
      })
      .catch((error: any | PromiseLike<IDomain>) => {
        dispatch({ type: 'DOMAIN_FETCH_ERROR', error });
      });
  }
}

export function reloadDomain(dispatch: any, history: any, location: any) {
  dispatch({ type: 'DOMAIN_FETCH_CLEAN' });
  dispatch({ type: 'DOMAIN_EVENTS_FETCH_CLEAN' });
  dispatch({ type: 'DOMAIN_REVISIONS_FETCH_CLEAN' });
  history.push(location.pathname);
}

/***
 * Funzione per il recupero del dettaglio dominio relativi alla query passata .
 * Rispetto a fetchDomain recupera anche il dettaglio del contatto Registrar
 * con il valore Stato cosa nn possibile con le api attuali con fetchDomain
 *
 * @param dispatch
 * @param domain - valore proveniente dallo stato del reducer
 * @param domainId
 * @param query - queryString corrente
 */
export function fetchDomainWithFullRegistrant(
  dispatch: any,
  domain: IFetchStateType<IDomain>,
  domainId: string,
  query: string,
) {
  if (query === '' || query === '?') {
    dispatch({ type: 'DOMAIN_FETCH_CLEAN' });
  } else if (domain.query.localeCompare(query) !== 0) {
    dispatch({ type: 'DOMAIN_FETCH_LOAD', query });
    dispatch({ type: 'DOMAIN_NOTES_FETCH_CLEAN' });

    apiGetDomainDetails(domainId)
      .then((domainDetailResponse: AxiosResponse<IDomain>) => {
        if (domainDetailResponse.data.registrant?.id.toString()) {
          apiGetContactDetails(
            domainDetailResponse.data.registrant?.id.toString(),
          )
            .then((contactDetailResponse: any) => {
              dispatch({
                type: 'DOMAIN_FETCH_SUCCESS',
                query,
                payload: {
                  ...domainDetailResponse,
                  data: {
                    ...domainDetailResponse.data,
                    registrant: { ...contactDetailResponse.data },
                  },
                },
              });
            })
            .catch((error: any | PromiseLike<IContact>) => {
              dispatch({ type: 'DOMAIN_FETCH_ERROR', error });
            });
        } else {
          // Usato nel caso nn ci sia il Registrante, tipicamente quando cè un
          // dominio in stato DELETE
          dispatch({
            type: 'DOMAIN_FETCH_SUCCESS',
            query,
            payload: {
              ...domainDetailResponse,
              data: { ...domainDetailResponse.data },
            },
          });
        }
      })
      .catch((error: any | PromiseLike<IDomain>) => {
        dispatch({ type: 'DOMAIN_FETCH_ERROR', error });
      });
  }
}
