import * as React from "react";
import { IDomainEvent } from "../types/domainEvents";
import { Divider, Result } from "antd";
import { DomainEvent } from "../TableHelpers";


export const AvailableEventResultSuccessful: React.FC<{ data: IDomainEvent }> = props => (
  <Result
    status="success"
    title="Operazione completata con successo"
    subTitle={
      <>
        <Divider type={"horizontal"} />
        <DomainEvent event={props.data} />
      </>
    }
    // extra={[
    //     <Button type="primary" key="console">
    //         Go Console
    //     </Button>,
    // ]}
  />);
