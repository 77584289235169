import {AuthContext} from "../Auth/AuthProvider";
import {SelectProps} from "antd/lib/select";
import * as React from 'react';
import {Select, Spin} from "antd";
import styled from 'styled-components';


const {Option} = Select;

/**
 * Permette di eseguire un ricerca univoca per il contactId specificato
 * Tramite il paramentro valuFieldName si specifica il valore del campo form che verrà esposto tramite la form
 * @param {SelectProps & {form: any; valueFieldName: string} & {children?: React.ReactNode}} props
 * @returns {any}
 * @constructor
 */

const StyledSelect = styled(Select)`
    .ant-select-selection-selected-value{
        position : absolute;
`;


export interface ISelectLookUp {
    form: any;
    icon: string;
    forwardRef: any

    fetch(value: any, setData: any, keycloak: any): void;
}

const SelectLookUp: React.FC<SelectProps & ISelectLookUp> = React.memo(props => {

    const [data, setData] = React.useState([]);
    const {keycloak} = React.useContext(AuthContext);

    React.useEffect(() => {
        if (props.value !== undefined) {
            props.fetch(props.value, (d: any) => setData(d), keycloak);
        }
    }, [])

    const handleSearch = (val: any) => {
        if (val) {
            props.fetch(val, (d: any) => setData(d), keycloak);
        } else {
            setData([]);
        }
    };

    return (
        <StyledSelect
            labelInValue
            showSearch
            allowClear
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={props.loading ? <Spin size="small" /> : 'Non trovato'}
            {...props}
        >
            {/*<Option value={undefined}>{"-"}</Option>*/}
            {data.map((d: any) => <Option value={d.value} key={d.value}>{d.text}</Option>)}
        </StyledSelect>)

})

export default SelectLookUp;