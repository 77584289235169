import { ParsedQuery } from "query-string";
import ExportResult, { IStreamFormat, openExtractResultModal } from "../../../Common/Feedback/ExportResult/ExportResult";
import { Alert, Button } from "antd";
import * as React from "react";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { AxiosRequestConfig } from "axios";
import { apiGetContactsStream } from "../../../../const/api-calls";
import { searchAdpater } from "../../../../utils/url-manipulation";
import { CheckboxViewSelector, RadioGroupExportFormat } from "../../../Common/Helper/Selectors";
import { formats } from "../../../../const/common";

/***
 * Sezione per l'esportazione dei risultati della ricerca dei contatti
 * Contiene tutti gli handler per costruire la richiesta di scaricamento dati mostrati a video
 *
 * @param props
 * @constructor
 */
export default function ExportButton(props: { query: ParsedQuery, totalElements: number }) {

  const showModal = () => openExtractResultModal(
    "Esporta risultati",
    <ExportContatcResultModal {...props} />
  );

  return (<Button
    icon={"download"}
    onClick={showModal}>
    Esporta
  </Button>);
}




function ExportContatcResultModal(props: { query: ParsedQuery, totalElements: number }) {

  const [views, setViews] = React.useState<CheckboxValueType[]>(["linked"]);
  const [format, setFormat] = React.useState<IStreamFormat | undefined>();

  const handleOnChangeFormat = (fmt: IStreamFormat) => {
    setFormat(fmt);
  };

  function handleOnChangeViewSelector(checkedValues: any) {
    setViews([].concat(checkedValues));
  }

  const functionToCall = (config?: AxiosRequestConfig) => {
    return apiGetContactsStream(searchAdpater(props.query), views, format?.display as "csv" | "jsonlines", config);
  };

  return <ExportResult
    format={format}
    totalElements={props.totalElements}
    fetchData={functionToCall}
    fixedContent={<Alert
      message="Operazione limitata"
      type="warning"
      description="Estrazioni onerose saranno automaticamente interrotte dopo alcuni minuti."
    />}
    filename={'mida-contactExport'}
  >
    <>
      <div style={{ textAlign: "center", marginTop: 30 }}>
        <CheckboxViewSelector
          onChangeSelection={handleOnChangeViewSelector}
          defaultValue={views}
          query={props.query}
          options={["linked","registrar"]}
        />
      </div>
      <div style={{ textAlign: "center", marginTop: 30 }}>
        <RadioGroupExportFormat formatList={formats}
                                onChangeFormat={handleOnChangeFormat} />
      </div>
    </>
  </ExportResult>;
}


