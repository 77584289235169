import * as React from 'react';

// const DrawerContent: React.FC = () => {
//     const {domainRevisions} = React.useContext(StateContext);
//
//     return (
//         <React.Fragment>
//             <div
//                 style={{
//                     margin: '-24px',
//                     marginBottom: '20px',
//                     textAlign: 'center',
//                     padding: '5px',
//                 }}
//             >
//                 <i>
//                     Totale
//                     revisioni: <b>{domainRevisions.response ? domainRevisions.response.data.page.totalElements : 0}</b>
//                 </i>
//             </div>
//             <div id='divScroll ' style={{height: '1000px', overflow: 'auto', textAlign: 'center'}}>
//                 <Timeline
//                     loading={domainRevisions.loading}
//                     data={domainRevisions.response ? domainRevisions.response.data : {} as IRevisions}
//                 />
//             </div>
//         </React.Fragment>
//     );
// };


export const DrawerContent: React.FC<{ data: any } & { children: (props: any) => JSX.Element }> = ({data, children}) => {
    return (
        <>
            {/*<div*/}
            {/*    style={{*/}
            {/*        margin: '-24px',*/}
            {/*        marginBottom: '20px',*/}
            {/*        textAlign: 'center',*/}
            {/*        padding: '5px',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <i>*/}
            {/*        Totale*/}
            {/*        revisioni: <b>{data.page.totalElements || 0}</b>*/}
            {/*    </i>*/}
            {/*</div>*/}
            <div id='divScroll ' style={{
                height: '1000px',
                // overflow: 'auto',
                textAlign: 'center',
            }}>
                {children(data)}
            </div>
        </>
    );
};
export default DrawerContent;
