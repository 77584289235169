import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { DomainRevision } from "../types/domain";
import { IScene } from "../../../Type/IScene";
import DomainMaster from "../DomainMaster";
import { DispatchContext, StateContext } from "../../../StoreProvider";
import { fetchDomainWithFullRegistrant } from "../operations";
import { FetchController } from "../../../Common/Navigation/FetchController/FetchController";

const DomainPage: React.FC<RouteComponentProps<{}> & IScene> = props => {

  const dispatch = React.useContext(DispatchContext);
  const { domain } = React.useContext(StateContext);

  React.useEffect(() => {
    /*
     Metodo sostituito con fetchDomainWithFullRegistrant perchè in fetchDomain non viene attualmente visualizzato
     il dettaglio del contatto Registrar con il valore dello 'stato'
     */
    // fetchDomain(dispatch, domain, props.match.params['domain'], props.location.pathname);
    fetchDomainWithFullRegistrant(dispatch, domain, props.match.params["domain"], props.location.pathname);
  });

  return (
    <FetchController pageCentered {...domain} >
      {(value: DomainRevision) => (
        <DomainMaster
          title={"Dettaglio dominio"}
          domain={value}
        />
      )}
    </FetchController>
  );
};

export default DomainPage;
