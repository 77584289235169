import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChessRook } from "@fortawesome/free-solid-svg-icons";
import { Button, Tooltip } from "antd";
import { MIDA_OLD_HOST } from "../../../midaConfig";

export interface IMidaOldLink {
  /**  Id della risorsa da visualizzare */
  id: number;
  /** Nome della risorsa da visualizzare mostrato nella drescrizione */
  name: string;
  /** Tipologia della risorsa da visualizzare */
  type: "domain" | "contact";
}

export const MidaOldLink = (props: IMidaOldLink) =>
  <Tooltip
    title={`Visualizza il ${props.type === "contact" ? "contatto" : "dominio"} '${props.name}' nel vecchio mida`}>
    <Button>
      <Link {...props} />
    </Button>
  </Tooltip>;

/**
 * Link al Registrar su Jbilling
 * @param props
 * @constructor
 */
const Link = (props: { id: number, type: "domain" | "contact" }) => <a
  href={`http://${MIDA_OLD_HOST}/mida/${props.type}/${props.id}/view`}
  target="_blank"
  rel="noopener noreferrer"><FontAwesomeIcon icon={faChessRook} />
</a>;