import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledStatusbarContainer } from '../../../Common/Styled/StatusBar';
import { Button, Dropdown, Icon, Menu, Tooltip } from 'antd';
import { useRouter } from '../../../Common/Hooks/useRouter';
import { MidaOldLink } from '../../../Common/Link/MidaOld';
import ContactStatusBar from '../ContactStatusBar';
import { DispatchContext } from '../../../StoreProvider';
import { IContact } from '../contacts';
import { faCog, faEdit, faSync } from '@fortawesome/free-solid-svg-icons';
import { reloadContact } from '../operations';
import { AbilityContext } from '../../../Common/Ability/AbilityContext';
import { EditContactModal } from '../EditContact/EditContactModal';

const Toolbar: React.FC<{ contact: IContact; refRevision?: string }> = ({
  contact,
  refRevision,
}) => {
  // const { visibile, open, close } = useModal();
  const { history, location } = useRouter();
  const dispatch = React.useContext(DispatchContext);

  return (
    <div className={'toolbar'}>
      <StyledStatusbarContainer>
        <ContactStatusBar contact={contact} />

        {/* REFRESH BUTTON*/}
        <div style={{ display: 'inherit' }}>
          <div style={{ marginLeft: '5px', marginRight: '5px' }}>
            <Tooltip title={'Ricarica il contatto'}>
              <Button
                onClick={() => {
                  reloadContact(dispatch, history, location);
                }}
              >
                {' '}
                <FontAwesomeIcon icon={faSync} />
              </Button>
            </Tooltip>
          </div>
          <div style={{ marginRight: '5px' }}>
            <MidaOldLink id={contact.id} name={contact.name} type={'contact'} />
          </div>

          {/* ACTION SELECTOR */}
          <ActionMenu contact={contact} refRevision={refRevision} />
        </div>
      </StyledStatusbarContainer>
    </div>
  );
};
export default Toolbar;

/***
 * ACTION MENU
 */

export interface IActionMenu {
  /** dati del dominio */
  contact: IContact;
  /** revisione di riferimento */
  refRevision?: string;
}

/***
 * Drop down menu che mostra le azioni disponibili
 * @param props
 * @constructor
 */
function ActionMenu(props: IActionMenu) {
  const { ability } = React.useContext(AbilityContext);

  const menu = () => (
    <Menu>
      <Menu.ItemGroup key="g1" title="Strumenti">
        <Menu.Item
          key={'authinfo'}
          // TODO eliminare parte  che disabilita SEMPRE il comando - AGGIUNTA IN ATTESA DELLA PUBBLICAZIONE UFFICIALE
          disabled={ability.cannot('registry-update', 'Domain') || true}
        >
          {/*TODO eliminare parte  che disabilita SEMPRE il comando - AGGIUNTA IN ATTESA DELLA PUBBLICAZIONE UFFICIALE*/}
          <FontAwesomeIcon icon={faEdit} />
          <EditContactModal
            contact={props.contact}
            disabled={ability.cannot('registry-update', 'Domain') || true}
          />
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Dropdown overlay={menu()}>
      <Button>
        <FontAwesomeIcon icon={faCog} /> &nbsp;Azioni&nbsp; <Icon type="down" />
      </Button>
    </Dropdown>
  );
}
