import {IRevisions} from "../../Common/Navigation/Revisions/revisions";

export function revisionNavigator(currentRevisionNumber: number, revisions?: IRevisions) {
    const arrayRevisions = revisions && revisions.elements.map(r => r.number) || [];
    const currentId = arrayRevisions.indexOf(currentRevisionNumber);

    const nextRevision = currentId > 0 ? revisions && revisions.elements[currentId - 1] : undefined;

    const prevRevision = revisions && currentId < revisions.elements.length ? revisions.elements[currentId + 1] : undefined;

    return {nextRevision, prevRevision}
}