import { Button, Col, Form, Input, InputNumber, Row } from 'antd';
import { capitalizeFirstLetter } from '../../utils/various';
import { AddDocumentTips } from '../../const/TipsComponent';
import * as React from 'react';
import { useAddDocument } from './hooks';
import { FormComponentProps } from 'antd/lib/form/Form';
import { GenericDocument } from './documents';
import { DrawerAddDocument } from './DrawerAddDocument';
import { useModal } from '../Common/Hooks/useModal';
import { ShowDocument } from './ShowDocument';

interface IAddDocumentForm extends FormComponentProps {
  documentValue?: GenericDocument | undefined;
  required?: boolean;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const AddDocumentFormItem: React.FC<IAddDocumentForm> = ({
  form,
  documentValue,
  required: isRequired,
}) => {
  const { visibile, open, close } = useModal();
  const {
    saveDocument,
    responseStatus,
    responseMessage,
    document,
    loading,
  } = useAddDocument(documentValue);

  const isDocumentLoad =
    document ||
    (responseStatus &&
      document &&
      200 <= responseStatus &&
      responseStatus <= 300);
  // console.log("DOOOCument ", document);

  const handleSubmitAddDocument = (values: GenericDocument) => {
    saveDocument(values);
  };

  React.useEffect(() => {
    form.setFields({
      documentId: {
        value: document?.id,
      },
    });
  }, [document]);

  return (
    <>
      {/* Document */}
      <Row>
        <Col span={24}>
          <Form.Item
            {...formItemLayout}
            label={
              document
                ? capitalizeFirstLetter(document.documentType)
                : 'Documento'
            }
          >
            <Row>
              <Col span={22}>
                {form.getFieldDecorator('documentId', {
                  initialValue: (document && document.id) || undefined,
                  rules: [
                    {
                      required: isRequired,
                      message: 'Associa un documento',
                    },
                  ],
                })(<InputNumber style={{ width: '100%' }} disabled />)}
                {responseStatus &&
                  400 <= responseStatus &&
                  responseStatus <= 600 && (
                    <div
                      className="ant-form-explain"
                      style={{ color: '#f5222d' }}
                    >
                      {responseMessage}
                    </div>
                  )}
              </Col>
              {isDocumentLoad && document && (
                <ShowDocument documentData={document} />
              )}
            </Row>
          </Form.Item>

          <Row>
            <Col span={24}>
              <Form.Item
                {...formItemLayout}
                style={{ display: 'none' }}
                label={<span>Status</span>}
              >
                {form.getFieldDecorator('document', {
                  initialValue: document,
                })(<Input disabled />)}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item {...tailFormItemLayout}>
            <Button
              loading={loading}
              type={isDocumentLoad ? 'dashed' : 'primary'}
              onClick={open}
              icon={'plus'}
            >
              {isDocumentLoad ? 'Cambia' : 'Associa'} documento
            </Button>
            <AddDocumentTips />
          </Form.Item>
        </Col>
      </Row>
      <DrawerAddDocument
        show={visibile}
        onClose={close}
        onSubmit={values => handleSubmitAddDocument(values)}
      />
    </>
  );
};

export default AddDocumentFormItem;
