import * as React from 'react';
import { AuthContext } from '../../Auth/AuthProvider';
import { StateContext } from '../../../StoreProvider';
import { useRouteMatch } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import {
  Alert,
  Button,
  Divider,
  Icon,
  Modal,
  Spin,
  Tag,
  Typography,
} from 'antd';
import CardWithViewer from '../CardWithViewer/CardWithViewer';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDetailedRevision, IRevision, IRevisions } from './revisions';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import RevisionPrevNext, {
  RevisionLabel,
} from './RevisionPrevNext/RevisionPrevNext';
import { IViewerContentType } from '../CardWithViewer/Type/IViewerContentType';
import { buildDiffContent as buildContactDiffContent } from '../../../Contacts/Detail/buildDiffContent';
import { buildDiffContent as buildDomainDiffContent } from '../../../Domains/Domain/Detail/buildDiffContent';
import { printAxiosFetchError } from '../FetchController/FetchController';
import * as moment from 'moment';

/***
 * Logica per la gestione della modale per la visualizzazione della comparazione tra due revisioni, la corrente caricata e quelle da comparare.
 *
 * @param visibile
 * @param onCancel
 * @param revisionNumber
 * @param onOk
 * @constructor
 */

interface ICompareRevisionModalContainer {
  visibile: boolean;
  fetchFunction: (
    resourceId: string,
    revisionId: string,
    keycloack: any,
  ) => any;
  revisionNumber: number;

  onCancel(): void;

  onOk(): void;
}

export const CompareRevisionModalContainer: React.FC<ICompareRevisionModalContainer> = ({
  visibile,
  onCancel,
  revisionNumber,
  onOk,
  fetchFunction,
}) => {
  const { keycloak } = React.useContext(AuthContext);
  const { revision: currentRevision } = React.useContext(StateContext);
  const { domain, contact } = React.useContext(StateContext);
  const { domainRevisions, contactRevisions } = React.useContext(StateContext);

  const { params } = useRouteMatch();

  const [revisionA, setRevisionA] = React.useState({} as AxiosResponse);
  const [revisionB, setRevisionB] = React.useState(
    {} as AxiosResponse<IDetailedRevision>,
  );

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<AxiosError | undefined>(undefined);

  function getResourceId(param: string, loadedResource: any) {
    console.debug('getResourceId ', param, loadedResource, currentRevision);
    // se revisione già selezionata
    if (param && currentRevision.response) {
      return param;
    } else if (loadedResource.response) {
      //// altrimenti prendi quella in memoria
      console.debug(' loadedResource', loadedResource);
      return loadedResource.response.data.id.toString();
    }
  }

  function setLeftRevision(param: string, loadedResource: any) {
    // se revisione già selezionata
    if (param && currentRevision.response) {
      console.debug('here ');
      setRevisionA(currentRevision.response);
    } else if (loadedResource.response) {
      console.debug('there ');
      setRevisionA(loadedResource.response);
    }
  }

  const fetchData = async () => {
    try {
      let resourceId: string = '';

      if (params['contact']) {
        resourceId = getResourceId(params['contact'], contact);
        setLeftRevision(params['contact'], contact);
      } else if (params['domain']) {
        resourceId = getResourceId(params['domain'], domain);
        setLeftRevision(params['domain'], domain);
      }

      console.debug('Res ID ', resourceId, params['contact'], params['domain']);

      const target = await fetchFunction(
        resourceId,
        revisionNumber.toString(),
        keycloak,
      );
      setRevisionB(target);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  React.useEffect(() => {
    if (visibile) {
      setLoading(true);
      fetchData();
    }
  }, [visibile]);

  const handleClickPrevRevision = async (prev: IRevision) => {
    setLoading(true);
    let resourceId: string = '';

    if (params['contact']) {
      resourceId = getResourceId(params['contact'], contact);
    } else if (params['domain']) {
      resourceId = getResourceId(params['domain'], domain);
    }
    const result = await fetchFunction(
      resourceId,
      prev.number.toString(),
      keycloak,
    );
    setRevisionB(result);
    setLoading(false);
  };
  const handleClickNextRevision = async (next: IRevision) => {
    setLoading(true);

    let resourceId: string = '';

    if (params['contact']) {
      resourceId = getResourceId(params['contact'], contact);
    } else if (params['domain']) {
      resourceId = getResourceId(params['domain'], domain);
    }

    const result = await fetchFunction(
      resourceId,
      next.number.toString(),
      keycloak,
    );
    setRevisionB(result);
    setLoading(false);
  };

  return (
    <CompareRevisionModal
      loading={loading}
      error={error}
      title={
        <div>
          <FontAwesomeIcon
            icon={faExchangeAlt}
            style={{ display: 'inline', fontSize: 25 }}
          />
          <Typography.Title level={3} style={{ display: 'inline' }}>
            {' '}
            Comparatore revisioni
          </Typography.Title>
        </div>
      }
      onCancel={onCancel}
      onOk={onOk}
      revisionA={revisionA}
      revisionB={revisionB}
      visible={visibile}
      onClickNextRevision={handleClickNextRevision}
      onClickPrevRevision={handleClickPrevRevision}
      buildContent={
        params['domain'] ? buildDomainDiffContent : buildContactDiffContent
      }
      revisions={
        params['domain']
          ? domainRevisions.response && domainRevisions.response.data
          : contactRevisions.response && contactRevisions.response.data
      }
    />
  );
};

export const RevisionTag: React.FC<{
  prefixText?: string;
  text: string;
  color: string;
}> = ({ prefixText, text, color }) => {
  return (
    <Tag color={color}>
      <div style={{ textAlign: 'left' }}>
        <FontAwesomeIcon icon={faTag} style={{ marginRight: 5 }} />
        {prefixText && (
          <span>
            {prefixText}
            <Divider type={'vertical'} />{' '}
          </span>
        )}
        <span>{text}</span>
      </div>
    </Tag>
  );
};

export const RevisionTagBlock: React.FC<{
  prefixText?: string;
  text: string;
  color: string;
  opacity?: number;
}> = ({ prefixText, text, color, opacity, children }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'inline',
        opacity: opacity ? opacity : 1,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <RevisionTag text={text} color={color} />
        </div>
        {children}
      </div>
    </div>
  );
};

interface ICompareRevisionModal {
  revisionA: AxiosResponse;
  revisionB: AxiosResponse<IDetailedRevision>;
  visible: boolean;
  title?: any;
  loading: boolean;
  error?: AxiosError;
  buildContent: (
    domainA: IDetailedRevision,
    domainB: IDetailedRevision,
  ) => IViewerContentType[];
  revisions?: IRevisions;

  onOk(): void;

  onClickNextRevision(nextRev: IRevision): void;

  onClickPrevRevision(prevRev: IRevision): void;

  onCancel(): void;
}

/***
 * Modale per la visualizzazione della comparazione tra due revisioni, la corrente caricata e quelle da comparare.
 * @param revisionA
 * @param revisionB
 * @param visible
 * @param onClickPrevRevision
 * @param onClickNextRevision
 * @param onCancel
 * @param onOk
 * @param title
 * @param loading
 * @param error
 * @param buildContent
 * @param revisions
 * @constructor
 */
const CompareRevisionModal: React.FC<ICompareRevisionModal> = ({
  revisionA,
  revisionB,
  visible,
  onClickPrevRevision,
  onClickNextRevision,
  onCancel,
  onOk,
  title,
  loading,
  error,
  buildContent,
  revisions,
}) => {
  const Spinner = () => {
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    return <Spin indicator={antIcon} tip={'Caricamento revisione...'} />;
  };

  const ModalTitle = () => {
    if (revisionB.data) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RevisionTagBlock text={'Revisione A'} color={'orange'} opacity={0.6}>
            {revisionA.data && revisionA.data['revision'] ? (
              RevisionLabel(revisionA.data.revision.date)
            ) : (
              <div style={{ fontSize: '22px', marginBottom: '20px' }}>
                Corrente
              </div>
            )}
          </RevisionTagBlock>
          <div>
            <FontAwesomeIcon
              icon={faExchangeAlt}
              style={{ marginRight: 15, marginLeft: 35, fontSize: 22 }}
            />
          </div>
          {/*<RevisionTag color={'blue'} prefixText={'Revisione B'}*/}
          {/*             text={getRevisionTextDate(revisionB)}/>*/}
          {revisions && (
            <RevisionTagBlock text={'Revisione B'} color={'blue'}>
              <div>
                <RevisionPrevNext
                  {...revisionB.data.revision}
                  revisions={revisions}
                  onClickPrev={onClickPrevRevision}
                  onClickNext={onClickNextRevision}
                />
              </div>
            </RevisionTagBlock>
          )}
        </div>
      );
    }

    return <></>;
  };

  const RevB = getRevisionTextDate(revisionB);

  return (
    <Modal
      width={900}
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onOk}>
          {`Carica Revisione B ${RevB ? '(' + RevB + ')' : ''}`}
        </Button>,
      ]}
    >
      {error ? (
        <Alert
          message="Errore"
          description={printAxiosFetchError(error)}
          type={'error'}
          showIcon={true}
        />
      ) : !revisionB.data ? (
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      ) : (
        <CardWithViewer
          defaultView={'tab'}
          extraTab={<ModalTitle />}
          loading={loading}
          content={buildContent(revisionA.data, revisionB.data)}
        />
      )}
    </Modal>
  );
};

function getRevisionTextDate(revision: AxiosResponse<IDetailedRevision>) {
  return (
    revision &&
    revision.data &&
    `${moment(revision.data.revision.date).format('LLL')}:${moment(
      revision.data.revision.date,
    ).seconds()}`
  );
}
