import * as React from "react";
import { IDomain } from "../../types/domain";
import { Divider, Input, Typography } from "antd";
import { AxiosControllerComponent } from "../../../../Common/Navigation/FetchController/FetchController";
import {
  apiGetDecryptedAuthinfo,
  apiGetDomainAuthinfo,
  apiGetDomainHistoryAuthinfo
} from "../../../../../const/api-calls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";


export const AuthInfo: React.FC<{ domain: IDomain, refRevision?: string, revisionNumber?: string }> = ({
                                                                                                         domain,
                                                                                                         revisionNumber,
                                                                                                         refRevision
                                                                                                       }) => {
  const toCall = revisionNumber ?
    apiGetDomainHistoryAuthinfo(domain.id.toString(), revisionNumber) :
    apiGetDomainAuthinfo(domain.id.toString());

  return (
    <div>
      <Divider />
      <AxiosControllerComponent call={toCall}>
        {(response: { data: { authInfo: string, keyTag: number } }) =>
          DisplayAuthInfo({ data: response.data })
        }
      </AxiosControllerComponent>
    </div>
  );
};

/* Componente per la visualizzazione dell'Authinfo */
const InputAuthInfo = (props: { authInfo: string, label: string, key: string, crypted?: boolean }) =>
  <div key={props.key}>
    <Typography.Text strong>{props.label}</Typography.Text>
    <Input.Password
      readOnly
      style={{ color: "rgba(0,0,0,.25)" }}
      defaultValue={props.authInfo}
      prefix={props.crypted ? <FontAwesomeIcon icon={faKey} style={{ color: "rgba(0,0,0,.25)" }} /> : ""}
      size="large" />
  </div>;


const DisplayAuthInfo = (props: { data: { authInfo: string, keyTag: number } }) => {

  /* Se authinfo è cifrato */
  if (props.data.keyTag) {
    return <AxiosControllerComponent
      call={apiGetDecryptedAuthinfo({
        encodedPassword: props.data.authInfo,
        keyTag: props.data.keyTag
      })}>
      {(nextResponse: { data: { password: string } }) => {
        return InputAuthInfo({
          authInfo: nextResponse.data.password,
          label: "AuthInfo",
          crypted: true,
          key: "decryptedAi"
        });
      }}</AxiosControllerComponent>;
  } else { /* SE authinfo è in chiaro */
    return InputAuthInfo({
      authInfo: props.data.authInfo,
      label: "AuthInfo",
      key: "notCryptedAi"
    });
  }
};
