import * as React from "react";
import { AxiosResponse } from "axios";
import { AvailableEventResultSuccessful } from "./AvailableEventResultSuccesful";
import { AvailableEventResultFailed } from "./AvailableEventResultFailed";

export const AvailableEventResult: React.FC<AxiosResponse> = ({ data, status }) => {
  switch (status.toString()) {
    case "201" :
      return <AvailableEventResultSuccessful data={data} />;
    case "403" :
      return <></>;
    case "404" :
      return <></>;
    case "409" :
      return <AvailableEventResultFailed data={data} />;
    default:
      return <></>;
  }
};