import * as React from 'react';
import { IDomain } from '../types/domain';
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Spin,
  Tag,
  Tooltip,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as moment from 'moment';
import {
  apiGetDomainRevision,
  apiGetDomainRevisions,
  apiGetRegistrar,
} from '../../../../const/api-calls';
import {
  printAxiosFetchError,
  useFetchCall,
} from '../../../Common/Navigation/FetchController/FetchController';
import { IRevision } from '../../../Common/Navigation/Revisions/revisions';
import { IRegistrar } from '../../../Registrar/IRegistrar';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import locale from 'antd/lib/date-picker/locale/it_IT';
import { AxiosError } from 'axios';
import { whoisPDFReport } from '../../../../utils/whois/report';

/**
 * Pannello che mostra un Datepicker con cui selezionare la data da cui generare il WHOIS
 * è possibile selezionare se l'whois deve essere a uso interno o meno
 * @param domain
 * @constructor
 */
export const SnapshotWhois: React.FC<{ domain: IDomain }> = ({ domain }) => {
  const [date, setDate] = React.useState<moment.Moment | undefined>(undefined);
  const [internal, setInternal] = React.useState<boolean>(false);

  function onOk(value: moment.Moment) {
    setDate(value);
  }

  function onChange(e: any) {
    setInternal(e.target.checked);
  }

  return (
    <>
      <Divider />
      <p>Specificare la data da cui generare le informazioni WHOIS</p>
      <Form layout="inline">
        <Form.Item label={<b>Data snapshot</b>}>
          <DatePicker
            onChange={() => setDate(undefined)}
            format={'DD-MM-YYYY hh:mm:ss'}
            showTime
            placeholder="Scegli l'orario"
            onOk={onOk}
            popupStyle={{ zIndex: 20001 }}
            locale={locale}
            showToday={false}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox onChange={onChange}>Uso Interno</Checkbox>
        </Form.Item>
        <Form.Item>
          {date && (
            <WHOISPdfGeneratorRow
              domainID={domain.id}
              refDate={date}
              internal={internal}
            />
          )}
        </Form.Item>
      </Form>
    </>
  );
};

/**
 * Recupera le revisioni del dominio e decide tra tutte quale sarà la targetRevision e cioè quella più vicina a quella
 * scelta. Decisa la target revision visualizza il pulsante per la generazione del PDF
 * @param props
 * @constructor
 */

export function WHOISPdfGeneratorRow(props: {
  domainID: number;
  refDate: moment.Moment;
  internal: boolean;
}) {
  const { loading, data, error } = useFetchCall(
    apiGetDomainRevisions(props.domainID.toString()),
  );
  if (error) {
    console.error(error);
  }

  if (loading) {
    return <Spin />;
  }

  if (data) {
    const { elements } = data;
    const beforeDateArray = elements.filter((element: IRevision) =>
      moment(element.date).isBefore(props.refDate),
    );
    let targetRevision;
    targetRevision =
      beforeDateArray.length === 0
        ? elements[elements.length - 1]
        : beforeDateArray[0];
    return (
      <WHOISPdfGenerator
        domainId={props.domainID}
        revision={targetRevision}
        refDate={props.refDate}
        internal={props.internal}
      />
    );
  }

  return <></>;
}

/**
 * Recupera tutti i dati relativi alla revisione del dominio  e quelli del Registrar associato in quella particolare
 * revisione. Poi visualizza il bottone per la generazione del PDF.
 *
 * @param props
 * @constructor
 */
function WHOISPdfGenerator(props: {
  domainId: number;
  revision: IRevision;
  refDate: moment.Moment;
  internal: boolean;
}) {
  const [domainRevision, setDomainRevision] = React.useState<
    IDomain | undefined
  >(undefined);
  const [registrar, setRegistrar] = React.useState<IRegistrar | undefined>(
    undefined,
  );
  const [loading, setLoading] = React.useState(false);
  const [error, setErrror] = React.useState<AxiosError | undefined>(undefined);
  const revisionUrl = `/domains/${props.domainId}/revisions`;

  React.useEffect(() => {
    if (props.domainId && props.revision) {
      setLoading(true);
      apiGetDomainRevision(
        props.domainId.toString(),
        props.revision.number.toString(),
      )
        .then(_domainRevision => {
          setDomainRevision(_domainRevision.data);
          apiGetRegistrar(_domainRevision.data.registrarId)
            .then(_registrar => {
              setRegistrar(_registrar.data);
              setLoading(false);
            })
            .catch(e => setErrror(e));
        })
        .catch(e => setErrror(e));
    }
  }, [props.domainId, props.revision]);

  function handlOnClick() {
    // console.log("onclick ", domainRevision, registrar);
    if (domainRevision && registrar) {
      whoisPDFReport(
        domainRevision,
        props.revision.date,
        registrar,
        props.refDate.format(),
        props.internal ? 'internal' : undefined,
      );
    }
  }

  return (
    <>
      <span style={{ marginLeft: 10 }}>
        <Tag color="#52C31B">
          <a
            href={`${revisionUrl}/${props.revision.number}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Tooltip
              title={'Revisione associata (clicca per visualizzarla)'}
              overlayStyle={{ zIndex: 2002 }}
            >
              <FontAwesomeIcon icon={faHistory} />{' '}
              {moment(props.revision.date).format('DD-MM-YYYY hh:mm:ss')}
            </Tooltip>
          </a>
        </Tag>
      </span>
      <div style={{ marginTop: 20, textAlign: 'center' }}>
        <Button
          type={'primary'}
          size={'large'}
          onClick={() => handlOnClick()}
          loading={loading}
          icon={'file-pdf'}
        >
          <span style={{ marginLeft: 10 }}>Genera WHOIS </span>
          {/*{console.log("all ", domainRevision, registrar)}*/}
        </Button>
        {error && (
          <Alert
            message="Errore"
            description={printAxiosFetchError(error)}
            type={'error'}
            showIcon={true}
          />
        )}
      </div>
    </>
  );
}
