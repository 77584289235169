import * as React from 'react';
import {OpenDrawerButton, OpenDrawerNotesButton} from './OpenDrawerButton';
import {Affix, Badge, Drawer} from 'antd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faComment} from "@fortawesome/free-solid-svg-icons";
import {DispatchContext, StateContext} from "../../../../StoreProvider";

const RevisionsDrawer: React.FC<{ elementCount: number, children: React.ReactNode }> = props => {
    const [visible, setVisible] = React.useState(false);

    const handleOnClose = () => {
        setVisible(false);
    }

    const handleOnOpen = () => {
        setVisible(true);
    }
    return (
        <div>
            <Drawer
                title={<DrawerTitle
                    elementsCount={props.elementCount}
                    title={'Storico'}
                    subTitle={<i>
                        Totale
                        revisioni: <b>{props.elementCount}</b>
                    </i>}
                />}
                placement={'right'}
                closable={false}
                onClose={handleOnClose}
                visible={visible}
                width={340}
            >
                {props.children}
            </Drawer>
            <Affix style={{position: 'absolute', top: 0, right: 0}}>
                <OpenDrawerButton
                    right={!visible}
                    type="primary"
                    onClick={handleOnOpen}
                ><Badge
                    overflowCount={999}
                    count={props.elementCount || 0} offset={[-35, -10]}
                    // style={{backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset'}}
                    style={{backgroundColor: '#52c41a'}}
                ><FontAwesomeIcon icon={faClock} style={{fontSize: '20pt'}}/>
                </Badge>
                </OpenDrawerButton>
            </Affix>

        </div>
    );
};
export default RevisionsDrawer;


function DrawerTitle(props: { elementsCount: number, title: React.ReactNode, subTitle?: React.ReactNode }) {
    return <div style={{
        fontSize: 20,
        lineHeight: '20px',
        marginBottom: 7,
        color: 'rgba(0,0,0,0.65)',
    }}><FontAwesomeIcon icon={faClock}/> {props.title}
        <div style={{
            fontSize: 12, textAlign: "right", display: 'inline', float: "right", lineHeight: '26px',
        }}>
            {props.subTitle && props.subTitle}
        </div>
    </div>
}


export const NotesDrawer: React.FC<{ elementCount: number, visible?: boolean, children: React.ReactNode }> = props => {
    const dispatch = React.useContext(DispatchContext);
    const {domainNotes} = React.useContext(StateContext);

    const handleOnClose = () => {
        dispatch({type: 'DOMAIN_NOTES_CLOSE'})

    }

    const handleOnOpen = () => {
        dispatch({type: 'DOMAIN_NOTES_OPEN'})
    }
    return (
        <div>
            <Drawer
                placement={'right'}
                closable={false}
                onClose={handleOnClose}
                visible={domainNotes.extra.showDrawer}
                width={350}
            >
                {props.children}
            </Drawer>
            <Affix style={{position: 'absolute', top: 0, right: 0}}>

                <OpenDrawerNotesButton
                    right={!domainNotes.extra.showDrawer}
                    type="primary"
                    onClick={handleOnOpen}
                ><Badge
                    overflowCount={999}
                    count={props.elementCount || 0} offset={[-35, -10]}
                    // style={{backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset'}}
                    style={{backgroundColor: '#52c41a'}}
                ><FontAwesomeIcon icon={faComment} style={{fontSize: '20pt'}}/>
                </Badge>
                </OpenDrawerNotesButton>
            </Affix>
        </div>
    );
};