import * as React from "react";
import {DispatchContext, StateContext} from "../../StoreProvider";
import {useLocation, useRouteMatch} from "react-router-dom";
import {NotesDrawer} from "../../Common/Navigation/Revisions/RevisionDrawer/RevisionsDrawer";
import {fetchDomainNotes} from "./operations";
import {IDomainNote} from "./types";
import {List} from "antd";
import {apiDeleteDomainNote} from "../../../const/api-calls";
import {NoteItem} from "./NoteItem";
// import {fetchNotes} from "./operations";

const Drawer: React.FC = props => {

    const {domainNotes} = React.useContext(StateContext);
    const {domainNote} = React.useContext(StateContext);
    const dispatch = React.useContext(DispatchContext);
    const {params} = useRouteMatch();
    const location = useLocation();

    const notes = domainNotes && domainNotes.response && domainNotes.response.data
        && domainNotes.response.data;

    React.useEffect(() => {
        fetchDomainNotes(dispatch, domainNotes, params['domain'], location)
    });

    function reloadNotes() {
        const domainId = params['domain'];
        dispatch({type: 'DOMAIN_NOTES_FETCH_CLEAN'})
        fetchDomainNotes(dispatch, domainNotes, domainId.toString(), location)
    }

    const handleOnDelete = (id: number) => {
        apiDeleteDomainNote(id).then(() => console.debug(" delete"))
        if (domainNote.data && domainNote.data.id === id) {
            dispatch({type: 'DOMAIN_NOTE_CLEAN'});
        }
        reloadNotes();
    }

    const handleOnSelect = (data: IDomainNote) => {
        dispatch({type: 'DOMAIN_NOTE_LOAD', payload: data})
        dispatch({type: 'DOMAIN_NOTES_CLOSE'})
    }

    const handleOnClone = (data: IDomainNote) => {
        dispatch({type: 'DOMAIN_NOTE_EDIT', payload: data})
        dispatch({type: 'DOMAIN_NOTES_CLOSE'})
    }

    if (notes) {
        return (
            <NotesDrawer
                elementCount={notes.page.totalElements}
                visible={domainNotes.extra.showDrawer}
            >
                <div id='divScroll ' style={{height: '1000px'}}>
                    <NoteList
                        notes={notes.elements}
                        onDelete={handleOnDelete}
                        onSelect={handleOnSelect}
                        onClone={handleOnClone}
                        onEdit={handleOnClone}
                    />
                </div>
            </NotesDrawer>
        )
    }
    return <></>
}

export default Drawer;


const NoteList = (props: {
    notes: IDomainNote[],
    onDelete: (id: number) => void,
    onSelect: (data: IDomainNote) => void
    onEdit: any
    onClone: any
}) => (
    <List
        dataSource={props.notes}
        header={`${props.notes.length} ${props.notes.length > 1 ? 'Note' : 'Nota'}`}
        itemLayout="horizontal"
        renderItem={note =>
            <div style={{backgroundColor: '#fff7b1', marginBottom: 5, padding: 5}}>
                <NoteItem
                    data={note}
                    control={"base"}
                    onDelete={props.onDelete}
                    onSelect={props.onSelect}
                    onEdit={props.onEdit}
                />
            </div>
        }
    />
);