import * as React from "react";
import { moment } from "../../../../utils/various";


export interface ITwoLineDates {
  date: string;
  style?: React.CSSProperties;
}

/***
 * Mostra la data su due linee
 * @param props
 * @constructor
 */
export function TwoLinesDate(props: ITwoLineDates) {
  return (
    <div style={props.style}>
      <div>{moment(props.date).format("DD MMMM YYYY")}</div>
      <div>{moment(props.date).format("HH:mm:ss")}</div>
    </div>
  );
}