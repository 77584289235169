import * as React from 'react';
import {IRevision} from './revisions';
import {Link, useHistory} from 'react-router-dom';
import {moment} from '../../../../utils/various';
import {Button} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
import {CompareRevisionModalContainer} from './CompareRevisionModal';


/***
 * Mostra testo creato per indentificare una Revisione
 *
 * @param revisionUrl
 * @param rev
 * @constructor
 */
export const RevisionText: React.FC<{ rev: IRevision, style?: React.CSSProperties }> = ({ rev, style}) => {
        return (
            <div style={style}>
                <div>{moment(rev.date).format('DD MMMM YYYY')}</div>
                <div>{moment(rev.date).format('HH:mm:ss')}</div>
            </div>
        )
};


/***
 * Componente che identifica il link ad una Revisione.
 *
 * @param revisionUrl
 * @param rev
 * @constructor
 */
export const LinkToRevision: React.FC<{ revisionUrl: string, rev: IRevision }> = ({revisionUrl, rev}) => {
    console.log("rev",rev,revisionUrl)
    return (
        <Link to={revisionUrl}>
            {revisionUrl && <RevisionText  rev={rev}/>}
        </Link>
    );

};


interface IRevisionItem {
    revisionUrl: string,
    rev: IRevision,
    linkable: boolean,
    extra?: React.ReactNode
    fetchFunction: (resourceId: string, revisionId: string, keycloack: any) => any
}

/***
 * Componente che contiene la logica necessaria alla visualizzazione della'item relativo alla revisione che prevede un
 * bottone per la comparazione della revisione già caricata e quella da confrontare abilitato con l'onMouseOver.
 *
 * @param revisionUrl
 * @param rev
 * @param linkable
 * @param extra
 * @param fetchFunction
 * @constructor
 */
export const RevisionItem: React.FC<IRevisionItem> = ({revisionUrl, rev, linkable, extra, fetchFunction}) => {

    const [showCompare, setShowCompare] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);

    const history = useHistory();

    const handleOnOk = () => {
        history.push(revisionUrl);
        setShowModal(true);
    };

    return (
        <div onMouseOver={() => setShowCompare(true)} onMouseLeave={() => setShowCompare(false)}
             style={{display: 'flex', flexDirection: 'column'}}>
            {linkable ?
                <LinkToRevision rev={rev} revisionUrl={revisionUrl}/> :
                <RevisionText rev={rev} style={{color: '#595959'}}/>}
            {extra && extra}
            {linkable && showCompare &&
            <Button
                style={{backgroundColor: '#E9F8FF', color: '#4CA1FF'}}
                block
                size={'small'}
                onClick={() => setShowModal(true)}
            >
                <div>Compara
                    <FontAwesomeIcon
                        // style={{marginLeft: 5}}
                        style={{marginRight: 5, marginLeft: 5}}
                        icon={faExchangeAlt}/>
                </div>
            </Button>
            }
            <CompareRevisionModalContainer
                visibile={showModal}
                onCancel={() => setShowModal(false)}
                onOk={handleOnOk}
                revisionNumber={rev.number}
                fetchFunction={fetchFunction}
            />
        </div>
    );
};





