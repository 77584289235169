import {FormComponentProps} from "antd/lib/form/Form";
import {IDomainNote} from "../types";
import {AxiosError} from "axios";
import * as React from "react";
import {Button, Form, Input} from "antd";
import {printAxiosFetchError} from "../../../Common/Navigation/FetchController/FetchController";
const {TextArea} = Input;

interface IEditorForm extends FormComponentProps {
    value?: IDomainNote;
    loading: boolean;
    onCancel: any;
    error?: AxiosError;
    onSubmit: (err: any, value: any) => any;
}

const EditorForm: React.FC<IEditorForm> = (props) => {
    const {getFieldDecorator} = props.form;

    const handleOnSubmit = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err: any, values: any) => {
            if (!err) {
                props.onSubmit(err, values)
            }
        })
    }

    return <Form onSubmit={handleOnSubmit}>
        <Form.Item>
            {getFieldDecorator(`description`, {
                initialValue: props.value && props.value.description,
                rules: [
                    {
                        required: true,
                        message: 'Inserisci un titolo ',
                    },
                ],
            })(
                <Input
                    placeholder={'Titolo'}
                    size={"small"}
                />)}
        </Form.Item>
        <Form.Item>
            {getFieldDecorator(`note`, {
                initialValue: props.value && props.value.note,
                rules: [
                    {
                        required: true,
                        message: 'Inserisci una descrizione ',
                    },
                ],
            })(
                <TextArea
                    rows={2}
                    placeholder={'Descrizione'}
                />)}
        </Form.Item>
        <Form.Item>
            {props.error && <div style={{color: 'red'}}>{printAxiosFetchError(props.error)}</div>}
            <Button htmlType="submit" type="primary" size={"small"}
                    loading={props.loading}>
                Invia
            </Button>

            <Button htmlType="submit" onClick={props.onCancel} size={"small"}>
                Cancella
            </Button>
        </Form.Item>
    </Form>
}
export const WrappedEditorForm = Form.create<IEditorForm>({name: 'horizontal_login'})(EditorForm);