import * as React from 'react';
import { Form, Row, Col, Input, Button, Checkbox, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { ParsedQuery } from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { transformNestedFieldsIntoNestedDotFields } from '../../utils';
import { useHistory } from 'react-router-dom';

// TODO finire di completare la customizzazione dei campi di ricerca e capire le regole e i validatori

export interface IDomainSearchForm {
  values: ParsedQuery<React.ReactText>;
  onSubmit: (err: any, values: any) => void;
}

const DomainSearchFormRaw: React.FC<IDomainSearchForm &
  FormComponentProps> = React.memo(props => {
  const [itemValues, setItemValues] = React.useState<
    ParsedQuery<React.ReactText> | undefined
  >({});

  const history = useHistory();
  React.useEffect(() => {
    setItemValues(props.values);
  }, [props.values]);

  // Per generare dinamicamente i campi da visualizzare
  const getFields = () => {
    const { getFieldDecorator } = props.form;
    const children = [];

    {
      // ID
      children.push(
        <Col span={9} key={'domain2'} style={{ display: 'block' }}>
          <Form.Item label={``}>
            {getFieldDecorator(`domain`, {
              initialValue: (itemValues && itemValues.domain) || undefined,

              rules: [
                {
                  required: true,
                  message: 'Inserisci un nome a dominio .it',
                },
              ],
            })(
              <Input
                prefix={
                  <FontAwesomeIcon
                    icon={faGlobe}
                    style={{ color: 'rgba(0,0,0,0.25)' }}
                  />
                }
                placeholder="dominio.it"
                allowClear={true}
              />,
            )}
          </Form.Item>
        </Col>,
      );

      // Deleted
      children.push(
        <Col span={3} key={'deleted2'} style={{ display: 'block' }}>
          <Form.Item>
            {getFieldDecorator('showDeleted', {
              valuePropName: 'checked',
              initialValue: (itemValues && itemValues.showDeleted) || true,
            })(
              <Checkbox data-testid="deleted-checkbox">
                <FontAwesomeIcon
                  icon={faEraser}
                  style={{ color: 'rgba(0,0,0,.25)' }}
                />
                {'deleted'}
              </Checkbox>,
            )}
          </Form.Item>
        </Col>,
      );
    }

    return children;
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    props.form.validateFields((err: any, values: any) => {
      // console.log('Advanced Domain Form  - Received values: ', values,);

      if (err) {
        console.error('***** err', err);
      }

      if (!err) {
        let valuesToSubmit: any;
        valuesToSubmit = {
          ...values,
          domain: values.domain && values.domain.toLowerCase(),
        };
        // console.log('*Received values of form*: ', valuesToSubmit);

        valuesToSubmit = transformNestedFieldsIntoNestedDotFields(
          valuesToSubmit,
        );

        props.onSubmit(err, valuesToSubmit);
      }
    });
  };

  const handleClear = () => {
    props.form.resetFields();
  };

  const handleReset = () => {
    handleClear();
    setItemValues(undefined);
  };

  const buttons = () => (
    <div style={{ lineHeight: 3 }}>
      <Button
        type="primary"
        htmlType="submit"
        data-testid="searchDomains-button"
      >
        Search
      </Button>
      <Button
        style={{ marginLeft: 8 }}
        onClick={handleReset}
        data-testid="reset-button"
      >
        Reset
      </Button>
      <Button
        type={'link'}
        onClick={() => history.push('/domains/advanced_search')}
      >
        Ricerca avanzata
      </Button>
    </div>
  );

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={2}>Ricerca domini</Typography.Title>
      </div>
      <Form
        data-testid="searchDomains-form"
        className="ant-advanced-search-form"
        onSubmit={handleSearch}
      >
        <Row type={'flex'} gutter={24} justify={'center'}>
          {getFields()}
          {buttons()}
        </Row>
      </Form>
    </>
  );
});

export const DomainsSearchForm = Form.create<
  IDomainSearchForm & FormComponentProps
>({
  name: 'domains_search_form',
})(DomainSearchFormRaw);
