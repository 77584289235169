import { Button, Typography } from 'antd';
import * as React from 'react';
import { EventIconInfoMapping } from '../const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IModalAvailableEventBody {
  eventName: string;
  showAcceptButton: boolean;
  showRejectButton: boolean;
  onClickReject: () => void;
  onClickAccept: () => void;
}

export const ModalAvailableEventBody: React.FC<IModalAvailableEventBody> = ({
  showRejectButton,
  showAcceptButton,
  onClickAccept,
  onClickReject,
  eventName,
}) => (
  <div style={{ textAlign: 'center' }}>
    <Typography.Paragraph style={{ marginTop: 40, marginBottom: 40 }}>
      <Typography.Title code level={3}>
        <span>
          <FontAwesomeIcon
            icon={
              EventIconInfoMapping[eventName] &&
              EventIconInfoMapping[eventName].icon
            }
            opacity={
              EventIconInfoMapping[eventName] &&
              EventIconInfoMapping[eventName].opacity
            }
            color={
              EventIconInfoMapping[eventName] &&
              EventIconInfoMapping[eventName].color
            }
            style={{ marginRight: 5 }}
          />
          {eventName}
        </span>
      </Typography.Title>
      <p>
        {EventIconInfoMapping[eventName] &&
          EventIconInfoMapping[eventName].text}
      </p>
    </Typography.Paragraph>
    <Typography.Paragraph>
      {showRejectButton && (
        <Button type={showAcceptButton ? 'danger': 'primary'} onClick={onClickReject} size={'large'}>
          {showAcceptButton ? 'Respingi': 'Prosegui'}

        </Button>
      )}
      {showAcceptButton && (
        <Button
          type={'primary'}
          style={{ marginLeft: '5px' }}
          size={'large'}
          onClick={onClickAccept}
        >
          {showRejectButton ? 'Accetta': 'Prosegui'}
        </Button>
      )}
    </Typography.Paragraph>
  </div>
);
