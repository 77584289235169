import { DispatchContext, StateContext } from '../../../../StoreProvider';
import { fetchSingleDomain } from '../../operations';
import React from 'react';
import { documentTitle } from '../../../../../utils/various';
import { useRouter } from '../../../../Common/Hooks/useRouter';
import { LimitedSearchDomainsComponent } from './LimitedSearchDomainsComponent';

/**
 * Pagina di ricerca dominio.
 *
 * Mostra il form di ricerca dominio  con sotto la visualizzazione dei risultati.
 * Il risultato viene recuperato tramite la fetchSingleDomain che tramite un context ritorna il dominio all'interno di domains.
 * La gestione della fetchSingleDomain è demandata al FetchController.
 *
 * Una volta ottenuti il dominio viene visualizzato per mezzo del ResultPane.
 */
export const LimitedSearchDomainsPage: React.FunctionComponent<{}> = props => {
  documentTitle('Ricerca dominio');

  const {
    match: { params },
  } = useRouter();

  const dispatch = React.useContext(DispatchContext);
  const { domains } = React.useContext(StateContext);

  React.useEffect(() => {
    if (params['domain']) {
      fetchSingleDomain(dispatch, domains, params['domain']);
    } else {
      dispatch({ type: 'DOMAINS_FETCH_CLEAN' });
    }
  }, [params['domain']]);
  console.debug(" params['domain']", params['domain']);
  return (
    <LimitedSearchDomainsComponent query={params['domain']} domains={domains} />
  );
};
