import * as React from "react";
import {DispatchContext, StateContext} from "../../StoreProvider";
import {useRouteMatch} from "react-router-dom";
import {fetchDomainRevisions} from "./operations";
import RevisionsDrawer from "../../Common/Navigation/Revisions/RevisionDrawer/RevisionsDrawer";
import {IRevisions} from "../../Common/Navigation/Revisions/revisions";
import DrawerContent from "../../Common/Navigation/Revisions/DrawerContent";
import Timeline from "../../Common/Navigation/Revisions/Timeline/Timeline";
import {apiPatchedGetDomainRevision} from "../../../const/api-calls";

/***
 *
 * @param props
 * @constructor
 */
const Drawer: React.FC = props => {
    const {domainRevisions} = React.useContext(StateContext);
    const dispatch = React.useContext(DispatchContext);
    const {params} = useRouteMatch();
    const revisionUrl = `/domains/${params['domain']}/revisions`;

    React.useEffect(() => {
        fetchDomainRevisions(dispatch, domainRevisions, params['domain'])
    });
    if (domainRevisions && domainRevisions.response && domainRevisions.response.data
        && domainRevisions.response.data.elements) {
        return (
            <RevisionsDrawer elementCount={domainRevisions.response.data.elements.length}>
                <DrawerContent data={domainRevisions.response.data}>
                    {(data: IRevisions) =>
                        <Timeline data={data} loading={false} revisionUrl={revisionUrl}
                                  fetchFunction={apiPatchedGetDomainRevision}
                        />
                    }
                </DrawerContent>
            </RevisionsDrawer>
        )
    }
    return <></>
}

export default Drawer;