import { Table, Tag, Typography } from 'antd';
import * as React from 'react';
import { HelpTips } from '../components/Common/DataDisplay/HelpTips/HelpTips';
import { faCheck, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { Paragraph, Text } = Typography;

const LabeledTips: React.FC<{ label?: string, content?: React.ReactNode, title?: React.ReactNode }> = ({ label, title, content }) => (
  <span><span style={{ marginRight: 5 }}>{label}</span><HelpTips content={content} title={title}/></span>);


const NotIndexedField = () => <Paragraph><Text mark>Attenzione, il campo non è indicizzato e le ricerche potrebbe
  risultare lente </Text></Paragraph>;

const Note = () => <Text mark strong>Nota:</Text>;
// *********************
// *                   *
// *   FORM COMPONENT  *
// *      TIPS         *
// *                   *
// *********************

export const RegCodeTips = () => (
  <LabeledTips label={'RegCode o CF'} title={'Registrant RegCode'} content={regCodeContent}/>
);

export const SchoolCodeTips = () => (
  <LabeledTips label={'SchoolCode'} title={'Registrant SchoolCode'} content={regSchoolCodeContent}/>
);

export const StatuseTips = () => (
  <LabeledTips label={'Status'} title={'Stati dei domini'} content={statusContent}/>
);

export const StateOrProvinceTips = () => (
  <LabeledTips label={'StateOrProvince'} title={'Province'} content={stateOrProvinceContent}/>
);

export const LinkedTips = () => (
  <LabeledTips label={'Linked'} title={'Stato (Linked) '} content={linkedContent}/>
);

export const LinkedDomainsTips = () => (
  <LabeledTips label={'Linked Domains'} title={'Domini associati'} content={linkedDomainsContent}/>
);

export const AddDocumentTips = () => (
    <LabeledTips  title={'Associa documenti'} content={addDocumentContent}/>
);


export const LastUpdateRegistrantTips = () => (
    <LabeledTips  title={'Nota su lastupdate'} content={lastUpdateRegistrantTips}/>
)

// *********************
// *                   *
// *    CONTENTUTI     *
// *                   *
// *********************

const regCodeContent =
  <Paragraph style={{ maxWidth: 300 }}>
    Codice numerico che identifica il registrante.
    <ul>
      <li>
        Se <Text strong>EntityType = 1</Text>
        <Paragraph>
          ed il Registrante è una
          persona fisica italiana,
          contiene il codice fiscale;
          nel caso di persone fisiche
          straniere può contenere il
          numero del documento di
          identità.
        </Paragraph>
      </li>
      <li>
        Se <Text strong>EntityType = 4</Text>
        <Paragraph>
          ed il Registrante è una
          associazione priva di partita
          IVA e di codice fiscale
          numerico, contiene il valore
          “n.a.”.
        </Paragraph>

      </li>
      <li>
        Se <Text strong>EntityType = 7</Text>
        <Paragraph>
          contiene un tax code
          straniero.
        </Paragraph>
      </li>
      <li>
        In tutti gli altri casi, contiene
        la partita IVA o il codice
        fiscale numerico
      </li>
    </ul>
  </Paragraph>;


const regSchoolCodeContent =
  <Paragraph style={{ maxWidth: 300 }}>
    <NotIndexedField/>
    <Paragraph>
      Codice alfanumerico relativo ad una scuola.
    </Paragraph>
    <Paragraph>
      <Text type={'warning'} strong>Nota:</Text> ogni scuola viene in realtà identificata da una
      coppia <Text strong>schoolCode</Text> e <Text strong>regCode</Text>. Impiegando anche il secondo parametro
      (indicizzato) le ricerche saranno più veloci.
    </Paragraph>
  </Paragraph>;


const data = [
  {
    key: 1,
    name: 'ok',
    description: 'Nome a dominio registrato, attivo è disponibile per qualsiasi operazione.',
  },
  {
    key: 2,
    name: 'inactive/dnsHold',
    description: 'Nome a dominio registrato ma non attivo e disponibile per qualsiasi operazione. ' +
      'Questo stato identifica i nomi a dominio per i quali il controllo periodico di configurazione del DNS, ' +
      'effettuato dal Registro, non ha esito positivo.',
  },
  {
    key: 3,
    name: 'pendingUpdate',
    description: 'Nome a dominio per il quale è stata richiesta una modifica dei nameserver autoritativi o dei record.\n' +
      'Delegation Signer (DS) e in attesa di un controllo di configurazione del DNS con esito positivo. Il nome a dominio rimane in tale stato per un periodo massimo di 5 (cinque) giorni. Se entro tale termine la nuova configurazione del DNS non viene validata dal Registro con esito positivo, essa viene abbandonata. Il nome a dominio ritorna nello stato precedente',
  },
  {
    key: 4,
    name: 'inactive/clientHold ',
    description: 'Nome a dominio per il quale il Registrar ha sospeso l’operatività e inibito qualsiasi operazione, a seguito dell’apertura di un provvedimento giudiziario sul nome a dominio relativo all’uso e/o all’assegnazione dello stesso. Unica operazione consentita: rimozione del “clientHold” da parte del Registrar.',
  },
  {
    key: 5,
    name: 'pendingDelete/redemptionPeriod ',
    description: '..',
  },
  {
    key: 6,
    name: 'pendingTransfer ',
    description: '..',
  },
  {
    key: 7,
    name: 'pendingTransfer/bulk',
    description: '..',
  },
  {
    key: 8,
    name: 'autoRenewPeriod',
    description: '..',
  },
  {
    key: 9,
    name: '',
    description: '..',
  },
  {
    key: 10,
    name: '',
    description: '..',
  },
  {
    key: 11,
    name: '',
    description: '..',
  },
  {
    key: 12,
    name: '',
    description: '..',
  },
];

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text: string) => <Text strong>{text}</Text>,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },

];

const statusContent =
  <Paragraph style={{ maxWidth: 1200 }}>
    <Paragraph>
      Raccolta dei possibili stati che possono caratterizare un dominio utilizzando il concetto di <Text
      strong>“multistato”</Text> di un nome a dominio.
    </Paragraph>
    <Paragraph>
      Significa che, in ogni istante del ciclo di vita di un nome a dominio, ad esso può essere
      associato non un unico stato ma una combinazione di stati che ne determinano sia la sua
      situazione corrente che i vincoli imposti dal Registrar o dal Registro. Tali vincoli disciplinano
      le operazioni cui il dominio stesso può essere sottoposto.
    </Paragraph>
    <Paragraph>
      E' possibile quindi effettuare una selezione multipla dei stati che in combinazione si vogliono ricercare.
    </Paragraph>
    <Paragraph>
      <Text type={'warning'} strong>Nota:</Text> con il simbolo <Text strong>not</Text> davanti al campo
      si vuole indicare il concetto <Text strong>diverso da</Text>.
    </Paragraph>
    <Paragraph>
      Sfruttando questa capacità sarà possibile ad esempio eseguire
      ricerche del tipo <Tag>pendingDelete</Tag><Tag><Text strong>not </Text>redemptionPeriod</Tag> così da
      intercettare tutti gli stati <Text code>pendingDelete/pendingDelete</Text> escludendo tutti quelli
      <Text code>pendigDelete/redemptionPeriod</Text>.
    </Paragraph>
    <Paragraph>
      <Table columns={columns} dataSource={data} size={'small'}/>
    </Paragraph>
  </Paragraph>;

const stateOrProvinceContent =
  <Paragraph style={{ maxWidth: 300 }}>
    <Paragraph>
      Provincia del contatto.
    </Paragraph>
    <Paragraph>
      Se <Text strong>Country = IT</Text>, quindi il contatto è italiano, la provincia va selezionata tre quelle
      italiane proposte.
    </Paragraph>
    <Paragraph>
      Al contrario la provincia andrà indicata manualmente attraverso il campo apposito.
    </Paragraph>
  </Paragraph>;

const linkedContent =
  <Paragraph style={{ maxWidth: 300 }}>
    <Paragraph>
      Si riferisce allo stato <Text code>linked</Text> del contatto ed indica che il contatto è associato o meno
      ad almeno un dominio.
    </Paragraph>
    <Paragraph>
      Il parametro linked nelle ricerche dei contatti permette di determinare quali contatti vogliamo ottenere nei
      risultati basandosi sul fatto che siano o meno associati ai domini. I valori ammissibili sono:
    </Paragraph>
    <Paragraph>
      <ul>
        <li><Text strong>Registrant</Text>
          <Paragraph>
            Riporta i soli contatti referenziati come registrante in almeno un dominio
          </Paragraph>
        </li>
        <li><Text strong>Admin</Text>
          <Paragraph>
            Riporta i soli contatti referenziati come admin in almeno un dominio
          </Paragraph>
        </li>
        <li><Text strong>Tech</Text>
          <Paragraph>
            Riporta i soli contatti referenziati come tecnici in almeno un dominio
          </Paragraph>
        </li>
        <li><Text strong>Registrante o Contatto</Text>
          <Paragraph>
            Riporta i soli contatti (di uno qualsiasi dei tre tipi Registrant, Admin e Tech) referenziati in
            almeno un dominio
          </Paragraph>
        </li>
        <li><Text strong>non collegati</Text>
          <Paragraph>
            Riporta i soli contatti non referenziati in alcun dominio
          </Paragraph>
        </li>

      </ul>
    </Paragraph>
  </Paragraph>;


const linkedDomainsContent =
  <Paragraph style={{ maxWidth: 300 }}>
    <Paragraph>
      Si riferisce allo stato <Text code>linked</Text> del contatto ed indica che il contatto è associato o meno
      ad almeno un dominio.
    </Paragraph>
    <Paragraph>
      Il parametro linked nelle ricerche dei contatti permette di determinare qual è il numero dei domini
      associati
      al contatto. Le suddivisione avviene per :
    </Paragraph>
    <Paragraph>
      <ul>
        <li><Text strong>Registrant</Text>
          <Paragraph>
            Riporta i domini in cui il contatto e associato come registrante
          </Paragraph>
        </li>
        <li><Text strong>Admin</Text>
          <Paragraph>
            Riporta i domini in cui il contatto e associato come amministratore
          </Paragraph>
        </li>
        <li><Text strong>Tech</Text>
          <Paragraph>
            Riporta i domini in cui il contatto e associato come contatto tecnico
          </Paragraph>
        </li>
        <li><Text strong><FontAwesomeIcon icon={faLink}/></Text>
          <Paragraph>
            Riporta lo stato <Text code>linked</Text> del contatto, quindi <Text code>true</Text>
            <FontAwesomeIcon icon={faCheck} style={{ color: '#52c41a' }}/> e
            <Text code> false</Text> <FontAwesomeIcon icon={faCheck} style={{ color: 'rgba(0, 0, 0, 0.25)' }}/>
          </Paragraph>
        </li>
      </ul>
      <Paragraph>Cliccando in corrispondenza del valore calcolato per ogni categoria è possibile vedere tutti i
        domini associati al contatto.</Paragraph>
    </Paragraph>
    <Paragraph>
      <Note/> Quando per tutte e tre le categorie si visualizza il simbolo <Text code>-</Text> significa che
      il contatto <Text strong> non ha domini collegati</Text>.
    </Paragraph>
  </Paragraph>;


  export const addDocumentContent =
      <Paragraph  style={{ maxWidth: 400 }}>
        Tramite una form viene data la possibilità di creare nuovi documenti o associarne uno esistente.
        <Paragraph>
        <Text strong>Modalità</Text>
        <ul>
          <li>
            <Text strong>documento esistente e dati coincidenti</Text>
            <Paragraph>
              se il documento è già presente nel database (si verifica sulla base del faxId o del protocollo) e coincide con i dati del documento che si vuole creare, non verrà creato un nuovo documento ma verrà associato il documento esistente
            </Paragraph>
          </li>
          <li>
            <Text strong>documento esistente dati non coincidenti</Text>
            <Paragraph>
              se il documento esistente ha dati non coincidenti, l'associazione non avverrà
            </Paragraph>
          </li>
          <li>
            <Text strong>documento non esistente</Text>
            <Paragraph>
              se il documento non esiste viene creato un nuovo documento e associato
            </Paragraph>
          </li>
        </ul>
      </Paragraph>
      </Paragraph>


export const lastUpdateRegistrantTips =
    <Paragraph style={{ maxWidth: 300 ,zIndex:2000}}>
      <Paragraph>
        Il campo <i>lastupdate</i> quando visualizzato all'interno di una revisione potrebbe mostrare una data non propriamente corretta.
      </Paragraph>
      <Paragraph>
        Il  valore di <i>ultima modifica</i> quanto relativo ad un revisione va così interpretato, per date:
        <ul>
          <li><Text strong>successive al 26 gennaio 2021</Text> il campo è affidabile</li>
          <li><Text strong>antecedenti al 26 gennaio 2021</Text> se il dato di ultima modifica del contatto non è presente nel database allora si intende valida la data associata alla revisione ma questa assunzione potrebbe non essere veritiera.</li>
        </ul>
      </Paragraph>
    </Paragraph>
