import * as React from "react";
import { MenuProps } from "antd/lib/menu";
import { Avatar, Button, Icon, Menu, Spin, Tag } from "antd";
import { IUserInfo } from "../../../Type/IUserInfo";
import { AuthContext } from "../../Auth/AuthProvider";
import styled from "styled-components";
import { getRealmRoles, getResourceAccessRoles, rolePermissions } from "../../Ability/config";
import { BugModal } from "../../Feedback/BugModal/BugModal";
import { useMediaQuery } from "../../Hooks/useMediaQuery";
import { getInitials } from "../../../../utils/various";
import ChangelogModal from "../../Feedback/ChangeLogModal/ChangeLogModal";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const DivItemMenuRoles = styled.div`
    max-width: 200px!important;
    padding: 5px!important;
    display: flex!important;
    flex-wrap: wrap!important;
`;

/***
 * Componente Avatar + User name usato per visualizzare il nome utente assiem all'avatar nell'intestazione del menù
 * @constructor
 * @param props
 */
function AvatarUsername(props: { name?: string }) {
  const { name } = props;
  const isPageWide = useMediaQuery("(min-width: 1560px)");
  return (
    <>
      <Avatar>{isPageWide ? <Icon type="user" style={{ marginRight: 0 }} /> : name && getInitials(name)}</Avatar>
      {isPageWide && <span style={{ marginLeft: "10px" }}>{name ? name : "-"}</span>}
    </>
  );
}


/***
 * Menu utente da aggiungere nella barra dei menu
 * Mostra :
 * - Avatar + nome utente
 * - Ruoli e permessi reame
 * - Altro , visualizzazione changelog
 * - Logout
 *
 * @param props
 * @constructor
 */
export const UserMenu: React.FC<MenuProps> = props => {


  const { keycloak } = React.useContext(AuthContext);
  const { tokenParsed } = keycloak;
  const userInfo: IUserInfo = tokenParsed;

  const midaRealmRoles = getRealmRoles(userInfo).filter((role: string) => typeof rolePermissions[role.toUpperCase()] === "function");
  const midaResourceAccess = getResourceAccessRoles(userInfo).filter((role: string) => typeof rolePermissions[role.toUpperCase()] === "function");


  // const handleChangeglog = () => {
  //   localStorage.setItem("changelog", "");
  //   if (location.pathname === "/") {
  //     history.push("/changelog");
  //   } else {
  //     history.push("/");
  //   }
  // };


  return (
    <SubMenu {...props} title={
      !userInfo ? <Spin /> : <AvatarUsername name={userInfo.name} />
    }>
      <MenuItemGroup title="Ruoli reame">
        <Menu.Item key="realmRoles" style={{ height: "auto" }}>
          <DivItemMenuRoles key={"roles"}>
            {midaRealmRoles && midaRealmRoles.map((role) => (
              <Tag key={role} color={(role.includes("-admin") ? "orange" : "")}>{role}</Tag>))}
          </DivItemMenuRoles>
        </Menu.Item>
      </MenuItemGroup>
      {
        midaResourceAccess && midaResourceAccess.length > 0 &&
        <MenuItemGroup title="Permessi aggiuntivi">
          <Menu.Item key="additionalPermitions" style={{ height: "auto" }}>
            <DivItemMenuRoles key={"permissions"}>
              {midaResourceAccess && midaResourceAccess.map((role) => <Tag key={role}>{role}</Tag>)}
            </DivItemMenuRoles>
          </Menu.Item>
        </MenuItemGroup>
      }
      <MenuItemGroup title={"Altro"}>
        <Menu.Item key="changelog" >
          <Icon type="pull-request" />
          <ChangelogModal label='Visualizza changelog'/>
        </Menu.Item>
        <Menu.Item key="bug" onClick={BugModal}>
          <Icon type="bug" />
          Segnala (bug,altro..)
        </Menu.Item>

      </MenuItemGroup>
      <MenuItemGroup>
        <Menu.Item key="logout" style={{ borderTop: "1px solid #d9d9d9" }} >
          {/*<Link to="/logout">*/}
            <Button type={"link"} onClick={()=>keycloak.logout()}><Icon type="logout" />
              Esci</Button>
          {/*</Link>*/}

        </Menu.Item>
      </MenuItemGroup>
    </SubMenu>
  );
};
export default UserMenu;