import * as React from 'react';
import { Col, Form, Input, InputNumber, Row, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { CommonDocumentForm } from './CommonDocumentForm';
import { GenericDocument } from '../documents';

export interface IProtocolForm {
  items?: GenericDocument;
  onChangeProtocolNumberOrYear?: (
    protNumber?: number,
    protYear?: number,
  ) => void;
  loading?: boolean;
  error?: any;
}

/**
 * Form del Protocollo
 * Permette la definizione di un protocollo con tutti i campi previsti nell'API.
 * @param props
 * @constructor
 */
export const ProtocolForm: React.FC<IProtocolForm &
  FormComponentProps> = props => {
  const { getFieldDecorator, getFieldValue } = props.form;

  const [stateProtNum, setStateProtNum] = React.useState<number | undefined>(
    getFieldValue('protNum'),
  );
  const [stateProtYear, setStateProtYear] = React.useState<number | undefined>(
    getFieldValue('protYear'),
  );

  /*
   *
   * La modifica del numero protocollo e anno scatenano grazie alla gestione degli onChange
   * la chiamata all'api che ritorna un eventuale protocollo. Questo però avviene ogni
   * qual volta viene modificato un numero. Non è perciò ottimizzata ne ideale.
   * Per come è implementato ora, visto che si usano i vari hooks non è facile utilizzare il debounce come al solito
   * perchè non ha effetto.
   *
   * Qui c'è una soluzione che risolve il problema utilizzando callback e memo che sembra sia il modo migliore
   * https://www.developerway.com/posts/debouncing-in-react
   *
   * */

  const handleOnChangeProtocolNumber = (v?: number) => {
    props.onChangeProtocolNumberOrYear?.(v, stateProtYear);
    setStateProtNum(v);
  };

  const handleOnChangeProtocolYear = (v?: number) => {
    props.onChangeProtocolNumberOrYear?.(stateProtNum, v);
    setStateProtYear(v);
  };

  return (
    <div>
      {props.loading && (
        <div
          style={
            {
              // padding: 10,
              // zIndex: 10,
              // textAlign: 'center',
              // verticalAlign: 'middle',
              // position: 'absolute',
              // width: '95%',
              // height: '100%',
              // background: 'rgba(0, 0, 0, 0.05)',
            }
          }
        >
          <div
            style={{
              position: 'fixed',
              zIndex: 1031 /* High z-index so it is on top of the page */,
              marginLeft: 280,
              marginTop: 175,
            }}
          >
            <Spin />
            <span
              style={{ marginLeft: 5 }}
            >{`Ricerca protocollo esistente....`}</span>
          </div>
        </div>
      )}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Numero protocollo">
            {getFieldDecorator('protNum', {
              rules: [
                {
                  required: true,
                  message: 'Please input a ProtNum!',
                },
              ],
            })(
              <InputNumber
                style={{ width: '200px' }}
                maxLength={8}
                placeholder={'Numero del protocollo'}
                onChange={handleOnChangeProtocolNumber}
              />,
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Anno protocollo">
            {getFieldDecorator('protYear', {
              rules: [
                {
                  required: true,
                  message: 'Please input a ProtYear!',
                },
              ],
            })(
              <InputNumber
                style={{ width: '150px' }}
                min={2000}
                maxLength={4}
                placeholder={'Anno dal 2019'}
                onChange={handleOnChangeProtocolYear}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <CommonDocumentForm {...props} />
      <Row>
        <Col>
          <Form.Item label="Organizzazione">
            {getFieldDecorator('org', {
              rules: [
                {
                  required: false,
                  message: 'Please input a valid Organization!',
                },
              ],
            })(<Input placeholder={"ccTLD '.it' Registry - IIT/CNR"} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item label="Nome a dominio">
            {getFieldDecorator('domain', {
              rules: [
                {
                  required: false,
                  message: 'Please input a domain name',
                },
              ],
            })(<Input placeholder={'nic.it'} />)}
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item label="Registrar">
            {getFieldDecorator('registrar', {
              rules: [
                {
                  required: false,
                  message: 'Please input a valid Registrar!',
                },
              ],
            })(<Input placeholder={'REGISTER-REG'} />)}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
