import {IFetchStateType} from "../../../Type/IFetchtStateType";
import {IDetailedRevision} from "../../../Common/Navigation/Revisions/revisions";
import {apiPatchedGetDomainRevision} from "../../../../const/api-calls";

/***
 * Funzione per il recupero del dettaglio dominio relativi alla query passata.
 *
 * @param dispatch
 * @param domainId
 * @param revisionNumber
 * @param revision
 * @param query - queryString corrente
 * @param keycloak
 */
export function fetchDomainRevision(dispatch: any, domainId: string, revisionNumber: string, revision: IFetchStateType<IDetailedRevision>, query: string) {

    if (query === '') {
        dispatch({type: 'DOMAIN_REVISION_FETCH_CLEAN'})
    } else {
        // Se query memorizzato è diverso da quello cercato e non ci sono errori allora fetchDomains

        dispatch({type: 'DOMAIN_REVISION_FETCH_LOAD', query})

        // apiGetDomainRevision(domainId, revisionNumber, keycloak).then((res: any) => {
        //     dispatch({type: 'DOMAIN_REVISION_FETCH_SUCCESS', payload: res})
        // }).catch((error: any | PromiseLike<IDetailedRevision>) => {
        //     dispatch({type: 'DOMAIN_REVISION_FETCH_ERROR', error})
        // })

        apiPatchedGetDomainRevision(domainId, revisionNumber).then((res: any) => {
            dispatch({type: 'DOMAIN_REVISION_FETCH_SUCCESS', payload: res})
        }).catch((error: any | PromiseLike<IDetailedRevision>) => {
            dispatch({type: 'DOMAIN_REVISION_FETCH_ERROR', error})
        })
    }
}