import {
  ALERT_SIZE,
  EX_DATE_ALERT,
  EX_DATE_EXPIRE,
  EX_DATE_EXPIRED,
  EX_DATE_INFO,
  EXPIRED_SIZE,
  EXPIRING_SIZE,
  INFO_SIZE,
} from './const';

export function getExDateDetail(date: Date): { days: number; type: string } {
  const days: number = getDaysBetweenDates(date, new Date());
  // const a: Date = new Date();
  // console.log('days ', days, date.getDay(), a.getDay());
  const type = getDateType(days);
  return { days, type };
}

// Ritorna i giorni trascorsi tra due date
export function getDaysBetweenDates(d0: Date, d1: Date) {
  // Get 1 day in milliseconds
  const msPerDay = 8.64e7;

  // Convert both dates to milliseconds
  const x0: Date = new Date(d0);
  const x1: Date = new Date(d1);

  // Set to noon - avoid DST errors
  d0.setHours(12, 0, 0);
  d1.setHours(12, 0, 0);

  // Round to remove daylight saving errors
  return Math.round((x1.getTime() - x0.getTime()) / msPerDay);
}

function getDateType(days: number): string {
  if (days >= EXPIRED_SIZE) {
    return EX_DATE_EXPIRED;
  } else if (days >= EXPIRING_SIZE) {
    return EX_DATE_EXPIRE;
  } else if (days >= ALERT_SIZE) {
    return EX_DATE_ALERT;
  } else if (days >= INFO_SIZE) {
    return EX_DATE_INFO;
  }
  return '';
}

