import * as React from 'react';
import { Button, Divider, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { GenericDocument } from '../documents';
import { YMDHMS } from '../../../const/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { ProtocolFormHandler } from '../ProtocolFormHandler';

export const AssociateDocumentProtocolForm: React.FC<ICreateDocument &
  FormComponentProps> = props => {
  const { validateFieldsAndScroll } = props.form;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    validateFieldsAndScroll((err: any, fieldsValue: any) => {
      if (!err) {
        const values = {
          ...fieldsValue,
          dateArrive: fieldsValue['dateArrive'].format(YMDHMS),
        };
        props.onSubmit(values);
      }
    });
  };

  const handleOnChangeProt = (protNumber?: number, protYear?: number) => {
    if (protNumber && protYear) {
      console.log('handleOnChangeProt', protNumber, protYear);
      props.onFindProtocol?.(protNumber, protYear);
    }
  };

  const protocol: GenericDocument | undefined = {
    documentType: 'protocol',
    dateArrive: '1.1.1',
    protNum: 1111,
    protYear: 2222,
    id: 11111,
    numpag: 1,
  };

  return (
    <>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <>
          <Divider type={'horizontal'}>
            <FontAwesomeIcon icon={faPaperclip} /> Protocollo
          </Divider>
          <ProtocolFormHandler
            {...props}
            items={protocol}
            onChangeProtocolNumberOrYear={(_number, _year) =>
              handleOnChangeProt(_number, _year)
            }
          />
        </>

        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button style={{ marginRight: 8 }} onClick={props.onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Associa
          </Button>
        </div>
      </Form>
    </>
  );
};

export const WrappedAssociateDocumentProtocolForm = Form.create<
  ICreateDocument & FormComponentProps
>({ name: 'genericDocument' })(AssociateDocumentProtocolForm);

export interface ICreateDocument {
  items?: GenericDocument;
  onSubmit: (values: GenericDocument) => void;
  onCancel: () => void;
  onFindProtocol?: (protNum: number, protYear: number) => void;
}
