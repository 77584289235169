import * as React from 'react';
import Table from './Table';
import { IDomainEvents } from './types/domainEvents';
import { Paginator } from '../../Common/Navigation/Paginator/Paginator';
import { useQuery } from '../../../utils/various';
import { useHistory, useLocation } from 'react-router-dom';
import { FiltersBarPane } from '../../Common/Navigation/FiltersBarPane/FiltersBarPane';
import {
  addFilter,
  decodeQuerySort,
  sortResult,
} from '../../../utils/url-manipulation';
import { StateContext } from '../../StoreProvider';
import { EventSelectorPane } from './EventSelector';
import { Flex } from '../../Common/Layout/Flex/Flex';

const PaginatedTable: React.FC<IDomainEvents & {
  refRevisionDate?: string;
}> = props => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const { domainRevisions } = React.useContext(StateContext);

  const handleOnSort = (a: any, b: any, d: any) => {
    sortResult(d, query, history, location);
  };

  const handleAddFilter = (filter: any, values: any) => {
    addFilter(filter, values, query, history, location);
  };

  return (
    <React.Fragment>
      <Flex
        container
        flexDirection={'row'}
        alignItems={'stretch'}
        style={{ gap: 5 }}
        justifyContent={'space-between'}
      >
        <FiltersBarPane
          urlPrefix={location.pathname}
          style={{ marginBottom: 10, width: '100%' }}
        />
        <EventSelectorPane onAddFilter={handleAddFilter} />
      </Flex>
      <Table
        domainEvents={props.elements}
        domainRevisions={domainRevisions.response?.data}
        refRevisionDate={props.refRevisionDate}
        sortColumnKey={decodeQuerySort(query.sort).columnKey}
        sortOrder={decodeQuerySort(query.sort).order}
        onChange={handleOnSort}
        onAddFilter={handleAddFilter}
      />
      <Paginator page={props.page} />
    </React.Fragment>
  );
};

export default PaginatedTable;
