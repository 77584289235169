import * as React from 'react';
import { IDomainHost, IDomainHostAddress } from '../../types/domain';
import { TableWrapped } from '../../../../Common/Styled/TableWrapped';
import { DetailContent } from '../../../../Common/Styled/Detail';
import { eqString } from '../../../../Common/Navigation/Revisions/DetailCard/DetailCardBox';

const NameServerDiffTable: React.FC<{
  hostsLeft: IDomainHost[];
  hostsRight: IDomainHost[];
}> = ({ hostsLeft, hostsRight }) => {
  const printIpv4 = (value: any) => {
    if (value && value.length > 0) {
      const ipv4 = value.filter((val: any) => val.type === 'v4');
      if (ipv4.length > 0) {
        return ipv4[0].address;
      }
    }
    return '-';
  };

  const printIpv6 = (value: any) => {
    if (value && value.length > 0) {
      const ipv6 = value.filter((val: any) => val.type === 'v6');
      if (ipv6.length > 0) {
        return ipv6[0].address;
      }
    }
    return '-';
  };

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Nome',
      align: 'center' as 'center',
      render: (value: any, record: any, index: number) => {
        if (
          hostsLeft[index] &&
          hostsRight[index] &&
          hostsLeft[index]['name'] &&
          hostsRight[index]['name']
        ) {
          const isDifferent = !eqString(
            hostsLeft[index]['name'],
            hostsRight[index]['name'],
          );
          return (
            <DetailContent isDifferent={isDifferent}>
              {value ? value : '-'}
            </DetailContent>
          );
        }
        return (
          <DetailContent isDifferent={true}>
            {value ? value : '-'}
          </DetailContent>
        );
      },
    },
    {
      title: 'Indirizzo IPv4',
      dataIndex: 'addresses',
      align: 'center' as 'center',
      key: 'ip4',
      render: (value: IDomainHostAddress[], record: any, index: number) => {
        if (hostsLeft[index] && hostsRight[index]) {
          if (hostsLeft[index].addresses && hostsRight[index].addresses) {
            const isDifferent = !eqString(
              printIpv4(hostsLeft[index].addresses),
              printIpv4(hostsRight[index].addresses),
            );
            return (
              <DetailContent isDifferent={isDifferent}>
                {printIpv4(value)}
              </DetailContent>
            );
          }
        } else {
          return <DetailContent isDifferent>{printIpv4(value)}</DetailContent>;
        }
        return '-';
      },
    },
    {
      title: 'Indirizzo IPv6',
      dataIndex: 'addresses',
      align: 'center' as 'center',
      key: 'ip6',
      render: (value: IDomainHostAddress[], record: any, index: number) => {
        if (hostsLeft[index] && hostsRight[index]) {
          if (hostsLeft[index].addresses && hostsRight[index].addresses) {
            const isDifferent = !eqString(
              printIpv6(hostsLeft[index].addresses),
              printIpv6(hostsRight[index].addresses),
            );
            return (
              <DetailContent isDifferent={isDifferent}>
                <span style={{ wordBreak: 'break-all' }}>
                  {printIpv6(value)}
                </span>
              </DetailContent>
            );
          }
        } else {
          return (
            <DetailContent isDifferent>
              <span style={{ wordBreak: 'break-all' }}>{printIpv4(value)}</span>
            </DetailContent>
          );
        }
        return '-';
      },
    },
  ];

  return (
    <TableWrapped
      dataSource={hostsLeft}
      columns={columns}
      rowKey="name"
      pagination={false}
      size={'small'}
      bordered={true}
    />
  );
};

export default NameServerDiffTable;
