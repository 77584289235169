import {AxiosResponse, AxiosError} from "axios";
import {IFetchStateType} from "../../Type/IFetchtStateType";
import {IDomainNotes} from "./types";

interface IDomainNoteActionType {
    type: 'DOMAIN_NOTES_FETCH_SUCCESS' | 'DOMAIN_NOTES_FETCH_ERROR' | 'DOMAIN_NOTES_FETCH_LOAD' | 'DOMAIN_NOTES_FETCH_CLEAN' | 'DOMAIN_NOTES_OPEN' | 'DOMAIN_NOTES_CLOSE';
    payload?: AxiosResponse;
    error?: AxiosError;
    query?: any;
}

export interface ICustomFetchStateType<T, K> extends IFetchStateType<T> {
    extra: K;
}

export const initialState: ICustomFetchStateType<IDomainNotes, { showDrawer: boolean }> = {
    loading: false,
    query: '',
    error: undefined,
    response: undefined,
    extra: {showDrawer: false}
}

export const reducer = (state: IFetchStateType<IDomainNotes>, action: IDomainNoteActionType) => {
    switch (action.type) {
        case "DOMAIN_NOTES_FETCH_LOAD":
            return {...state,loading: true, error: undefined, query: action.query, response: undefined}
        case "DOMAIN_NOTES_FETCH_SUCCESS":
            return {...state, loading: false, error: undefined, response: action.payload}
        case "DOMAIN_NOTES_FETCH_ERROR":
            return {...state, loading: false, error: action.error}
        case "DOMAIN_NOTES_FETCH_CLEAN":
            return {...state,loading: false, error: undefined, query: '', response: undefined}
        case "DOMAIN_NOTES_OPEN":
            return {...state, extra: {showDrawer: true}};
        case "DOMAIN_NOTES_CLOSE":
            return {...state, extra: {showDrawer: false}};
        default:
            return state;
    }
};


export default reducer;