import * as React from "react";
import {Tag, Icon, Divider, Popover, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";


const FilterIcon = (props: any) => <FontAwesomeIcon
    icon={faSearch}
    color={'grey'}
    size={'xs'}
    {...props}
/>

export const ContactFilter: React.FC<{  title?: string, url: string, content?: React.ReactNode, color?: string }> = props => (
    <Tooltip title={props.title ? props.title : 'Ricerca per contatti'}>
        <Tag color={props.color ? props.color : 'green'} style={{cursor: "pointer"}}>
            <Link to={encodeURI(props.url)}>
                <strong>
                    <Icon type='user'/>
                    <Divider type={'vertical'}/>
                </strong> {props.content || 'Contatti'}
                <FilterIcon style={{cursor: "pointer", display: "inline", marginLeft: 5}}/>
            </Link>
        </Tag>
    </Tooltip>
);

/**
 * Controllo cont Tag per aggiungere un filtro che interviene nella ricerca domini
 *
 * @param title
 * @param url es: /domains?registrant.contacID=cctl1
 * @param content
 * @param color
 * @constructor
 */
export const DomainFilter: React.FC<{ title?: string, url: string, content?: React.ReactNode, color?: string }> = ({ title, url, content, color}) => (
    <Tooltip title={title ? title : 'Ricerca per domini'}>
        <Tag color={color ? color : 'blue'}>
            <Link to={encodeURI(url)}>
                <strong>
                    <Icon type='global'/>
                    <Divider type={'vertical'}/>
                </strong> {content || 'Domini'}
                <FilterIcon
                    style={{cursor: "pointer", display: "inline", marginLeft: 5}}
                />
            </Link>
        </Tag>
    </Tooltip>
);


export const FilterDivider: React.FC<{ children: React.ReactNode }> = props => (<span>
                <Divider type='vertical'/>
                <span style={{marginLeft: 5, marginRight: 5}}/>
    {props.children}
                </span>);


export const BasicPopoverFilter: React.FC<{ allowed: boolean, content: React.ReactNode }> = props => {
    return (props.allowed ? <>
        <Divider type='vertical'/>
        <Popover content={<div style={{textAlign: 'center'}}>{props.content}</div>}
                 title={<div style={{textAlign: 'center'}}>Esegui ricerca</div>}>
            <FilterIcon style={{cursor: "pointer", display: "inline", marginLeft: 10}}/>
        </Popover>
    </> : <></>)
};
