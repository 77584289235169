import {AxiosResponse, AxiosError} from "axios";
import {IFetchStateType} from "../../Type/IFetchtStateType";
import {IRevisions} from "../../Common/Navigation/Revisions/revisions";

interface IRevisionActionType {
    type: 'CONTACT_REVISIONS_FETCH_SUCCESS' | 'CONTACT_REVISIONS_FETCH_ERROR' | 'CONTACT_REVISIONS_FETCH_LOAD' | 'CONTACT_REVISIONS_FETCH_CLEAN' | 'CONTACT_REVISIONS_OPEN' | 'CONTACT_REVISIONS_CLOSE';
    payload?: AxiosResponse;
    error?: AxiosError;
    query?: any;
}


export const initialState: IFetchStateType<IRevisions> = {
    loading: false,
    query: '',
    error: undefined,
    response: undefined,
}

export const reducer = (state: IFetchStateType<IRevisions>, action: IRevisionActionType) => {
    switch (action.type) {
        case "CONTACT_REVISIONS_FETCH_LOAD":
            return {...state, loading: true, error: undefined, query: action.query, response: undefined}
        case "CONTACT_REVISIONS_FETCH_SUCCESS":
            return {...state, loading: false, error: undefined, query: action.query, response: action.payload}
        case "CONTACT_REVISIONS_FETCH_ERROR":
            return {...state, loading: false, error: action.error}
        case "CONTACT_REVISIONS_FETCH_CLEAN":
            return {...state, loading: false, error: undefined, query: '', response: undefined}
        default:
            return state;
    }
};

export default reducer;