import * as React from "react";
import {ParsedQuery} from "query-string";
import {IFetchStateType} from "../../../../Type/IFetchtStateType";
import {StyledHomeBox} from "../../../../Pages/Home/SearchBar/SearchBarBox";
import {Input, Typography} from "antd";
import {AbilityContext} from "../../../../Common/Ability/AbilityContext";
import {useHistory} from "react-router-dom";
import {DispatchContext} from "../../../../StoreProvider";
import {routeSearchTo, setSearchType} from "../../../../Pages/Home/SearchBar/SearchBarForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IContacts} from "../../../types/contacts";
import {faUser} from "@fortawesome/free-solid-svg-icons";

export const SearchPane: React.FC<{ query: ParsedQuery<React.ReactText>, domains: IFetchStateType<IContacts> }> = ({query, domains}) => (
    <><div style={{textAlign: 'center'}}>
        <Typography.Title level={2}>Ricerca contatto</Typography.Title>
    </div>
    <StyledHomeBox data-testid="styledHomeBox" size={'small'} style={{textAlign: 'center'}}>
        <ContactSingleSearchBar loading={domains.loading} contactId={query ? query.toString() : undefined}/>
    </StyledHomeBox>
    </>
)


/**
 * Barra di ricerca per nome del dominio
 * @param domainName
 * @param loading
 * @constructor
 */
const ContactSingleSearchBar: React.FC<{ contactId?: string, loading?: boolean }> = ({contactId, loading}) => {
    const {Search} = Input;
    const {ability} = React.useContext(AbilityContext);

    const history = useHistory();
    const dispatch = React.useContext(DispatchContext);
    const [val, setVal] = React.useState(contactId);

    React.useEffect(()=>setVal(contactId),[contactId])

    const handleOnSearch = (value: string) => {
        if (value) {
            routeSearchTo(history, setSearchType(value, ability), value, dispatch)
        }
    }
    return <Search
        prefix={<FontAwesomeIcon icon={faUser} style={{color: 'rgba(0,0,0,0.25)'}}/>}
        placeholder="contactId (es. CCTL1)"
        onSearch={handleOnSearch}
        onChange={(v) => setVal(v.target.value)}
        style={{width: 300}}
        allowClear
        loading={loading}
        enterButton
        defaultValue={contactId}
        value={val}
    />
}