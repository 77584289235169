import { Card } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import { IViewerContentType } from '../Type/IViewerContentType';

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const GridItem = styled(Card)`
  width: 49.6% !important;
  min-width: 49.6% !important;
  margin-top: 20px !important;
`;

const GridView: React.FC<{ content: IViewerContentType[] }> = ({ content }) => {
  return (
    <React.Fragment>
      <GridContainer>
        {content.map((data: IViewerContentType, index: number) => (
          <GridItem key={index} title={data.label}>
            {data.component}
          </GridItem>
        ))}
      </GridContainer>
    </React.Fragment>
  );
};
export default GridView;
