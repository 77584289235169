import * as React from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { ParsedQuery } from 'query-string';
import { multipleMomentValues } from '../../../../../../utils/various';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLandmark,
  faUser,
  faUserGraduate,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import SelectEntityType from '../../../../../Common/Input/SelectEntityType/SelectEntityType';
import SelectCountry from '../../../../../Common/Input/SelectCountry/SelectCountry';
import SelectStateOrProvince from '../../../../../Common/Input/SelectStateOrProvince/SelectStateOrProvince';
import {
  LinkedTips,
  RegCodeTips,
  StateOrProvinceTips,
} from '../../../../../../const/TipsComponent';
import { YMDHMS } from '../../../../../../const/common';
import RegistrarLookUp from '../../../../../Registrar/LocaStorage/RegistrarLookUp';
import { SelectProps } from 'antd/lib/select';
import { transformNestedFieldsIntoNestedDotFields } from '../../../../../Domains/Search/utils';

const RangePicker = DatePicker.RangePicker;

const { Option } = Select;

// TODO finire di completare la customizzazione dei campi di ricerca e capire le regole e i validatori

interface IAdvancedSearchForm extends FormComponentProps {
  advanced?: boolean;
  values: ParsedQuery<React.ReactText>;
  onSubmit: (err: any, values: any) => void;
}

// esportare
type Keys = 'notEquals' | 'contains' | 'startsWith' | 'endsWith';
type Value = 'diverso' | 'contiene' | 'inizia' | 'finisce';

interface ICriteriaOptions<K, V> {
  notEquals?: 'diverso';
  contains?: 'contiene';
  startsWith?: 'inizia';
  endsWith?: 'finisce';
}

const AdvancedContactsSearchForm: React.FC<IAdvancedSearchForm> = props => {
  // const [expand, setExpand] = React.useState(!singleSearch);
  const [itemValues, setItemValues] = React.useState<
    ParsedQuery<React.ReactText> | undefined
  >({});

  React.useEffect(() => {
    setItemValues(props.values);
  }, [props.values]);

  // To generate mock Form.Item
  const getFields = () => {
    // const count = expand ? 10 : 1;
    const { getFieldDecorator } = props.form;
    const children = [];

    const allCriteria = (
      name: string,
      options: ICriteriaOptions<Keys, Value>,
      initialValue?: ParsedQuery<React.ReactText>,
      style?: any,
    ) =>
      getFieldDecorator(`${name}`, {
        initialValue: (initialValue && initialValue[`${name}`]) || '',
      })(
        <Select>
          <Option value="">esatto</Option>
          {Object.keys(options).map(key => (
            <Option key={key} value={key}>
              {options[key]}
            </Option>
          ))}
        </Select>,
      );

    // // ID
    // children.push(
    //   <Col
    //     xs={24}
    //     md={12}
    //     xl={8}
    //     key={'contactId'}
    //     style={{ display: 'block' }}
    //   >
    //     <Form.Item label={`ContactId`}>
    //       {getFieldDecorator(`contactId`, {
    //         initialValue: itemValues && itemValues.contactId,
    //
    //         rules: [
    //           {
    //             required: false,
    //             message: 'Input something!',
    //           },
    //         ],
    //       })(<Input placeholder="es. CCTL1" allowClear={true} />)}
    //     </Form.Item>
    //   </Col>,
    // );

    // NAME
    children.push(
      <Col xs={24} md={12} xl={8} key={'name'} style={{ display: 'block' }}>
        <Form.Item label={`Nome`}>
          {getFieldDecorator(`name-ft`, {
            initialValue: (itemValues && itemValues[`name-ft`]) || undefined,
            rules: [
              {
                required: false,
                message: 'Input something!',
              },
            ],
          })(<Input placeholder="es. Mario Rossi" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // Name Or Org
    children.push(
      <Col xs={24} md={12} xl={8} key={'org'} style={{ display: 'block' }}>
        <Form.Item label={`Org`}>
          {getFieldDecorator(`org-ft`, {
            initialValue: (itemValues && itemValues[`org-ft`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(
            <Input placeholder="es. Istituto Informatica" allowClear={true} />,
          )}
        </Form.Item>
      </Col>,
    );
    // Status
    children.push(
      <Col xs={24} md={12} xl={8} key={'status'} style={{ display: 'block' }}>
        <Form.Item label={'Stato'}>
          {getFieldDecorator(`status`, {
            initialValue: (itemValues && itemValues[`status`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<ContactSelectStatusMono form={props.form} />)}
        </Form.Item>
      </Col>,
    );
    // RegistrarId
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'RegistrarId'}
        style={{ display: 'block' }}
      >
        <Form.Item label={'Registrar'}>
          {getFieldDecorator(`registrarId`, {
            initialValue:
              itemValues &&
              itemValues[`registrarId.label`] &&
              itemValues[`registrarId.key`]
                ? {
                    key: itemValues[`registrarId.key`],
                    label: itemValues[`registrarId.label`],
                  }
                : undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(
            <RegistrarLookUp
              icon={'home'}
              {...props}
              allowClear
              placeholder={<React.Fragment>es. REGISTRY-REG</React.Fragment>}
            />,
          )}
        </Form.Item>
      </Col>,
    );

    // Linked
    children.push(
      <Col xs={24} md={12} xl={8} key={'linked'} style={{ display: 'block' }}>
        <Form.Item label={<LinkedTips />}>
          {getFieldDecorator(`linked`, {
            initialValue:
              itemValues &&
              itemValues[`linked.label`] &&
              itemValues[`linked.key`]
                ? {
                    key: itemValues[`linked.key`],
                    label: itemValues[`linked.label`],
                  }
                : undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(
            <Select
              {...props}
              showSearch
              allowClear
              labelInValue
              showArrow={true}
            >
              <Select.Option value={'any'}>
                come Registrant o Admin o Tech
              </Select.Option>
              <Select.Option value={'registrant'}>
                come Registrant
              </Select.Option>
              <Select.Option value={'admin'}>come Admin</Select.Option>
              <Select.Option value={'tech'}>come Tech</Select.Option>
              <Select.Option value={'unlinked'}>non collegato</Select.Option>
            </Select>,
          )}
        </Form.Item>
      </Col>,
    );

    // ContactType
    children.push(
      <Col xs={24} md={12} xl={8} key={'Type'} style={{ display: 'block' }}>
        <Form.Item label={`Tipologia`}>
          {getFieldDecorator(`type`, {
            initialValue: (itemValues && itemValues.type) || undefined,
            rules: [
              {
                required: false,
                message: 'Input something!',
              },
            ],
          })(
            <Select allowClear={true} placeholder={'Seleziona tipo contatto'}>
              <Select.Option value="registrant">
                <FontAwesomeIcon
                  icon={faUserTie}
                  style={{ color: 'rgba(0,0,0,.25)' }}
                />{' '}
                Registrante
              </Select.Option>
              <Select.Option value="contact">
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ color: 'rgba(0,0,0,.25)' }}
                />{' '}
                Contatto
              </Select.Option>
              <Select.Option value="edu">
                <FontAwesomeIcon
                  icon={faUserGraduate}
                  style={{ color: 'rgba(0,0,0,.25)' }}
                />{' '}
                Edu
              </Select.Option>
              <Select.Option value="gov">
                <FontAwesomeIcon
                  icon={faLandmark}
                  style={{ color: 'rgba(0,0,0,.25)' }}
                />{' '}
                Gov
              </Select.Option>
            </Select>,
          )}
        </Form.Item>
      </Col>,
    );

    // RegistrantType
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'RegistrantType'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`RegistrantType`}>
          {getFieldDecorator(`registrantType`, {
            initialValue:
              (itemValues && itemValues.registrantType) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(
            <SelectEntityType
              {...props}
              disabled={props.form.getFieldValue('type') !== 'registrant'}
            />,
          )}
        </Form.Item>
      </Col>,
    );

    // RegistrantRecCode
    children.push(
      <Col xs={24} md={12} xl={8} key={'Regcode'} style={{ display: 'block' }}>
        <Form.Item label={<RegCodeTips />}>
          {getFieldDecorator(`regcode`, {
            initialValue: (itemValues && itemValues[`regcode`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(
            <Input
              allowClear
              prefix={
                <FontAwesomeIcon
                  icon={faUserTie}
                  style={{ color: 'rgba(0,0,0,.25)' }}
                />
              }
              placeholder="es. 02118311006"
            />,
          )}
        </Form.Item>
      </Col>,
    );

    // SchoolCode
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'schoolCode'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`SchoolCode`}>
          {getFieldDecorator(`schoolCode-field`, {
            initialValue:
              (itemValues && itemValues[`schoolCode-field`]) || undefined,

            rules: [
              {
                required: false,
              },
            ],
          })(
            <Input
              addonBefore={allCriteria(
                'schoolCode-criteria',
                {
                  contains: 'contiene',
                  startsWith: 'inizia',
                },
                itemValues && itemValues,
              )}
              placeholder="es. PIIC83300V"
              allowClear={true}
            />,
          )}
        </Form.Item>
      </Col>,
    );
    // IPACode
    children.push(
      <Col xs={24} md={12} xl={8} key={'ipaCode'} style={{ display: 'block' }}>
        <Form.Item label={`IPACode`}>
          {getFieldDecorator(`ipaCode-field`, {
            initialValue:
              (itemValues && itemValues[`ipaCode-field`]) || undefined,

            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. ACI" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );
    // UOCode
    children.push(
      <Col xs={24} md={12} xl={8} key={'uoCode'} style={{ display: 'block' }}>
        <Form.Item label={`UOCode`}>
          {getFieldDecorator(`uoCode-field`, {
            initialValue:
              (itemValues && itemValues[`uoCode-field`]) || undefined,

            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. 0BAEAG" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );
    // email
    children.push(
      <Col xs={24} md={12} xl={8} key={'email'} style={{ display: 'block' }}>
        <Form.Item label={`E-mail`}>
          {getFieldDecorator(`email`, {
            initialValue: (itemValues && itemValues[`email`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. mario.rossi@nic.it" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // nationality
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantNationality'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`Nationality`}>
          {getFieldDecorator(`nationality`, {
            initialValue:
              (itemValues && itemValues[`nationality`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<SelectCountry {...props} placeholder="es. IT (Italy)" />)}
        </Form.Item>
      </Col>,
    );

    // Country
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantCountry'}
        style={{ display: 'block' }}
      >
        <Form.Item label={`Country`}>
          {getFieldDecorator(`country`, {
            initialValue: (itemValues && itemValues[`country`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<SelectCountry {...props} placeholder="es. IT (Italy)" />)}
        </Form.Item>
      </Col>,
    );

    // StateOrProvince
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'registrantState'}
        style={{ display: 'block' }}
      >
        <Form.Item label={<StateOrProvinceTips />}>
          {getFieldDecorator(`state`, {
            initialValue: (itemValues && itemValues[`state`]) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<SelectStateOrProvince />)}
        </Form.Item>
      </Col>,
    );

    // City
    children.push(
      <Col xs={24} md={12} xl={8} key={'city'} style={{ display: 'block' }}>
        <Form.Item label={`Città`}>
          {getFieldDecorator(`city`, {
            initialValue: (itemValues && itemValues.city) || undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<Input placeholder="es. Pisa" allowClear={true} />)}
        </Form.Item>
      </Col>,
    );

    // Created Range
    children.push(
      <Col xs={24} md={12} xl={8} key={'created'} style={{ display: 'block' }}>
        <Form.Item label={'Created'}>
          {getFieldDecorator(`created-range`, {
            initialValue:
              (itemValues &&
                multipleMomentValues(itemValues, 'created-range')) ||
              undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<RangePicker showToday />)}
        </Form.Item>
      </Col>,
    );

    // Last Update Range
    children.push(
      <Col
        xs={24}
        md={12}
        xl={8}
        key={'lastUpdate'}
        style={{ display: 'block' }}
      >
        <Form.Item label={'LastUpdate'}>
          {getFieldDecorator(`lastupdate-range`, {
            initialValue:
              (itemValues &&
                multipleMomentValues(itemValues, 'lastupdate-range')) ||
              undefined,
            rules: [
              {
                required: false,
              },
            ],
          })(<RangePicker style={{ width: '100%' }} showTime />)}
        </Form.Item>
      </Col>,
    );

    return children;
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    props.form.validateFields((err: any, values: any) => {
      // console.log('Received values of form: ', values);
      if (!err) {
        // Should format date value before submit.
        const createdRange = values['created-range'] || [];
        const lastupdateRange = values['lastupdate-range'] || [];
        let valuesToSubmit = {
          ...values,
          'created-field': values['created-field']
            ? values['created-field'].format(YMDHMS)
            : undefined,
          'lastupdate-field': values['lastupdate-field']
            ? values['lastupdate-field'].format(YMDHMS)
            : undefined,
          'created-range':
            createdRange && createdRange.length > 0
              ? [createdRange[0].format(YMDHMS), createdRange[1].format(YMDHMS)]
              : [],
          'lastupdate-range':
            lastupdateRange && lastupdateRange.length > 0
              ? [
                  lastupdateRange[0].format(YMDHMS),
                  lastupdateRange[1].format(YMDHMS),
                ]
              : [],
        };
        // console.log('*Received values of form*: ', valuesToSubmit);

        valuesToSubmit = transformNestedFieldsIntoNestedDotFields(
          valuesToSubmit,
        );

        props.onSubmit(err, valuesToSubmit);

        //
        // // sottomette tutti i valori diversi da undefined e non stringa vuota
        // props.onSubmit(err, pickBy(values, (lValue) => lValue !== undefined && lValue !== ''));
      }
    });
  };

  const handleClear = () => {
    props.form.resetFields();
  };

  const handleReset = () => {
    handleClear();
    setItemValues(undefined);
  };

  const buttons = () => (
    <div style={{ lineHeight: 3 }}>
      <Button type="primary" htmlType="submit">
        Search
      </Button>
      {/*<Button style={{marginLeft: 8}} onClick={handleClear}>*/}
      {/*Clear*/}
      {/*</Button>*/}
      <Button style={{ marginLeft: 8 }} onClick={handleReset}>
        Reset
      </Button>
    </div>
  );

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={2}>Ricerca avanzata contatti</Typography.Title>
      </div>

      <Form className="ant-advanced-search-form" onSubmit={handleSearch}>
        <div>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 1]}>
            {getFields()}
          </Row>
          <Row>
            {' '}
            <Col span={24} style={{ textAlign: 'right' }}>
              {buttons()}
            </Col>
          </Row>
        </div>
      </Form>

      {/*<FiltersBarPane values={itemValues} />*/}
    </>
  );
};

export const WrappedAdvancedSearchForm = Form.create<IAdvancedSearchForm>({
  name: 'contacts_advanced_search',
})(AdvancedContactsSearchForm);

/***
 * Select che permette la scelta di un solo stato, da usare se si opta per il non MULTISTATO
 */
export const ContactSelectStatusMono = React.forwardRef(
  (props: FormComponentProps & SelectProps, ref) => {
    return (
      <Select
        allowClear
        {...props}
        placeholder={
          <React.Fragment>
            <Icon type="tag" />
            &nbsp; Status
          </React.Fragment>
        }
      >
        <Select.OptGroup key={'lab3'} label={<strong>Proibizioni</strong>}>
          <Select.Option value="clientUpdateProhibited">
            clientUpdateProhibited
          </Select.Option>
          <Select.Option value="clientDeleteProhibited">
            clientDeleteProhibited
          </Select.Option>
          <Select.Option value="serverUpdateProhibited">
            serverUpdateProhibited
          </Select.Option>
          <Select.Option value="serverDeleteProhibited">
            serverDeleteProhibited
          </Select.Option>
        </Select.OptGroup>
      </Select>
    );
  },
);
