import { Dd, Dl, DlBox, Dt } from "../../../Common/Styled/DefinitionList";
import { ContactInfo } from "../Info/ContactInfo";
import * as React from "react";
import { IStep } from "./Steps";
import { Alert, Button, Spin } from "antd";
import { IContact } from "../contacts";
import { useUpdateContact } from "./operationHook";
import { RegistrantInfo } from "../Info/RegistrantInfo";
import { omit } from "lodash";
import {ShowDocument} from "../../../Documents/ShowDocument";

/***
 * Mostra il riepilogo dei dati relativi alla modifica del contatto e gestisce la conferma del risultato o dell'errore
 * @param props
 * @constructor
 */

export function SummaryBox(props: IStep & { originalContact: IContact , onDone(done:boolean):void }) {
  const { editContactOperation, responseMessage, responseStatus, updateContact, loading } = useUpdateContact();
  const { data } = props;


  const ShowContactInfo = () => {
    if (data && data.contact) {
      const dataContact = { ...props.originalContact, ...data.contact };
      return data.contact.registrant ? <RegistrantInfo {...dataContact} clean /> :
        <ContactInfo contact={{ ...dataContact }} clean />;
    }
    return null;
  };


  function ShowResponse() {
    switch (responseStatus) {
      case 200 :
        props.onDone(true);
        // Aggiunger messeaggio di successo
        return <>
          <p>
            <Alert message="Contatto aggiornato con successo" type="success" showIcon />
          </p>
          <ContactInfo contact={editContactOperation as IContact} clean />
        </>;
      case 428 :
        // Stampare messaggio che dice manca If-match o catturare lo statusText dalla message   "response":{"data":"","status":428,"statusText":"Precondition Required"
        return <Alert
          message="Error 412"
          description={"Precondizione assente"}
          type="error"
          showIcon
        />;
      case 412 :
        // Stampare messaggio che dice manca If-match o catturare lo statusText dalla message   "response":{"data":"","status":428,"statusText":"Precondition Required"
        return <Alert
          message="Error 404"
          description={responseMessage}
          type="error"
          showIcon
        />;
      case 400 :
        return <Alert
          message="Error 400"
          description={<>{`${responseMessage["message"]}: ${JSON.stringify(responseMessage["errors"], null, 3)}`}</>}
          type="error"
          showIcon
        />;
      case 404 :
        return <Alert
          message="Error 404"
          description={JSON.stringify(responseMessage, null, 2)}
          type="error"
          showIcon
        />;
      default:
        return <Alert
          message={"Error " + responseStatus}
          description={JSON.stringify(responseMessage, null, 2)}
          type="error"
          showIcon
        />;
        ;
    }
  }


  if (editContactOperation) {
    return <>
      {loading && <><Spin /><> Validazione richiesta di modifica in corso ...</>
      </>}
      {responseStatus   && <ShowResponse />}
    </>;
  } else if (data) {
    return (<>
      <DlBox data-testid='summaryBox'>
        <Dl>
          <Dt>Descrizione</Dt>
          <Dd data-testid={"description"}>{data.description ? data?.description : "-"}</Dd>
          <Dt>Documento</Dt>
          <Dd data-testid={"status"}>
            <div style={{ display: "inline-block" }}>
              {data.documentId ? data?.documentId : "-"}</div>
            <div style={{ display: "inline-block" }}>
              {data.document && <ShowDocument documentData={data.document} />}
            </div>
          </Dd>
          <Dt>Tipo modifica</Dt>
          <Dd data-testid={"type"}>{data.type ? data.type : "-"}</Dd>
          <Dt>Stato</Dt>
          <Dd data-testid={"status"}>{data.status ? data?.status : "-"}</Dd>

        </Dl>
        <ShowContactInfo />
      </DlBox>
      <div style={{ textAlign: "center" }}>
        <Button type={"primary"} size={"large"} onClick={() => {
          const dataToSubmit = data;
          // console.log(" dataToSubmit", omit(dataToSubmit, ["etag", "document"]));
          updateContact(props.originalContact.id, omit(dataToSubmit, ["document"]));
        }} loading={loading}>
          Esegui
        </Button>
      </div>
    </>)
  } else {
    return <>Pippo</>;
  }
}

