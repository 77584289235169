import { ParsedQuery, stringify } from 'query-string';
import { IDomains } from './types/domains';
import { IFetchStateType } from '../../Type/IFetchtStateType';
import { apiGetDomainByName, apiGetDomains } from '../../../const/api-calls';
import { searchAdpater } from '../../../utils/url-manipulation';

/***
 * Funzione per il recupero dei domini relativi alla query passata.
 *
 * @param dispatch
 * @param domains - valore proveniente dallo stato del reducer
 * @param query - queryString corrente
 * @param keycloak
 */
export function fetchDomains(
  dispatch: any,
  domains: IFetchStateType<IDomains>,
  query: ParsedQuery<string | number>,
) {
  const queryString = stringify(query);
  // console.log('fetchDomains queryString', queryString);

  if (queryString === '' || queryString === '?') {
    dispatch({ type: 'DOMAINS_FETCH_CLEAN' });
  } else if (
    !domains.query ||
    domains.query.localeCompare(stringify(query)) !== 0
  ) {
    dispatch({ type: 'DOMAINS_FETCH_LOAD', query: queryString });
    apiGetDomains(searchAdpater(query))
      .then((res: any) => {
        dispatch({ type: 'DOMAINS_FETCH_SUCCESS', payload: res });
      })
      .catch((error: any | PromiseLike<IDomains>) => {
        dispatch({ type: 'DOMAINS_FETCH_ERROR', error });
      });
  }
}

/***
 * Funzione per il recupero del dominio tramite nome
 *
 * @param dispatch
 * @param domains - valore proveniente dallo stato del reducer
 * @param query - queryString corrente
 * @param keycloak
 */
export function fetchSingleDomain(
  dispatch: any,
  domains: IFetchStateType<IDomains>,
  query: ParsedQuery<string | number>,
) {
  const queryString = query.toString();

  if (queryString === '') {
    dispatch({ type: 'DOMAINS_FETCH_CLEAN' });
  } else if (
    !domains.query ||
    domains.query.localeCompare(stringify(query)) !== 0
  ) {
    dispatch({ type: 'DOMAINS_FETCH_LOAD', query: queryString });

    apiGetDomainByName(query.toString())
      .then((res: any) => {
        // adattamento per poter sfruttare il reducer di domains cosi da poter visualizzare anche questo risultato
        // attraverso l'apposito pannello che accetta IDomains
        const domain2domains = { data: { elements: [res.data] } };
        dispatch({ type: 'DOMAINS_FETCH_SUCCESS', payload: domain2domains });
      })
      .catch((error: any | PromiseLike<IDomains>) => {
        dispatch({ type: 'DOMAINS_FETCH_ERROR', error });
      });
  }
}
