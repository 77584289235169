import { IStreamFormat } from "../components/Common/Feedback/ExportResult/ExportResult";

export const EMPTY = 'n.d.';
export const YMDHMS = 'YYYY-MM-DDTHH:mm:ss'
export const YMD = 'YYYY-MM-DD'
export const DMYdotHMS = 'DD MMMM YYYY, HH:mm:ss'
export const DMY = 'DD MMMM YYYY'
export const DMYdotHMSmS = 'DD MMMM YYYY, HH:mm:ss:SS'



// Localstorare const
export const MIDA_SETTINGS = 'mida-settings';

export const EMPTY_STRING = 'n.d.';  // sostituisce "-"

export const formats: IStreamFormat[] = [{ display: "csv", value: "csv" }, { display: "jsonlines", value: "json" }];
