import * as React from 'react';
import {IDomainDsRecord, INewDsRecord} from '../../types/domain';
import {printDSRecordAlg, printDSRecordDigestType} from '../../utils';
import {TableWrapped} from "../../../../Common/Styled/TableWrapped";
import {moment} from "../../../../../utils/various";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const columns = [
    {
        dataIndex: 'keytag',
        key: 'keytag',
        title: 'KeyTag',
        align: 'center' as 'center',
    },
    {
        title: 'Algorithm',
        dataIndex: 'algorithm',
        align: 'center' as 'center',
        key: 'algorithm',
        render: (text: string) => {
            return printDSRecordAlg(text);
        },
    },
    {
        title: 'DigestType',
        dataIndex: 'digestType',
        align: 'center' as 'center',
        key: 'digestType',
        render: (text: string) => {
            return <span style={{wordBreak: 'break-word'}}>{printDSRecordDigestType(parseInt(text, 10))}</span>
        },
    },
    {
        title: 'Digest',
        dataIndex: 'digest',
        align: 'center' as 'center',
        key: 'digest',
    },
];


const extraColumns = [
    {
        title: 'Created',
        dataIndex: 'created',
        align: 'center' as 'center',
        key: 'created',
        render: (value: string) => {
            if (value) {
                return moment(value).format('LLL');
            }

            return '-';
        },
    },
    {
        title: 'Type',
        dataIndex: 'type',
        align: 'center' as 'center',
        key: 'type',
        render: (value: 'rem' | 'add') => {
            return <FontAwesomeIcon icon={value === 'rem' ? faMinus : faPlus}/>;
        },
    },
];


export const DSRecordTable: React.FC<{ dsRecords?: IDomainDsRecord[] }> = ({dsRecords}) => {
    return <TableWrapped dataSource={dsRecords} columns={columns} rowKey="keytag" pagination={false} size={'small'}
                         style={{backgroundColor: 'white'}}
    />;
};

export const NewDSRecordTable: React.FC<{ newDsRecords?: INewDsRecord[] }> = ({newDsRecords}) => {
    return <TableWrapped dataSource={newDsRecords} columns={[...columns, ...extraColumns]}
                         rowKey="keytag" pagination={false} size={'small'}
                         rowClassName={(record: INewDsRecord) => record.operation === 'rem' ? 'remove' : 'add'}
                         style={{backgroundColor: 'white'}}
    />;
};