import * as React from "react";
import {DispatchContext, StateContext} from "../../StoreProvider";
import {useRouteMatch} from "react-router-dom";
import RevisionsDrawer from "../../Common/Navigation/Revisions/RevisionDrawer/RevisionsDrawer";
import {fetchContactRevisions} from "./operations";
import {IRevisions} from "../../Common/Navigation/Revisions/revisions";
import DrawerContent from "../../Common/Navigation/Revisions/DrawerContent";
import Timeline from "../../Common/Navigation/Revisions/Timeline/Timeline";
import {apiPatchedGetContactRevision} from "../../../const/api-calls";

const Drawer: React.FC = () => {

    const {contactRevisions} = React.useContext(StateContext);
    const dispatch = React.useContext(DispatchContext);
    const {params} = useRouteMatch();
    const revisionUrl = `/contacts/${params['contact']}/revisions`;

    React.useEffect(() => {
        fetchContactRevisions(dispatch, contactRevisions, params['contact'])
    });

    if (contactRevisions && contactRevisions.response && contactRevisions.response.data
        && contactRevisions.response.data.elements) {
        return (
            <RevisionsDrawer elementCount={contactRevisions.response.data.page.totalElements}>
                <DrawerContent data={contactRevisions.response.data}>
                    {(data: IRevisions) =>
                        <Timeline
                            data={data}
                            loading={false}
                            revisionUrl={revisionUrl}
                            fetchFunction={apiPatchedGetContactRevision}
                        />
                    }
                </DrawerContent>
            </RevisionsDrawer>
        )
    }

    return <></>
}

export default Drawer;
