import * as React from 'react';
import i18n from 'i18next';
import { Divider, Typography } from 'antd';
import { IContact } from '../../contacts';
import { Dd, Dt } from '../../../../Common/Styled/DefinitionList';
import { ContactBaseInfo } from './ContactBaseInfo';
import {
  BasicPopoverFilter,
  DomainFilter,
} from '../../../../Common/Navigation/Filter/Filter';
import { ENTITY_TYPE } from '../../const';
import { AbilityContext } from '../../../../Common/Ability/AbilityContext';
import { StateField } from '../../../../Common/DataDisplay/StateOrProvinceField/StateOrProvinceField';
import { HelpTips } from '../../../../Common/DataDisplay/HelpTips/HelpTips';
import AmministrazioneViewer from '../../../../Gov/AmministrazioneViewer';
import { AxiosControllerComponent } from '../../../../Common/Navigation/FetchController/FetchController';
import {
  apiGetGovAdministration,
  apiGetGovAdministrationByIpaAndUOCode,
} from '../../../../../const/api-calls';
import OrganizationUnitViewer from '../../../../Gov/OrganizationUnitViewer';

/**
 * Visualizza le info base del registrante utilizzando il TAG DL (description list)
 * @param props
 * @constructor
 */

export const RegistrantBaseInfo: React.FC<IContact & {
  clean?: boolean;
}> = props => {
  const { ability } = React.useContext(AbilityContext);
  const _contact: IContact = { ...props, type: 'registrant' };
  return (
    <>
      <ContactBaseInfo contact={_contact} clean={props.clean} />

      {props.registrant && (
        <React.Fragment>
          <Divider dashed />
          <Dt>{i18n.t('contact_regCode')}:</Dt>
          <Dd>
            {props.registrant.regcode || '-'}
            {!props.clean && props.registrant.regcode && (
              <BasicPopoverFilter
                allowed={ability.can('search', 'Domain')}
                content={
                  <DomainFilter
                    url={`/domains?registrant.regcode=${props.registrant.regcode}`}
                    content={`registrant.regcode = ${props.registrant.regcode}`}
                  />
                }
              />
            )}
          </Dd>
          {props.registrant.schoolCode && (
            <>
              <Dt>{i18n.t('contact_schoolCode')}:</Dt>
              <Dd>
                {props.registrant.schoolCode || '-'}
                {!props.clean && props.registrant.schoolCode && (
                  <BasicPopoverFilter
                    allowed={ability.can('search', 'Domain')}
                    content={
                      <DomainFilter
                        url={`/domains?registrant.schoolCode=${props.registrant.schoolCode}`}
                        content={`registrant.schoolCode = ${props.registrant.schoolCode}`}
                      />
                    }
                  />
                )}
              </Dd>
            </>
          )}

          {props.registrant.gov && (
            <>
              <Dt>{i18n.t('contact_ipaCode')}:</Dt>
              <Dd>
                {props.registrant.gov.ipaCode ? (
                  <PrintGovIPACodeDetail
                    ipa_code={props.registrant.gov.ipaCode}
                  />
                ) : (
                  '-'
                )}
                {!props.clean && props.registrant.gov.ipaCode && (
                  <BasicPopoverFilter
                    allowed={ability.can('search', 'Domain')}
                    content={
                      <DomainFilter
                        url={`/domains?registrant.ipaCode=${props.registrant.gov.ipaCode}`}
                        content={`registrant.ipaCode = ${props.registrant.gov.ipaCode}`}
                      />
                    }
                  />
                )}
              </Dd>
              <Dt>{i18n.t('contact_uoCode')}:</Dt>
              <Dd>
                {props.registrant.gov.uoCode ? (
                  <PrintGovUOCodeDetail
                    ipa_code={props.registrant.gov.ipaCode}
                    uo_code={props.registrant.gov.uoCode}
                  />
                ) : (
                  '-'
                )}
                {!props.clean && props.registrant.gov.uoCode && (
                  <BasicPopoverFilter
                    allowed={ability.can('search', 'Domain')}
                    content={
                      <DomainFilter
                        url={`/domains?registrant.uoCode=${props.registrant.gov.uoCode}`}
                        content={`registrant.uoCode = ${props.registrant.gov.uoCode}`}
                      />
                    }
                  />
                )}
              </Dd>
            </>
          )}

          <Dt>{i18n.t('contact_entityType')}:</Dt>
          <Dd>
            {props.registrant.entityType} -{' '}
            <i>{`(${ENTITY_TYPE[Number(props.registrant.entityType) - 1]})`}</i>
          </Dd>

          <Dt>{i18n.t('contact_nationality')}:</Dt>
          <Dd>
            <StateField val={props.registrant.nationality} />
          </Dd>
        </React.Fragment>
      )}
    </>
  );
};

const PrintGovIPACodeDetail: React.FC<{ ipa_code: string }> = props => {
  return (
    <>
      {props.ipa_code}
      <Divider type={'vertical'} />
      <AxiosControllerComponent call={apiGetGovAdministration(props.ipa_code)}>
        {response => (
          <HelpTips
            iconClassName={'icon_detail'}
            title={
              <Typography.Title level={2}>
                {'Dettaglio Amministrazione'}
              </Typography.Title>
            }
            content={
              <AmministrazioneViewer
                amministrazione={response.data}
                view={'full'}
                direction={'row'}
              />
            }
            icon={'info-circle'}
          />
        )}
      </AxiosControllerComponent>
    </>
  );
};

const PrintGovUOCodeDetail: React.FC<{
  ipa_code: string;
  uo_code: string;
}> = props => {
  return (
    <>
      {props.uo_code} <Divider type={'vertical'} />
      <AxiosControllerComponent
        call={apiGetGovAdministrationByIpaAndUOCode(
          props.ipa_code,
          props.uo_code,
        )}
      >
        {response => (
          <HelpTips
            iconClassName={'icon_detail'}
            title={
              <Typography.Title level={2}>
                {'Dettaglio Unità Organizzativa'}
              </Typography.Title>
            }
            content={
              <OrganizationUnitViewer
                organizationUnit={response.data?.elements?.[0]}
                direction={'row'}
              />
            }
            icon={'info-circle'}
          />
        )}
      </AxiosControllerComponent>
    </>
  );
};
