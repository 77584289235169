import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { SelectProps } from 'antd/lib/select';
import { Icon, Select } from 'antd';

// TODO Attualmente non usato - a regime se non utile rimuovere
/***
 *
 * Select che filtra i risultati già inseriti
 */
const SelectStatus = React.forwardRef(
  (props: FormComponentProps & SelectProps, ref) => {
    const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];

    const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

    const handleChange = (items: any) => {
      setSelectedItems(items);
    };

    const filteredOptions = OPTIONS.filter(
      (o: string) => !selectedItems.includes(o),
    );

    return (
      <Select
        {...props}
        mode="multiple"
        placeholder="Inserted are removed"
        value={selectedItems}
        onChange={handleChange}
        style={{ width: '100%' }}
      >
        {filteredOptions.map((item: any) => (
          <Select.Option key={item} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>
    );
  },
);
export default SelectStatus;

// TODO Attualmente non usato - a regime se non utile rimuovere
/***
 * Select che permette la scelta di un solo stato, da usare se si opta per il non MULTISTATO
 */
export const SelectStatusMono = React.forwardRef(
  (props: FormComponentProps & SelectProps, ref) => {
    return (
      <Select
        allowClear
        {...props}
        placeholder={
          <React.Fragment>
            <Icon type="tag" />
            &nbsp; Evento
          </React.Fragment>
        }
      >
        <Select.OptGroup label={<b>Registry</b>}>
          <Select.Option value="auto-renew-period-expired">
            auto-renew-period-expired
          </Select.Option>
          <Select.Option value="bulk_transfer">bulk_transfer</Select.Option>
          <Select.Option value="bulk_transfer_expired">
            bulk_transfer_expired
          </Select.Option>
          <Select.Option value="bulk_transfer_request">
            bulk_transfer_request
          </Select.Option>
          <Select.Option value="challenge-end">challenge-end</Select.Option>
          <Select.Option value="challenge-start">challenge-start</Select.Option>
          <Select.Option value="contract_transfer">
            contract_transfer
          </Select.Option>
          <Select.Option value="credit-refunded-for-not-renewed">
            credit-refunded-for-not-renewed
          </Select.Option>
          <Select.Option value="dns-check-failed">
            dns-check-failed
          </Select.Option>
          <Select.Option value="dns-check-succeeded">
            dns-check-succeeded
          </Select.Option>
          <Select.Option value="dns-hold-expired">
            dns-hold-expired
          </Select.Option>
          <Select.Option value="domain-create-reserved">
            domain-create-reserved
          </Select.Option>
          <Select.Option value="domain-delete-by-accuracy">
            domain-delete-by-accuracy
          </Select.Option>
          <Select.Option value="domain-deleted-by-registry">
            domain-deleted-by-registry
          </Select.Option>
          <Select.Option value="domain-expired">domain-expired</Select.Option>
          <Select.Option value="domain-not-renewed">
            domain-not-renewed
          </Select.Option>
          <Select.Option value="end_server_delete_prohibited">
            end_server_delete_prohibited
          </Select.Option>
          <Select.Option value="mnt-to-reg-transfer">
            mnt-to-reg-transfer
          </Select.Option>
          <Select.Option value="mnt-to-reg-transfer-trade">
            mnt-to-reg-transfer-trade
          </Select.Option>
          <Select.Option value="no-registrar-expired">
            no-registrar-expired
          </Select.Option>
          <Select.Option value="no-registrar-started">
            no-registrar-started
          </Select.Option>
          <Select.Option value="not-renewed-expired">
            not-renewed-expired
          </Select.Option>
          <Select.Option value="not_allowed_request">
            not_allowed_request
          </Select.Option>
          <Select.Option value="pending-delete-expired">
            pending-delete-expired
          </Select.Option>
          <Select.Option value="pending-transfer-approved-by-registry">
            pending-transfer-approved-by-registry
          </Select.Option>
          <Select.Option value="pending-transfer-cancelled-by-registry">
            pending-transfer-cancelled-by-registry
          </Select.Option>
          <Select.Option value="pending-transfer-expired">
            pending-transfer-expired
          </Select.Option>
          <Select.Option value="pending-transfer-trade-approved-by-registry">
            pending-transfer-trade-approved-by-registry
          </Select.Option>
          <Select.Option value="pending-transfer-trade-cancelled-by-registry">
            pending-transfer-trade-cancelled-by-registry
          </Select.Option>
          <Select.Option value="pending-transfer-trade-expired">
            pending-transfer-trade-expired
          </Select.Option>
          <Select.Option value="pending-update-expired">
            pending-update-expired
          </Select.Option>
          <Select.Option value="reassign">reassign</Select.Option>
          <Select.Option value="reassignation-expired">
            reassignation-expired
          </Select.Option>
          <Select.Option value="redemption-period-expired">
            redemption-period-expired
          </Select.Option>
          <Select.Option value="reg-to-mnt-transfer">
            reg-to-mnt-transfer
          </Select.Option>
          <Select.Option value="reg-to-mnt-transfer-trade">
            reg-to-mnt-transfer-trade
          </Select.Option>
          <Select.Option value="registrant-hold">registrant-hold</Select.Option>
          <Select.Option value="registrar-migration">
            registrar-migration
          </Select.Option>
          <Select.Option value="revoke-expired">revoke-expired</Select.Option>
          <Select.Option value="revoke-started">revoke-started</Select.Option>
          <Select.Option value="server-hold">server-hold</Select.Option>
          <Select.Option value="server-hold-by-accuracy">
            server-hold-by-accuracy
          </Select.Option>
          <Select.Option value="server-lock">server-lock</Select.Option>
          <Select.Option value="server-lock-by-accuracy">
            server-lock-by-accuracy
          </Select.Option>
          <Select.Option value="server-unhold">server-unhold</Select.Option>
          <Select.Option value="server-unlock">server-unlock</Select.Option>
          <Select.Option value="server-unlock-by-accuracy">
            server-unlock-by-accuracy
          </Select.Option>
          <Select.Option value="start_server_delete_prohibited">
            start_server_delete_prohibited
          </Select.Option>
        </Select.OptGroup>
        <Select.OptGroup label={<b>EPP</b>}>
          <Select.Option value="domain-check">domain-check</Select.Option>
          <Select.Option value="domain-create">domain-create</Select.Option>
          <Select.Option value="domain-delete">domain-delete</Select.Option>
          <Select.Option value="domain-info">domain-info</Select.Option>
          <Select.Option value="domain-restore">domain-restore</Select.Option>
          <Select.Option value="domain-trade">domain-trade</Select.Option>
          <Select.Option value="domain-transfer">domain-transfer</Select.Option>
          <Select.Option value="domain-transfer-approve">
            domain-transfer-approve
          </Select.Option>
          <Select.Option value="domain-transfer-cancel">
            domain-transfer-cancel
          </Select.Option>
          <Select.Option value="domain-transfer-query">
            domain-transfer-query
          </Select.Option>
          <Select.Option value="domain-transfer-reject">
            domain-transfer-reject
          </Select.Option>
          <Select.Option value="domain-transfer-request">
            domain-transfer-request
          </Select.Option>
          <Select.Option value="domain-transfer-trade">
            domain-transfer-trade
          </Select.Option>
          <Select.Option value="domain-transfer-trade-approve">
            domain-transfer-trade-approve
          </Select.Option>
          <Select.Option value="domain-transfer-trade-cancel">
            domain-transfer-trade-cancel
          </Select.Option>
          <Select.Option value="domain-transfer-trade-reject">
            domain-transfer-trade-reject
          </Select.Option>
          <Select.Option value="domain-transfer-trade-request">
            domain-transfer-trade-request
          </Select.Option>
          <Select.Option value="domain-update">domain-update</Select.Option>
          <Select.Option value="domain-update-contacts">
            domain-update-contacts
          </Select.Option>
          <Select.Option value="domain-update-hosts">
            domain-update-hosts
          </Select.Option>
          <Select.Option value="domain-update-simple">
            domain-update-simple
          </Select.Option>
          <Select.Option value="domain-update-statuses">
            domain-update-statuses
          </Select.Option>
        </Select.OptGroup>
      </Select>
    );
  },
);

/***
 * Select Multistato
 */
export const SelectStatusMulti = React.forwardRef(
  (props: FormComponentProps & SelectProps, ref) => {
    const NOT = () => <strong>not </strong>;
    return (
      <Select
        allowClear
        {...props}
        placeholder={
          <React.Fragment>
            <Icon type="tag" />
            &nbsp; Status
          </React.Fragment>
        }
        mode={'multiple'}
      >
        <Select.OptGroup key={'lab1'} label={<strong>Stati</strong>}>
          <Select.Option value="autoRenewPeriod">autoRenewPeriod</Select.Option>
          <Select.Option value="bulk">bulk</Select.Option>
          <Select.Option value="challenged">challenged</Select.Option>
          <Select.Option value="deleted">deleted</Select.Option>
          <Select.Option value="dnsHold">dnsHold</Select.Option>
          <Select.Option value="inactive">inactive</Select.Option>
          <Select.Option value="noRegistrar">noRegistrar</Select.Option>
          <Select.Option value="notRenewed">notRenewed</Select.Option>
          <Select.Option value="ok">ok</Select.Option>
          <Select.Option value="pendingDelete">pendingDelete</Select.Option>
          <Select.Option value="pendingTransfer">pendingTransfer</Select.Option>
          <Select.Option value="pendingUpdate">pendingUpdate</Select.Option>
          <Select.Option value="redemptionPeriod">
            redemptionPeriod
          </Select.Option>
          <Select.Option value="rejectedRequest">rejectedRequest</Select.Option>
          <Select.Option value="revoked">revoked</Select.Option>
          <Select.Option value="toBeReassigned">toBeReassigned</Select.Option>
        </Select.OptGroup>
        <Select.OptGroup
          key={'lab2'}
          label={
            <strong>
              Stati (not - <i>diverso da</i>)
            </strong>
          }
        >
          <Select.Option value="notEquals:autoRenewPeriod">
            <NOT />
            autoRenewPeriod
          </Select.Option>
          <Select.Option value="notEquals:bulk">
            <NOT />
            bulk
          </Select.Option>
          <Select.Option value="notEquals:challenged">
            <NOT />
            challenged
          </Select.Option>
          <Select.Option value="notEquals:deleted">
            <NOT />
            deleted
          </Select.Option>
          <Select.Option value="notEquals:dnsHold">
            <NOT />
            dnsHold
          </Select.Option>
          <Select.Option value="notEquals:inactive">
            <NOT />
            inactive
          </Select.Option>
          <Select.Option value="notEquals:noRegistrar">
            <NOT />
            noRegistrar
          </Select.Option>
          <Select.Option value="notEquals:notRenewed">
            <NOT />
            notRenewed
          </Select.Option>
          <Select.Option value="notEquals:ok">
            <NOT />
            ok
          </Select.Option>
          <Select.Option value="notEquals:pendingDelete">
            <NOT />
            pendingDelete
          </Select.Option>
          <Select.Option value="notEquals:pendingTransfer">
            <NOT />
            pendingTransfer
          </Select.Option>
          <Select.Option value="notEquals:pendingUpdate">
            <NOT />
            pendingUpdate
          </Select.Option>
          <Select.Option value="notEquals:redemptionPeriod">
            <NOT />
            redemptionPeriod
          </Select.Option>
          <Select.Option value="notEquals:rejectedRequest">
            <NOT />
            rejectedRequest
          </Select.Option>
          <Select.Option value="notEquals:revoked">
            <NOT />
            revoked
          </Select.Option>
          <Select.Option value="notEquals:toBeReassigned">
            <NOT />
            toBeReassigned
          </Select.Option>
        </Select.OptGroup>

        <Select.OptGroup key={'lab3'} label={<strong>Proibizioni</strong>}>
          <Select.Option value="clientDeleteProhibited">
            clientDeleteProhibited
          </Select.Option>
          <Select.Option value="clientHold">clientHold</Select.Option>
          <Select.Option value="clientTransferProhibited">
            clientTransferProhibited
          </Select.Option>
          <Select.Option value="clientUpdateProhibited">
            clientUpdateProhibited
          </Select.Option>
          <Select.Option value="serverDeleteProhibited">
            serverDeleteProhibited
          </Select.Option>
          <Select.Option value="serverHold">serverHold</Select.Option>
          <Select.Option value="serverTransferProhibited">
            serverTransferProhibited
          </Select.Option>
          <Select.Option value="serverUpdateProhibited">
            serverUpdateProhibited
          </Select.Option>
        </Select.OptGroup>
        <Select.OptGroup
          key={'lab4'}
          label={
            <strong>
              Proibizioni (not - <i>diverso da</i>)
            </strong>
          }
        >
          <Select.Option value="notEquals:clientDeleteProhibited">
            <NOT />
            clientDeleteProhibited
          </Select.Option>
          <Select.Option value="notEquals:clientHold">
            <NOT />
            clientHold
          </Select.Option>
          <Select.Option value="notEquals:clientTransferProhibited">
            <NOT />
            clientTransferProhibited
          </Select.Option>
          <Select.Option value="notEquals:clientUpdateProhibited">
            <NOT />
            clientUpdateProhibited
          </Select.Option>
          <Select.Option value="notEquals:serverHold">
            <NOT />
            serverHold
          </Select.Option>
          <Select.Option value="notEquals:serverDeleteProhibited">
            <NOT />
            serverDeleteProhibited
          </Select.Option>
          <Select.Option value="notEquals:serverTransferProhibited">
            <NOT />
            serverTransferProhibited
          </Select.Option>
          <Select.Option value="notEquals:serverUpdateProhibited">
            <NOT />
            serverUpdateProhibited
          </Select.Option>
        </Select.OptGroup>
      </Select>
    );
  },
);

// TODO Attualmente non usato - a regime se non utile rimuovere
/***
 * Opzioni da aggiungere nel caso al Cascader se si opta per questa opzione
 */
export const CASCADER_STATUS_OPTIONS = [
  {
    value: 'ok',
    label: 'ok',
    children: [
      {
        value: 'autoRenewPeriod',
        label: 'autoRenewPeriod',
        children: [
          {
            value: 'clientDeleteProhibited',
            label: 'clientDeleteProhibited',
          },
        ],
      },
    ],
  },
  {
    value: 'inactive',
    label: 'inactive',
    children: [
      {
        value: 'serverHold',
        label: 'serverHold',
      },
      {
        value: 'notRenewed',
        label: 'notRenewed',
      },
      {
        value: 'challenged',
        label: 'challenged',
        children: [
          {
            value: 'serverHold',
            label: 'serverHold',
          },
        ],
      },
      {
        value: 'revoked',
        label: 'revoked',
      },
    ],
  },
  {
    value: 'pendingDelete',
    label: 'pendingDelete',
    children: [
      {
        value: 'notEquals:redemptionPeriod',
        label: 'pendingDelete',
        children: [
          {
            value: 'zhonghuamen',
            label: 'Zhong Hua Men',
          },
        ],
      },
      {
        value: 'redemptionPeriod',
        label: 'redemptionPeriod',
      },
    ],
  },
  {
    value: 'clientDeleteProhibited',
    label: 'clientDeleteProhibited',
  },
  {
    value: 'clientUpdateProhibited',
    label: 'clientUpdateProhibited',
  },
];
