import styled from 'styled-components';
import { Button } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';

export const OpenDrawerButton = styled(Button)<{ right: boolean } & NativeButtonProps>`
  position: absolute!important;
  top: 240px!important;
  background: #1890ff!important;
  width: 48px!important;
  height: 48px!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  cursor: pointer!important;
  pointer-events: auto!important;
  z-index: 0!important;
  text-align: center!important;
  font-size: 16px!important;
  border-radius: 4px 0 0 4px!important;
  right: ${props => (props.right ? '0px': '320px')}!important;
`;


export const OpenDrawerNotesButton = styled(Button)<{ right: boolean } & NativeButtonProps>`
  position: absolute!important;
  top: 340px!important;
  background: #1890ff!important;
  width: 48px!important;
  height: 48px!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  cursor: pointer!important;
  pointer-events: auto!important;
  z-index: 0!important;
  text-align: center!important;
  font-size: 16px!important;
  border-radius: 4px 0 0 4px!important;
  right: ${props => (props.right ? '0px': '350px')}!important;
`;
