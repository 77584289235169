import * as React from 'react';
import { Paginator } from '../../Common/Navigation/Paginator/Paginator';
import { useQuery } from '../../../utils/various';
import { useHistory, useLocation } from 'react-router-dom';
import { FiltersBarPane } from '../../Common/Navigation/FiltersBarPane/FiltersBarPane';
import { addFilter, decodeQuerySort, sortResult } from '../../../utils/url-manipulation';
import { IContactEvents } from "./types/contactEvents";
import Table from "./Table";

const PaginatedTable: React.FC<IContactEvents & { refRevisionDate?: string }> = (props) => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();


  const handleOnSort = (a: any, b: any, d: any) => {
    sortResult(d, query, history, location);
  };

  const handleAddFilter = (filter: any, values: any) => {
    addFilter(filter, values, query, history, location);
  };

  return (
    <React.Fragment>
      <FiltersBarPane urlPrefix={location.pathname} style={{ marginBottom: 10 }}/>
      <Table
        contactEvents={props.elements}
        refRevisionDate={props.refRevisionDate}
        sortColumnKey={decodeQuerySort(query.sort).columnKey}
        sortOrder={decodeQuerySort(query.sort).order}
        onChange={handleOnSort}
        onAddFilter={handleAddFilter}
      />
      <Paginator page={props.page}/>
    </React.Fragment>
  );

};

export default PaginatedTable;