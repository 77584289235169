import * as React from 'react';
import axiosinstance from '../../../const/axiosInstances';
import { isEmpty } from 'lodash';
import { getAccessTokenLifespanRemainingTime } from '../../../utils/token';
import * as Keycloak from 'keycloak-js';

export interface IAuthContext {
  keycloak: Keycloak.KeycloakInstance | any;
}

/*
 Qualche nota riguardo al token Keycloak

 iat : momento in cui era il JWT rilasciato : The "iat" (issued at) claim identifies the time at which the JWT was issued.
 exp : tempo di scadenza, the "exp" (expiration time) claim identifies the expiration time on or after which the JWT MUST NOT be accepted for processing.
 timeSkew: tempo di disallineamento



 Nota di funzionamento componente:

 metodo proattivo - componentDiMount() esegue l'inizializzazione e il login con Keycloak e lancia un timer che verifica ogni X secondi se il token è ancora attivo
 (X : durata di vita del token di accesso.) Quando si verifica la validità del token si specifica un range di comporto, se la data di
 scadenza del token sta all'interno (della data attuale - il valore di comporto) finestra di comporto all'ora il token viene aggiornato (refreshUpdateToken)


 metodo lazy -  tramite interceptors ogni volta che si esegue una chiamata all'api si verifica che il token sia attivo altrimenti si riaggiorna

Qualche nota in più
https://www.janua.fr/understanding-token-usage-in-keycloak/

 */

export const AuthContext = React.createContext<IAuthContext>({
  keycloak: undefined,
});

class AuthProvider extends React.Component {
  public state = { keycloak: {} as Keycloak.KeycloakInstance };

  /* TODO implementare gestione errore, come? Messaggio di notifica rosso in alto
   oppure  pagina di erroe? messaggio in console???
   */
  public componentDidMount(): void {
    const keycloak = Keycloak.default('/keycloak.json');

    // console.log("Keycloak ", keycloak);
    // console.debug("login ");

    keycloak
      .init({ onLoad: 'login-required', checkLoginIframe: false })
      .success((authenticated: boolean) => {
        this.setState({ keycloak });
        // calcola la finestra di scadenza del token, sottrae 2 seondi e verifica con quell'intervallo la validità del token
        const delay: number =
          getAccessTokenLifespanRemainingTime(keycloak) - 1000;
        console.debug('Check keycloak token in:', delay / 1000 + ' seconds');
        // setInterval(() => this.refreshToken(keycloak), delay);
      })
      .error(() => {
        console.error('Failed to initialized keycloak session');
      });
  }

  /* TODO implementare i metodi successo o errore, capire se necessario*/
  public refreshToken = (keycloak: any): void => {
    // console.log("Keycloak refreshed", keycloak);
    console.debug(
      'Trying refreshing Keycloak access token, expire in ' +
        getAccessTokenLifespanRemainingTime(keycloak) / 1000 +
        ' seconds',
    );
    keycloak
      .updateToken(5)
      .success((refreshed: boolean) => {
        if (refreshed) {
          console.debug(
            'Token was successfully refreshed, expire in ' +
              getAccessTokenLifespanRemainingTime(keycloak) / 1000 +
              ' seconds',
          );
        } else {
          console.warn(
            'Token not refreshed, valid for ',
            getAccessTokenLifespanRemainingTime(keycloak) / 1000 + ' seconds',
          );
        }
      })
      .error(() => {
        keycloak.clearToken();
        console.error(
          'Failed to refresh the token, or the session has expired',
        );
      });
  };

  public render() {
    if (!isEmpty(this.state.keycloak)) {
      axiosinstance(this.state.keycloak);
    }

    return (
      <AuthContext.Provider
        value={{
          keycloak: this.state.keycloak as Keycloak.KeycloakInstance,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
