import * as React from "react";
import { Alert, Icon, Modal, Progress } from "antd";
import { find_occurences, moment, saveBlob } from "../../../../utils/various";
import { AxiosError, AxiosPromise, AxiosRequestConfig } from "axios";
import { printAxiosFetchError } from "../../Navigation/FetchController/FetchController";

/***
 * indica il tipo di formato da usare per l'estrazione
 *  - display: come viene visualizzato
 *  - value: il valore dell'estensione
 */
export interface IStreamFormat {
  display: string,
  value: string
}


/***
 * Box dedicato alla export.
 * Presenta una seziona fissa {fixedContent}
 * Presenta una seziona che si nasconde {children} a seconda della presenza di {format}
 * Invoca il Downloader per la barra progressiva
 * @param props
 * @constructor
 */
export default function ExportResult(props: React.PropsWithChildren<{
  fetchData<T = any>(config?: AxiosRequestConfig): AxiosPromise<T>,
  totalElements: number,
  filename?: string,
  format?: IStreamFormat
  fixedContent?: React.ReactNode,
}>) {
  const [done, setDone] = React.useState(false);
  return <div>
    {
      !done && props.fixedContent
    }
    {
      !props.format && props.children
    }
    {
      props.format &&
      <Downloader
        format={props.format}
        fetchData={props.fetchData}
        totElements={props.totalElements}
        filename={props.filename}
        onDone={setDone}
      />
    }
  </div>;
}


/***
 * Mostra una barra di avanzamento progressivo che indica la percentuale di download  effettuato.
 * Per farlo conta gli elementi del chunk ottenuto tramite l'invocazione di fetchData.
 * @param props
 * @constructor
 */
export function Downloader(props: {
  fetchData<T = any>(config?: AxiosRequestConfig): AxiosPromise<T>, totElements: number, format?: IStreamFormat, filename?: string, onDone?: (done: boolean) => void
}) {
  const [currentDownloaded, setCurrentDownloaded] = React.useState(0);
  const [done, setDone] = React.useState(false);
  const [error, setError] = React.useState<AxiosError>();


  React.useEffect(() => {
    props.fetchData({
        responseType: "stream",
        timeout: 150000,
        onDownloadProgress: progressEvent => {
          const dataChunk = progressEvent.currentTarget.response;
          const dataChunkCount = find_occurences(dataChunk, "\n");

          if (props.format?.display === "jsonlines") {
            setCurrentDownloaded(dataChunkCount);
          } else {
            // va tolta una riga che corrisponde all'intestazione mostrata nei risultati
            setCurrentDownloaded(dataChunkCount > 0 ? dataChunkCount - 1 : 0);
          }
        }
      })
      .then(res => {
        saveBlob(res.data, `${props.filename ?? "mida-export"}_${moment(new Date()).format("YYYYMMDD_HHmmss")}.${props.format?.value ?? "csv"}`, props.format?.value ?? "csv");
        setDone(true);
        if (props.onDone) {
          props.onDone(true);
        }

        return res.data;
      })
      .catch(err => {
          setError(err);
        }
      );
  }, []);

  return <div style={{ marginTop: 25 }}>

    {error ?
      <Alert message="Errore" description={printAxiosFetchError(error)} type={"error"} showIcon={true} /> :
      <ProgressBar done={done} currentDownloaded={currentDownloaded} totElements={props.totElements} />
    }
  </div>;

}

/***
 * Barra di avanzamento del download
 * @param props
 * @constructor
 */
function ProgressBar(props: { currentDownloaded: number, totElements: number, done: boolean }) {
  return <React.Fragment>
    {`Record estratti: ${props.currentDownloaded} / ${props.totElements}`}
    <Progress percent={Math.round((props.currentDownloaded * 100) / props.totElements)} />
    {!props.done && <div>Esportazione in corso...</div>}
    {props.done && <div>
      <div className="ant-result ant-result-success">
        <div className="ant-result-icon"><i aria-label="icon: check-circle"
                                            className="anticon anticon-check-circle">
          <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="check-circle"
               width="1em"
               height="1em" fill="currentColor" aria-hidden="true">
            <path
              d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z" />
          </svg>
        </i></div>
        <div className="ant-result-title">File esportato con successo!</div>
        {props.currentDownloaded !== props.totElements &&
        <div className="ant-result-subtitle">
          <Icon type="warning"
                style={{ color: "#FAAD14", marginRight: 10 }} />Esportazione
          interrotta per
          dimensioni eccessive</div>}
      </div>
    </div>
    }
  </React.Fragment>;
}

/***
 *  Apre una modale con all'interno il componente passato in content
 *
 */

export function openExtractResultModal(title: string, content: React.ReactNode) {
  return Modal.info({
    title,
    centered: true,
    width: 600,
    okText: "Chiudi",
    content,
    icon: <Icon type="download" />
  });
}
