import { TabledContactsResults } from "./TabledContactsResults";
import { useQuery } from "../../../utils/various";
import { PaginationDiv } from "../../Common/Styled/PaginationDiv";
import { Paginator } from "../../Common/Navigation/Paginator/Paginator";
import { CardDetailSection } from "../../Common/Styled/CardDetailSection";
import * as React from "react";
import { IContacts } from "../types/contacts";
import { decodeQuerySort } from "../../../utils/url-manipulation";
import ExportButton from "./ExportButton/ExportButton";
import { Flex } from "../../Common/Layout/Flex/Flex";


export const ResultsPane: React.FC<{
  contacts: IContacts,
  onAddFilter?: any,
  onSort?: any,
}> = ({ contacts, onAddFilter, onSort }) => {
  const query = useQuery();

  return (
    <CardDetailSection>
      <Flex container flexDirection={"row-reverse"} marginBottom={10}>
        <ExportButton query={query} totalElements={contacts.page.totalElements} />
      </Flex>
      <TabledContactsResults
        onAddFilter={onAddFilter}
        sortColumnKey={decodeQuerySort(query.sort).columnKey}
        sortOrder={decodeQuerySort(query.sort).order}
        data={contacts.elements} handleOnChange={onSort}
      />
      {
        contacts.page && contacts.page.totalElements > 1 &&
        <PaginationDiv>
          <Paginator page={contacts.page} />
        </PaginationDiv>
      }
    </CardDetailSection>
  );
};