import * as React from 'react';
import { apiCountAssociatedDomains } from '../../../../const/api-calls';
import { Alert, Badge, Spin, Tag, Tooltip } from 'antd';
import { printAxiosFetchError } from '../FetchController/FetchController';
import { AxiosError } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { IContact, ILinkedInfo } from '../../../Contacts/Detail/contacts';
import styled from 'styled-components';

interface IContactDomainLoader {
  contactId: string;
  type: 'registrant' | 'tech' | 'admin';
}

const StyledTag = styled(Tag)`{
  a { color: #1890ff!important;}
}`


/***
 * Visualizzatore di numero di domini collegati che recupera tramite la query passata il valore dei domini
 * @param type
 * @param contactId
 * @param linkedInfo
 * @constructor
 */
export const ContactDomainsCounter: React.FC<IContactDomainLoader> = ({ type, contactId }) => {
  const query = `${type}.contactId=${contactId}`;
  return <DomainsCounter
    query={query}
    linkTo={`/domains?${type}.contactId=${contactId}`}
    linkText={<span>è <span style={{ textTransform: 'capitalize' }}>{type}</span></span>}
  />;
};


interface ILinkeDomains extends IContactDomainLoader {
  linkedInfo?: ILinkedInfo;
}

/***
 * Visualizzatore di numero di domini collegati che sfrutta le informazioni presenti LinkedInfo
 * @param type
 * @param contactId
 * @param linkedInfo
 * @constructor
 */
export const LinkedDomains: React.FC<ILinkeDomains> = ({ type, contactId, linkedInfo }) => {
  let count = 0;
  if (linkedInfo && linkedInfo.counter) {
    switch (type) {
      case 'tech':
        count = linkedInfo.counter.tech;
        break;
      case 'registrant':
        count = linkedInfo.counter.registrant;
        break;
      case 'admin':
        count = linkedInfo.counter.admin;
        break;
    }


    return <DomainsCounterComponent
      count={count}
      linkTo={`/domains?${type}.contactId=${contactId}`}
      linkText={<span>è <span style={{ textTransform: 'capitalize' }}>{type}</span></span>}
    />;
  }
  return <></>;
};


/***
 * Componente Base per la visualizzazione tramite badge del numero dei domini collegati.
 * Offre anche il link ad un indirizzo specifico
 * @param props
 * @constructor
 */

export const DomainsCounter: React.FC<{ query: string, linkTo: string, linkText?: React.ReactNode }> = props => {
  const [data, setData] = React.useState<{ count: number } | undefined>(undefined);
  const [loading, setLoading] = React.useState({});
  const [error, setError] = React.useState<AxiosError | undefined>(undefined);


  React.useEffect(() => {
    setLoading(true);
    apiCountAssociatedDomains(props.query).then((response) => {
      setData(response.data);
      setLoading(false);

    }).catch((e: AxiosError) => {
      setError(e);
      setLoading(false);
    });
  }, []);


  if (loading) {
    return <Spin/>;
  }

  if (error) {
    return <Alert message="Errore" description={printAxiosFetchError(error)} type={'error'} showIcon={true}/>;
  }

  const count = data && data.count || 0;

  return <DomainsCounterComponent count={count} linkTo={props.linkTo} linkText={props.linkText}/>;

};
export default DomainsCounter;

export function DomainsCounterComponent(props: { count: number, linkTo: string, linkText?: React.ReactNode }) {

  const FilterIcon = () => <FontAwesomeIcon
    icon={faGlobe}
    // color={'grey'}
    style={{ marginRight: 5 }}
  />;

  const Label = () => <><FilterIcon/>{props.linkText}</>;


  const content = <Badge
    overflowCount={999}
    offset={[-5,0]}
    style={{ backgroundColor: '#CCCCCC', color: 'white', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
    // style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }} //Bianco
    // style={{ backgroundColor: '#F7FFEF', color: '#70CD4A', boxShadow: '0 0 0 1px #B7EB8F inset' }}
    count={props.count}
  >
    <StyledTag color={props.count>0?'blue':''}>
      {props.count > 0 ? <Link to={props.linkTo}><Label/></Link> : <Label/>}
    </StyledTag>
  </Badge>;

  return (
    <span style={{ marginRight: 20 }}>
        <Tooltip
          title={props.count > 0 ? `Domini associati ${props.count}, clicca per vederli` : 'Nessun dominio associato'}>
        {content}
        </Tooltip>
        </span>
  );
}

// const testInfo ={
//         counter: {
//             admin: 1,
//             registrant: 0,
//             tech: 0
//         },
//         linked: true
// } as ILinkedInfo;


export function LinkedDomainsVisualizer(props: { contact: IContact }) {
  return props.contact.linkedInfo !== undefined && !props.contact.linkedInfo.linked ?
    <Tag><FontAwesomeIcon icon={faGlobe}/> not Linked</Tag>
    :
    <>
      <LinkedDomains linkedInfo={props.contact.linkedInfo} contactId={props.contact.contactId} type={'registrant'}/>
      <LinkedDomains linkedInfo={props.contact.linkedInfo} contactId={props.contact.contactId} type={'admin'}/>
      <LinkedDomains linkedInfo={props.contact.linkedInfo} contactId={props.contact.contactId} type={'tech'}/>
    </>;
};
;