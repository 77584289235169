import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ParsedQuery, stringify } from 'query-string';
import { DomainsSearchForm } from './DomainsSearchForm';

/**
 *  Pannello che contiene la Form di ricerca dei domini con la gestione del evento Submit
 * @constructor
 */

export const DomainsSearchFormPane: React.FC<{
  values: ParsedQuery<React.ReactText>;
}> = ({ values }) => {
  const history = useHistory();

  const handleOnSubmit = (err: any, val: any) => {
    if (!err && val) {
      history.push(`/domains?${stringify(val)}`);
    } else {
      // non dovrebbe mai accadere per i controlli che ci sono, ma se accadesse, redirigi
      history.push(`/domains`);
    }
  };

  return (
    // <SearchForm onSubmit={handleOnSubmit} values={values} />
    <DomainsSearchForm onSubmit={handleOnSubmit} values={values} />
    // <SearchForm onSubmit={handleOnSubmit} values={values}/>
  );
};
