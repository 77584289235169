import * as React from 'react';
import {Layout} from 'antd';
import NavBarMenu from './NavBarMenu/NavBarMenu';
import styled from 'styled-components';
import {AbilityContext} from "../Ability/AbilityContext";
// // https://css-tricks.com/couple-takes-sticky-footer/
// const Logo = styled.div`
//   background-image: url(/logo.png);
// `;

const LayoutContent = styled(Layout.Content)`
  min-height: calc(100vh - 1px) !important;

  padding: 100px 10% 10% 10%;
  background-color: #c1bebe1f;
  
`;

const AppLayout: React.FC = props => {

    const {ability} = React.useContext(AbilityContext);

    return (
        <Layout>
            {/*{console.log('PROPS AppLayout', props)}*/}F
            <Layout.Header style={{
                position: 'fixed', zIndex: 1, width: '100%', backgroundColor: '#20639b'
                // , padding: 20
            }}>
                {/*<div*/}
                {/*style={{*/}
                {/*background: 'rgba(255,255,255,.2)',*/}
                {/*float: 'left',*/}
                {/*height: '21px',*/}
                {/*margin: '16px 24px 16px 10px',*/}
                {/*width: '100px',*/}
                {/*}}*/}
                {/*/>*/}
                <a href={"/"}>
                    <img
                        src={process.env.PUBLIC_URL + '/mida-little-crown.png'}
                        style={{
                            display: 'inline',
                            float: 'left',
                            height: 57,
                            margin: '3px 2px 0px 0px',
                            // padding: '3px 30px 0px 30px',
                            // border: '2px solid #6093be',
                            paddingRight: '10px',
                            // borderRight: '2px solid #6093be',
                            // borderRadius: '9px'
                        }}
                    />
                </a>
                <NavBarMenu  ability={ability}/>
            </Layout.Header>
            <LayoutContent>
                {props.children}
            </LayoutContent>
            <Layout.Footer
                style={{
                    height: '50px',
                    marginTop: '-50px',
                    textAlign: 'center',
                }}
            >
                Mida ©2020 Created by Registro .it
            </Layout.Footer>
        </Layout>
    )
};
export default AppLayout;
