import { IDomainRegistrant } from './types/domain';
import * as _ from 'lodash';

export function composeRegistrantName(registrant: IDomainRegistrant): string {
  if (registrant) {
    const name = registrant.name || 'n.d';
    const org = registrant.org || 'n.d';

    if (name === org) {
      return name;
    } else {
      return `${name} - (${org})`;
    }
  }
  return 'n.d.';
}

interface IAlg {
  id: number;
  name: string;
}

interface IDigest {
  [key:string]:{
  id: number;
  length: number;}
}


export const algorithms = [
  { id: 3, name: 'DSA/SHA-1' },
  { id: 5, name: 'RSA/SHA-1' },
  { id: 6, name: 'DSA-NSEC3-SHA1' },
  { id: 7, name: 'RSASHA1-NSEC3-SHA1' },
  { id: 8, name: 'RSA/SHA-256' },
  { id: 10, name: 'RSA/SHA-512' },
  { id: 12, name: 'ECC-GOST' },
  { id: 13, name: 'ECDSAP256SHA256' },
  { id: 14, name: 'ECDSAP384SHA384' },
];

export const digestTypes:IDigest = {
  'SHA-1': { id: 1, length: 40 },
  'SHA-256': { id: 2, length: 64 },
  'GOST R 34.11-94': { id: 3, length: 64 },
  'SHA-384': { id: 4, length: 96 },
};

export function printDSRecordAlg(idAlg: string): string {
  const alg = _.find(algorithms, { id: parseInt(idAlg, 10) })  as IAlg;
  return `${alg.id} [${alg.name}]`;
}

export function printDSRecordDigestType(idDigest:number) {
  let k:string='';
  Object.keys(digestTypes).forEach((key)=> {
    if (digestTypes[key].id === idDigest) {
      k = key;
    }
  });
  return `${digestTypes[k].id} [${k}]`;
}
