import { Select } from 'antd';
import * as React from 'react';
import PaginationButton, { PaginationButtonStyled } from './PaginationButton';
import styled from 'styled-components';
import { IPaginationProps } from './type';

const pageSizeOptions = ['5', '10', '20', '50', '100', '250', '500'];

const Text = styled.span`
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
`;

// Todo, implementare la parte mancante che gestisce l'editing della pagina nel campo che riporta il numero di pagina corrente

class Pagination extends React.Component<IPaginationProps> {

  public handleOnChangePage = (page: number) => {
    if (this.props.onChangePage) {
      this.props.onChangePage(page);
    }
  };

  public handleOnChangeSize = (size: string) => {
    if (this.props.onChangeSize) {
      this.props.onChangeSize(parseInt(size, 10));
    }
  };

  public render() {

    // console.log('Pagination proaps ', this.props);
    const { page } = this.props;
    const isFirst = page.number !== 0;
    const isLast = page.number !== page.totalPages - 1;
    const hasNext = !isLast;
    const hasPrev = !isFirst;
    const current = page.number + 1;

    // console.log('PAGINATION  ', this.props);
    return (
      <React.Fragment>
        <PaginationButton
          title={'Prima'}
          bordered="false"
          disabled={!isFirst}
          icon={'vertical-right'}
          onClick={this.handleOnChangePage.bind(this, 0)}
        />
        <PaginationButton
          title={'Successiva'}
          bordered="false"
          disabled={hasPrev}
          icon={'left'}
          onClick={this.handleOnChangePage.bind(this, page.number - 1)}
        />
        <PaginationButtonStyled bordered="true">{current}</PaginationButtonStyled>
        <Text>di</Text>
        <PaginationButtonStyled bordered="false">{page.totalPages}</PaginationButtonStyled>
        <PaginationButton
          title={'Prossima'}
          bordered="false"
          disabled={hasNext}
          icon={'right'}
          onClick={this.handleOnChangePage.bind(this, page.number + 1)}
        />
        <PaginationButton
          title={'Ultima'}
          bordered="false"
          disabled={!isLast}
          icon={'vertical-left'}
          onClick={this.handleOnChangePage.bind(this, page.totalPages - 1)}
        />
        <Select defaultValue={page.size.toString()} onChange={this.handleOnChangeSize}>
          {pageSizeOptions.map((size: string, index: number) => (
            <Select.Option key={index.toString()} value={size}>
              {size}
            </Select.Option>
          ))}
        </Select>
        <Text>di</Text>
        <span>{page.totalElements}</span>
      </React.Fragment>
    );
  }
}

export default Pagination;
