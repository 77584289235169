import * as React from 'react';
import Routes from './Routes';
import AppLayout from './Common/Layout/AppLayout';
import { AuthConsumer } from './Common/Auth/AuthProvider';
import { Icon, Spin } from 'antd';
import { fetchStateAndProvince } from './Common/Input/SelectListStateProvince/operations';
import { DispatchContext } from './StoreProvider';
import { fetchEventsRequirements } from './Domains/Domain/Toolbar/operations';
// import {fetchRegistrar} from "./Registrar/Preloaded/operations";

const AppContent: React.FC = () => (
  <React.Fragment>
    <AppLayout>
      <AuthConsumer>
        {({ keycloak }) =>
          keycloak['tokenParsed'] !== undefined ? (
            <>
              <PreLoadingStuff />
              <Routes />
            </>
          ) : (
            <LoadingUserSpinner />
          )
        }
      </AuthConsumer>
    </AppLayout>
  </React.Fragment>
);
export default AppContent;

const LoadingUserSpinner: React.FC = () => {
  const antIcon = <Icon type="loading" style={{ fontSize: 50 }} spin />;
  return (
    <div style={{ textAlign: 'center', padding: '30px 50px' }}>
      <div>
        <h3>Caricamento profilo utente..</h3>
      </div>
      <Spin indicator={antIcon} />
    </div>
  );
};

/*** Inserire qui tutte le funzioni o procedure di precaricamento */
const PreLoadingStuff: React.FC = props => {
  const dispatch = React.useContext(DispatchContext);

  React.useEffect(() => {
    fetchStateAndProvince(dispatch);
    fetchEventsRequirements(dispatch);
    // fetchRegistrar(dispatch,keycloak);
  }, []);
  return <></>;
};
