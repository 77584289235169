import * as React from 'react';
import { IDomainDsRecord } from '../../types/domain';
import { printDSRecordAlg, printDSRecordDigestType } from '../../utils';
import { TableWrapped } from '../../../../Common/Styled/TableWrapped';
import { DetailContent } from '../../../../Common/Styled/Detail';
import { eqString } from '../../../../Common/Navigation/Revisions/DetailCard/DetailCardBox';

const DSRecordDiffTable: React.FC<{
  dsRecordLeft: IDomainDsRecord[];
  dsRecordRight: IDomainDsRecord[];
}> = ({ dsRecordLeft, dsRecordRight }) => {
  const columns = [
    {
      dataIndex: 'keytag',
      key: 'keytag',
      title: 'KeyTag',
      align: 'center' as 'center',
      render: (value: any, record: any, index: number) => {
        if (dsRecordLeft[index] && dsRecordRight[index]) {
          if (dsRecordLeft[index].keytag && dsRecordRight[index].keytag) {
            const a = dsRecordLeft[index].keytag.toString();
            const b = dsRecordRight[index].keytag.toString();
            const isDifferent = !eqString(a, b);
            return (
              <DetailContent isDifferent={isDifferent}>
                {value ? value : '-'}
              </DetailContent>
            );
          }
        } else {
          return (
            <DetailContent isDifferent>{value ? value : '-'}</DetailContent>
          );
        }
        return '-';
      },
    },
    {
      title: 'Algorithm',
      dataIndex: 'algorithm',
      align: 'center' as 'center',
      key: 'algorithm',

      render: (value: any, record: any, index: number) => {
        if (
          dsRecordLeft[index] &&
          dsRecordRight[index] &&
          dsRecordLeft[index].digestType &&
          dsRecordRight[index].digestType
        ) {
          const a = printDSRecordAlg(dsRecordLeft[index].algorithm.toString());
          const b = printDSRecordAlg(dsRecordRight[index].algorithm.toString());
          const isDifferent = !eqString(a, b);
          return (
            <DetailContent isDifferent={isDifferent}>
              {a ? a : '-'}
            </DetailContent>
          );
        } else {
          return (
            <DetailContent isDifferent>{value ? value : '-'}</DetailContent>
          );
        }
      },
    },
    {
      title: 'DigestType',
      dataIndex: 'digestType',
      align: 'center' as 'center',
      key: 'digestType',
      render: (value: any, record: any, index: number) => {
        if (
          dsRecordLeft[index] &&
          dsRecordRight[index] &&
          dsRecordLeft[index].digestType &&
          dsRecordRight[index].digestType
        ) {
          const a = printDSRecordDigestType(dsRecordLeft[index].digestType);
          const b = printDSRecordDigestType(dsRecordRight[index].digestType);
          const isDifferent = !eqString(a, b);
          return (
            <DetailContent isDifferent={isDifferent}>
              {a ? a : '-'}
            </DetailContent>
          );
        } else {
          return (
            <DetailContent isDifferent>{value ? value : '-'}</DetailContent>
          );
        }
      },
    },
    {
      title: 'Digest',
      dataIndex: 'digest',
      align: 'center' as 'center',
      key: 'digest',
      render: (value: any, record: any, index: number) => {
        if (
          dsRecordLeft[index] &&
          dsRecordRight[index] &&
          dsRecordLeft[index].digestType &&
          dsRecordRight[index].digestType
        ) {
          const a = dsRecordLeft[index].digest;
          const b = dsRecordRight[index].digest;
          const isDifferent = !eqString(a, b);
          return (
            <DetailContent isDifferent={isDifferent}>
              {value ? value : '-'}
            </DetailContent>
          );
        } else {
          return (
            <DetailContent isDifferent>{value ? value : '-'}</DetailContent>
          );
        }
      },
    },
  ];

  return (
    <TableWrapped
      dataSource={dsRecordLeft}
      columns={columns}
      rowKey="keytag"
      pagination={false}
      size={'small'}
    />
  );
};

export default DSRecordDiffTable;
