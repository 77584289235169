import {IContract, ICustomer, IRegistrar, IRegistrarContact, IRegistrarEmail} from "./IRegistrar";
import * as React from "react";
import {AxiosError} from "axios";
import {
    apiGetRegistrar,
    getRegistrarContacts,
    getRegistrarContract,
    getRegistrarCustomer,
    getRegistrarEmails
} from "../../const/api-calls";


/***
 * Permette la fetch dei dati del Registrar in maniera ottimizzata cioè
 * se gli input sono uguali non riesegue la fetch
 *
 * @param regsitrarId
 */
export function useFetchRegistrar(regsitrarId: number) {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<IRegistrar | undefined>(undefined);
    const [error, setError] = React.useState<AxiosError | undefined>(undefined);


    const fetchData = React.useCallback(() => {
        apiGetRegistrar(regsitrarId)
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((e) => {
                setError(e);
                setLoading(false);
            })
    }, [])
    React.useEffect(() => {
        fetchData()
    }, [fetchData])


    return React.useMemo(() => ({
        loading, data, error
    }), [
        loading, data, error
    ]);
}

/***
 * Permette la fetch dei dati del Registrar Customer in maniera ottimizzata cioè
 * se gli input sono uguali non riesegue la fetch
 * @param regsitrarId
 */
export function useFetchRegistrarCustomer(regsitrarId: number) {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<ICustomer | undefined>(undefined);
    const [error, setError] = React.useState<AxiosError | undefined>(undefined);


    const fetchData = React.useCallback(() => {
        getRegistrarCustomer(regsitrarId)
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((e) => {
                setError(e);
                setLoading(false);
            })
    }, [])
    React.useEffect(() => {
        fetchData()
    }, [fetchData])


    return React.useMemo(() => ({
        loading, data, error
    }), [
        loading, data, error
    ]);
}

/***
 * Permette la fetch dei dati del Registrar Contract in maniera ottimizzata cioè
 * se gli input sono uguali non riesegue la fetch
 * @param regsitrarId
 */
export function useFetchRegistrarContract(regsitrarId: number) {
    
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<IContract | undefined>(undefined);
    const [error, setError] = React.useState<AxiosError | undefined>(undefined);


    const fetchData = React.useCallback(() => {
        getRegistrarContract(regsitrarId)
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((e) => {
                setError(e);
                setLoading(false);
            })
    }, [])
    React.useEffect(() => {
        fetchData()
    }, [fetchData])


    return React.useMemo(() => ({
        loading, data, error
    }), [
        loading, data, error
    ]);
}

/***
 * Permette la fetch dei dati dei contatti del Registrar in maniera ottimizzata
 * se gli input sono uguali non riesegue la fetch
 * @param regsitrarId
 */
export function useFetchRegistrarContatcts(regsitrarId: number) {
    
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<IRegistrarContact[] | undefined>(undefined);
    const [error, setError] = React.useState<AxiosError | undefined>(undefined);


    const fetchData = React.useCallback(() => {
        getRegistrarContacts(regsitrarId)
            .then((response:any) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((e) => {
                setError(e);
                setLoading(false);
            })
    }, [])
    React.useEffect(() => {
        fetchData()
    }, [fetchData])


    return React.useMemo(() => ({
        loading, data, error
    }), [
        loading, data, error
    ]);
}

/***
 * Permette la fetch dei dati delle mail dei Registrar in maniera ottimizzata
 * se gli input sono uguali non riesegue la fetch
 * @param regsitrarId
 */
export function useFetchRegistrarEmails(regsitrarId: number) {
    
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<IRegistrarEmail[] | undefined>(undefined);
    const [error, setError] = React.useState<AxiosError | undefined>(undefined);


    const fetchData = React.useCallback(() => {
        getRegistrarEmails(regsitrarId)
            .then((response:any) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((e) => {
                setError(e);
                setLoading(false);
            })
    }, [])
    React.useEffect(() => {
        fetchData()
    }, [fetchData])


    return React.useMemo(() => ({
        loading, data, error
    }), [
        loading, data, error
    ]);
}