import * as React from "react";
import { IEditContactOperation } from "./Steps";
import { apiPutContact } from "../../../../const/api-calls";
import { AxiosError } from "axios";
import { printAxiosFetchError } from "../../../Common/Navigation/FetchController/FetchController";

/***
 * Permette l'agggiornamento di un contatto modificato
 */
export function useUpdateContact() {
  const [editContactOperation, setEditContactOperation] = React.useState<IEditContactOperation | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [responseStatus, setResponseStatus] = React.useState<number | undefined>(undefined);
  const [responseMessage, setResponseMessage] = React.useState("");

  const updateContact = (contactId:number,operation: IEditContactOperation) => {
    setEditContactOperation(operation);
    setResponseMessage("");
    setLoading(true);

    if (operation) {
      apiPutContact(contactId,operation).then((response) => {
          setResponseStatus(response.status);
          console.debug(" response", JSON.stringify(response));

          if (response.data.message) {
            setResponseMessage(response.data.message);
          } else {
            setEditContactOperation(response.data);
          }
          setLoading(false);
        })
        .catch((e: AxiosError) => {
          console.debug(" JSON.stringify(e.response", JSON.stringify(e.response));
          const status = e.response && e.response.status;
          setResponseStatus(status);
          if (e.response && e.response.data.errors) {
            setResponseMessage(e.response.data);
          } else if (e.response && e.response.data.message) {
            setResponseMessage(e.response.data.message);
          } else {
            setResponseMessage(printAxiosFetchError(e));
          }
          setLoading(false);
        });
    }
  };

  return { editContactOperation, responseMessage, responseStatus, updateContact, loading };
}

