import * as React from 'react';
import { Icon, Tooltip } from 'antd';
import styled from 'styled-components';
import { IconProps } from 'antd/lib/icon';

interface IStyledIconProps {
  /** indica se l'icona e quindi il seletteore è selezionato */
  selected: boolean;
}

interface ISelectorProps {
  /** Funzione legata all'onClick del selettore */
  onClick: any;
  /** Messaggio relativo al selettore */
  tooltipText: string;
}
interface ISelectorIconProps {
  /** Icona del selettore */
  type: string;
}

export const StyledSelectorIcon = styled(Icon)<IconProps & IStyledIconProps>`
  font-size: 18pt;
  margin-right: 5px;
  color: ${(props: { selected: boolean }) => (props.selected ? '' : 'gray')};
`;
StyledSelectorIcon.displayName = 'StyledSelectorIcon';

export const SelectorIcon: React.FC<ISelectorIconProps & IStyledIconProps> = ({ type, selected }) => (
  <StyledSelectorIcon selected={selected} type={type} />
);

const Selector: React.FC<ISelectorProps & ISelectorIconProps & IStyledIconProps> = ({
  type,
  onClick,
  selected,
  tooltipText,
}) => {
  return (
    <Tooltip title={tooltipText}>
      <a onClick={onClick}>
        <SelectorIcon type={type} selected={selected} />
      </a>
    </Tooltip>
  );
};

export default Selector;
