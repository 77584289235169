import {
  DetailCompareColumnA,
  DetailColumn,
  DetailHeader,
  DetailLabel,
  DetailRow,
  DetailWrapperBox,
  DetailCompareColumnB,
} from '../../../Styled/Detail';
import * as React from 'react';
import { DMYdotHMSmS, EMPTY_STRING } from '../../../../../const/common';
import { IRevision } from '../revisions';
import { moment } from '../../../../../utils/various';

function RevisionTitle(props: {
  text: string;
  color: string;
  revision?: IRevision;
}) {
  return (
    <DetailHeader className={'detail_header'}>
      <div style={{ color: props.color }}>{props.text}</div>
      {props.revision && props.revision.date && (
        <div
          style={{
            fontStyle: 'oblique',
            color: props.color,
            fontSize: 'smaller',
          }}
        >
          {moment(props.revision.date).format(DMYdotHMSmS)}
        </div>
      )}
    </DetailHeader>
  );
}

export function RevisionATitle(revision?: IRevision) {
  return (
    <RevisionTitle text="Revisione A" color={'orange'} revision={revision} />
  );
}

export function RevisionBTitle(revision?: IRevision) {
  return (
    <RevisionTitle text="Revisione B" color={'#1890FF'} revision={revision} />
  );
}

export const DetailBox: React.FC<{
  children: JSX.Element;
  revisionA?: IRevision;
  revisionB?: IRevision;
}> = ({ revisionB, revisionA, children }) => (
  <DetailWrapperBox>
    <DetailRowWrapper
      column2={RevisionATitle(revisionA)}
      column3={RevisionBTitle(revisionB)}
    />
    {children}
  </DetailWrapperBox>
);

export const DetailRowWrapper: React.FC<{
  label?: React.ReactNode;
  column2: JSX.Element;
  column3: JSX.Element;
}> = ({ label, column2, column3 }) => (
  <DetailRow>
    <DetailColumn>
      <DetailLabel>{label}</DetailLabel>
    </DetailColumn>
    <DetailCompareColumnA>
      <span test-id={`${label}-columnA`}>{column2}</span>
    </DetailCompareColumnA>
    <DetailCompareColumnB>
      <span test-id={`${label}-columnB`}>{column3}</span>
    </DetailCompareColumnB>
  </DetailRow>
);

export function eqString(valA?: string, valB?: string) {
  if (valA && valB) {
    return valA.toString().localeCompare(valB.toString()) === 0;
  }

  return valA === valB;
}

export function handleEmptyValue(object: any, label: string) {
  if (object && object && object[label]) {
    return object[label];
  }
  return EMPTY_STRING;
}
