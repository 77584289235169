import styled from 'styled-components';
import {Table} from "antd";

export const TableWrapped = styled(Table)`
  td  {word-break:break-all;}
  
  tr.remove {
    color: #dbdbdb;
    font-style: italic;
    background: #F6F6F6;
    
  }
  
  tr.add {
    color: #52C419;
  }`
;



export const FilteredLabelField = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`;
