import * as React from 'react';
import {IDomain} from '../../../Domain/types/domain';
import {Button, List as AntList,} from 'antd';
import {IDomains} from '../../types/domains';
import {RadioChangeEvent} from 'antd/lib/radio/interface';
import SortOrderButton from '../../../../Common/Navigation/SortOrderButton/SortOrderButton';
import {AbilityContext} from '../../../../Common/Ability/AbilityContext';
import Item from './Item';

const ButtonGroup = Button.Group;


export interface IResultListProps {
    sort?: string;
    direction?: string,
    onChange?: (e: RadioChangeEvent) => void;
    onAddFilter?: (filter: any, values: string) => void
}

const List: React.FC<IDomains & IResultListProps>
    = ({elements: domains, page, sort, direction, onAddFilter, onChange}) => {

    const {ability} = React.useContext(AbilityContext);

    return (
        <div
            style={{
                border: '1px solid #e8e8e8',
                borderRadius: '4px',
                overflow: 'auto',
                padding: '8px 24px',
                // height: '300px',
            }}
        >
            <div
                style={{
                    backgroundColor: '#80808017',
                    marginTop: '-8px',
                    marginLeft: '-24px',
                    marginRight: '-24px',
                    marginBottom: '30px',
                    padding: '10px',
                    // textAlign: 'right',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {page && page.totalElements && page.totalElements > 1 ?
                    <div>
                        <strong>Ordina per </strong>
                        <ButtonGroup style={{marginLeft: 5}}>
                            <SortOrderButton fieldName={'domain'}
                                             value={sort}
                                             order={direction}
                                             onClick={onChange}>
                                Domain</SortOrderButton>
                            <SortOrderButton fieldName={'created'}
                                             value={sort}
                                             order={direction}
                                             onClick={onChange}>
                                Created</SortOrderButton>
                            <SortOrderButton fieldName={'expire'}
                                             value={sort}
                                             onClick={onChange}
                                             order={direction}>
                                Expire</SortOrderButton>
                            <SortOrderButton fieldName={'lastupdate'}
                                             value={sort}
                                             onClick={onChange}
                                             order={direction}>
                                Last update</SortOrderButton>
                        </ButtonGroup>
                    </div> : <div/>}
                {page && page.totalElements && page.totalElements > 1 &&
                <div><strong>Trovati:</strong> {page.totalElements}</div>}

            </div>

            <AntList
                // Per togliere la griglia basta commentare questa riga (grid)
                grid={{
                    gutter: 30, md: 1, lg: 2, xl: 2, xxl: 3,
                }}
                // header={<h2>Oridna per <button>nome</button><button>id</button><button>data</button></h2>}
                dataSource={domains}
                renderItem={(item: IDomain) => (
                    <Item item={item} ability={ability} onAddFilter={onAddFilter}/>
                )}
            />
        </div>
    );
};
export default List;



