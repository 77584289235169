import {AxiosControllerComponent} from "../Common/Navigation/FetchController/FetchController";
import {apiGetDocument} from "../../const/api-calls";
import {GenericDocument} from "./documents";
import {PopoverDocument} from "./PopoverDocument";
import * as React from "react";

/***
 * Recupera il documento tramite l'API
 * @param props
 * @constructor
 */
export function Document(props: { documentId?: number }) {
    if (props.documentId) {
        return <AxiosControllerComponent call={apiGetDocument(props.documentId.toString())}>
            {(response: { data: GenericDocument }) => {
                return (
                    <PopoverDocument  {...response.data}/>
                );
            }}
        </AxiosControllerComponent>;
    }

    return null;
}