import * as React from 'react';
import { Button, Col, Form, Icon, Input, Row, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { ParsedQuery } from 'query-string';
import { transformNestedFieldsIntoNestedDotFields } from '../../../../Domains/Search/utils';
import { useHistory } from 'react-router-dom';

// TODO finire di completare la customizzazione dei campi di ricerca e capire le regole e i validatori

interface IContactsSearchForm extends FormComponentProps {
  advanced?: boolean;
  values: ParsedQuery<React.ReactText>;
  onSubmit: (err: any, values: any) => void;
}

const ContactsSearchForm: React.FC<IContactsSearchForm> = props => {
  // const [expand, setExpand] = React.useState(!singleSearch);
  const [itemValues, setItemValues] = React.useState<
    ParsedQuery<React.ReactText> | undefined
  >({});

  const history = useHistory();
  React.useEffect(() => {
    setItemValues(props.values);
  }, [props.values]);

  // To generate mock Form.Item
  const getFields = () => {
    // const count = expand ? 10 : 1;
    const { getFieldDecorator } = props.form;
    const children = [];

    // ID
    children.push(
      <Col span={9} key={'contactId'} style={{ display: 'block' }}>
        <Form.Item>
          {getFieldDecorator(`main`, {
            initialValue:
              (itemValues && itemValues['contactId']) ||
              (itemValues && itemValues[`name-ft`]) ||
              undefined,
            rules: [
              {
                required: true,
                message:
                  'Inserisci un contatto tramite ContactID o Nome Cognome ',
              },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="ContactID o Nome Cognome (es. CCTL1 o  Mario Rossi)"
              allowClear
            />,
          )}
        </Form.Item>
      </Col>,
    );

    return children;
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    props.form.validateFields((err: any, values: any) => {
      // console.log('Received values of form: ', values);
      if (!err) {
        let valuesToSubmit = {
          ...values,
        };
        valuesToSubmit = transformNestedFieldsIntoNestedDotFields(
          valuesToSubmit,
        );

        props.onSubmit(err, valuesToSubmit);
        // // sottomette tutti i valori diversi da undefined e non stringa vuota
        // props.onSubmit(err, pickBy(values, (lValue) => lValue !== undefined && lValue !== ''));
      }
    });
  };

  const handleClear = () => {
    props.form.resetFields();
  };

  const handleReset = () => {
    handleClear();
    setItemValues(undefined);
  };

  const buttons = () => (
    <div style={{ lineHeight: 3 }}>
      <Button type="primary" htmlType="submit">
        Search
      </Button>
      {/*<Button style={{marginLeft: 8}} onClick={handleClear}>*/}
      {/*Clear*/}
      {/*</Button>*/}
      <Button style={{ marginLeft: 8 }} onClick={handleReset}>
        Reset
      </Button>

      <Button
        type={'link'}
        onClick={() => history.push('/contacts/advanced_search')}
      >
        Ricerca avanzata
      </Button>
    </div>
  );

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={2}>Ricerca contatti</Typography.Title>
      </div>
      <Form className="ant-advanced-search-form" onSubmit={handleSearch}>
        <Row type={'flex'} gutter={24} justify={'center'}>
          {getFields()}
          {buttons()}
        </Row>
      </Form>
    </>
  );
};

export const WrappedContactsSearchForm = Form.create<IContactsSearchForm>({
  name: 'contacts_advanced_search',
})(ContactsSearchForm);
