import * as React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { AuthConsumer } from "./AuthProvider";
import { Alert } from "antd";
import { KeycloakInstance } from "keycloak-js";

export interface IProtectedRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  allowed: boolean;
}

//
// type RenderComponent = (keycloak: IKeycloak, props: RouteComponentProps<any>) => React.ReactNode;
//
// export class ProtectedRoute extends Route<IProtectedRouteProps> {
//
//   public render() {
//     const { component: Component, allowed = true, ...rest }: IProtectedRouteProps = this.props;
//
//     const renderComponent: RenderComponent = (keycloak, props) => {
//       return (keycloak.keycloak.authenticated && allowed) ?
//         <Component {...keycloak} {...props} /> : <NotAllowed />;
//     };
//
//     return (
//       <AuthConsumer>
//         {({ keycloak }) => {
//
//           return <Route {...rest} render={props => renderComponent({ keycloak }, props)} />;
//         }}
//       </AuthConsumer>
//     );
//   }
// }


export const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ component: Component, allowed, location, ...rest }) => {
  return (
    <AuthConsumer>
      {({ keycloak }) => {
        return <Route {...rest} render={props => PrivateRender(Component, keycloak, props, allowed)} />;
      }}
    </AuthConsumer>
  );
};

type renderComponentType = (Component: any, keycloak: KeycloakInstance, props: RouteComponentProps<any>, allowed: boolean) => React.ReactNode;

const PrivateRender: renderComponentType = (Component, keycloak, props, allowed) => {
  return (keycloak.authenticated && allowed) ?
    <Component keycloak={keycloak} {...props} /> : <NotAllowed />;
};


const NotAllowed = () => <Alert
  message="Operazione negata"
  description="L'utente non dispone dei permessi sufficienti"
  type="info"
/>;


