import { Redirect, Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from './Common/Auth/ProtectedRoute';
import * as React from 'react';
import { NoMatch } from './Pages/NoMatch';
import Exception500 from './Pages/Exception500';
import DomainRevisionPage from './Domains/Revisions/Detail/scenes/DomainRevisionPage';
import DomainPage from './Domains/Domain/Scene/DomainPage';
import ContactsSearchPage from './Contacts/Search/Scenes/full/ContactsSearchPage';
import ContactPage from './Contacts/Detail/Scenes/ContactPage';
import ContactRevisionPage from './Contacts/Revisions/Detail/scenes/ContactRevisionPage';
import { AbilityContext } from './Common/Ability/AbilityContext';
import HomePage from './Pages/Home/Scene/HomePage';
import DomainsSearchPage from './Domains/Search/scenes/full/DomainsSearchPage';
import ContactSearchPage from './Contacts/Search/Scenes/limited/ContactSearchPage';
import { LimitedSearchDomainsPage } from './Domains/Search/scenes/limited/LimitedSearchDomainsPage';
import AdvancedDomainsSearchPage from './Domains/Search/scenes/full/advanced/AdvancedDomainsSearchPage';
import AdvancedContactsSearchPage from './Contacts/Search/Scenes/full/advanced/AdvancedContactsSearchPage';
// import Logout from "./Common/Auth/Logout";

// DA LEGGERE, spunti e note importanti https://www.sitepoint.com/react-router-complete-guide/

// Trucco per far ricarcicare la home nel caso in cui si richiede di visualizzare il changelog dalla home stessa
const Changelog = () => <Redirect to={'/'} />;

const Routes: React.FC = props => {
  const { ability } = React.useContext(AbilityContext);
  return (
    <Switch>
      {/*{console.log(' this', props)}*/}
      <ProtectedRoute path="/" exact component={HomePage} allowed />
      {/*<ProtectedRoute*/}
      {/*    path="/logout"*/}
      {/*    component={Logout}*/}
      {/*    allowed*/}
      {/*/>*/}
      {/* Domini */}
      <ProtectedRoute
        path="/domains/findByName/:domain"
        allowed={ability.can('read', 'Domain')}
        exact
        component={LimitedSearchDomainsPage}
      />
      <ProtectedRoute
        path="/domains/findByName"
        exact
        allowed={ability.can('read', 'Domain')}
        component={LimitedSearchDomainsPage}
      />
      <ProtectedRoute
        path="/domains/advanced_search"
        exact
        component={AdvancedDomainsSearchPage}
        allowed={ability.can('search', 'Domain')}
      />
      <ProtectedRoute
        path="/domains/:domain"
        component={DomainPage}
        exact
        allowed
      />
      <ProtectedRoute
        path="/domains/:domain/revisions/:revision"
        exact
        component={DomainRevisionPage}
        allowed={ability.can('history', 'Domain')}
      />

      <ProtectedRoute
        path="/domains"
        exact
        component={DomainsSearchPage}
        allowed={ability.can('search', 'Domain')}
      />

      {/* Contatti */}
      <ProtectedRoute
        path="/contacts/findByContactId/:contact"
        exact
        component={ContactSearchPage}
        allowed={ability.can('read', 'Contact')}
      />
      <ProtectedRoute
        path="/contacts/findByContactId"
        exact
        component={ContactSearchPage}
        allowed={ability.can('read', 'Contact')}
      />
      <ProtectedRoute
        path="/contacts/advanced_search"
        exact
        component={AdvancedContactsSearchPage}
        // allowed={ability.can("search", "Contact")}
        allowed={ability.can('search', 'Contact')}
      />
      <ProtectedRoute
        path="/contacts"
        exact
        component={ContactsSearchPage}
        // allowed={ability.can("search", "Contact")}
        allowed={ability.can('search', 'Contact')}
      />

      <ProtectedRoute
        path="/contacts/:contact"
        exact
        component={ContactPage}
        allowed={ability.can('read', 'Contact')}
      />
      <ProtectedRoute
        path="/contacts/:contact/revisions/:revision"
        exact
        component={ContactRevisionPage}
        allowed={ability.can('history', 'Contact')}
      />

      {/*<ConditionalProtectedRoute*/}
      {/*path="/domains" exact falseRedirectTo={'/'}*/}
      {/*trueComponent={DomainsPage}*/}
      {/*condition={routeProps => routeProps.location ? paramsValidator(parse(routeProps.location.search), requiredParamsDomains) : false}*/}
      {/*/>*/}

      <Route path="/error/500" exact component={Exception500} />
      <Route path="/changelog" exact component={Changelog} />
      <ProtectedRoute component={NoMatch} allowed />
    </Switch>
  );
};
export default Routes;
