import * as React from 'react';
import {Button, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import {moment} from '../../../../../utils/various';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons/faChevronRight';
import styled from 'styled-components';
import {IRevision, IRevisions} from "../revisions";
import {DMYdotHMS} from "../../../../../const/common";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {revisionNavigator} from "../../../../Domains/Revisions/revisionNavigator";


const BoxRevision = styled('div')`
  //width: 200px;
  display:  inline-flex;
  flex-direction: column ;   
  text-align: center;
  vertical-align: baseline;
  line-height: 1.3;

  .date{
      font-size: 22px;
      position: relative;
  }
  
  .time{
      font-size: 18px;
      color: #C0C0C0;
      position: relative;
      font-weight:bold;
  }
  
  .seconds{
      font-size: 14px;
  }
`;
BoxRevision.displayName = 'BoxRevision';


/***
 * Informazioni delle revisione visualizzate al centro del navigatore
 * @param revision
 * @constructor
 */
export function RevisionLabel(revision: string) {
    return <BoxRevision>
        <div className={'date'}> {moment(revision).format('LL')}</div>
        <div><span className={'time'}>{moment(revision).format('HH:mm')}<span
            className={'seconds'}>:{moment(revision).seconds()}</span></span></div>
    </BoxRevision>;
}

/***
 * Pulsante revisione
 * @param props
 * @constructor
 */
function RevisionButton(props: { revision?: IRevision, labelHint: string, icon: IconProp, onClick: (revision: IRevision) => void }) {
    if (props.revision) {
        const handleOnClick = () => props.revision && props.onClick(props.revision);
        return (
            <Tooltip title={<>
                <div style={{textAlign: "center"}}>{props.labelHint}:</div>
                <div>{moment(props.revision.date).format(DMYdotHMS)}</div>
            </>}>
                <Button type={"link"} size={"large"} style={{fontSize: 24}} onClick={handleOnClick}>
                    <FontAwesomeIcon icon={props.icon}/>
                </Button>
            </Tooltip>
        )
    } else {
        return null;
    }


}


/***
 * Pulsantiera next e prev che mostra i link alla revisioni
 * @param props
 * @constructor
 */
const RevisionPrevNext: React.FC<IRevision & { revisions: IRevisions, onClickNext: (nextRev: IRevision) => void, onClickPrev: (prevRev: IRevision) => void}> = (props) => {

    const {nextRevision, prevRevision} = revisionNavigator(props.number, props.revisions);

    return (
        <>
            <RevisionButton
                revision={prevRevision}
                labelHint={'Precedente'}
                icon={faChevronLeft}
                onClick={props.onClickPrev}
            />
            <span style={{padding: '10px'}}>
            <Tooltip title={'Revisione corrente'}>{RevisionLabel(props.date)}</Tooltip>
            </span>
            <RevisionButton
                revision={nextRevision}
                labelHint={'Successiva'}
                icon={faChevronRight}
                onClick={props.onClickNext}
            />
        </>
    );
};
export default RevisionPrevNext;


