import styled from 'styled-components';
import * as React from 'react';

export const StyledFAIcon = styled.i`
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const FAIcon: React.FC<any> = ({ type }) => <StyledFAIcon className={type} />;
export default FAIcon;
