import * as React from 'react';
import { Typography } from 'antd';
import { IRegistryEvent } from '../types/domainEvents';
import { IEventRequirement } from '../../Domain/types/domain';
import { WrappedAvailableEventForm } from './AvailableEventForm';

export interface IModalAvailableEventBodyAccept {
  userId: number;
  eventName: string;
  availableEvent: IEventRequirement;
  onSubmit: (err: any, values: IRegistryEvent) => void;
}

export const ModalAvailableEventBodyAccept: React.FC<IModalAvailableEventBodyAccept> = ({
  onSubmit,
  userId,
  eventName,
  availableEvent,
}) => (
  <div className={'modal_available_event_body_accept'}>
    <Typography.Text strong> Creazione evento:</Typography.Text>
    <WrappedAvailableEventForm
      availableEvent={availableEvent}
      onSubmit={onSubmit}
      value={{ name: eventName, status: 'succeeded', userId }}
    />
  </div>
);
