import * as React from "react";

export function useModal(show?: boolean) {
    const [visibile, setVisibile] = React.useState(show || false);

    const open = React.useCallback(() => {
        setVisibile(true);
    }, [setVisibile]);

    const close = React.useCallback(() => {
        setVisibile(false);
    }, [setVisibile]);

    return {visibile, open, close}
}