import {IDomainEvents} from "./types/domainEvents";
import {IFetchStateTypeDomainEvents} from "./reducer";
import {apiGetDomainEvents} from "../../../const/api-calls";
import {parse} from "query-string";

/***
 * Funzione per il recupero degli eventi legati al dominio relativi alla query passata.
 *
 * @param dispatch
 * @param domainEvents - caricati nello store
 * @param domainId - id del dominio
 * @param location - attuale
 */
export function fetchDomainEvents(dispatch: any, domainEvents: IFetchStateTypeDomainEvents, domainId: string,  location: any) {
    const resultQuery = `${location.pathname}${location.search}`;


    console.debug("resultQuery ",resultQuery)
    console.debug("domainEvents.query ",domainEvents.query)
    if (resultQuery === '') {
        dispatch({type: 'DOMAIN_EVENTS_FETCH_CLEAN'})
    } else {
        // Se query memorizzato è diverso da quello cercato e non ci sono errori allora fetchDomains
        if (resultQuery.localeCompare(domainEvents.query) !== 0) {

            dispatch({type: 'DOMAIN_EVENTS_FETCH_LOAD', query: resultQuery})


            const searchParams = lookForKeyLabel('registrarId',location.search)

            apiGetDomainEvents(domainId, searchParams).then((res: any) => {
                dispatch({type: 'DOMAIN_EVENTS_FETCH_SUCCESS', query: resultQuery, payload: res})
            }).catch((error: any | PromiseLike<IDomainEvents>) => {
                dispatch({type: 'DOMAIN_EVENTS_FETCH_ERROR', error})
            })
        }
    }
}


/***
 * Cerca se esistono campi innestati con key e label e se esitono ritorna un terzo campo con il valore di key
 */
function lookForKeyLabel(keyName: string,locationSearch: string){
    const parsedQuery = parse(locationSearch);
    if (locationSearch && locationSearch.includes(keyName.concat(".key")) && locationSearch.includes((keyName.concat(".label")))){
        return locationSearch.concat(`&${keyName}=${parsedQuery[`${keyName}.key`]}`)
    }

    return  locationSearch;
}