import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import it_IT from '../translations/it_IT';

export function internazionalization(): void {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        it: it_IT,
      },
      lng: 'it',
      fallbackLng: 'it',

      interpolation: {
        escapeValue: false,
      },
    });
}

