import {Table, Tooltip} from "antd";
import * as React from "react";
import {IContact} from "../Detail/contacts";
import {contactTypeTag} from "./utils/utils";
import {Link} from "react-router-dom";
import {AbilityContext} from "../../Common/Ability/AbilityContext";
import AddFilter from "../../Common/Navigation/Filter/AddFilter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBuilding,
    faCheck,
    faGlobe,
    faHashtag,
    faHome,
    faLink,
    faQuoteRight,
    faTag,
    faUser,
    faUserCog,
    faUserTie
} from "@fortawesome/free-solid-svg-icons";
import {LinkedDomainsTips} from "../../../const/TipsComponent";
import {Ability} from "@casl/ability";
import {IRegistrar} from "../../Registrar/IRegistrar";
import styled from "styled-components";
import {faPlug} from "@fortawesome/free-solid-svg-icons/faPlug";
import {moment} from "../../../utils/various";
import {ISortedInfo, ITabledResult} from "../../Type/Common";
import {columnSortOrder} from "../../../utils/url-manipulation";
import {FilteredLabelField} from "../../Common/Styled/TableWrapped";
import {Registrar} from "../../Registrar/Registrar";
import {JbillingLink} from "../../Registrar/JBillingLink";
import {Status} from "../../Common/Styled/Status";
import {Flex} from "../../Common/Layout/Flex/Flex";

const {Column, ColumnGroup} = Table;


export type IConcatTabledResults = { data: IContact[] } & ITabledResult;

// TODO rimuovere è stata sostituita con  columnSortOrder in utils/various.ts , ma ad ora per problemi di compilazione nn riesce a togliere
export function order(columnKey: string, sortedInfo: ISortedInfo): boolean | "ascend" | "descend" | undefined {
    if (sortedInfo.columnKey === columnKey) {
        return sortedInfo.order;
    }
    return undefined;
}


// const FilterIcon = () => <FontAwesomeIcon
//   icon={faGlobe}
//   color={'grey'}
//   size={'sm'}
//   style={{ marginRight: 5 }}
// />;
export const HeaderIcon = styled(FontAwesomeIcon)`margin-right: 5px`;

/***
 * Tabella che mostra il risultato di ricerca dei contatti
 * @param data
 * @param sortColumnKey
 * @param sortOrder
 * @param handleOnChange
 * @param onAddFilter
 * @constructor
 */
export const TabledContactsResults: React.FC<IConcatTabledResults> = ({
                                                                          data,
                                                                          sortColumnKey,
                                                                          sortOrder,
                                                                          handleOnChange,
                                                                          onAddFilter
                                                                      }) => {
    const [sortedInfo] = React.useState<ISortedInfo>(
        {order: sortOrder, columnKey: sortColumnKey}
    );
    const {ability} = React.useContext(AbilityContext);

    return <Table
        dataSource={data}
        pagination={false}
        rowKey={(record: IContact) => record.contactId}
        onChange={handleOnChange}
        bordered
        scroll={{x:true}}
    >

        {/* ContactId */}
        <Column title={<span><HeaderIcon icon={faHashtag}/> ContactId</span>}
                ellipsis
                dataIndex={"contactId"}
                key={"contactId"}
                render={(text: any, record: IContact) =>
                    <LinkableContactId record={record}/>}
        />

        {/* Organization */}
        <Column
            title={<span><HeaderIcon icon={faBuilding}/> Organization</span>}
            dataIndex={"org"}
            key={"org"}
            sorter={true}
            sortOrder={columnSortOrder("org", sortedInfo)}
            render={(text: string, record: IContact) => {
                return <Flex><FilteredLabelField>{text}<AddFilter
                    text={text}
                    filter={"org-ft"}
                    onAddFilter={onAddFilter}/>
                </FilteredLabelField></Flex>;
            }}/>

        {/* NAme */}
        <Column
            title={<span><HeaderIcon icon={faQuoteRight}/> Name</span>}
            dataIndex={"name"}
            key={"name"}
            sorter={true}
            sortOrder={columnSortOrder("name", sortedInfo)}
            render={(text: string, record: IContact) => {
                return <Flex><FilteredLabelField>{text}
                    <AddFilter
                        text={text}
                        filter={"name-ft"}
                        onAddFilter={onAddFilter}/>
                </FilteredLabelField></Flex>;
            }}/>

        {/* Type */}
        <Column
            title={<span><HeaderIcon icon={faTag}/> Type</span>}
            dataIndex="type"
            key="type"
            width={'140px'}
            render={(text: string, record: IContact) => {
                const type = contactTypeTag(record, true, onAddFilter);
                return <Flex><FilteredLabelField><Flex >{text} {type}</Flex> </FilteredLabelField></Flex>;
            }}/>

        {/* Status */}
        <Column
            title={<span><HeaderIcon icon={faTag}/> Status</span>}
            dataIndex={"status"}
            key={"status"}
            render={(text: string, record: IContact) => {
                return <Flex>
                    <div key={"status" + record.status} >
                        <FilteredLabelField>
                            <Status size={"small"} >{record.status}</Status>
                            {/* FILTRO DISIBILITATO PERCHé la ricerca dei contatti non supporta il parametro stato */}
                            {/*{record.statuses && <AddFilter text={record.statuses.map((s: { status: string, date: any }) => s.status)}*/}
                            {/*                               filter={"status"}*/}
                            {/*                               onAddFilter={onAddFilter}*/}
                            {/*/>}*/}
                        </FilteredLabelField>
                    </div>
                </Flex>;
            }}
        />

        {/*<Column*/}
        {/*  title='City'*/}
        {/*  dataIndex='city'*/}
        {/*  key='city'*/}
        {/*  sorter={true}*/}
        {/*  sortOrder={order('city', sortedInfo)}*/}
        {/*  render={(text: string, record: IContact) => {*/}
        {/*    return <div>{text} <AddFilter*/}
        {/*      text={text}*/}
        {/*      filter={'city'}*/}
        {/*      onAddFilter={onAddFilter}/>*/}
        {/*    </div>;*/}
        {/*  }}/>*/}

        {/* Registrar */}
        <Column
            title={<span><HeaderIcon icon={faHome}/> Registrar</span>}
            dataIndex="registrarId"
            key="registrarId"
            // sorter={true}
            // sortOrder={order('registrar', sortedInfo)}
            render={(text: string, record: IContact) => {
                return <div>
                    <Registrar registrarId={record.registrarId} children={(registrar: IRegistrar) =>
                        <FilteredLabelField>
                            <JbillingLink registrar={registrar}/>
                            <AddFilter
                                text={{key: registrar.id, label: registrar.name}}
                                filter={"registrarId"}
                                onAddFilter={onAddFilter}
                            />
                        </FilteredLabelField>}/>
                </div>;
            }}/>

        {/* Created */}
        <Column
            title={<span><HeaderIcon icon={faPlug}/> Created</span>}
            dataIndex="created"
            key="created"
            // sorter={true}
            // sortOrder={order('registrar', sortedInfo)}
            render={(text: string, record: IContact) => {
                return <div style={{wordWrap:'break-word'}}>{moment(text).format("LLL")}
                    {/*  <AddFilter*/}
                    {/*    text={{ key: registrar.id, label: registrar.name }}*/}
                    {/*    filter={'registrarId'}*/}
                    {/*    onAddFilter={onAddFilter}/>*/}
                    {/*/>*/}
                </div>;
            }}/>

        {/* Linked */}
        <ColumnGroup title={<><HeaderIcon icon={faGlobe}/><LinkedDomainsTips/></>}>
            <Column
                ellipsis
                title={<Tooltip title={"come Registrant"}><HeaderIcon icon={faUserTie}/></Tooltip>}
                dataIndex="registrant" key="registrant" align="right"
                render={(text: string, record: IContact) => {
                    const {registrant} = linkToLinkedDomains(record, ability);
                    return registrant;
                }}
            />
            <Column
                ellipsis
                title={<Tooltip title={"come Admin"}><FontAwesomeIcon icon={faUser}/></Tooltip>}
                dataIndex="admin" key="admin" align="right"
                render={(text: string, record: IContact) => {
                    const {admin} = linkToLinkedDomains(record, ability);
                    return admin;
                }}
            />
            <Column
                ellipsis
                title={<Tooltip title={"come Tech"}><FontAwesomeIcon icon={faUserCog}/></Tooltip>}
                dataIndex="tech" key="tech" align="right"
                render={(text: string, record: IContact) => {
                    const {tech} = linkToLinkedDomains(record, ability);
                    return tech;
                }}
            />
            <Column
                ellipsis
                title={<Tooltip title={"Linked"}><FontAwesomeIcon icon={faLink}/></Tooltip>}
                dataIndex="linked" key="linked" align="center"
                render={(text: string, record: IContact) => {
                    return record.linkedInfo && record.linkedInfo.linked ?
                        <FontAwesomeIcon icon={faCheck} style={{color: "#52c41a", fontWeight: "bold"}}/> :
                        <FontAwesomeIcon icon={faCheck} style={{color: "rgba(0, 0, 0, 0.25)"}}/>;
                }}
            />
        </ColumnGroup>
    </Table>;
};


/***
 * Offre un link al contactId dell'utente passato gestendo l'ability relativa
 * @param contactId
 * @param id
 * @constructor
 */
export const LinkableContactId: React.FC<{ record: IContact }> = ({record: {contactId, id}}) => {
    const {ability} = React.useContext(AbilityContext);
    return (ability.can("read", "Contact") ?
        <Link to={`/contacts/${id}`}>{contactId}</Link> :
        <div>{contactId}</div>);
};

/***
 * Ritorna un oggetto contenente le tre categorie (admin, registrant, tech) con associati i tre rispettivi Link di
 * ricerca e il numero di domini associati.
 * Gestisce anche il discorso dell'ablitity e cioè se l'utente ga diritto o meno a sfruttare il link proposto
 * @param contact
 * @param ability
 * @constructor
 */
export function linkToLinkedDomains(contact: IContact, ability: Ability): {
    admin: React.ReactElement<any>,
    tech: React.ReactElement<any>,
    registrant: React.ReactElement<any>
} {
    const canInteractWithDomain = ability.can("search", "Domain");

    const result = {
        admin: <span>-</span>,
        tech: <span>-</span>,
        registrant: <span>-</span>
    };
    if (contact.linkedInfo && !contact.linkedInfo.linked) {
        return result;
    }
    if (contact.linkedInfo && contact.linkedInfo.counter) {

        const {registrant, admin, tech} = contact.linkedInfo.counter;

        return canInteractWithDomain ? {
            admin: <Link to={`/domains?admin.contactId=${contact.contactId}`}>{admin}</Link>,
            tech: <Link to={`/domains?tech.contactId=${contact.contactId}`}>{tech}</Link>,
            registrant: <Link to={`/domains?registrant.contactId=${contact.contactId}`}>{registrant}</Link>
        } : {
            admin: <span>{admin}</span>,
            tech: <span>{tech}</span>,
            registrant: <span>{registrant}</span>
        };
    }

    return result;

}