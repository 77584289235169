import styled from 'styled-components';

const ViewerSelectorBox = styled.div<{ align: "center" | "left" | "right" }>`
  float: ${(props  => props.align)};
  border-left: 1px solid #ebedee;
  // margin-left: 10px;
   padding-left: 10px;
`;
ViewerSelectorBox.displayName = 'ViewerSelectorBox';
export default ViewerSelectorBox;
