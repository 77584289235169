import {IOptionSelectList} from "../../SelectList/SelectList";
import {IStateOrProvince} from "./reducer";
import {apiGetStateOrProvince} from "../../../../const/api-calls";

/***
 * Funzione per il recupero del dettaglio dominio relativi alla query passata.
 *
 * @param dispatch
 * @param keycloak
 */
export function fetchStateAndProvince(dispatch: any) {

    Promise.all(apiGetStateOrProvince()).then((responces) => {
        const res: { countries: IOptionSelectList[], provinces: IOptionSelectList[] } = {countries: [], provinces: []};
        // Countries
        if (responces[0].data) {
            responces[0].data.forEach((r: any) => res.countries.push({value: r.id, label: r.name}));
        }

        // Province
        if (responces[1].data) {
            responces[1].data.forEach((r: any) => res.provinces.push({value: r.code, label: r.name}));
        }

        dispatch({type: 'STATEORPROVINCE_FETCH_SUCCESS', payload: res})
    }).catch((error: any | PromiseLike<IStateOrProvince>) => {
        dispatch({type: 'STATEORPROVINCE_FETCH_ERROR', error})
    });

}