import * as React from 'react';
import {DlBox} from '../../Common/Styled/DefinitionList';
import {ContactInfo} from './Info/ContactInfo';
import {IContact} from './contacts';
import {ContactsInfoDiff} from "./Info/Diff/ContactsInfoDiff";
import {DetailBox} from "../../Common/Navigation/Revisions/DetailCard/DetailCardBox";
import {Divider} from "antd";

/***
 * Visualizza il contenuto della Lista contatti
 * @param contacts
 * @constructor
 */
export const ContactList: React.FC<{ contacts: any[] }> = ({contacts}) => (
    <DlBox>
        {contacts.map((value: IContact, index: number) => (
            <ContactInfo key={index} contact={value} />
        ))}
    </DlBox>
);


/***
 * Visualizza il contenuto della Lista contatti evidenziandone le differenze
 * @param contacts
 * @constructor
 */
export const ContactDiffList: React.FC<{ contactsA: IContact[], contactsB: any[] }> = ({contactsA, contactsB}) => {

    let contacts: any[] = contactsB;

    if (contactsA.length > contactsB.length) {
        contacts = contactsA;
    }

    return (
        <DetailBox >
            <>{contacts.map((value: IContact, index: number) => (
                <>
                    <ContactsInfoDiff key={index}
                                      contactA={contactsA[index]}
                                      contactB={contactsB[index]}
                    />
                    <Divider type={"horizontal"}/>
                </>
            ))}</>
        </DetailBox>
    )
};
