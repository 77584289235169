import {Button, Form, Icon, Input} from 'antd';
import * as React from 'react';
// import {domainItValidator as validator} from '../../../utils/domainItValidator';
import {WrappedFormUtils, FormComponentProps} from 'antd/lib/form/Form';
import i18n from 'i18next';
import {useHistory} from "react-router-dom";
import {stringify} from "querystring";
import {DispatchContext} from "../../../StoreProvider";
import {AbilityContext} from "../../../Common/Ability/AbilityContext";
import {Ability} from "@casl/ability";


export interface ISearchingType {
    resource: 'domain' | 'contactId' | 'contactName';
    type: 'search' | 'read'
}

export interface IFormFinderInterface {
    notAllowedSearching: boolean; // permette o meno la ricerca abilitando/disabilitando il bottone
}

/***
 * Decide il tipo di ricerca da effettuare se per contatti o domini tenendo conto anche dei permessi passati
 * @param term
 * @param ability
 */
export function setSearchType(term: string, ability: Ability): ISearchingType {


    if (term.includes(".it")) { // Se è presente un .it Imposta la ricerca per dominio

        if (ability.cannot("search", "Domain")) {
            return {type: "read", resource: "domain"}
        }

        return {type: "search", resource: "domain"}

    } else if (term.includes(" ") && ability.can("search", 'Contact')) {
        // Se c'è un spazio imposta la ricerca per nome contatto
        return {type: "search", resource: 'contactName'};
    } else {
        // Altrimenti ricerca per ID contatto
        if (ability.cannot("search", "Contact")) {
            return {type: "read", resource: "contactId"}
        }
        return {type: "search", resource: 'contactId'};
    }
}

/**
 * Gestione del routing interno deciso dal tipo (searchType) di ricerca che si vuole effettuare
 *
 * @param history
 * @param searchType
 * @param term
 * @param dispatch
 */
export function routeSearchTo(history: any, searchType: ISearchingType, term: string, dispatch: any) {

    if (searchType.type === 'search') { // Ricerca "Avanzata" o completa con permesso SEARCH
        switch (searchType.resource) {
            case 'domain':

                history.push(`/domains?${stringify({domain: term.toLowerCase().trim(), showDeleted: true})}`);
                break;

            case 'contactName':
                history.push(`/contacts?${stringify({'name-ft': term.trim()})}`)
                break;

            default:
                history.push(`/contacts?${stringify({contactId: term.trim()})}`)
                break;

            // Metter il caso se Read a ContactID
        }
    } else { // Ricerca "Limitata" con permesso READ
        switch (searchType.resource) {
            case 'domain':
                dispatch({type: 'DOMAIN_FETCH_CLEAN'})
                dispatch({type: 'DOMAIN_EVENTS_FETCH_CLEAN'})
                dispatch({type: 'DOMAIN_REVISIONS_FETCH_CLEAN'})
                history.push(`/domains/findByName/${term.toLowerCase().trim()}`);
                break;

            default:
                history.push(`/contacts/findByContactId/${term.trim()}`)
                break;

            // Metter il caso se Read a ContactID
        }
    }

}

function handleSubmit(
    e: any,
    form: WrappedFormUtils,
    history: any,
    dispatch: any,
    ability: Ability
): void {
    e.preventDefault();
    form.validateFields((err: any, values: any) => {
        if (!err) {
            const searching = values['searching'];
            if (searching) {
                const searchType = setSearchType(searching, ability);
                routeSearchTo(history, searchType, searching, dispatch);
            }
        }
    });
}


/**
 * Barra di ricerca per Domini e Contatti che viene abilitata o disabilita tramite il parametro notAllowedSearching.
 *
 * Se l'utente ha il permesso di ricercare (quindi notAllowedSearching = false) allora basta inserisce il termine e poi
 * la logica viene decisa nel SearchBarBox nell'onSubmit.
 *
 * @param form
 * @param notAllowedSearching
 * @constructor
 */
export const SearchBarRawForm: React.FC<IFormFinderInterface & FormComponentProps> = ({form, notAllowedSearching}) => {
    const dispatch = React.useContext(DispatchContext);
    const {ability} = React.useContext(AbilityContext);
    const history = useHistory();
    const {getFieldDecorator} = form;

    return (
        <Form data-testid="search-form" layout="inline"
              onSubmit={e => handleSubmit(e, form, history, dispatch, ability)}>
            <Form.Item hasFeedback={true}>
                {getFieldDecorator('searching', {
                    // validateFirst: true,
                    rules: [
                        {required: true, message: i18n.t('insertSearchAll')},
                        {min: 3},
                        {
                            // validator,
                        },
                    ],
                })(
                    <Input
                        data-testid="input-search"
                        style={{width: '500px'}}
                        prefix={<Icon type="search" style={{color: 'rgba(0,0,0,.25)'}}/>}
                        placeholder={ability.can("search", "Domain") ? i18n.t('searchHint') : i18n.t('readHint')}
                        allowClear
                    />,
                )}
            </Form.Item>

            {/* Prima c'era il campo checked per i domini cancallati e per questa ricerca mista l'opzione è stata rimossa*/}
            {/*<Form.Item>*/}
            {/*{getFieldDecorator('showDeleted', {*/}
            {/*valuePropName: 'checked',*/}
            {/*initialValue: value.showDeleted,*/}
            {/*})(<Checkbox>{i18n.t('deleted')}</Checkbox>)}*/}
            {/*</Form.Item>*/}

            <Form.Item>
                <Button id="button-search" data-testid="button-search" type="primary" htmlType="submit"
                        disabled={notAllowedSearching}>
                    {i18n.t('search')}
                </Button>
            </Form.Item>
        </Form>
    );
};

/**
 * Barra di ricerca per Domini e Contatti che viene abilitata o disabilita tramite il parametro notAllowedSearching.
 *
 * Se l'utente ha il permesso di ricercare (quindi notAllowedSearching = false) allora basta inserisce il termine e poi
 * la logica viene decisa nel SearchBarBox nell'onSubmit.
 *
 * @param notAllowedSearching
 * @constructor
 */
const SearchBarForm = Form.create<IFormFinderInterface & FormComponentProps>()(SearchBarRawForm);
export default SearchBarForm;
