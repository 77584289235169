import {AxiosResponse, AxiosError} from "axios";
import {IFetchStateType} from "../../Type/IFetchtStateType";
import { IContactEvents,  } from "./types/contactEvents";

interface IEventActionType {
    type: 'CONTACT_EVENTS_FETCH_SUCCESS' | 'CONTACT_EVENTS_FETCH_ERROR' | 'CONTACT_EVENTS_FETCH_LOAD' | 'CONTACT_EVENTS_FETCH_CLEAN';
    payload?: AxiosResponse;
    error?: AxiosError;
    query?: any;
}


export interface IFetchStateTypeContactEvents extends IFetchStateType<IContactEvents> {
    show: boolean;
}

export const initialState: IFetchStateTypeContactEvents = {
    loading: false,
    query: '',
    error: undefined,
    response: undefined,
    show: false,
}

export const reducer = (state: IFetchStateType<IContactEvents>, action: IEventActionType) => {
    switch (action.type) {
        case "CONTACT_EVENTS_FETCH_LOAD":
            return {...state, loading: true, error: undefined, query: action.query, response: undefined, show: false}
        case "CONTACT_EVENTS_FETCH_SUCCESS":
            return {
                ...state,
                loading: false,
                error: undefined,
                query: action.query,
                response: action.payload,
                show: true
            }
        case "CONTACT_EVENTS_FETCH_ERROR":
            return {...state, loading: false, error: action.error}
        case "CONTACT_EVENTS_FETCH_CLEAN":
            return {...state, loading: false, error: undefined, query: '', response: undefined}
        default:
            return state;
    }
};

export default reducer;