import * as React from 'react';
import {IRegistrar} from './IRegistrar';
import {AbilityContext} from '../Common/Ability/AbilityContext';
import {createCanBoundTo} from '@casl/react';
import {FetchController} from '../Common/Navigation/FetchController/FetchController';
import {Divider, Tag, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPiggyBank} from '@fortawesome/free-solid-svg-icons';
import {JBILLING_HOST} from '../../midaConfig';
import {useFetchRegistrarContract} from "./hooks";

/**
 * Mostra un Tag che contiene il linke del Registrar a Jbilling previo recupero delle informazioni necessarie prese
 * dall'endpoint.
 *
 * @param registrar
 * @constructor
 */
export const JbillingLink: React.FC<{ registrar: IRegistrar, tag?: boolean }> = ({registrar, tag}) => {
    const {ability} = React.useContext(AbilityContext);
    const Can = createCanBoundTo(ability);
    const {loading, data, error} = useFetchRegistrarContract(registrar.id);
    return (
        <Can do={'read'} on={'Registrar'}>
            <FetchController
                loading={loading}
                error={error}
                payload={data}
            >
                {(values) => <Tooltip
                    title={`Visualizza i dettagli di ${registrar.name} su JBilling`}
                >
                    {tag ?
                        <Tag color={'orange'}>
                            <FontAwesomeIcon icon={faPiggyBank}/>
                            <Divider type={'vertical'}/>
                            <Link id={values.id} name={registrar.name}/>
                        </Tag>
                        : <Link id={values.id} name={registrar.name}/>
                    }
                </Tooltip>}
            </FetchController>
        </Can>
    );
};

/**
 * Link al Registrar su Jbilling
 * @param props
 * @constructor
 */
const Link = (props: { id: number, name: string }) => <a
    href={`http://${JBILLING_HOST}/contract/${props.id}`}
    target="_blank"
    rel="noopener noreferrer">{props.name}</a>;
