import { Button, Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import { NativeButtonProps } from 'antd/lib/button/button';

export interface IPaginationButtonProps extends NativeButtonProps, IPaginationButtonStyledProps {
  title: string;
}

export interface IPaginationButtonStyledProps {
  /** Disabilita il bottone */
  disabled?: boolean;
  /** Specifica l'icona compatibile antd */
  icon?: string;
  /** Se falso elimina il bordo al bottone {'true'|'false'} */
  // Forzato a stringa anzichè a boolean questo campo per a boolean dava un warning irrisolvibile dovuto a qualche bug della versione attuale di react
  bordered: 'true' | 'false';
}

export const PaginationButtonStyled = styled(Button)<IPaginationButtonStyledProps>`
  margin-right: 2px;
  border: ${props => {
    return props.bordered === 'true' ? '' : '0px!important';
  }};
`;
PaginationButtonStyled.displayName = 'PaginationButtonStyled';

class PaginationButton extends React.Component<IPaginationButtonProps, {}> {
  public render(): React.ReactNode {
    const { ...rest }: IPaginationButtonStyledProps = this.props;
    return (
      <Tooltip title={this.props.title}>
        <PaginationButtonStyled {...rest} />
      </Tooltip>
    );
  }
}
export default PaginationButton;
