import * as React from 'react';
import styled from 'styled-components';
// import {AbilityContext} from "../../../Common/Ability/AbilityContext";
import {Flex} from "../../../Common/Layout/Flex/Flex";
import SearchBarForm from "./SearchBarForm";


export const StyledHomeBox = styled.div <{ size: 'small' | 'large' }>`
  background-color: #2075bb45;
  background-color: #fbfbfb;
  //margin-left: 25%;
  //margin-right: 25%;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #d9d9d9;

  border-radius: ${props => {
    switch (props.size) {
        case 'small' :
            return '6px';
        case 'large' :
            return '60px';
        default:
            return '5px';
    }
}
}
  
  ${props => {
    switch (props.size) {
        case 'small' :
            return 'padding-top: 20px ; padding-bottom: 20px';
        case 'large' :
            return 'padding-top: 40px ; padding-bottom: 50px';
        default:
            return 10;
    }
}
}
`;
StyledHomeBox.displayName = 'StyledHomeBox';



interface IProps {
    boxed: boolean;
    boxedSize?: 'small' | 'large';
}

/***
 * Mostra la barra di ricerca che può optare tra questi tre tipi di ricerca:  'domain' | 'contactId' | 'contactName'.
 * In primis però vengono anche controllate le abilità dell'utente e quindi se è possibile permettere la ricerca
 *
 * @param boxed
 * @param boxedSize
 * @constructor
 */
const SearchBarBox: React.FC<IProps> = ({boxed, boxedSize}) => {


    // const {ability} = React.useContext(AbilityContext);
    // const notAllowedSearching = ability.cannot('search', 'Domain') && ability.cannot('search', 'Contact');
    // console.log(" ",notAllowedSearching)

    const RenderSearchBar = () =>
        <Flex justifyContent={"center"} alignItems={"flex-start"}>
            <Flex justifyContent={"center"} alignItems={"baseline"}>
                <SearchBarForm
                    notAllowedSearching={false}
                />
            </Flex>
        </Flex>;


    return (
        <React.Fragment>
            {boxed ?
                <StyledHomeBox data-testid="styledHomeBox" size={boxedSize || 'small'}>
                    <RenderSearchBar/>
                </StyledHomeBox>
                :
                <RenderSearchBar/>
            }
        </React.Fragment>
    );
};


export default SearchBarBox;

