import * as React from "react";
import {DispatchContext, StateContext} from "../../../StoreProvider";
import {fetchDomainEvents} from "../operations";
import {FetchController} from "../../../Common/Navigation/FetchController/FetchController";
import {IDomainEvents} from "../types/domainEvents";
import PaginatedTable from "../PaginatedTable";
import {useLocation, useRouteMatch} from "react-router-dom";

const DomainEvents: React.FC<{refRevisionDate?: string }> = ({refRevisionDate}) => {

    const dispatch = React.useContext(DispatchContext);
    const {domainEvents} = React.useContext(StateContext);
    const location = useLocation();
    const {params} = useRouteMatch();

    React.useEffect(() => {
        fetchDomainEvents(dispatch, domainEvents, params['domain'], location);
    }, [location.pathname])

    return (
        <div>
            <FetchController {...domainEvents} >
                {(data: IDomainEvents) => <PaginatedTable {...data} refRevisionDate={refRevisionDate}/>}
            </FetchController>
        </div>
    )
}

export default DomainEvents;