import { Button, Table as LocalTable, Tag } from 'antd';
import * as React from 'react';
import { IDomainEvent } from './types/domainEvents';
import styled from 'styled-components';
import { moment, stringToHslColor, useQuery } from '../../../utils/various';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faCaretDown,
  faCaretUp,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { order } from '../../Contacts/Search/TabledContactsResults';
import {
  SorterResult,
  SortOrder,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';
import { PaginationConfig } from 'antd/lib/pagination';
import AddFilter from '../../Common/Navigation/Filter/AddFilter';
import { IRegistrar } from '../../Registrar/IRegistrar';
import { ISortedInfo } from '../../Type/Common';
import { FilteredLabelField } from '../../Common/Styled/TableWrapped';
import { TwoLinesDate } from '../../Common/DataDisplay/Dates/Dates';
import { Registrar } from '../../Registrar/Registrar';
import {
  EppDescriptionContent,
  RegistryDescriptionContent,
} from './TableHelpers';
import UserWithAvatar from '../../Common/DataDisplay/UserWithAvatar/UserWIthAvatar';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { stringify } from 'query-string';
import {
  IRevision,
  IRevisions,
} from '../../Common/Navigation/Revisions/revisions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Column from 'antd/es/table/Column';

export function acronym(str: string) {
  return str
    .replace('-', '')
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), '');
}

function handleClassNameRow(
  record: any,
  domainRevisions?: IRevisions,
  refRevisionDate?: string,
): string {
  const status = 'status';
  const created = 'created';
  const recordDate = moment(record[created]);
  let className = '';
  const revision = domainRevisions?.elements?.filter(
    value => recordDate >= moment(value.date),
  )[0];

  record[status] === 'succeeded'
    ? (className += ' succeeded')
    : (className += ' fail');

  if (refRevisionDate) {
    const revisionDate = moment(refRevisionDate);
    if (revision && moment(revision.date) > revisionDate) {
      record[status] === 'succeeded'
        ? (className += ' hide succeeded')
        : (className += ' hide fail');
      // className = 'hide';
    }
  }

  return className;
}

const StyledTable = styled(LocalTable)`
  background-color: white;

  tr.fail {
    color: #f5222c;
    background-color: snow;
  }

  tr.succeeded {
    color: #52c419 !important;
    //background-color: honeydew;
  }

  tr.hide-succeeded {
    color: #c3e2c5;
  }

  tr.hide-fail {
    //color: #f4d2d2;
    opacity: 0.2;
  }

  tr.hide-icon {
    color: #c3e2c5;
  }

  tr.hide {
    opacity: 0.35;
  }

  td.white {
    background-color: white;
  }
`;

const Table: React.FC<{
  domainEvents: IDomainEvent[];
  domainRevisions?: IRevisions;
  refRevisionDate?: string;
  sortOrder?: SortOrder;
  sortColumnKey?: string;
  onAddFilter?: (filter: any, values: string) => void;
  onChange?: (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof any, string[]>>,
    sorter: SorterResult<any>,
    extra: TableCurrentDataSource<any>,
  ) => void;
}> = props => {
  const { params } = useRouteMatch();
  const query = useQuery();
  const _revisionUrl = `/domains/${params['domain']}/revisions`;
  console.log('DomainsRevision', props.domainRevisions);
  console.log('[Table DomainsRevision]', props);

  const [sortedInfo] = React.useState<ISortedInfo>({
    order: props.sortOrder,
    columnKey: props.sortColumnKey,
  });

  const columns: Column<any> | any = [
    {
      // TIPO
      dataIndex: 'type',
      key: 'type',
      title: 'Tipo',
      sorter: true,
      sortOrder: order('type', sortedInfo),
      width: 100,
      // ellipsis: true,
      render: (text: string) => {
        return (
          <FilteredLabelField>
            {text}
            <AddFilter
              text={text}
              filter={'type'}
              onAddFilter={props.onAddFilter}
            />
          </FilteredLabelField>
        );
      },
    },
    {
      // EVENTO
      dataIndex: 'name',
      key: 'name',
      title: 'Evento',
      // width: 250,
      render: (text: string, record: IDomainEvent) => {
        return (
          <FilteredLabelField>
            <Tag
              color={stringToHslColor(
                text.substring(text.length - 10, text.length),
                50,
                90,
              )}
            >
              <span
                style={{
                  // fontWeight: '600',
                  color: stringToHslColor(
                    text.substring(text.length - 10, text.length),
                    50,
                    45,
                  ),
                }}
              >
                {text}
              </span>
            </Tag>
            <AddFilter
              text={text}
              filter={'name'}
              onAddFilter={props.onAddFilter}
            />
          </FilteredLabelField>
        );
      },
    },
    {
      // DESCRIZIONE
      dataIndex: 'epp',
      key: 'epp',
      title: 'Descrizione / Codice',
      render: (text: string, record: IDomainEvent) => {
        if (record.registry) {
          return <RegistryDescriptionContent data={record.registry} />;
        } else if (record.epp) {
          return <EppDescriptionContent data={record.epp} />;
        } else {
          return '';
        }
      },
    },

    // {
    //   // ESITO
    //   dataIndex: 'status',
    //   key: 'status',
    //   title: 'Esito',
    //   // width: 50,
    //   ellipsis: true,
    //
    //   render: (text: string) => {
    //     let element;
    //     switch (text) {
    //       case 'succeeded':
    //         element = <FontAwesomeIcon icon={faCheckCircle} />;
    //         break;
    //       case 'failed':
    //         element = <FontAwesomeIcon icon={faTimesCircle} />;
    //         break;
    //       default:
    //         element = 'n.d';
    //     }
    //     return (
    //       <FilteredLabelField
    //         style={{
    //           textAlign: 'center',
    //           justifyContent: 'center',
    //           fontSize: 16,
    //         }}
    //       >
    //         {element}
    //         <AddFilter
    //           text={text}
    //           filter={'status'}
    //           onAddFilter={props.onAddFilter}
    //         />
    //       </FilteredLabelField>
    //     );
    //   },
    // },

    {
      // UTENTE
      dataIndex: 'user',
      key: 'user',
      title: 'Utente',
      // width: 65,
      className: 'white',
      // https://marcoslooten.com/blog/creating-avatars-with-colors-using-the-modulus/
      ellipsis: true,

      render: (text: string, record: IDomainEvent) => {
        if (record.registry && record.registry.userId) {
          return (
            <div style={{ textAlign: 'center', justifyContent: 'center' }}>
              <UserWithAvatar userId={record.registry.userId} />
            </div>
          );
        } else {
          return '';
        }
      },
    },
    // {
    //   dataIndex: 'userId',
    //   key: 'userId',
    //   title: 'Utente',
    // },

    {
      // CREATO
      dataIndex: 'created',
      key: 'created',
      title: 'Creato',
      sorter: true,
      sortOrder: order('created', sortedInfo),
      colSpan: 1,
      // width: '160px',
      className: 'white',

      render: (text: string) => {
        // return <span>{moment(text).format('DD/MM/YYYY, HH:mm:ss')}</span>;
        return (
          <div style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
            <TwoLinesDate date={text} />
          </div>
        );
      },
    },
    {
      // REGISTRAR
      dataIndex: 'registrarId',
      key: 'registrarId',
      title: 'Registrar',

      // className: 'white',
      // ellipsis: true,

      render: (text: string, record: IDomainEvent) => {
        return (
          <Registrar
            registrarId={record.registrarId}
            children={(registrar: IRegistrar) => (
              <FilteredLabelField>
                <div
                  style={{
                    color: stringToHslColor(registrar.name, 60, 50),
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {registrar.name}
                </div>
                <AddFilter
                  text={{ key: registrar.id, label: registrar.name }}
                  filter={'registrarId'}
                  onAddFilter={props.onAddFilter}
                />
              </FilteredLabelField>
            )}
          />
        );
      },
    },
    {
      // Revision
      key: 'revision',
      title: 'Revisioni associate',
      width: 250,
      className: 'white',
      ellipsis: true,

      render: (text: string, record: any) => {
        const currentEventDate = moment(record.created);
        // prende la prima revisione di valore < alla data evento
        const upRevision = props?.domainRevisions?.elements
          ?.filter(value => moment(value.date) > currentEventDate)
          .slice(-1)[0];
        // prende la prima revisione di valore >= alla data evento
        const downRevision = props?.domainRevisions?.elements?.filter(
          value => currentEventDate >= moment(value.date),
        )[0];

        // valore utile alla generazione del colore per la revisione UP, il * 10 serve a distanziare un po' i valori vicini
        const upColorstring =
          upRevision &&
          (props.domainRevisions?.elements.indexOf(upRevision, 0)
            ? props.domainRevisions?.elements.indexOf(upRevision, 0) * 10
            : 0
          ).toString();
        // valore utile alla generazione del colore per la revisione DOWN, il * 10 serve a distanziare un po' i valori vicini
        // const downColorstring = downRevision && (props.domainRevisions?.elements.indexOf(downRevision, 0) ? props.domainRevisions?.elements.indexOf(downRevision, 0) * 10 : 0).toString();

        // colore per la revisione UP
        const upgGeneratedColor = upColorstring
          ? stringToHslColor(
              upColorstring,
              30,
              getDelayFromDates(record.created, upRevision?.date),
            )
          : 'grey';
        // colore per la revisione DOWN
        const downGeneratedColor = upColorstring
          ? stringToHslColor(
              upColorstring,
              30,
              getDelayFromDates(record.created, downRevision?.date),
            )
          : 'grey';

        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            {upRevision && (
              <RevisionTimeTag
                revision={upRevision}
                revisionUrl={_revisionUrl}
                color={upgGeneratedColor}
                query={stringify(query)}
                type={'up'}
              />
            )}
            <span
              style={{
                backgroundColor: 'rgb(232, 232, 232)',
                height: '1px',
                marginLeft: 10,
                marginRight: 10,
              }}
            />
            {downRevision && (
              <RevisionTimeTag
                revision={downRevision}
                revisionUrl={_revisionUrl}
                color={downGeneratedColor}
                query={stringify(query)}
                type={'down'}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <StyledTable
      dataSource={props.domainEvents}
      columns={columns}
      rowKey="id"
      bordered
      // scroll={{  y: 300 }}
      scroll={{ x: true }}
      pagination={false}
      size={'small'}
      rowClassName={record =>
        handleClassNameRow(record, props.domainRevisions, props.refRevisionDate)
      }
      onChange={props.onChange}
    />
  );
};
export default Table;

interface ITimeTag {
  color: string;
  leftTime?: string;
  rightTime?: string;
}

/**
 * Visualizza uno o due tag, nel primo la data e nel secondo il tempo
 * @param props
 * @constructor
 */
export function TimeTag(props: ITimeTag) {
  const fontSize = '0.8rem';
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Tag style={{ cursor: 'pointer' }} color={props.color}>
          <FontAwesomeIcon icon={faCalendar as IconProp} />
          <span style={{ marginLeft: 5 }}>{props.leftTime}</span>
        </Tag>
      </div>
      {props.rightTime && (
        <div style={{ flexDirection: 'row', fontSize }}>
          <Tag style={{ cursor: 'pointer' }} color={props.color}>
            <FontAwesomeIcon icon={faClock as IconProp} />
            <span style={{ marginLeft: 5 }}>{props.rightTime}</span>
          </Tag>
        </div>
      )}
    </div>
  );
}

interface IRevisionTimeTag {
  revision: IRevision;
  query: string;
  revisionUrl: string;
  color: string;
  type: 'up' | 'down';
  disabled?: boolean;
}

/***
 * Visualizza un TimeTag che linka ad una revisione.
 * Inoltre viene anche mostrato un'icona che indica se la revisione è il del limite superiore o inferiore
 * @param props
 * @constructor
 */
function RevisionTimeTag(props: IRevisionTimeTag) {
  const history = useHistory();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        alignItems: props.type === 'up' ? 'flex-start' : 'flex-end',
      }}
      className={'revision-time_tag'}
    >
      <FontAwesomeIcon
        icon={(props.type === 'down' ? faCaretDown : faCaretUp) as IconProp}
        style={{ color: '#808080', fontSize: '1.2rem' }}
      />
      <Button
        type={'link'}
        onClick={() =>
          history.push(
            `${props.revisionUrl}/${props.revision.number}?${props.query}`,
          )
        }
      >
        <TimeTag
          color={props.color}
          leftTime={moment(props.revision.date).format('DD/MM/YYYY')}
          rightTime={moment(props.revision.date).format('HH:mm:ss')}
        />
      </Button>
    </div>
  );
}

/**
 * Ritorna un valore associabile al valore di luminosità da applicare alla generazione del colore
 * legato ai giorni di distanza dalla data
 * @param startDate
 * @param endDate
 */
function getDelayFromDates(startDate?: string, endDate?: string): number {
  if (!(startDate && endDate)) {
    return 10;
  }
  const days = Math.abs(moment(startDate).diff(endDate, 'days'));
  if (days < 1) {
    return 50;
  }
  if (days < 5) {
    return 75;
  }
  if (days < 25) {
    return 80;
  }
  if (days < 100) {
    return 90;
  }
  return 95;
}
