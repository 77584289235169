import * as React from 'react';
import {  useRouteMatch} from 'react-router-dom';
import {IScene} from '../../../../Type/IScene';
import RevisionPrevNext from '../../../../Common/Navigation/Revisions/RevisionPrevNext/RevisionPrevNext';
import {Tag, Typography} from "antd";
import ContactMaster from "../../../Detail/ContactMaster";
import ContactStatusBar from "../../../Detail/ContactStatusBar";
import {IContact} from "../../../Detail/contacts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHistory} from "@fortawesome/free-solid-svg-icons";
import {DispatchContext, StateContext} from "../../../../StoreProvider";
import {FetchController} from "../../../../Common/Navigation/FetchController/FetchController";
import {fetchContactRevision} from "../operations";
import {useRouter} from "../../../../Common/Hooks/useRouter";

interface IContactRevisionPageType {
    contact?: string;
}


const ContactRevisionPage: React.FC<IContactRevisionPageType & IScene> = props => {
    const dispatch = React.useContext(DispatchContext);
    const {contactRevision} = React.useContext(StateContext);
    const {contactRevisions} = React.useContext(StateContext);
    const {location, history} = useRouter();
    const {params} = useRouteMatch();

    const query = `${location.pathname}${location.search}`;

    React.useEffect(() => {
        fetchContactRevision(dispatch, params['contact'], params['revision'], contactRevision, query);
    }, [query]);

    return (
        <FetchController {...contactRevision}>
            {(data) => (
                <ContactMaster
                    bgColor="#B7CBE1"
                    contact={data}
                    title={<div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography.Title level={2}
                                          style={{marginRight: '10px'}}>{'Dettaglio contatto'}</Typography.Title>
                        <Tag color="#108ee9"><FontAwesomeIcon icon={faHistory}/> REVISIONE</Tag>
                        <RevType
                            type={data.revision.type}/>
                    </div>}
                    extraTab={
                        <ContactStatusBar contact={data as IContact}/>
                    }
                    revNav={
                        contactRevisions.response &&
                        <div style={{float: 'right'}}><RevisionPrevNext
                            {...data.revision}
                            revisions={contactRevisions.response.data}
                            onClickNext={(nextRev) => history.push(nextRev.number.toString())}
                            onClickPrev={(prevRev) => history.push(prevRev.number.toString())}
                        /></div>
                    }
                />
            )}
        </FetchController>
    );
};

export default ContactRevisionPage;

const RevType: React.FC<{ type: string | undefined }> = ({type}) => {
    if (type === 'MOD') {
        return <Tag color="#f50">Tipo: MODIFICA</Tag>
    }

    if (type === 'ADD') {
        return <Tag color="#87d068">Tipo: CREAZIONE</Tag>
    }
    return <div/>
};