export default [
    // REASONS
    {code: 10001, title: "DNSSEC: registrar non accreditato DNSSEC"},
    {code: 10002, title: "DNSSEC: elemento maxSigLife non supportato"},
    {code: 10003, title: "DNSSEC: elemento keyData non supportato"},
    {code: 10004, title: "DNSSEC: attributo urgent non supportato"},
    {code: 10005, title: "DNSSEC: nessun elemento dsData da rimuovere o aggiungere"},
    {code: 10006, title: "DNSSEC: troppi elementi dsData"},
    {code: 10007, title: "DNSSEC: valore di digestType non valido"},
    {code: 10008, title: "DNSSEC: valore di alg non valido"},
    {code: 10009, title: "DNSSEC: valore di digest non valido"},
    {code: 10010, title: "DNSSEC: elemento dsData duplicato"},
    {code: 10011, title: "DNSSEC: elemento dsData da aggiungere già associato al dominio"},
    {code: 10012, title: "DNSSEC: elemento dsData da rimuovere non e'associato al dominio"},
    {code: 10013, title: "DNSSEC: elemento dsData non esiste"},
    {code: 10101, title: "DNSSEC Accreditation test: violazione di un vincolo del commando"},
    {code: 10102, title: "DNSSEC Accreditation test: controllo DNS terminato con fallimento"},
    {code: 9001, title: "AuthInfo mancante"},
    {code: 9002, title: "AuthInfo del dominio non valido"},
    {code: 9003, title: "Contatto non esistente"},
    {code: 9004, title: "Nomi di name server duplicati"},
    {code: 9005, title: "Pochi name servers"},
    {code: 9006, title: "Troppi name servers"},
    {code: 9007, title: "Errore di sintassi nel dominio"},
    {code: 9009, title: "ID del nuovo registrante e' l'ID del registrante corrente"},
    {code: 9010, title: "Almeno un contatto amministrativo e' richiesto"},
    {code: 9011, title: "Pochi contatti amministrativi"},
    {code: 9012, title: "Troppi contatti amministrativi"},
    {code: 9013, title: "Almeno un contatto tecnico e' richiesto"},
    {code: 9014, title: "Pochi contatti tecnici"},
    {code: 9015, title: "Troppi contatti tecnici"},
    {code: 9016, title: "Registrante mancante"},
    {
        code: 9017,
        title: "Dominio non puo' essere associato ad un registrante con entity type ! 1 e consentForPublishing impostato a false"
    },
    {
        code: 9018,
        title: "Registrar di destinazione dell'operazione transfer e' il registrar che detiene il dominio"
    },
    {code: 9019, title: "Non c'e' niente da modificare"},
    {code: 9020, title: "Opzione di transfer non supportata"},
    {code: 9021, title: "Dominio e' riservato"},
    {code: 9022, title: "Dominio ha status clientTransferProhibited"},
    {code: 9023, title: "Dominio ha status serverTransferProhibited"},
    {code: 9024, title: "Dominio ha status clientDeleteProhibited"},
    {code: 9025, title: "Dominio ha status serverDeleteProhibited"},
    {code: 9026, title: "Dominio ha status clientUpdateProhibited"},
    {code: 9027, title: "Dominio ha status serverUpdateProhibited"},
    {code: 9028, title: "Contatto da aggiungere già associato al dominio"},
    {code: 9029, title: "Contatto da rimuovere non e' associato al dominio"},
    {code: 9030, title: "Status da aggiungere non ha prefisso \"client\""},
    {code: 9031, title: "Status da rimuovere non ha prefisso \"client\""},
    {code: 9032, title: "Status in add già associato al dominio"},
    {code: 9033, title: "Status da rimuovere non e' associato al dominio"},
    {code: 9034, title: "Name server da aggiungere già associato al dominio"},
    {code: 9035, title: "Name server da rimuovere non e' associato al dominio"},
    {code: 9036, title: "Dominio non esiste"},
    {code: 9037, title: "Contatti duplicati"},
    {code: 9038, title: "Elemento domain:add e' vuoto"},
    {code: 9039, title: "Elemento domain:rem e' vuoto"},
    {code: 9040, title: "Elemento domain:chg e' vuoto"},
    {code: 9041, title: "Combinazione di domain update status, host e registrant non permessa"},
    {code: 9042, title: "Dominio e' registrato"},
    {code: 9043, title: "Dominio e' non assegnabile"},
    {code: 9044, title: "Dominio e' geografico"},
    {code: 9046, title: "Dominio ha status clientHold"},
    {code: 9047, title: "Domainio ha status serverHold"},
    {code: 9048, title: "Il Name server da aggiungere e' subordinato al dominio ma non ha indirizzi IP"},
    {code: 9049, title: "Lunghezza del campo authInfo non valida"},
    {code: 9050, title: "Troppi nomi a dominio"},
    {code: 9051, title: "Assenza dei permessi per vedere lo stato della richiesta di trasferimento"},
    {code: 9053, title: "Mancano i permessi per cancellare la richiesta di trasferimento del dominio"},
    {code: 9054, title: "Trasferimento dominio non in corso"},
    {code: 9055, title: "Dominio ha status ok"},
    {code: 9056, title: "Dominio ha status inactive"},
    {code: 9057, title: "Dominio ha status dnsHold"},
    {code: 9058, title: "Dominio ha status autoRenewPeriod"},
    {code: 9059, title: "Dominio ha status status pendingUpdate"},
    {code: 9060, title: "Dominio ha status status pendingTransfer"},
    {code: 9061, title: "Dominio ha status noRegistrar"},
    {code: 9062, title: "Dominio ha status toBeReassigned"},
    {code: 9063, title: "Dominio ha status challenged"},
    {code: 9064, title: "Dominio ha status redemptionPeriod"},
    {code: 9065, title: "Dominio ha status revoked"},
    {code: 9066, title: "Dominio ha status pendingDelete"},
    {code: 9067, title: "AuthInfo nuova e' l'authInfo corrente"},
    {code: 9068, title: "AuthInfo mancante nella modifica del dominio"},
    {code: 9070, title: "Contatti billing non sono permessi"},
    {code: 9071, title: "Mancano i permessi per approvare la richiesta di trasferimento del dominio"},
    {code: 9072, title: "Mancano i permessi per rigettare la richiesta di trasferimento del dominio"},
    {code: 9073, title: "Status di dominio non implementato dal server"},
    {code: 9074, title: "Almeno due name servers sono richiesti"},
    {code: 9075, title: "Duplicato lo status da aggiungere"},
    {code: 9076, title: "Duplicato lo status da rimuovere"},
    {code: 9077, title: "Dominio ha status notRenewed"},
    {
        code: 9078,
        title: "I nomi a dominio cancellati da meno di 7 giorni devono essere registrati su epp-deleted.nic.it (epp-deleted.pubtest.nic.it per l'ambiente di test)"
    },
    {
        code: 9079,
        title: "La richiesta per il dominio riferisce un contatto incompleto.Un campo obbligatorio e' vuoto o ha un valore errato"
    },
    {
        code: 9080,
        title: "La richiesta per il dominio riferisce un registrante incompleto.Un campo obbligatorio e' vuoto o ha un valore errato"
    },
    {code: 9081, title: "Dominio soggetto ad una operazione bulk"},
    {
        code: 9083,
        title: "Solo i nomi a dominio cancellati da meno di 7 giorni possono essere registrati su questo server"
    },
    {code: 9085, title: "AuthInfo del dominio non valido o dominio non esiste"},
    {code: 9086, title: "Opzione hostObj non supportata"},
    {code: 9087, title: "Opzione report non supportata"},
    {code: 9100, title: "Registrar non e' abilitato a registrare domini 'edu.it' "},
    {code: 9101, title: "Impossibile verificare se il dominio 'edu.it' e' riservato"},
    {code: 9102, title: "Dominio 'edu.it' non puo' essere associato ad un registrante senza school code"},
    {code: 9103, title: "Dominio 'edu.it' e' riservato ad un registrante con uno school code diverso"},
    {code: 8001, title: "Contact ID errore di sintassi"},
    {code: 8002, title: "Contact ID prefisso non valido"},
    {code: 8004, title: "Non c'e' niente da modificare"},
    {code: 8005, title: "Contatto associato con uno o piu' domini"},
    {code: 8006, title: "Il contatto ha lo status clientDeleteProhibited"},
    {code: 8007, title: "Il contatto ha lo status serverDeleteProhibited"},
    {code: 8008, title: "Il contatto ha lo status clientUpdateProhibited"},
    {code: 8009, title: "Il contatto ha lo status serverUpdateProhibited"},
    {code: 8010, title: "Duplicato lo status da aggiungere"},
    {code: 8011, title: "Duplicato lo status da rimuovere"},
    {code: 8012, title: "Status da aggiungere non ha il prefisso \"client\""},
    {code: 8013, title: "Status da rimuovere non ha il prefisso \"client\""},
    {code: 8014, title: "Status da aggiungere e' già associato al contatto"},
    {code: 8015, title: "Status da rimuovere non e' associato al contatto"},
    {code: 8017, title: "Troppi elementi postalInfo in forma locale"},
    {code: 8018, title: "Indirizzo email sintatticamente non corretto"},
    {code: 8019, title: "Indirizzo email mancante"},
    {code: 8020, title: "Consenso alla pubblicazione mancante"},
    {code: 8021, title: "Troppi identificatori del contatto"},
    {code: 8022, title: "Numero di telefono mancante"},
    {code: 8023, title: "Registrante: entity type mancante"},
    {code: 8024, title: "Registrante: entity type non valido"},
    {code: 8025, title: "Registrante: nationality code mancante"},
    {code: 8026, title: "Registrante: reg code mancante"},
    {code: 8027, title: "Registrante: reg code non valido"},
    {
        code: 8028,
        title: "Registrante: consentForPublishing non puo' essere impostato a false se entity type ! 1"
    },
    {code: 8029, title: "Registrante: registrante con entity type   1 e admin sono differenti"},
    {code: 8030, title: "Il contatto non e' un registrante"},
    {code: 8031, title: "Il postalInfo in forma internazionale non e' permesso"},
    {code: 8032, title: "PostalInfo mancante"},
    {code: 8034, title: "PostalInfo: name mancante"},
    {code: 8035, title: "PostalInfo: org mancante"},
    {code: 8036, title: "PostalInfo: addr mancante"},
    {code: 8037, title: "PostalInfo: street mancante"},
    {code: 8038, title: "PostalInfo: troppe streets"},
    {code: 8039, title: "PostalInfo: city mancante"},
    {code: 8040, title: "PostalInfo: sp mancante"},
    {code: 8041, title: "PostalInfo: pc mancante"},
    {code: 8042, title: "PostalInfo: cc mancante"},
    {code: 8043, title: "PostalInfo: non e' possibile cambiare il nome di un registrante con entity type  1"},
    {code: 8044, title: "PostalInfo: non e' possibile cambiare il campo org per un registrante"},
    {code: 8045, title: "PostalInfo: non e' possibile cambiare il cc di un registrante con entity type !1"},
    {code: 8046, title: "Email non può essere cambiato con una stringa vuota"},
    {code: 8047, title: "Voice non può essere cambiato con una stringa vuota"},
    {code: 8048, title: "PostalInfo: cc non valido"},
    {code: 8049, title: "PostalInfo: sp non valido"},
    {code: 8050, title: "Contatto non detenuto dal registrar"},
    {code: 8051, title: "Registrante: nationality code non valido"},
    {code: 8052, title: "Registrante: nationality code non consentito"},
    {code: 8053, title: "Voice sintatticamente non corretto "},
    {code: 8054, title: "Fax sintatticamente non corretto"},
    {code: 8055, title: "Il registrante generato da un contatto admin/tech deve avere entity type 1"},
    {code: 8056, title: "Registrante: contatto già presente come registrante - modifica proibita"},
    {code: 8057, title: "Registrante: registrante con entity type  1 org e name sono differenti"},
    {code: 8058, title: "Il contatto già esiste"},
    {code: 8059, title: "Status di contatto non implementato dal server"},
    {code: 8060, title: "Registrante: il registrante non può essere un minorenne"},
    {code: 8061, title: "Elemento contact:add e' vuoto"},
    {code: 8062, title: "Elemento contact:rem e' vuoto"},
    {code: 8063, title: "Elemento contact:chg e' vuoto"},
    {code: 8064, title: "Registrante: entity type non e' compatibile con nationality code"},
    {code: 8065, title: "PostalInfo: pc non valido"},
    {code: 8066, title: "Estensione di voice sintatticamente non corretta"},
    {code: 8067, title: "Estensione di fax sintatticamente non corretta"},
    {code: 8069, title: "Registrante: country code non consentito"},
    {code: 8070, title: "PostalInfo: org non valido"},
    {code: 8100, title: "Registrante: entity type non e' compatibile con school code"},
    {code: 8101, title: "Registrante: school code non e' valido"},
    {code: 8102, title: "Registrante: school code non esiste"},
    {code: 8103, title: "Registrante: school code non e' attivo"},
    {code: 8104, title: "Registrante: reg code non e' compatibile con school code"},
    {code: 8105, title: "Registrante: impossibile verificare lo school code"},
    {code: 7001, title: "Errore di sintassi nel nome dell'host"},
    {code: 7002, title: "Indirizzi IP duplicati"},
    {code: 7003, title: "Errore di sintassi nell'indirizzo IP"},
    {code: 7004, title: "Host non esiste"},
    {code: 7005, title: "Pochi indirizzi IP"},
    {code: 7006, title: "Troppi indirizzi IP"},
    {code: 7007, title: "Un indirizzo IP v4 e' richiesto per questo host"},
    {code: 7008, title: "Indirizzo IP da aggiungere già presente"},
    {code: 6001, title: "Mancanza dei permessi per eseguire il comando"},
    {code: 6002, title: "Oggetto non esistente"},
    {code: 6003, title: "Account scaduto"},
    {code: 6004, title: "Password scaduta"},
    {code: 6005, title: "Username o password non validi"},
    {code: 6006, title: "Login fallito"},
    {code: 6007, title: "Account disabilitato"},
    {code: 6008, title: "Nuova password non valida"},
    {code: 6009, title: "Mancanza dei permessi per eseguire il comando o oggetto non esistente"},
    {code: 5001, title: "Message ID manca"},
    {code: 5002, title: "Message ID non e' permesso"},
    {code: 5003, title: "Message ID non e' il primo messaggio della coda"},
    {code: 5004, title: "Non ci sono messaggi nella coda"},
    {code: 5005, title: "Messaggio riferisce un URI di un namespace non presente nella richiesta Login"},
    {code: 5050, title: "Raggiunto il limite dei comandi"},
    {code: 5051, title: "Raggiunto il limite delle sessioni aperte"},
    {code: 5052, title: "Indirizzo IP utente non permesso"},
    {code: 5053, title: "Proprietà e' obbligatoria"},
    {code: 5054, title: "Credito basso: solo i rinnovi e comandi non fatturabili saranno eseguiti"},
    {code: 5055, title: "Credito esaurito"},
    {code: 5056, title: "Credito andra' sotto la soglia di mantenimento per il costo dell'operazione"},
    {code: 5057, title: "Comando proveniente da indirizzo IP non uguale a quello della Login"},
    {code: 5058, title: "Il Registrar e' sospeso"},
    {code: 4000, title: "Errore nel database"},
    {code: 4001, title: "Errore dovuto alla concorrenza"},
    {code: 4002, title: "Valori non validi"},
    {code: 4003, title: "Errore di sintassi"},
    {
        code: 4004,
        title: "Comando gia' eseguito con successo durante il test di accreditamento. L'errore non inficia il risultato del test"
    },
    {code: 4005, title: "Comando inaspettato nel test di accreditamento"},
    {code: 4006, title: "Comando inaspettato nel test di accreditamento - Test completato"},
    {code: 4007, title: "Comando non consentito su questo server"},
    {code: 4008, title: "Lingua non supportata"},
    {code: 4009, title: "Object URI non supportato"},
    {code: 4010, title: "Extension URI non supportato"},
    {code: 4011, title: "Object URI mancate"},
    {code: 4012, title: "Extension URI mancante"},
    {code: 4013, title: "ID della sessione non aspettato inserito in una richiesta Hello o Login"},
    {code: 4014, title: "Richiesta di Login inviata su una sessione aperta"},
    {code: 4015, title: "Prima richiesta arrivata su una nuova sessione non è stata Login"},
    {code: 1000, title: "Comando eseguito con successo"},

    // RESULTS
    {code: 1001, title: "Comando eseguito con successo; azione pendente"},
    {code: 1300, title: "Comando eseguito con successo; nessun messaggio"},
    {code: 1301, title: "Comando eseguito con successo; inviare un ack per rimuovere dalla coda"},
    {code: 1302, title: "Comando eseguito con successo; nulla di fatto"},
    {code: 1500, title: "Comando eseguito con successo; fine della sessione"},
    {code: 2000, title: "Comando sconosciuto"},
    {code: 2001, title: "Comando sintatticamente errato"},
    {code: 2002, title: "Comando usato erroneamente"},
    {code: 2003, title: "Parametro obbligatorio mancante"},
    {code: 2004, title: "Valore del parametro non ammissibile"},
    {code: 2005, title: "Valore del parametro sintatticamente errato"},
    {code: 2100, title: "Versione del protocollo non supportata"},
    {code: 2101, title: "Comando non implementato"},
    {code: 2102, title: "Opzione non implementata"},
    {code: 2103, title: "Estensione non implementata"},
    {code: 2104, title: "Fallimento della fatturazione"},
    {code: 2105, title: "Oggetto non disponibile per il trasferimento"},
    {code: 2200, title: "Errore di autenticazione"},
    {code: 2201, title: "Errore di autorizzazione"},
    {code: 2202, title: "AuthInfo non valida"},
    {code: 2300, title: "Oggetto in trasferimento pendente"},
    {code: 2301, title: "Oggetto non in trasferimento"},
    {code: 2302, title: "Oggetto già esistente"},
    {code: 2303, title: "Oggetto non esistente"},
    {code: 2304, title: "Lo status dell'oggetto proibisce l'operazione"},
    {code: 2305, title: "Le associazioni dell'oggetto proibiscono l'operazione"},
    {code: 2306, title: "Valore di un parametro non conforme alla politica del sistema"},
    {code: 2307, title: "Servizio non implementato sull'oggetto"},
    {code: 2308, title: "Violazione della politica di gestione dei dati"},
    {code: 2400, title: "Comando fallito"},
    {code: 2500, title: "Comando fallito; sessione terminata dal server"},
    {code: 2501, title: "Timeout; sessione terminata dal server"},
    {code: 2502, title: "Limite di sessione raggiunto; connessione chiusa dal server"},
];
