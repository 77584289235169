import * as React from "react";
import { IDomainEvent } from "../types/domainEvents";
import { Result } from "antd";

export const AvailableEventResultFailed: React.FC<{ data: IDomainEvent }> = props => (
  <Result
    status={403}
    title="Operazione fallita"
    subTitle={JSON.stringify(props.data)}
    // extra={[
    //     <Button type="primary" key="console">
    //         Go Console
    //     </Button>,
    // ]}
  />);