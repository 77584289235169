import {Input} from "antd";
import * as React from "react";
import {useHistory} from "react-router-dom";
import {DispatchContext} from "../../../StoreProvider";
import {routeSearchTo, setSearchType} from "../../../Pages/Home/SearchBar/SearchBarForm";
import {AbilityContext} from "../../Ability/AbilityContext";

const {Search} = Input;
export const SearchBarMenu: React.FC = () => {
    const history = useHistory();
    const dispatch = React.useContext(DispatchContext);
    const {ability} = React.useContext(AbilityContext);

    const [val, setVal] = React.useState('');

    const handleOnSearch = (value: string) => {
        if (value) {
            const searchType = setSearchType(value,ability);
            routeSearchTo(history, searchType, value, dispatch);
            setVal('')
        }
    }
    return <Search
        placeholder="Cerca dominio o contatto"
        onSearch={handleOnSearch}
        onChange={(v) => setVal(v.target.value)}
        style={{width: 250}}
        allowClear
        value={val}
    />
}
