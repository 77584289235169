import * as React from 'react';
import { IconTip } from '../IconTip/IconTip';

export const HelpTips: React.FC<{
  content?: React.ReactNode;
  title?: React.ReactNode;
  icon?: string;
  iconStyle?: React.CSSProperties;
  iconClassName?: string;
  color?: string;
}> = props => <IconTip {...props} />;
