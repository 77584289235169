import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { IProtocolForm, ProtocolForm } from './Forms/ProtocolForm';
import { AxiosError } from 'axios';
import { apiGetDocumentByYearAndNumber } from '../../const/api-calls';
import { IProtocol } from './documents';
import { moment } from '../../utils/various';
// import { moment } from '../../utils/various';

export const ProtocolFormHandler: React.FC<IProtocolForm &
  FormComponentProps> = props => {
  const { getFieldValue, setFieldsValue } = props.form;
  const [data, setData] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<AxiosError>();

  const [stateProtNum, setStateProtNum] = React.useState<number>(
    getFieldValue('protNum'),
  );
  const [stateProtYear, setStateProtYear] = React.useState<number>(
    getFieldValue('protYear'),
  );

  const findProtocol = (_protYear: number, _protNum: number) => {
    setLoading(true);
    setData(undefined);
    handleSetProtocol(undefined);
    apiGetDocumentByYearAndNumber(stateProtYear, stateProtNum)
      .then(res => {
        setData(res.data);
        setLoading(false);
        handleSetProtocol(res.data);
      })
      .catch(e => {
        setError(e);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (stateProtYear && stateProtNum) {
      findProtocol(stateProtYear, stateProtNum);
    }
  }, [stateProtNum, stateProtYear]);

  const handleOnChange = (_protNumber?: number, _protYear?: number) => {
    if (_protYear) {
      setStateProtYear(_protYear);
    }
    if (_protNumber) {
      setStateProtNum(_protNumber);
    }
  };

  const handleSetProtocol = (protocol?: IProtocol) => {
    setFieldsValue({
      ['org']: protocol?.org,
      ['domain']: protocol?.domain,
      ['registrar']: protocol?.registrar,
      ['dateArrive']: protocol && moment(protocol?.dateArrive),
      ['numpag']: protocol?.numpag,
      ['id']: protocol?.id,
    });
  };

  return (
    <ProtocolForm
      {...props}
      items={data}
      onChangeProtocolNumberOrYear={handleOnChange}
      loading={loading}
      error={error}
    />
  );
};
