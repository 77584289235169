import {IFetchStateType} from "../../Type/IFetchtStateType";
import {IContact} from "./contacts";
import {apiGetContactDetails} from "../../../const/api-calls";

/***
 * Funzione per il recupero del dettaglio del contatto.
 *
 * @param dispatch
 * @param contactId
 * @param contact
 * @param query - queryString corrente
 */
export function fetchContact(dispatch: any,contactId:string, contact: IFetchStateType<IContact>, query: string) {

    console.log("fetchContact ");
    console.log("contact.query.localeCompare(query) !== 0 ",contact.query.localeCompare(query) !== 0,contact.query.localeCompare,query);
    if (contact.query.localeCompare(query) !== 0) {

        dispatch({type: 'CONTACT_FETCH_LOAD', query})
        apiGetContactDetails(contactId).then((res: any) => {
            dispatch({type: 'CONTACT_FETCH_SUCCESS', query, payload: res})
        }).catch((error: any | PromiseLike<IContact>) => {
            dispatch({type: 'CONTACT_FETCH_ERROR', error})
        })
    }

}

export function fetchContactByContactId(dispatch: any,contactId:string, contact: IFetchStateType<IContact>, query: string) {


    if (contact.query.localeCompare(query) !== 0) {

        dispatch({type: 'CONTACT_FETCH_LOAD', query})
        apiGetContactDetails(contactId).then((res: any) => {
            dispatch({type: 'CONTACT_FETCH_SUCCESS', query, payload: res})
        }).catch((error: any | PromiseLike<IContact>) => {
            dispatch({type: 'CONTACT_FETCH_ERROR', error})
        })
    }

}


export function reloadContact(dispatch: any,history:any,location:any){
    dispatch({type: 'CONTACT_FETCH_CLEAN'})
    dispatch({type: 'CONTACT_EVENTS_FETCH_CLEAN'})
    dispatch({type: 'CONTACT_REVISIONS_FETCH_CLEAN'})
    console.log("location.pathname) ",location.pathname);
    history.push(location.pathname)

}




