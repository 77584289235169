import {AxiosResponse, AxiosError} from "axios";
import {IFetchStateType} from "../../../Type/IFetchtStateType";
import {IOptionSelectList} from "../../SelectList/SelectList";


interface IRevisionActionType {
    type: 'STATEORPROVINCE_FETCH_SUCCESS' | 'STATEORPROVINCE_FETCH_ERROR' | 'STATEORPROVINCE_FETCH_LOAD' | 'STATEORPROVINCE_FETCH_CLEAN';
    payload?: AxiosResponse;
    error?: AxiosError;
}

export interface  IStateOrProvince {
    countries: IOptionSelectList[], provinces: IOptionSelectList[] };


export const initialState: IFetchStateType<IStateOrProvince> = {
    loading: false,
    error: undefined,
    payload: {countries:[],provinces:[]}
}

export const reducer = (state: IFetchStateType<IStateOrProvince>, action: IRevisionActionType) => {
    switch (action.type) {
        case "STATEORPROVINCE_FETCH_LOAD":
            return {loading: true, error: undefined, payload:  {countries:[],provinces:[]}}
        case "STATEORPROVINCE_FETCH_SUCCESS":
            return {loading: false, error: undefined, payload: action.payload}
        case "STATEORPROVINCE_FETCH_ERROR":
            return {loading: false, error: action.error}
        case "STATEORPROVINCE_FETCH_CLEAN":
            return {loading: false, error: undefined, payload:  {countries:[],provinces:[]}}
        default:
            return state;
    }
};

export default reducer;