import * as React from 'react';
import LoadingSpinner from '../../Feedback/LoadingSpinner/LoadingSpinner';
import Exception500, { Exception500Little } from '../../../Pages/Exception500';
import { Alert } from 'antd';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { IFetchStateType } from '../../../Type/IFetchtStateType';
import { isEmpty } from 'lodash';

export function printAxiosFetchError(error: AxiosError): string {
  if (error.response && error.response.data) {
    const { response } = error;
    console.error(JSON.stringify(error.response));
    return `(${response.statusText}) - ${response.data.message} ${
      response.data.errors ? JSON.stringify(response.data.errors) : ''
    }  `;
  }

  return JSON.stringify(error);
}

/***
 * Componente che si occupa di visualizzare errori e spinner quando si ha una risposta da una chiamata axios
 *
 * @param props
 * @constructor
 */
export const FetchController: React.FC<IFetchStateType<any> & {
  pageCentered?: boolean;
  children: (props: any) => JSX.Element;
}> = props => {
  // console.log('FetchController ', props);
  const {
    loading,
    customSpinner,
    error,
    response,
    children,
    pageCentered,
    payload,
  } = props;
  if (loading) {
    if (customSpinner) {
      return <div style={{ textAlign: 'center' }}>{customSpinner}</div>;
    }
    return <LoadingSpinner pageCentered={pageCentered} />;
  } else if (error) {
    if (error.response && error.response.status) {
      return error.response.status === 500 ? (
        // TODO - patch brutta da migliorare,
        <Exception500 description={printAxiosFetchError(error)} />
      ) : (
        <Alert
          message="Errore"
          description={printAxiosFetchError(error)}
          type={'error'}
          showIcon={true}
        />
        // <Alert message="Errore" description={error.message} type={'error'} showIcon={true}/>
      );
    } else {
      console.error(' ', error);
      return (
        <Alert
          message="Errore"
          description={`Uknowkn reason: ${error}`}
          type={'error'}
          showIcon={true}
        />
      );
    }
  } else if (response) {
    if (response.status === 400) {
      // Riferimento
      // https://www.drlinkcheck.com/blog/free-http-error-images
      return (
        <img
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
          }}
          src={process.env.PUBLIC_URL + '/400-bad-request.png'}
          alt={'Bad request'}
        />
      );
    }
    // console.log(" PRINT CHILDREN",)
    return children(response.data);
  } else if (payload) {
    return children(payload);
  } else {
    // console.warn('Remote2 flow control not properly configured', props);
    // return <div>Check Remote2 component</div>;
    return <></>;
  }
};

/**
 * Componente che prende in ingresso una call (AxiosPromise) e che gestisce in autonomia errori, loading passando al children la response quando
 * axios completa la request senza errori
 *
 * E' possibile personalizzare il componente mostrato durante il loading
 * @param props
 * @constructor
 */
export const AxiosControllerComponent: React.FC<{
  call: AxiosPromise;
  pageCentered?: boolean;
  customSpinner?: JSX.Element;
} & {
  children: (props: AxiosResponse) => JSX.Element;
}> = props => {
  const { error, loading, response } = useFetchCall(props.call);

  if (loading) {
    return props.customSpinner ? (
      props.customSpinner
    ) : (
      <LoadingSpinner pageCentered={props.pageCentered} />
    );
  } else if (!isEmpty(error)) {
    if (error && error.response && error.response.status) {
      return error.response.status === 500 ? (
        // TODO - patch brutta da migliorare,
        <Exception500Little description={printAxiosFetchError(error)} />
      ) : (
        <Alert
          message="Errore"
          description={printAxiosFetchError(error)}
          type={'error'}
          showIcon={true}
        />
        // <Alert message="Errore" description={error.message} type={'error'} showIcon={true}/>
      );
    } else {
      return (
        <Alert
          message="Errore"
          description={'Uknowkn reason'}
          type={'error'}
          showIcon={true}
        />
      );
    }
  } else if (!isEmpty(response)) {
    if (response.status === 400) {
      // Riferimento
      // https://www.drlinkcheck.com/blog/free-http-error-images
      return (
        <img
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
          }}
          src={process.env.PUBLIC_URL + '/400-bad-request.png'}
          alt={'Bad request'}
        />
      );
    }
    // console.log(" PRINT CHILDREN",)
    return props.children(response);
  } else {
    // console.warn('Remote2 flow control not properly configured', props);
    // return <div>Check Remote2 component</div>;
    return <></>;
  }
};

/***
 * Hooks per eseguire la fetch di una AxiosPromise.
 * Ritorna  error, loading, response .
 * @param call
 */
export function useFetchCall(call: AxiosPromise) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>(undefined);
  const [error, setError] = React.useState<AxiosError | undefined>(undefined);
  const [response, setResponse] = React.useState<AxiosResponse | any>({});

  const fetchData = React.useCallback(() => {
    setLoading(true);
    call
      .then(resp => {
        setResponse(resp);
        setData(resp.data);
        setLoading(false);
      })
      .catch(e => {
        setError(e);
        setLoading(false);
      });
  }, []);
  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return React.useMemo(
    () => ({
      loading,
      data,
      error,
      response,
    }),
    [loading, data, error, response],
  );
}
