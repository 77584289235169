import * as React from 'react';
import { Dd, Dt } from '../../../../Common/Styled/DefinitionList';
import { IContact } from '../../contacts';
import { moment } from '../../../../../utils/various';
import i18n from 'i18next';
import {
  BasicPopoverFilter,
  ContactFilter,
} from '../../../../Common/Navigation/Filter/Filter';
import { LinkableContactId } from '../../../Search/TabledContactsResults';
import { Divider, Typography } from 'antd';
import { LinkedDomainsVisualizer } from '../../../../Common/Navigation/AssociatedDomainsCounter/DomainsCounter';
import { AbilityContext } from '../../../../Common/Ability/AbilityContext';
import { LastUpdateRegistrantTips } from '../../../../../const/TipsComponent';
import {
  StateField,
  StateOrProvinceField,
} from '../../../../Common/DataDisplay/StateOrProvinceField/StateOrProvinceField';
import { Status } from '../../../../Common/Styled/Status';
import { HelpTips } from '../../../../Common/DataDisplay/HelpTips/HelpTips';
import { StatusList } from '../../../../Common/DataDisplay/StatusList/StatusList';

// Se il numero di strade è > 1 allora ritorna l'indice corrente+1 altrimenti torna stringa vuota
function printStreetNumber(streetsLength: number, index: number): string {
  if (streetsLength > 1) {
    return (index + 1).toString();
  } else {
    return '';
  }
}

/***
 * Visualizza le info base di un Contatto
 * id, name, org, country, city,cap , state or province, streets, voice, fax, email, creationDate, lastUpdate,
 * consentForPublishing
 * @param contact
 * @param clean
 * @constructor
 */

export interface IContactBaseInfo {
  contact: IContact;
  /** rimuove filtri e link */
  clean?: boolean;
}

export const ContactBaseInfo: React.FC<IContactBaseInfo> = ({
  contact,
  clean,
}) => {
  const { ability } = React.useContext(AbilityContext);

  // export function printProvince(val: string): string {
  //    const target = provinces.filter((c: { value: string, label: string }) => c.value === country)[0];
  //    return `${target.value.toUpperCase()} (${capitalizeFirstLetter(target.label)})`;
  //  }

  return (
    <React.Fragment>
      <Dt>{i18n.t('contact_id')}:</Dt>
      <Dd>
        <LinkableContactId record={contact} />
        {!clean && contact.linkedInfo && ability.can('search', 'Domain') && (
          <>
            <Divider type={'vertical'} />
            <LinkedDomainsVisualizer contact={contact} />
          </>
        )}
      </Dd>

      {/*<Dd><LinkableContactId record={props}/>*/}
      {/*    <Divider type={"vertical"}/>*/}
      {/*    {testInfo!== undefined && !testInfo.linked ?*/}
      {/*        <Tag><StatusbarIcon icon={faTag}/>Not Linked</Tag>*/}
      {/*        :*/}
      {/*        <>*/}
      {/*            <LinkedDomains linkedInfo={testInfo} contactId={contactId} type={"registrant"}/>*/}
      {/*            <LinkedDomains linkedInfo={testInfo} contactId={contactId} type={"admin"}/>*/}
      {/*            <LinkedDomains linkedInfo={testInfo} contactId={contactId} type={"tech"}/>*/}
      {/*        </>}*/}
      {/*</Dd>*/}
      <Dt>{i18n.t('contact_name')}:</Dt>
      <Dd>{contact.name || '-'}</Dd>

      <Dt>{i18n.t('contact_org')}:</Dt>
      <Dd>
        {contact.org || '-'}
        {!clean && contact.org && (
          <BasicPopoverFilter
            allowed={ability.can('search', 'Contact')}
            content={
              <ContactFilter
                url={`/contacts?org=${contact.org}`}
                content={`org = ${contact.org}`}
              />
            }
          />
        )}
      </Dd>

      <Dt>{i18n.t('contact_cc')}:</Dt>
      <Dd>
        {' '}
        <StateField val={contact.country} />
      </Dd>

      <Dt>{i18n.t('contact_city')}:</Dt>
      <Dd>
        {contact.city || '-'}
        {!clean && contact.city && (
          <BasicPopoverFilter
            allowed={ability.can('search', 'Contact')}
            content={
              <ContactFilter
                url={`/contacts?city=${contact.city}`}
                content={`city = ${contact.city}`}
              />
            }
          />
        )}
      </Dd>

      <Dt>{i18n.t('contact_cap')}:</Dt>
      <Dd>{contact.postalCode || '-'}</Dd>

      <Dt>{i18n.t('contact_sp')}:</Dt>
      <Dd>
        <StateOrProvinceField val={contact.state} />
        {/*{state && <BasicPopoverFilter*/}
        {/*    content={<ContactFilter url={`/contacts?state=${state}`}*/}
        {/*                            content={`stateOrProvince=${state}`}/>}*/}
        {/*/>}*/}
      </Dd>

      {contact.streets &&
        contact.streets.map((street, index) => (
          <React.Fragment key={index}>
            <Dt>
              {i18n.t('contact_street') +
                ' ' +
                printStreetNumber(contact.streets.length, index)}
            </Dt>
            <Dd>{street}</Dd>
          </React.Fragment>
        ))}

      <Dt>{i18n.t('contact_voice')}:</Dt>
      <Dd>{contact.voice || '-'}</Dd>

      <Dt>{i18n.t('contact_fax')}:</Dt>
      <Dd>{contact.fax || '-'}</Dd>

      <Dt>{i18n.t('contact_email')}:</Dt>
      <Dd>
        {contact.email || '-'}
        {!clean && contact.email && (
          <BasicPopoverFilter
            allowed={ability.can('search', 'Contact')}
            content={
              <ContactFilter
                url={`/contacts?email=${contact.email}`}
                content={`email = ${contact.email}`}
              />
            }
          />
        )}
      </Dd>

      <Dt>{i18n.t('contact_crDate')}:</Dt>
      <Dd>
        {contact.created
          ? moment(contact.created).format('DD MMMM YYYY, HH:mm:ss')
          : '-'}
        {!clean && contact.created && (
          <BasicPopoverFilter
            allowed={ability.can('search', 'Contact')}
            content={
              <div>
                <div>
                  <ContactFilter
                    url={`/contacts?created-range=${contact.created}&created-range=${contact.created}`}
                    content={` created = ${moment(contact.created).format(
                      'DD MMMM YYYY, HH:mm:ss',
                    )}`}
                  />
                </div>

                {/*<div>*/}
                {/*    <DomainFilter*/}
                {/*        url={`/domains?created-range=${moment(created).subtract(2, 'days').format("YYYY-MM-DD")}&created-range=${moment(created).format("YYYY-MM-DD")}`}*/}
                {/*        content={`2 GG precedenti`}/>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <DomainFilter*/}
                {/*        url={`/domains?created-range=${moment(created).subtract(7, 'days').format("YYYY-MM-DD")}&created-range=${moment(created).format("YYYY-MM-DD")}`}*/}
                {/*        content={`1 W precedente`}/>*/}
                {/*</div>*/}
              </div>
            }
          />
        )}
      </Dd>

      <Dt>
        {contact.type === 'registrant' ? (
          <>
            {`${i18n.t('contact_upDate')}`}
            <LastUpdateRegistrantTips /> :
          </>
        ) : (
          <>{`${i18n.t('contact_upDate')}`}</>
        )}
      </Dt>

      <Dd>
        {contact.lastupdate
          ? moment(contact.lastupdate).format('DD MMMM YYYY, HH:mm:ss')
          : '-'}
      </Dd>

      {contact.statuses && (
        <>
          <Dt>{i18n.t('domain_status')}:</Dt>
          <Dd>
            <Status size={'normal'}>{contact.status}</Status>{' '}
            <Divider type={'vertical'} />
            <HelpTips
              title={
                <Typography.Title level={2}>
                  {'Dettaglio Stati'}
                </Typography.Title>
              }
              content={<StatusList statuses={contact.statuses} />}
              icon={'info-circle'}
              iconClassName={'icon_detail'}
            />
          </Dd>
        </>
      )}

      <Dt>{i18n.t('contact_consentForPublishing')}:</Dt>
      <Dd>{contact.consentForPublish ? 'SI' : 'NO'}</Dd>
    </React.Fragment>
  );
};
