import {Avatar, Badge, Comment, Modal, Tooltip, Typography} from "antd";
import styled from "styled-components";
import {IDomainNote} from "./types";
import { UserName} from "../../Users/User";
import {moment} from "../../../utils/various";
import * as React from "react";
const {confirm} = Modal;

function renderContent(title: string, content: string) {
    return <>
        <Typography.Text strong>{title}</Typography.Text>
        <Typography.Paragraph>{content}</Typography.Paragraph>
    </>
}


const StyledBadge = styled(Badge)` 
   font-size: 8px!important;
   
   > .ant-badge-count { 
    font-size: 10px!important;
    height: 15px!important;
    line-height: 15px !important;
   }
`;

/**
 * Visualizzatore singola nota
 * @param props
 * @constructor
 */

export interface INoteItem {
    data: IDomainNote,
    control: 'full' | 'base',
    counter?: number,
    onNew?: any,
    onShowAll?: any,
    onDelete?: (id: number) => void,
    onSelect?: (data: IDomainNote) => void
    onEdit?: (data: IDomainNote) => void
}


function showDeleteConfirm(ok: any, cancel: any) {
    confirm({
        title: 'Sei sicuro di voler eliminare questa nota?',
        // content: 'Some descriptions',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
            ok();
        },
        onCancel() {
            cancel();
        },
    });
}

export function NoteItem(props: INoteItem) {

    const deleteAction = <span key="delete"
                               onClick={() => showDeleteConfirm(
                                   () => props.onDelete && props.onDelete(props.data.id),
                                   () => {
                                       return
                                   })}>Elimina</span>;

    const editAction = <span key="edit" onClick={() => props.onEdit && props.onEdit(props.data)}>Edita</span>;

    const fullControlActions = [
        editAction,
        deleteAction,
        <span key="new" onClick={props.onNew}>Crea</span>,
        <span key="view" style={{fontSize: 12}}>
                {props.counter && <StyledBadge
                    count={props.counter} offset={[5, -5]}
                    style={{backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset'}}
                >
                        <span style={{fontSize: 12}} onClick={props.onShowAll}>
                            Mostra tutte
                        </span>
                </StyledBadge>
                }
                </span>]

    const baseActions = [
        <span key="select"
              onClick={() => props.onSelect && props.onSelect(props.data)}>Seleziona</span>,
        editAction,
        deleteAction,
    ]

    return <Comment
        actions={props.control === 'base' ? baseActions : fullControlActions}
        author={<UserName userId={props.data.userId}/>}
        avatar={
            <Avatar
                // src="https://ps.w.org/simple-author-box/assets/icon-128x128.jpg?rev=1821054"
                src="https://www.netclipart.com/pp/m/63-639230_clipart-king-icon-king-cartoon-icon-png.png"
                // src="https://pbs.twimg.com/profile_images/831750586852896768/qndptW2u_400x400.jpg"
                // src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                alt="Han Solo"
            />
        }
        content={renderContent(props.data.description, props.data.note)}
        datetime={
            <Tooltip title={moment(props.data.created).format('YYYY-MM-DD HH:mm:ss')}>
                <span>{moment(props.data.created).fromNow()}</span>
            </Tooltip>
        }
    />
}
