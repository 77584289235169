import {IFetchStateType} from "../../Type/IFetchtStateType";
import {IRevisions} from "../../Common/Navigation/Revisions/revisions";
import {apiGetDomainRevisions} from "../../../const/api-calls";

/***
 * Funzione per il recupero del dettaglio dominio relativi alla query passata.
 *
 * @param dispatch
 * @param domainRevision
 * @param domainId
 * @param keycloak
 */
export function fetchDomainRevisions(dispatch: any, domainRevision: IFetchStateType<IRevisions>, domainId: string) {
    const query = `domains/${domainId}/revisions`;

    // Se query memorizzato è diverso da quello cercato e non ci sono errori allora fetchDomains
    if (query.localeCompare(domainRevision.query) !== 0) {

        dispatch({type: 'DOMAIN_REVISIONS_FETCH_LOAD', query})

        apiGetDomainRevisions(domainId).then((res: any) => {
            dispatch({type: 'DOMAIN_REVISIONS_FETCH_SUCCESS', query, payload: res})
        }).catch((error: any | PromiseLike<IRevisions>) => {
            dispatch({type: 'DOMAIN_REVISIONS_FETCH_ERROR', error})
        })
    }
}