import * as React from "react";
import GridView from "./GridView/GridView";
import ListView from "./ListView/ListView";
import TabView from "./TabView/TabView";
import { IViewerRenderProps } from "./Type/IViewerRenderProps";
import { CardDetailSection } from "../../Styled/CardDetailSection";
import { ViewType } from "./Type/ViewType";
import { ViewControlBar } from "./ViewControlBar";
import { Icon } from "antd";

const Spinner = () => {
  return <div style={{ marginBottom: 15 }}><Icon type="loading" style={{ fontSize: 12 }} spin /> Caricamento....</div>;
};

class CardWithViewer extends React.Component<IViewerRenderProps, { view: ViewType }> {
  public state: { view: ViewType } = {
    view: this.props.defaultView
  };

  public handleOnChange(view: ViewType) {
    this.setState({ view });
  }


  public render() {
    const { defaultView } = this.props;
    return (
      <React.Fragment>
        <CardDetailSection
          bgcolor={this.props.bgColor}
          title={
            <div style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              width: "100%"
            }}>
              <div style={{ width: "100%" }}>{this.props.extraTab}</div>
              <div style={{
                marginLeft: "5px",
                paddingLeft: "5px"
              }}>
                <ViewControlBar default={defaultView} align={"right"}
                                onChange={this.handleOnChange.bind(this)} />
              </div>
            </div>
          }
        >
          <div>
            {this.props.aboveContent && this.props.aboveContent}
            {this.props.loading && <div style={{ textAlign: "center" }}><Spinner /></div>}
            <div style={{ filter: this.props.loading ? "blur(3px)" : "none" }}>
              {this.state.view === "list" && <ListView {...this.props} />}
              {this.state.view === "grid" && <GridView {...this.props} />}
              {this.state.view === "tab" && <TabView {...this.props} />}
            </div>
            {this.props.belowContent && this.props.belowContent}


          </div>
        </CardDetailSection>
      </React.Fragment>
    );
  }
}

export default CardWithViewer;
