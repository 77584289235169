import * as React from 'react';
import Statusbar from '../Statusbar/Statusbar';
import { IDomain, IEventRequirement } from '../types/domain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { StyledStatusbarContainer } from '../../../Common/Styled/StatusBar';
import { Button, Tooltip } from 'antd';
import { reloadDomain } from '../operations';
import { DispatchContext, StateContext } from '../../../StoreProvider';
import { fetchDomainAvailableEvents } from './operations';
import { useModal } from '../../../Common/Hooks/useModal';
import { useRouter } from '../../../Common/Hooks/useRouter';
import { MidaOldLink } from '../../../Common/Link/MidaOld';
import { useFetchRegistrar } from '../../../Registrar/hooks';
import { ActionMenu } from './ActionMenu';
import { ModalAvailableEvent } from '../../Events/AvailableEvent/ModalAvailableEvent';
import { IconTip as AlertTip } from '../../../Common/DataDisplay/IconTip/IconTip';
import { Flex } from '../../../Common/Layout/Flex/Flex';

const Toolbar: React.FC<{ domain: IDomain; refRevision?: string }> = ({
  domain,
  refRevision,
}) => {
  const { visibile, open: openModal, close } = useModal();
  const { history, location, match } = useRouter();
  const { domainAvailableEvents, eventsRequirements } = React.useContext(
    StateContext,
  );
  const dispatch = React.useContext(DispatchContext);
  const [operation, setOperation] = React.useState<string>('');
  const {
    loading: regLoading,
    data: regData,
    error: regError,
  } = useFetchRegistrar(domain.registrarId);

  React.useEffect(() => {
    fetchDomainAvailableEvents(
      dispatch,
      domainAvailableEvents,
      domain.id.toString(),
      location,
    );
  }, []);

  const hanldeOnSelectOperation = (op: string) => {
    openModal();
    setOperation(op);
  };

  return (
    <div className={'toolbar'}>
      <StyledStatusbarContainer>
        <Statusbar
          domain={domain}
          registrar={{ loading: regLoading, data: regData, error: regError }}
          org={(domain.admin && domain.admin.org) || ''}
          refRevision={refRevision}
        />

        {/* REFRESH BUTTON*/}
        <div style={{ display: 'inherit' }}>
          <div style={{ marginLeft: '5px', marginRight: '5px' }}>
            <Tooltip title={'Ricarica il dominio'}>
              <Button
                onClick={() => {
                  reloadDomain(dispatch, history, location);
                }}
              >
                {' '}
                <FontAwesomeIcon icon={faSync} />
              </Button>
            </Tooltip>
          </div>
          <div style={{ marginRight: '5px' }}>
            <MidaOldLink
              id={domain.id}
              name={domain.domainIDN || domain.domain}
              type={'domain'}
            />
          </div>

          {/* ACTION SELECTOR */}
          <Flex alignItems={'center'}>
            <ActionMenu
              eventsRequirementsError={!!eventsRequirements.error}
              eventsRequirements={eventsRequirements.response?.data}
              availableEvents={domainAvailableEvents.response?.data}
              onSelectOperation={hanldeOnSelectOperation}
              registrar={regData}
              open={openModal}
              domain={domain}
              refRevision={refRevision}
              revisionNumber={match.params['revision']}
            />
            {eventsRequirements.response?.data ? (
              <ModalAvailableEvent
                eventName={operation}
                availableEvent={
                  eventsRequirements.response?.data[operation] ||
                  ({
                    statuses: ['succeeded', 'failed'],
                    description: 'optional',
                    document: 'optional',
                  } as IEventRequirement)
                }
                domain={domain}
                visible={visibile}
                onOk={close}
                onCancel={close}
              />
            ) : (
              <div style={{ marginLeft: 10, display: 'inline-block' }}>
                <AlertTip
                  color={'red'}
                  icon={'warning'}
                  title={'Attenzione!'}
                  content={
                    "E' stato riscontrato un errore. Il caricamento della impostazioni di configurazione degli eventi eseguibili dall'operatore non è avvenuto correttamente. Si prega di segnalare il problema. "
                  }
                />
              </div>
            )}
          </Flex>
        </div>
      </StyledStatusbarContainer>
    </div>
  );
};
export default Toolbar;
