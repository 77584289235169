import { IContact, IRegistrant } from '../../Detail/contacts';
import { Tag } from 'antd';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons/faUserTie';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons/faUserGraduate';
import {faFilter, faLandmark} from '@fortawesome/free-solid-svg-icons';

type ContactType = 'contact' | 'registrant';

// dice il tipo del contatto
export function contactType(contact: IContact): ContactType {
  if (contact.registrant) {
    return 'registrant';
  } else {
    return 'contact';
  }
}

// dice se il registrante è edu o meno
export function isEdu(registrant: IRegistrant): boolean {
  if (registrant.schoolCode) {
    return true;
  }
  return false;
}

export function entityType(id: number): string {
  switch (id) {
    case 1:
      return '1 - Persone';
    case 2:
      return '2 - Società';
    case 3:
      return '3 - Enti profit';
    case 4:
      return '4 - Enti no profit';
    case 5:
      return '5 - Enti pubblici';
    case 6:
      return '6 - Altri';
    case 7:
      return '7 - Sog. stranieri';
    default:
      return '';
  }
}

// Ritorna i tag che rappresentano il contanto es. contatto o registrante - edu  o solo registrante
export function contactTypeTag(contact: IContact, filter?: boolean, onAddFilter?: (filter: string, values: string) => void,
): JSX.Element {
  switch (contactType(contact)) {
    case 'contact' :
      if (filter) {
        return <Tag color="green" style={{ cursor: 'pointer' }}
                    onClick={() => onAddFilter && onAddFilter('type', 'contact')}><FontAwesomeIcon
          icon={faUser}/> Contact {filter &&
        <FontAwesomeIcon size={'xs'} color={'grey'} icon={faFilter}/>}</Tag>;
      } else {
        return <Tag color="green"><FontAwesomeIcon icon={faUser}/> Contact </Tag>;
      }

    case 'registrant' :
      if (contact.registrant) {
        return registrantTypeTag(contact.registrant, filter, onAddFilter);
      }

      if (filter) {
        return <Tag color="blue" style={{ cursor: 'pointer' }}
                    onClick={() => onAddFilter && onAddFilter('type', 'registrant')}><FontAwesomeIcon
          icon={faUser}/> Registrant {filter &&
        <FontAwesomeIcon size={'xs'} color={'grey'} icon={faFilter}/>}</Tag>;
      } else {
        return <Tag color="green"><FontAwesomeIcon icon={faUser}/> Registrant </Tag>;
      }
  }
}

// Ritorna i tag che rappresentano il contanto es. contatto o registrante - edu  o solo registrante
export function registrantRegistrantTypeTag(
  registrant: IRegistrant, filter?: boolean, onAddFilter?: (filter: string, values: string) => void,
): JSX.Element {
  if (registrant.entityType) {
    if (filter) {
      switch (registrant.entityType.toString()) {
        case '1' :
          return <Tag key={1} color="cyan" style={{ cursor: 'pointer' }}
                      onClick={() => onAddFilter && onAddFilter('registrantType', '1')}>
            {entityType(1)}
            <FontAwesomeIcon size={'xs'} color={'grey'} icon={faFilter}/></Tag>;

        case '2' :
          return <Tag key={2} color="orange" style={{ cursor: 'pointer' }}
                      onClick={() => onAddFilter && onAddFilter('registrantType', '2')}>
            {entityType(2)}
            <FontAwesomeIcon size={'xs'} color={'grey'} icon={faFilter}/></Tag>
            ;
        case '3' :
          return <Tag key={3} color="red" style={{ cursor: 'pointer' }}
                      onClick={() => onAddFilter && onAddFilter('registrantType', '3')}>
            {entityType(3)}
            <FontAwesomeIcon size={'xs'} color={'grey'} icon={faFilter}/></Tag>
            ;
        case '4' :
          return <Tag key={4} color="gold" style={{ cursor: 'pointer' }}
                      onClick={() => onAddFilter && onAddFilter('registrantType', '4')}>
            {entityType(4)}
            <FontAwesomeIcon size={'xs'} color={'grey'} icon={faFilter}/></Tag>;
        case '5' :
          return <Tag key={5} color="green" style={{ cursor: 'pointer' }}
                      onClick={() => onAddFilter && onAddFilter('registrantType', '5')}>
            {entityType(5)}
            <FontAwesomeIcon size={'xs'} color={'grey'} icon={faFilter}/></Tag>;
        case '6' :
          return <Tag key={6} color="geekblue" style={{ cursor: 'pointer' }}
                      onClick={() => onAddFilter && onAddFilter('registrantType', '6')}>
            {entityType(6)}
            <FontAwesomeIcon size={'xs'} color={'grey'} icon={faFilter}/></Tag>;
        case '7' :
          return <Tag key={7} color="volcano" style={{ cursor: 'pointer' }}
                      onClick={() => onAddFilter && onAddFilter('registrantType', '7')}>
            {entityType(7)}
            <FontAwesomeIcon size={'xs'} color={'grey'} icon={faFilter}/></Tag>;

        default:
          return <span/>;
      }
    } else {
      switch (registrant.entityType.toString()) {
        case '1' :
          return <Tag key={1} color="cyan">{entityType(1)}</Tag>;
        case '2' :
          return <Tag key={2} color="orange">{entityType(2)}</Tag>;
        case '3' :
          return <Tag key={3} color="red">{entityType(3)}</Tag>;
        case '4' :
          return <Tag key={4} color="gold">{entityType(4)}</Tag>;
        case '5' :
          return <Tag key={5} color="green">{entityType(5)}</Tag>;
        case '6' :
          return <Tag key={6} color="geekblue">{entityType(6)}</Tag>;
        case '7' :
          return <Tag key={7} color="volcano">{entityType(7)}</Tag>;
        default:
          return <span/>;
      }
    }
  } else {
    return <span/>;

  }
}


// Ritorna i tag che rappresentano il registrante, es : registrante - edu  o solo registrante
export function registrantTypeTag(registrant: IRegistrant, filter?: boolean, onAddFilter?: (filter: string, values: string) => void): JSX.Element {

  const res = [filter ? <Tag color="blue" style={{ cursor: 'pointer' }}
                             onClick={() => onAddFilter && onAddFilter('type', 'registrant')}><FontAwesomeIcon
      icon={faUserTie}/> Registrant {filter &&
    <FontAwesomeIcon size={'xs'} color={'grey'} icon={faFilter}/>}</Tag> :
    <Tag color="blue"><FontAwesomeIcon icon={faUserTie}/> Registrant </Tag>,
  ];

  if (registrant.schoolCode) {
    res.push(<Tag key={'edu'} color="purple"><FontAwesomeIcon icon={faUserGraduate}/> Edu</Tag>);
  }
    if (registrant.gov) {
        res.push(<Tag key={'gov'} color="red"><FontAwesomeIcon icon={faLandmark}/> Gov</Tag>);
    }


  res.push(registrantRegistrantTypeTag(registrant, filter, onAddFilter));

  return <span>{res.map((e, i) => <span key={i}>{e}</span>)}</span>;
}
