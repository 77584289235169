import * as React from "react";
import {Divider, Icon, Modal, Typography} from "antd";

export function BugModal() {
    Modal.info({
        title: <h2>Segnalazioni</h2>,
        content: (
            <div>
                <Divider type={"horizontal"}/>
                <h3><Icon type="bug" style={{fontSize:'20px'} }/> Come effettuare la segnalazione di un <u>bug</u></h3>
                <p>
                    <ol>
                        <li>Catturare se possibile la <b>schermata</b></li>

                        <li>Catturare se possibile la <b>url corrente</b></li>
                        <li>Descrivere quali <b>dati</b> sono stati usati e quali <b>operazioni</b> sono state
                            compiute
                        </li>
                        <li>Descrivere se possibile il <b>problema</b> (grafico, relativo ad un crash o malfunzionamento,
                            ...)
                        </li>
                    </ol>
                </p>

                <Divider type={"horizontal"}/>
                <h3><Icon type="alert"  style={{fontSize:'20px'} }/> Come effettuare un <u>suggerimento</u> o <u>altro</u></h3>
                <p>
                    <ul>
                        <li>Scrivici descrivendo il suggerimento o altra comunicazione</li>
                    </ul>
                </p>
                <Divider type={"horizontal"}/>

                <p><h4><Typography.Text mark>Usando il tuo client di posta invia una mail che risponda ai punti sopra elencati all'indirizzo:</Typography.Text></h4>
                    <p style={{textAlign:'center'}}><Typography.Paragraph copyable strong>mida-segnalazioni@nic.it</Typography.Paragraph></p>
                </p>

            </div>
        ),
    });
}