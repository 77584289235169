import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {IScene} from '../../../Type/IScene';
import {IContact} from "../contacts";
import ContactMaster from "../ContactMaster";
import {DispatchContext, StateContext} from "../../../StoreProvider";
import {FetchController} from "../../../Common/Navigation/FetchController/FetchController";
import {fetchContact} from "../operations";


const ContactPage: React.FC<RouteComponentProps<{}> & IScene> = props => {
    const dispatch = React.useContext(DispatchContext);
    const {contact} = React.useContext(StateContext);

    React.useEffect(() => {
        // console.log("here ");
        fetchContact(dispatch, props.match.params['contact'], contact, props.location.pathname);
    }, );

    return (
        <FetchController {...contact} >
            {(value: IContact) => (
                <ContactMaster
                    title={<span>Dettaglio contatto</span>}
                    contact={value}
                    />
            )}
        </FetchController>
    );
};

export default ContactPage;
