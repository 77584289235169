// Se showDelete è false visualizza direttamente il valore del singolo domoinio altrimenti mostra la lista

import * as React from "react";
import { IDomains } from "../types/domains";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { parse, ParsedQuery } from "query-string";
import { CardDetailSection } from "../../../Common/Styled/CardDetailSection";
import { PaginationDiv } from "../../../Common/Styled/PaginationDiv";
import { Paginator } from "../../../Common/Navigation/Paginator/Paginator";
import { isEmpty } from "lodash";
import { Divider, Switch } from "antd";
import { IMidaSettings } from "../../../Type/Localstorage";
import { MIDA_SETTINGS } from "../../../../const/common";
import { SorterResult } from "antd/lib/table";
import List from "./List/List";
import TabledResult from "./Table/Table";
import { addFilter, decodeQuerySort, sortResult } from "../../../../utils/url-manipulation";
import ExportButton from "./ExportButton/ExportButton";

const obj = localStorage.getItem(MIDA_SETTINGS);

/***
 * Box contenente il risultato della ricerca domini
 *
 * @param showDomain : usato per mostrare direttamente il singolo dominio
 * @param value
 * @constructor
 */
export const ResultsPane: React.FC<{ showSingleDomain?: boolean; value: IDomains }> = ({ showSingleDomain, value }) => {

  const history = useHistory();
  const location = useLocation();
  const query = parse(location.search);

  const handleOnSortList = (a: any) => {
    sortResult(a, query, history, location);
  };

  const handleOnSortTable = (a: any, b: any, d: SorterResult<any>) => {
    sortResult(d, query, history, location);
  };

  const handleAddFilter = (filter: any, values: any) => {
    addFilter(filter, values, query, history, location);
  };

  if (showSingleDomain) {
    return <Redirect to={`/domains/${value.elements[0].id}`} />;
  }

  const val = query["sort"] as string;
  const { sort, direction } = extractSortAndDirection(val);


  let midaSettings: IMidaSettings = {};

  if (obj !== null && !isEmpty(obj)) {
    midaSettings = JSON.parse(obj);
  }
  const [showTable, setShowTable] = React.useState(midaSettings && midaSettings.domains && midaSettings.domains.tableView || false);

  function handleOnChange() {
    const newValue = !showTable;
    localStorage.setItem("mida-settings", JSON.stringify({
      ...midaSettings,
      domains: { tableView: newValue }
    } as IMidaSettings));
    setShowTable(newValue);
  }


  return (
    <CardDetailSection>
      <UpperBar>
        <DomainsViewSelector checked={showTable} onChangeView={handleOnChange} />
        <ExportButton query={query} totalElements={value.page.totalElements} />
      </UpperBar>
      <TableOrList
        onSortTable={handleOnSortTable}
        onSortList={handleOnSortList}
        addFilter={handleAddFilter}
        query={query}
        sort={sort}
        direction={direction}
        domains={value}
        showTable={showTable}
      />
    </CardDetailSection>
  );
};


/***
 *
 * Ritorna un oggetto contenente {sort, direction} parsando il contenuto del campo 'SORT'
 *
 * @param {string } sort -  campo SORT estratto dalla stringa dei parametri di ricerca (search) della URL.
 *
 */

export function extractSortAndDirection(sort: string): { sort: string, direction: string } {

  const sorting = sort;

  if (sorting && sorting.indexOf(",") > 0) {
    const res = sorting.split(",");

    return { sort: res[0], direction: res[1] };

  } else if (sorting) {

    return { sort: sorting, direction: "" };
  }

  return { sort: "", direction: "" };
}


/***
 * Selettore che decide se visualizzare la lista o la tabella
 * @param style
 * @param children
 * @constructor
 */
const DomainsViewSelector: React.FC<{
  style?: React.CSSProperties,
  onChangeView(checked: boolean): void
  checked: boolean
}> = ({ style, onChangeView, checked }) => {
  return (
    <div style={style}>
      <b>Mostra</b>:
      <Switch
        style={{ margin: 5 }}
        checked={checked}
        onChange={onChangeView}
        title={"Tabella"}
        checkedChildren="tabella"
        unCheckedChildren="lista"
      />
    </div>
  );
};


/***
 * Barra posta sopra la tabella che raccoglie le varie funzionalità legate alla tabella
 * @param children
 * @constructor
 */
const UpperBar: React.FC<{ children: JSX.Element[]; }> = ({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row-reverse", marginBottom: 10, alignItems: "center" }}>
      {children.map((name, index) => {
        return index <= children.length ? <>{name}<Divider type="vertical" /></> : { name };
      })}
    </div>);
};

/***
 * Visualizza la lista domini in formato lista o in formato tabella
 *
 * @param props
 * @constructor
 */
function TableOrList(props: {
  sort: string,
  direction: string,
  domains: IDomains,
  showTable: boolean,
  query: ParsedQuery<string>,
  addFilter(filter: any, values: any): void,
  onSortTable(a: any, b: any, d: SorterResult<any>): void,
  onSortList(a: any): void,
}) {
  return (<>{props.showTable ?
    <List
      sort={props.sort}
      direction={props.direction}
      {...props.domains}
      onChange={props.onSortList}
      onAddFilter={props.addFilter}
    />
    : <TabledResult
      data={props.domains.elements}
      onAddFilter={props.addFilter}
      handleOnChange={props.onSortTable}
      sortColumnKey={decodeQuerySort(props.query.sort).columnKey}
      sortOrder={decodeQuerySort(props.query.sort).order}
    />}
    <PaginationDiv>
      <Paginator page={props.domains.page} />
    </PaginationDiv>
  </>);
}
