export default {
    translation: {

        'deleted': 'Cancellati',
        'advancedSearch': 'Ricerca avanzata',
        'simpleSearch': 'Ricerca semplice',
        'domainIt': 'dominio.it',
        'searchHint': 'Dominio o contatto (es. dominio.it o CCTL1, Mario Rossi)',
        'readHint': 'Dominio o contatto (es. dominio.it o CCTL1)',
        'cancel': 'Annulla',
        'search': 'Cerca',
        'yes': 'SI',
        'no': 'NO',
        'insertSearchDomain': 'Inserisci un dominio .it da ricercare',
        'insertSearchAll': 'Inserisci un dominio .it o un contatto (es. CCTL1) da ricercare',

        // HOME
        'home_welcomeMida': 'Benvenuto nel portale <br> di amministrazione dei nomi a dominio',

        // CARD_WITH_VIEWR
        'cardWithViewer_showAsGrid': 'Visualizza come griglia',
        'cardWithViewer_showAsList': 'Visualizza come list',
        'cardWithViewer_showAsTab': 'Visualizza come tab',

        // Domain
        'tab_domain': 'Domain',
        'tab_contact': 'Contact',
        'tab_registrant': 'Registrant',
        'tab_admin': 'Admin',
        'tab_techs': 'Tech',
        'tab_nameServers': 'Nameservers',
        'tab_newNameServers': 'Nuovi Nameservers',
        'tab_dsRecord': 'DSRecord',
        'tab_registrar': 'Registrar',

        'dsRecord_keyTag': 'KeyTag',
        'dsRecord_alg': 'Algorithm',
        'dsRecord_digestType': 'DigestType',
        'dsRecord_digest': 'Digest',

        'domain_name': 'Domain',
        'domain_nameIDN': 'Domain IDN',
        'domain_crDate': 'Created',
        'domain_exDate': 'ExpireDate',
        'domain_upDate': 'LastUpdate',
        'domain_status': 'Status',
        'domain_dnssec': 'Signed',
        'domain_roid': 'ROID',

        // CONTACT
        'contact_name': 'Name',
        'contact_crDate': 'Created',
        'contact_exDate': 'ExpireDate',
        'contact_upDate': 'LastUpdate',
        'contact_status': 'Status',
        'contact_org': 'Organization',
        'contact_addr': 'Address',
        'contact_regCode': 'RegCode',
        'contact_schoolCode': 'SchoolCode',
        'contact_ipaCode': 'IPACode',
        'contact_uoCode': "UOCode",
        'contact_entityType': 'EntityType',
        'contact_city': 'City',
        'contact_street': 'Street',
        'contact_nationality': 'NationalityCode',
        'contact_voice': 'Phone',
        'contact_sp': 'StateOrProvince',
        'contact_cc': 'CountryCode',
        'contact_id': 'ContactID',
        'contact_cap': 'PostalCode',
        'contact_fax': 'Fax',
        'contact_email': 'E-mail',
        'contact_consentForPublishing': 'ConsentForPublishing',

        // REGISTRAR
        'registrar_id' : 'RegistrarId',
        'registrar_name' : 'Name',

        // EVENT
        'event_eppRequest': 'Epp Request',
        'event_eppResponse': 'Epp Response',
        'event_registry': 'Registry',

        'event_code': 'Code',
        'event_codeName': 'CodeName',
        'event_codeMessage': 'CodeMessage',
        'event_reason': 'Reason',
        'event_reasonName': 'ReasonName',
        'event_reasonMessage': 'ReasonMessage',
        'event_documentId': 'DocumentId',
        'event_userId': 'UserId',
        'event_user': 'User',
        'event_command': 'Command',
        'event_created': 'Created',
        'event_description': 'Description',
        'event_serverTrId': 'ServerTrId',
        'event_clientTrId': 'ClientTrId',
    },
};