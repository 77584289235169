/* exDate formato data => 2017-11-15T23:59:59+01:00  */
import * as React from 'react';
import { getExDateDetail } from './util';
import ExpireDate from './ExpireDate';
import { EX_DATE_EXPIRED } from './const';
import { Tooltip } from 'antd';
import { moment } from '../../../../../utils/various';

const ExpireDateContainer: React.FC<{ exDate: Date }> = ({ exDate }) => {
  const date = moment(exDate).format('LL')
  // console.log(' date', date, exDate);
  const { days, type } = getExDateDetail(exDate);
  const roundedDays = Math.abs(getExDateDetail(exDate).days);

  // console.log('rr ', getExDateDetail(exDate));

  const message =
    type === EX_DATE_EXPIRED
      ? `Il dominio è scaduto da ${roundedDays.toString()} giorni`
      : `Il dominio scadrà tra ${roundedDays.toString()} giorni`;

  return (
    <Tooltip title={message}>
      <ExpireDate date={date} days={days} type={type} />
    </Tooltip>
  );
};

export default ExpireDateContainer;
