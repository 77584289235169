import { Card } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import { IViewerContentType } from '../Type/IViewerContentType';

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ListItem = styled(Card)`
  width: 100% !important;
  margin-top: 20px !important;
`;

const ListView: React.FC<{content:IViewerContentType[]}> = ({ content }) => {
  return (
    <React.Fragment>
      <ListContainer>
        {content.map((data: IViewerContentType, index: number) => (
          <ListItem key={index} title={data.label}>
            {data.component}
          </ListItem>
        ))}
      </ListContainer>
    </React.Fragment>
  );
};
export default ListView;
