import React from 'react';
import { Div, Flex, VerticalLayout } from '@nic/ui-comps';
import {
  IGovAdministrationAddress,
  IGovOrganizationUnitData,
} from './Gov.types';

interface IOrganizationUnitViewer {
  organizationUnit: IGovOrganizationUnitData;
  direction: 'row' | 'column';
}

const organizationUnitStyle: React.CSSProperties = {
  border: '1px solid',
  borderRadius: '5px',
  padding: '20px',
  gap: 10,
};

function address(_address: IGovAdministrationAddress) {
  return (
    <Flex direction={'row'} justify={'flex-start'} style={{ gap: 5 }}>
      <Div>{_address.indirizzo}</Div>
      <Div>{_address.cap}</Div>
      <Div>{_address.comune}</Div>
      <Div>{_address.provincia}</Div>
    </Flex>
  );
}

function field(label: string, value: React.ReactNode) {
  return (
    <Flex direction={'column'} align={'flex-start'}>
      <Div fontSize={'0.9rem'} fontWeight={'700'}>{`${label}:`}</Div>
      <Div fontSize={'0.9rem'}>{value}</Div>
    </Flex>
  );
}

function full(organizationUnit: IGovOrganizationUnitData) {
  return (
    <VerticalLayout className={'info-box'} style={organizationUnitStyle}>
      {field('Codice IPA', organizationUnit.codiceIpa)}
      {field('Denominazione', organizationUnit.denominazione)}
      {field('Indirizzo', address(organizationUnit.address))}
      {field('Codice Fiscale', organizationUnit.codiceFiscale)}
    </VerticalLayout>
  );
}

const OrganizationUnitViewer: React.FC<IOrganizationUnitViewer> = props => {
  const { organizationUnit } = props;
  console.log(props);
  return full(organizationUnit);
};

export default OrganizationUnitViewer;
